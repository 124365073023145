import { API } from '@/const';
import request from '@/utils/request';

export const postGoodsInfoList = async (data: any) =>
  request(`${API.business}/businessGoodsField/page`, {
    method: 'post',
    data,
  });

export const deleteGoodsInfoById = async (id: number) =>
  request(`${API.business}/businessGoodsField/deleteById/${id}`, {
    method: 'delete',
  });

export const postGoodsInfoAdd = async (data: any) =>
  request(`${API.business}/businessGoodsField/save`, {
    method: 'post',
    data,
  });

export const putGoodsInfoEdit = async (data: any) =>
  request(`${API.business}/businessGoodsField/updateById`, {
    method: 'put',
    data,
  });

export const postGoodsFiledTypeList = async (data: any) =>
  request(`${API.business}/businessGoodsFieldType/list`, {
    method: 'post',
    data,
  });

export const getGoodsEnableInfo = async (data: any) =>
  request(`${API.basis}/basisConfig/getOne`, {
    method: 'post',
    data,
  });

export const postGoodsEnableInfoEdit = async (data: any) =>
  request(`${API.basis}/basisConfig/updateById`, {
    method: 'put',
    data,
  });
