import { DEFAULT_ID, API } from '@/const';
import { getLocaleStorage } from '@/utils/storage';
import request from '@/utils/request';

export async function getFakeCaptcha(phone?: string) {
  return request(`${API.auth}/sms/send/${phone}`, {
    method: 'POST'
  });
}

export async function postLogin({ phone, principal, password }: any) {
  const tenant_id = getLocaleStorage('TENANTID') || DEFAULT_ID.TENANT_ID;
  const client_id = getLocaleStorage('APPID') || DEFAULT_ID.APPID;
  let url = `${API.auth}/oauth/${password ? 'token' : 'login'}`;
  url = `${url}?grant_type=${password ? 'password' : 'mobile'}`;
  url = `${url}&tenant_id=${tenant_id}&client_id=${client_id}`;
  url = password ? `${url}&client_secret=123456&username=${phone}&password=${password}` : `${url}&phone=${phone}&principal=${principal}`;
  return request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export async function getUserInfo() {
  return request(`${API.upms}/sysAccount/getAccount`, { method: 'GET' });
}

export async function getRoleList() {
  return request(`${API.upms}/sysUserRelation/getRelationList`, { method: 'GET' });
}

export async function getTenantList() {
  return request(`${API.upms}/sysTenant/getTenantList`, { method: 'GET' });
}
export async function postLogout() {
  return request(`${API.auth}/oauth/logout`, {
    method: 'POST'
  });
}
