import { API } from '@/const';
import request from '@/utils/request';

export async function getDataList(data: any) {
  return request(`${API.wallet}/walletWithdrawal/page`, {
    method: 'post',
    data
  });
}

export const updateByIds = async (data: any) => {
  return request(`${API.wallet}/walletWithdrawal/updateByIds`, {
    method: 'put',
    data
  });
};

export const WithdrawalList = async (data: any) => {
  return request(`${API.wallet}/walletPayment/records`, {
    method: 'post',
    data
  });
};
export const walletConvergeWithdrawalList = async (data: any) => {
  return request(`${API.wallet}/walletConvergeWithdrawal/page`, {
    method: 'post',
    data
  });
};

export const walletBankcardSave = async (data: any) => {
  return request(`${API.wallet}/walletBankcard/save`, {
    method: 'post',
    data
  });
};


export const walletBankcardSaveUpdate = async (data: any) => {
  return request(`${API.wallet}/walletBankcard/modify`, {
    method: 'post',
    data
  });
};


export const walletBankcardPage = async (data: any) => {
  return request(`${API.wallet}/walletBankcard/page`, {
    method: 'post',
    data
  });
};

export const walletWithdrawalSave = async (data: any) => {
  return request(`${API.wallet}/walletWithdrawal/save`, {
    method: 'post',
    data
  });
};
export const walletConvergeWithdrawalSave = async (data: any) => {
  return request(`${API.wallet}/walletConvergeWithdrawal/save`, {
    method: 'post',
    data
  });
};
export const basisTysjBankList = async (data: any) => {
  return request(`${API.auth}/basisTysjBank/list`, {
    method: 'post',
    data
  });
};

export const walletWithdrawalUpdateById = async (data: any) => {
  return request(`${API.wallet}/walletWithdrawal/updateById`, {
    method: 'put',
    data
  });
};

export const walletConvergeWithdrawalUpdateById = async (data: any) => {
  return request(`${API.wallet}/walletConvergeWithdrawal/updateById`, {
    method: 'put',
    data
  });
};
