import { CustomImport, DriverImport, VehicleImport, WaybillImport } from '@/const/import';
import { getFreightInfo, getInfo } from '@/services/WorkBench/api';
const WorkBench = {
  state: {
    addMenu: [
      { ...CustomImport },
      { ...DriverImport },
      { ...VehicleImport },
      { ...WaybillImport },
      {
        text: '新增客户',
        img: `${BASE_IMG}47a6494c-1419-4e5b-9335-fdba324b23b6.png`,
        role: 'CUSTOM:FORMALCUSTOMERS:ADD',
        path: '/workBench/CustomerManagement/FormalCustomers',
        link: '/workBench/CustomerManagement/FormalCustomers/AddFormalCustomers/add/1',
        type: 'add'
      },
      {
        text: '新增车辆',
        img: `${BASE_IMG}5d685583-8993-423e-9e0a-13637d3f7db4.png`,
        role: 'VEHICLEMANAGMENT:VEHICLELIST:ADDVEHICLE',
        path: '/workBench/VehicleManagment/VehicleList',
        link: '/workBench/VehicleManagment/VehicleList/VehicleListAdd/1',
        type: 'add'
      },
      {
        text: '新增司机',
        img: `${BASE_IMG}41326612-af86-4fc2-8be5-7b3e57edd90f.png`,
        role: 'DRIVERMANAGEMENT:DRIVERLIST:ADD',
        path: '/workBench/DriverManagement/DriverList',
        link: '/workBench/DriverManagement/DriverList/DriverListAdd',
        type: 'add'
      },
      {
        text: '订单发布',
        img: `${BASE_IMG}f1d5d299-e93a-443b-b5ff-65afad374c44.png`,
        path: '/workBench/WaybillManage/OrderManagement',
        role: 'WAYBILLMANAGE:ORDERMANAFGEMENT:ADD',
        link: '/workBench/WaybillManage/OrderManagement/OrderManagementAdd',
        type: 'add'
      },
      {
        text: '立即下单',
        img: `${BASE_IMG}f1d5d299-e93a-443b-b5ff-65afad374c44.png`,
        path: ['/workBench/WaybillManage/WaybillList', '/workBench/WaybillManage/WaybillTemplateList'],
        role: 'WAYBILLMANAGE:WAYBILLLIST:PLACEANORDERNOW',
        link: '/workBench/WaybillManage/WaybillList/WaybillAddOrder',
        type: 'add'
      }
    ]
  },
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async getFreightInfo() {
      return await getFreightInfo();
    },
    async getInfo() {
      return await getInfo();
    }
  }
};

export default WorkBench;
