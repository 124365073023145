import moment from 'moment';
import { postVehicleInfoAdd, putVehicleInfoEdit, postVehicleOne, repairVehicleNo } from '@/services/VehicleList/api';
const covertData = (e: any) => {
  const data = { ...e };
  data.transportLicenceBegin = data?.transportLicenceRange?.[0];
  data.transportLicenceEnd = data?.transportLicenceRange?.[1];
  const arr = [
    'releaseDate',
    'transportLicenceStart',
    'transportBusinessEnd',
    'registeredDate',
    'purchaseDate',
    'forceExpiredDate',
    'registrationDate',
    'validityDate',
    'transportLicenceBegin',
    'transportLicenceEnd'
  ];
  for (const key in data) {
    const element = data[key];
    if (arr.includes(key)) {
      data[key] = element ? moment(element).format('YYYY-MM-DD 00:00:00') : '';
    }
  }
  data.linkList = (data.linkList || []).map((item: any) => ({ vehicleId: item.vehicleId }));
  return data;
};
const VehicleListEdit = {
  state: {},
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async postVehicleInfoAdd(payload: any) {
      const { data } = payload;
      return await postVehicleInfoAdd({ data: covertData(data) });
    },
    async putVehicleInfoEdit(payload: any) {
      const { data } = payload;
      return await putVehicleInfoEdit({ data: covertData(data) });
    },
    async postVehicleOne(payload: any) {
      const { data } = payload;
      return await postVehicleOne({ data });
    },
    async repairVehicleNo(payload: any) {
      const { data } = payload;
      return await repairVehicleNo({ data });
    }
  }
};
export default VehicleListEdit;
