import { API } from '@/const';
import request from '@/utils/request';



export async function getDataList(data: any) {
  return request(`${API.wallet}/walletExpense/page`, {
    method: "post",
    data
  })
}

