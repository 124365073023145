import { postAddressInfoAdd, putAddressInfoEdit, putAddressInfoUpdata } from '@/services/AddressList/api';
const covertData = (e: any) => {
  const data = JSON.parse(JSON.stringify(e));
  const location = data.location?.split?.(',') || [];
  return {
    label: data?.label,
    administrativeAreaCode: data.administrativeArea.join(','),
    administrativeArea: data.administrativeAreaText,
    contactList: data?.contactList || {},
    longitude: location?.[0],
    latitude: location?.[1],
    details: data?.details,
    type: data?.type?.join(','),
    tenantId: data?.tenantId,
    receiptCustomName: data?.receiptCustomName,
    customUscc: data?.customUscc
  };
};

const AddressListEdit = {
  state: {},
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async postAddressInfoAdd(payload: any) {
      const { data } = payload;
      return await postAddressInfoAdd({ data: covertData(data) });
    },
    async putAddressInfoEdit(payload: any) {
      const { data } = payload;
      return await putAddressInfoEdit({ data: { ...covertData(data), addressId: data.addressId } });
    },
    async putAddressInfoUpdata(payload: any) {
      const { data } = payload;
      return await putAddressInfoUpdata({ data });
    }
  }
};

export default AddressListEdit;
