import { getSessionStorage } from './storage';
import editRouteList from '@/routes/childRoutes/common/edit';
const API_ENV = process.env.API_ENV;
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { DEFAULT_ID, SYSYTYPE } from '@/const';
import { getLocaleStorage } from '@/utils/storage';
import country from '@/const/country';
export function getItemProps(schems: object, item: object) {
  const data = {};
  const itemKeys = Object.keys(item);
  const schemsKeys = Object.keys(schems);
  for (const s of schemsKeys) {
    if (item[s] === undefined) {
      data[s] = schems[s];
    }
  }
  for (const i of itemKeys) {
    if (schems[i] === undefined) {
      data[i] = item[i];
    }

    if (item[i] !== undefined && typeof item[i] !== 'object') {
      data[i] = item[i];
    }
    if (typeof schems[i] === 'object') {
      if (schems[i] instanceof Array) {
        data[i] = [...schems[i], ...item[i]];
      } else {
        data[i] = { ...schems[i], ...item[i] };
      }
    }
  }
  return data;
}
export const changePhoneType = (value: string) => {
  if (value) {
    const tells = value;
    const tellPatern = /(\d{3})\d*(\d{4})/;
    const phone = tells.replace(tellPatern, '$1****$2');
    return phone;
  } else {
    return '';
  }
};

export const getIsAdmin = () => {
  return getLocaleStorage('APPID') === getLocaleStorage(`TENANTID`);
};
export function getHeaders() {
  const token = getLocaleStorage('AILIEYUN_ACCESS_TOKEN');
  const ROLE_DATA = getLocaleStorage('ROLE_DATA') || '{}';
  let Domain = window.location.hostname;
  if (window.location.hostname === 'localhost') {
    Domain = API_ENV === 'lyt' || API_ENV === 'test2' ? 'lyt.lieyuntong.cn' : `${API_ENV}.ailieyun.com`;
  }
  return {
    'Content-Type': 'application/json',
    Authorization: `${token}`,
    dept_id: JSON.parse(ROLE_DATA)?.deptId || '',
    role_id: JSON.parse(ROLE_DATA)?.roleId || '',
    app_id: getLocaleStorage('APPID') || DEFAULT_ID?.APPID,
    tenant_id: getLocaleStorage(`TENANTID`) || DEFAULT_ID?.TENANT_ID,
    Domain
  };
}

export const keySearchObj = (arr: Object[] = [], key: any, value: any): any => {
  const keysValue = arr?.map((item: any) => {
    return item[key];
  });
  const keyIndex = keysValue?.indexOf(value);
  return arr[keyIndex] || {};
};
export const DeepKeySearchObjDel: any = (arr: any = [], children: any, key: any, value: any, keyArr: any = []) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]?.[key] === value) {
      keyArr.push(arr[i]);
      arr.splice(i, 1);
      i--;
      continue;
    }
    if (arr[i]?.[children]?.length) {
      DeepKeySearchObjDel(arr[i][children], children, key, value, keyArr);
    }
  }
  return {
    arr,
    keyArr
  };
};
export const keySearchObjDel = (arr: any = [], key: any, value: any): any => {
  const arrNew = cloneDeep(arr);
  const keysValue = arr?.map((item: any) => {
    return item[key];
  });
  const keyIndex = keysValue?.indexOf(value);
  if (keyIndex >= 0) {
    arrNew.splice(keyIndex, 1);
  }
  return arrNew;
};
export const getKeySearchObj = (arr: any = [], key: any, value: any): any => {
  const arrNew = cloneDeep(arr);
  const keysValue = arr?.map((item: any) => {
    return item[key];
  });
  const keyIndex = keysValue?.indexOf(value);
  if (keyIndex >= 0) {
    return arrNew[keyIndex];
  }
  return null;
};
export const getUrlParam = (paraName: string) => {
  let url = document.location.toString();
  let arrObj = url.split('?');
  if (arrObj.length > 1) {
    let arrPara = arrObj[1].split('&');
    let arr;
    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split('=');
      if (arr != null && arr[0] === paraName) {
        return arr[1];
      }
    }
    return '';
  }
  return '';
};

export const TreeToArray = (tree: any, children: string, item?: string) => {
  const arr: any = [];
  const expanded = (datas: any) => {
    if (datas && datas.length > 0) {
      datas.forEach((e: any) => {
        item ? arr.push(e[item]) : arr.push(e);
        expanded(e[children]);
      });
    }
  };
  expanded(tree);
  return [...new Set(arr)];
};

export const TreeNav = (data: any[], pos: string, children: string, title: string, del?: boolean) => {
  const posNumber = pos.split('-').map(item => {
    return Number(item);
  });

  if (del) {
    posNumber.splice(0, 1);
  }
  let dataSource = data;
  let titleArr = [];
  for (const i of posNumber) {
    titleArr.push(dataSource[i]?.[title]);
    dataSource = dataSource[i]?.[children] || [];
  }
  return titleArr;
};

export const covertDateRange = (start: string, end: string) => {
  if (start && end) {
    return start?.split?.(' ')?.[0] + ' 至 ' + end?.split?.(' ')?.[0];
  }
  return '-';
};

export const checkRepeat = (arr: Object[], key: any) => {
  const keys = arr.map((item: Object) => item[key]);
  const setKeys = new Set(keys);
  return setKeys.size < keys.length;
};

export const downFile = (content: any, fileName: any, type: any = 'application/excel') => {
  const blob = new Blob([content], { type });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(link.href);
  document.body.removeChild(link);
};

export const CustomColumnsData = (arr: any[], exportKey?: any) => {
  let Groups = arr.map(item => {
    return item.group;
  });
  let values = [];
  if (exportKey) {
    values = arr
      .filter(item => item.exportKey)
      .map(item => {
        return typeof item.exportKey === 'string' ? item.exportKey : item.exportKey.join(',');
      });
  } else {
    values = arr.map(item => {
      return typeof item.dataIndex === 'string' ? item.dataIndex : item.dataIndex.join(',');
    });
  }
  Groups = [...new Set(Groups)];
  let plainOptions = [];
  for (let i of Groups) {
    const item: any = {
      groupTitle: i,
      data: []
    };
    arr.forEach(j => {
      if (j.group === i) {
        if (exportKey && j.exportKey) {
          item.data.push({
            label: j.title,
            value: typeof j.exportKey === 'string' ? j.exportKey : j.exportKey.join(',')
          });
        }

        if (!exportKey) {
          item.data.push({
            label: j.title,
            value: typeof j.dataIndex === 'string' ? j.dataIndex : j.dataIndex.join(',')
          });
        }
      }
    });
    plainOptions.push(item);
  }
  return {
    options: plainOptions,
    length: arr.length,
    values
  };
};

export const ExportkeyValue = (checkedList: any, columns: any) => {
  const data = {};
  for (const i of checkedList) {
    const item = keySearchObj(columns, 'dataIndex', i);
    data[i] = item.title;
  }
  return data;
};
function toThousands(num: string) {
  let result = '';
  let counter = 0;
  for (let i = num.length - 1; i >= 0; i -= 1) {
    counter += 1;
    result = num.charAt(i) + result;
    if (!(counter % 3) && i !== 0) {
      result = `,${result}`;
    }
  }
  return result;
}
export const moneyHandle = (num: number | string, type?: string) => {
  let newNum = '';
  if (num || num === 0) {
    if (typeof num === 'string') {
      newNum = num;
    } else {
      newNum += num;
    }
  }

  if (newNum.indexOf('.') === -1) {
    newNum += '.00';
  }

  if (newNum.length - newNum.indexOf('.') === 2) {
    newNum += '0';
  }

  if (newNum.length - newNum.indexOf('.') > 3) {
    newNum = newNum.slice(0, newNum.indexOf('.') + 3);
  }

  const numFoot = newNum.split('.');
  const numHead = toThousands(numFoot[0]);
  return `${type || ''}${numHead}.${numFoot[1]}`;
};
export function cleanObjectEmpty(object: any) {
  Object.entries(object).forEach(([k, v]: any) => {
    if (v && typeof v === 'object') {
      if (Object.prototype.toString.call(v) === '[object Array]') {
        if (!v.length) {
          delete object[k];
        }
      }
      if (Object.prototype.toString.call(v) === '[object Object]') {
        if (!Object.keys(v).length) {
          delete object[k];
        }
      }
      cleanObjectEmpty(v);
    }
    if (!v && v !== 0 && v !== false) {
      delete object[k];
    }
  });
  return object;
}

export const getAddressAreaInfo = (code: string) => {
  let obj: any = {};
  try {
    country.forEach((i: any) => {
      if (i.adcode === code) {
        obj = {
          value: [i.adcode],
          label: i.name
        };
        throw Error('地区获取成功，退出循环');
      }
      i?.districts?.forEach?.((j: any) => {
        if (j.adcode === code) {
          obj = {
            value: [i.adcode, j.adcode],
            label: i.name + j.name
          };
          throw Error('地区获取成功，退出循环');
        }
        j?.districts?.forEach?.((k: any) => {
          if (k.adcode === code) {
            obj = {
              value: [i.adcode, j.adcode, k.adcode],
              label: i.name + j.name + k.name
            };
            throw Error('地区获取成功，退出循环');
          }
        });
      });
    });
  } catch (error) {
    console.log(error);
  }
  return obj;
};
export const formatDataHandle = (date: any) => {
  if (date) {
    let e: any = date.includes('年') ? date.replace('年', '-') : date;
    e = date.includes('月') ? e.replace('月', '-') : e;
    e = date.includes('日') ? e.replace('日', '') : e;
    e = date.includes('长期') ? moment('2999-12-31').format('YYYY-MM-DD') : e;
    if (e.includes('T')) {
      e = moment(e).format('YYYY-MM-DD HH:mm:ss');
    }
    if (e.includes(' 00:00:00')) {
      e = e.split(' 00:00:00')[0];
    }
    e = e.replace(/[^0-9-]/g, '');
    return moment(e).format('YYYY-MM-DD') !== 'Invalid date' ? moment(moment(e).format('YYYY-MM-DD'), 'YYYY-MM-DD') : undefined;
  } else {
    return undefined;
  }
};
export const coverTextArrHandle = (arr: any) => {
  return arr
    .filter((item: any) => item.value || item.value === 0)
    .map((item: any) => `${item.name} ${item.value} ${item.unit}`)
    .join('；');
};

export const getButtonRole = (auth: any) => {
  if (!auth || getLocaleStorage('SYSTYPE') === SYSYTYPE.CUSTOMER) {
    return true;
  }
  let bool = false;
  const permission = JSON.parse(getSessionStorage('BUTTON_ROLE') || '[]');
  const keysValue = permission?.map((item: any) => {
    return item['code'];
  });
  if (keysValue.includes(auth)) {
    bool = true;
  }
  return bool;
};
export const pathIsRole = (pathname = window.location.pathname) => {
  const ROUTES_ALL = JSON.parse(getSessionStorage('ROUTES_ALL') || '[]');
  const ROUTES_ROLE = JSON.parse(getSessionStorage('ROUTES_ROLE') || '[]');
  if (ROUTES_ALL.includes(pathname)) {
    if (ROUTES_ROLE.includes(pathname)) {
      return 1;
    } else {
      return 0;
    }
  } else {
    const edit = editRouteList.map((item: any) => item.path);
    const editMin = edit.filter((i: any) => i.includes('/:')).map(i => i.split('/:')[0]);
    const except = ['/workBench/Wallet/CorporateAccountNo'];
    const flag = editMin.find((i: any) => pathname.includes(i));
    if (!except.includes(pathname) && !edit.includes(pathname) && !flag) {
      return 404;
    } else {
      return 1;
    }
  }
};

export const ChangeImgUrl = (url: string) => {
  return (url || '').indexOf('http') === 0 ? url : BASE_IMG + url;
};

export const isVideo = (path: string) => {
  const suffix: any = path?.split('.')?.pop();
  return suffix == 'mp4';
};
export * from './address';
export * from './waybill';
