import { API } from '@/const';
import request from '@/utils/request';

export async function etcPush(data: any) {
  return request(`${API.business}/businessFreightEtc/etcPush`, {
    method: 'post',
    data
  });
}

export const etcEnd = async (data: any) => {
  return request(`${API.business}/businessFreightEtc/etcEnd`, {
    method: 'post',
    data
  });
};
export const gainInvoice = async (data: any) => {
  return request(`${API.business}/businessFreightEtcInvoice/gainInvoice`, {
    method: 'post',
    data
  });
};
