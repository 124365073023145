import { API } from '@/const';
import request from '@/utils/request';

export async function save({ data }: any) {
  return request(`${API.insurance}/insuranceClaimSettlement/save`, {
    method: 'post',
    data: {
      data
    }
  });
}

export async function modify({ data }: any) {
  return request(`${API.insurance}/insuranceClaimSettlement/updateById`, {
    method: 'put',
    data: {
      data
    }
  });
}
export async function modifyConsultationComplaint({ data }: any) {
  return request(`${API.insurance}/insuranceConsult/reply`, {
    method: 'post',
    data: {
      data
    }
  });
}
export async function businessCreditRatingSave({ data }: any) {
  return request(`${API.business}/businessCreditRating/save`, {
    method: 'post',
    data: {
      data
    }
  });
}
