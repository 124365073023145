export const API_ENV: string = process.env.API_ENV || 'dev';

export const MOBILELINK = {
  dev: 'https://dev-mobile.ailieyun.com/',
  dev2: 'https://dev2-mobile.ailieyun.com/',
  test: 'https://test-mobile.ailieyun.com/',
  test2: 'https://test2-mobile.ailieyun.com/',
  pre: 'https://pre-mobile.ailieyun.com/',
  lyt: 'https://mobile.lieyuntong.cn/'
}[API_ENV];
