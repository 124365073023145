import LyLog from "lkfe_log"

export const ApiErrorRobot = (res: any) => {
  let { userName, userPhone } = getUserInfo()
  LyLog.ApiErrorRobot({
    title: "猎运通项目报警",
    message: {
      userName,
      userPhone,
      response: res
    }
  })
}

export const CustomErrorRobot = (res: any) => {
  let { userName, userPhone } = getUserInfo()
  LyLog.CustomRobot({
    title: "猎运通项目报警",
    message: {
      userName,
      userPhone,
      data: res
    }
  })
}

const getUserInfo = () => {
  let userName = ""
  let userPhone = ""
  let CURRENTUSER: any = localStorage.getItem("CURRENTUSER") || "{}"
  try {
    CURRENTUSER = CURRENTUSER ? JSON.parse(CURRENTUSER || "{}") : null
    userName = CURRENTUSER?.name
    userPhone = CURRENTUSER?.phone
  } catch (e) { }
  return { userName, userPhone }
}
