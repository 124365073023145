import { deleteTree, postAddTree, postEidtTree, postGetTree } from '@/services/CustomerType';
const CustomerType = {
  state: {
    enterprise: [],
    personal: [],
    personalEnabled: 0,
    enterpriseEnabled: 0,
    personalId: false,
    enterpriseId: false,
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    },
  },
  effects: {
    async postGetTreeList({}: any, {}: any){
      const res = await postGetTree();
      return res;
    },

    async postAddTreeFun({ pid, name }: any, {}: any){
      const res = await postAddTree({
        pid,
        name,
      });
      return res;
    },
    async postEditTreeFun({ typeId, name, enabled }: any, {}: any){
      const res = await postEidtTree({
        typeId,
        name,
        enabled,
      });
      return res;
    },

    async deleteTreeFun({ id }: any){
      const res = await deleteTree(id);
      return res;
    },
  },
};

export default CustomerType;
