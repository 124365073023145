import { API } from '@/const';
import request from '@/utils/request';


export async function dataSave({ data }: any) {
  return request(`${API.wallet}/walletRecord/save`, {
    method: 'post',
    data: {
      data,
    },
  });
}

export async function getDataList(data: any) {
  return request(`${API.wallet}/walletRecord/page`, {
    method: "post",
    data
  })
}


export async function walletFreezePage(data: any) {
  return request(`${API.wallet}/walletFreeze/page`, {
    method: "post",
    data
  })
}

export async function dataModify(data: any) {
  return request(`${API.wallet}/walletFreeze/updateById`, {
    method: 'PUT',
    data: { data },
  });
}
