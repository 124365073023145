import { API } from '@/const';
import request from '@/utils/request';

export async function getDataList(data: any) {
  return request(`${API.wallet}/walletCenter/page`, {
    method: "post",
    data
  })
}

export async function walletCenterSave({ data }: any) {
  return request(`${API.wallet}/walletCenter/save`, {
    method: 'post',
    data: {
      data,
    },
  });
}

export async function walletCenterModify({ data }: any) {
  return request(`${API.wallet}/walletCenter/updateById`, {
    method: 'put',
    data: {
      data,
    },
  });
}

export async function walletCenterDeleteById(id: any) {
  return request(`${API.wallet}/walletCenter/deleteById/${id}`, {
    method: 'delete',
  });
}