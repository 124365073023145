import {
  postGoodsInfoList,
  deleteGoodsInfoById,
  postGoodsInfoAdd,
  putGoodsInfoEdit,
  postGoodsFiledTypeList,
  getGoodsEnableInfo,
  postGoodsEnableInfoEdit
} from '@/services/SupplementaryInformation/api';
const SupplementaryInformation = {
  namespace: 'SupplementaryInformation',
  state: {
    params: {},
    list: [],
    total: 0,
    typeMap: {},
    typeList: []
  },
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async postGoodsInfoList(payload: any) {
      const { data } = payload;
      const res: any = await postGoodsInfoList(data);
      const { result } = this as any;
      result({
        list: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      });
    },
    async postGoodsFiledTypeList() {
      const res: any = await postGoodsFiledTypeList({
        data: {}
      });
      const { result } = this as any;
      const _map = {};
      const _typeList = (res.data || []).map((item: any) => {
        _map[item.fieldTypeId] = item.typeDesc;
        return item.fieldTypeId;
      });
      result({
        typeMap: _map,
        typeList: _typeList
      });
    },
    async deleteGoodsInfoById(payload: any) {
      const { id } = payload;
      return await deleteGoodsInfoById(id);
    },
    async postGoodsInfoAdd(payload: any) {
      const { data } = payload;
      return await postGoodsInfoAdd(data);
    },
    async putGoodsInfoEdit(payload: any) {
      const { data } = payload;
      return await putGoodsInfoEdit(data);
    },
    async getGoodsEnableInfo(payload: any) {
      const { data } = payload;
      const res: any = await getGoodsEnableInfo(data);
      return res?.data || {};
    },
    async postGoodsEnableInfoEdit(payload: any) {
      const { data } = payload;
      return await postGoodsEnableInfoEdit(data);
    }
  }
};
export default SupplementaryInformation;
