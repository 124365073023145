import { API } from '@/const';
import request from '@/utils/request';

export async function sysResourceSave({ data }: any) {
  return request(`${API.upms}/sysResource/save`, {
    method: 'post',
    data: {
      data,
    },
  });
}

export async function sysResourceModify({ data }: any) {
  return request(`${API.upms}/sysResource/updateById`, {
    method: 'put',
    data: {
      data,
    },
  });
}

export async function sysResourcedeleteById(id: any) {
  return request(`${API.upms}/sysResource/deleteById/${id}`, {
    method: 'delete',
  });
}

export async function sysResourceDeleteByIds(data: any) {
  return request(`${API.upms}/sysResource/deleteByIds`, {
    method: 'delete',
    data: data,
  });
}


export async function sysResourceList(data: any) {
  return request(`${API.upms}/sysResource/list`, {
    method: 'post',
    data: {
      data
    }
  })
}


export async function updateBatch(data: any) {
  return request(`${API.upms}/sysResource/updateBatch`, {
    method: 'put',
    data
  });
}