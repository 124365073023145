import { walletCenterSave, walletCenterModify, getDataList, walletCenterDeleteById } from '@/services/Wallet/PayeeManagement';

const PayeeManagement = {
  state: {
    expandedKeys: ['0'],
    editMenuData: {},
    loading: false,
    dataSource: [],
    total: 0
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getDataList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      });
      try {
        const res: any = await getDataList(data);
        await result({
          loading: false,
          dataSource: res?.data?.content || [],
          total: res?.data?.totalElements || 0
        });
        return res;
      } catch (error) {
        result({
          loading: false
        });
        return {};
      }
    },
    async walletCenterSave({ data }: any) {
      const res: any = await walletCenterSave({ data });
      return res;
    },
    async walletCenterModify({ data }: any) {
      const res: any = await walletCenterModify({ data });
      return res;
    },
    async walletCenterDeleteById({ id }: any) {
      const res: any = await walletCenterDeleteById(id);
      return res;
    }
  }
};

export default PayeeManagement;
