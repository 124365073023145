import { postAddInfo, putEditInfo, putModifyInfo, getInfoById, postApproveInfo, deleteInfoById } from '@/services/OrderManagement/api';
import { postDriverInfoList } from '@/services/DriverManagement/api';
import { postVehicleInfoList } from '@/services/VehicleList/api';
const OrderManagement = {
  namespace: 'OrderManagement',
  state: {
    total: 0,
    list: []
  },
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async postAddInfo(payload: any) {
      const { data } = payload;
      return await postAddInfo({ data });
    },
    async putEditInfo(payload: any) {
      const { data } = payload;
      return await putEditInfo({ data });
    },
    async putModifyInfo(payload: any) {
      const { data } = payload;
      return await putModifyInfo({ data });
    },
    async getInfoById(payload: any) {
      const { id } = payload;
      return await getInfoById(id);
    },
    async postApproveInfo(payload: any) {
      const { data } = payload;
      return await postApproveInfo({ data });
    },
    async deleteInfoById(payload: any) {
      const { id } = payload;
      return await deleteInfoById(id);
    },
    async postDriverInfoList(payload: any) {
      const { name, isLeader } = payload;
      const obj: any = {
        name
      };
      if (isLeader) {
        obj.isLeader = isLeader;
      }
      const res: any = await postDriverInfoList({
        data: obj
      });
      const { data } = res || {};
      return data || [];
    },
    async postVehicleInfoList(payload: any) {
      const { name } = payload;
      const res: any = await postVehicleInfoList({
        data: {
          vehicleNo: name,
          vehicleType: '0'
        }
      });
      const { data } = res || {};
      return data || [];
    }
  }
};

export default OrderManagement;
