import { API } from '@/const';
import request from '@/utils/request';

export async function getList({ pid }: any) {
  return request(`${API.custom}/customType/getList`, {
    method: 'post',
    data: {
      data: {
        pid
      }
    }
  });
}

export async function customExpSave(data: any) {
  return request(`${API.custom}/customExp/save`, {
    method: `post`,
    data: {
      data
    }
  });
}

export async function basisTycCorpList(name: any, url: string) {
  return request(url, {
    method: `post`,
    data: {
      data: { name }
    }
  });
}

export async function customExpModify(params: any) {
  return request(`${API.custom}/customExp/modify`, {
    method: `put`,
    data: {
      ...params
    }
  });
}

export async function customConvert(data: any) {
  return request(`${API.custom}/custom/convert`, {
    method: `post`,
    data: {
      data
    }
  });
}
