import { getDataList, dataSave, updateByIds, dataDeleteById } from "@/services/Wallet/api"
const CorporateAccountNo = {
  state: {
    total: 0,
    TableDataSource: [],
    loading: false
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    },
  },
  effects: {
    async dataSave({ data }: any) {
      return await dataSave({ data })
    },
    async getDataList({ data }: any) {
      const { result } = this as any
      result({
        loading: true
      })
      const res: any = await getDataList(data)
      result({
        loading: false,
        TableDataSource: res?.data?.content,
        total: res?.data?.totalElements
      })
    },
    async dataUpdateById({ data }: any) {
      return await updateByIds(data)
    },
    async dataDeleteById(accountId: any) {
      return await dataDeleteById(accountId)
    },
  },
};

export default CorporateAccountNo;
