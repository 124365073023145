import { API } from '@/const';
import request from '@/utils/request';

export const postAddressInfoList = async (data: any) =>
  request(`${API.business}/businessAddress/page`, {
    method: 'post',
    data
  });

export const deleteAddressInfoById = async (id: string) => {
  const _id = id.includes('-') ? id.split('-')[0] : id;
  const tenant_id = id.includes('-') ? id.split('-')[1] : '';
  return request(`${API.business}/businessAddress/deleteById/${_id}`, {
    method: 'delete',
    tenant_id
  });
};

export const postAddressInfoAdd = async (data: any) =>
  request(`${API.business}/businessAddress/save`, {
    method: 'post',
    data
  });

export const putAddressInfoEdit = async (data: any) =>
  request(`${API.business}/businessAddress/modify`, {
    method: 'put',
    data
  });

export const putAddressInfoUpdata = async (data: any) =>
  request(`${API.business}/businessAddress/updateById`, {
    method: 'put',
    data
  });

export const postAddressList = async (data: any) =>
  request(`${API.business}/businessAddress/list`, {
    method: 'post',
    data
  });
