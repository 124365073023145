import { postBusinessLineInfoAdd, putBusinessLineInfoEdit, putBusinessLineInfoUpdata, postBusinessLineInfoSaveAndGet } from '@/services/DeliveryRouteList/api';
import { postAddressList } from '@/services/AddressList/api';
const DeliveryRouteListEdit = {
  state: {},
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async postBusinessLineInfoAdd(payload: any) {
      const { data } = payload;
      return await postBusinessLineInfoAdd({ data });
    },
    async postBusinessLineInfoSaveAndGet(payload: any) {
      const { data } = payload;
      return await postBusinessLineInfoSaveAndGet({ data });
    },

    async putBusinessLineInfoEdit(payload: any) {
      const { data } = payload;
      return await putBusinessLineInfoEdit({
        data: { ...data, addressId: data.addressId }
      });
    },
    async putBusinessLineInfoUpdata(payload: any) {
      const { data } = payload;
      return await putBusinessLineInfoUpdata({ data });
    },
    async postAddressList(payload: any) {
      const { keyword } = payload;
      const res: any = await postAddressList({
        keyword,
        data: {}
      });
      const { data } = res || {};
      return data;
    }
  }
};

export default DeliveryRouteListEdit;
