import { getFakeCaptchaBank, dataDeleteById, postBasisAccreditMessageSave, postBasisDriverPage } from '@/services/Wallet/ElectronicBanking';
const ElectronicBanking = {
  namespace: 'ElectronicBanking',
  state: {},
  reducers: {},
  effects: {
    async dataDeleteById(payload: any) {
      const { id } = payload;
      return await dataDeleteById(id);
    },
    async getFakeCaptchaBank(payload: any) {
      const { data } = payload;
      return await getFakeCaptchaBank({ data });
    },
    async postBasisAccreditMessageSave(payload: any) {
      const { data } = payload;
      return await postBasisAccreditMessageSave({ data });
    },
    async postBasisDriverPage(payload: any) {
      const { data } = payload;
      return await postBasisDriverPage({ data });
    }
  }
};

export default ElectronicBanking;
