import { API } from '@/const';
import request from '@/utils/request';

export const postDriverInfoSend = async (data: any) =>
  request(`${API.datacentre}/dataDriverinfo/driverInfoSend`, {
    method: 'post',
    data
  });

export const postVehicleInfoSend = async (data: any) =>
  request(`${API.datacentre}/dataVehicleinfo/vehicleInfoSend`, {
    method: 'post',
    data
  });

export const postWayBillSend = async (data: any) =>
  request(`${API.datacentre}/dataWaybill/waybillSend`, {
    method: 'post',
    data
  });

export const postFlowFundsSend = async (data: any) =>
  request(`${API.datacentre}/dataFlowfunds/flowfundsSend`, {
    method: 'post',
    data
  });

export const postSynchronousData = async (data: any) =>
  request(`${API.datacentre}/dataFlowfunds/synchronousData`, {
    method: 'post',
    data
  });

export const postUpdateReport = async (data: any) =>
  request(`${API.datacentre}/dataWaybill/withdrawalCreateSendData`, {
    method: 'post',
    data
  });

export const postSyncVehicleInfo = async (data: any) =>
  request(`${API.datacentre}/dataVehicleinfo/syncVehicleInfo`, {
    method: 'post',
    data
  });

export const postSyncDriverInfo = async (data: any) =>
  request(`${API.datacentre}/dataDriverinfo/syncDriverInfo`, {
    method: 'post',
    data
  });

export const postSynchronousConverge = async (data: any) =>
  request(`${API.datacentre}/dataFlowfunds/synchronousConverge`, {
    method: 'post',
    data
  });
export const postUpdateDriverInfo = async (data: any) =>
  request(`${API.datacentre}/dataDriverinfo/updateById`, {
    method: 'put',
    data
  });

export const postUpdateVehicleInfo = async (data: any) =>
  request(`${API.datacentre}/dataVehicleinfo/updateById`, {
    method: 'put',
    data
  });

export const postUpdateWayBill = async (data: any) =>
  request(`${API.datacentre}/dataWaybill/updateById`, {
    method: 'put',
    data
  });

export const postUpdateFlowFunds = async (data: any) =>
  request(`${API.datacentre}/dataFlowfunds/updateById`, {
    method: 'put',
    data
  });

export const postActualCarrierInfoSend = async (data: any) =>
  request(`${API.datacentre}/dataActualCarrierInfo/reportActual`, {
    method: 'post',
    data
  });
export const postUpdateActualCarrierInfo = async (data: any) =>
  request(`${API.datacentre}/dataActualCarrierInfo/updateById`, {
    method: 'put',
    data
  });
export const reportWaybillFirst = async (data: any) =>
  request(`${API.datacentre}/dataWaybill/reportWaybillFirst`, {
    method: 'post',
    data
  });

export const reportWaybillSecond = async (data: any) =>
  request(`${API.datacentre}/dataWaybill/reportWaybillSecond`, {
    method: 'post',
    data
  });
export const reportWaybillThirdly = async (data: any) =>
  request(`${API.datacentre}/dataWaybill/reportWaybillThirdly`, {
    method: 'post',
    data
  });
export const reporDataFirst = async (data: any) =>
  request(`${API.datacentre}/dataReport/reportFirst`, {
    method: 'post',
    data
  });

export const reporDataSecond = async (data: any) =>
  request(`${API.datacentre}/dataReport/reportSecond`, {
    method: 'post',
    data
  });
export const reporDataThirdly = async (data: any) =>
  request(`${API.datacentre}/dataReport/reportThirdly`, {
    method: 'post',
    data
  });
export const postUpdateDataReport = async (data: any) =>
  request(`${API.datacentre}/dataReport/updateById`, {
    method: 'put',
    data
  });
export const createReportSecond = async (data: any) =>
  request(`${API.datacentre}/dataReport/createReportSecond`, {
    method: 'post',
    data
  });
export const createReportThirdly = async (data: any) =>
  request(`${API.datacentre}/dataReport/createReportThirdly`, {
    method: 'post',
    data
  });

export const updateOwnerStatements = async (data: any) =>
  request(`${API.datacentre}/dataReport/updateOwnerStatements`, {
    method: 'post',
    data
  });
export const reportContract = async (data: any) =>
  request(`${API.datacentre}/dataReport/reportContract`, {
    method: 'post',
    data
  });
export const dataReportRepair = async (data: any) =>
  request(`${API.datacentre}/dataReport/repair`, {
    method: 'post',
    data
  });
export const dataReportStatementSave = async (data: any) =>
  request(`${API.datacentre}/dataReportStatement/save`, {
    method: 'post',
    data
  });
export const putDataInvoiceModify = async (data: any) =>
  request(`${API.datacentre}/dataInvoice/modify`, {
    method: 'put',
    data
  });
export const createReportInvoice = async (data: any) =>
  request(`${API.datacentre}/dataInvoice/reportThirdly`, {
    method: 'post',
    data
  });
export const reportThirdlyIds = async (data: any) =>
  request(`${API.datacentre}/dataReport/reportThirdlyIds`, {
    method: 'post',
    data
  });
export const syncActualCarrierBusinessLicense = async (data: any) =>
  request(`${API.datacentre}/dataActualCarrierInfo/syncActualCarrierBusinessLicense`, {
    method: 'post',
    data
  });
export const dataReportSynCustomSequence = async (data: any) =>
  request(`${API.datacentre}/dataReport/synCustomSequence`, {
    method: 'post',
    data
  });
