
import { API } from '@/const';
import request from '@/utils/request';


export async function postGetTree() {
  return request(`${API.custom}/customType/getTree`, {
    method: 'post',
    data: { data: {} },
  });
}

export async function postAddTree({ pid, name }: any) {
  return request(`${API.custom}/customType/save`, {
    method: 'post',
    data: {
      data: { pid, name },
    },
  });
}


export async function postEidtTree({ typeId, name, enabled }: any) {
  return request(`${API.custom}/customType/modify`, {
    method: 'PUT',
    data: {
      data: { typeId, name, enabled },
    },
  });
}

export async function deleteTree(id: any) {
  return request(`${API.custom}/customType/deleteById/${id}`, {
    method: 'delete',
  });
}
