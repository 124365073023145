import { API } from '@/const';
import request from '@/utils/request';

export const getInfo = async () =>
  request(`${API.business}/businessStatistics/getInfo`, {
    method: 'post',
    data: {
      data: {}
    }
  });
export const getFreightInfo = async () =>
  request(`${API.business}/businessStatistics/getFreightInfo`, {
    method: 'post',
    data: {
      data: {}
    }
  });
