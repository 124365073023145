import { API } from '@/const';
import request from '@/utils/request';

export async function sysAccountSave({ data }: any) {
  return request(`${API.upms}/sysAccount/save`, {
    method: 'post',
    data: {
      data
    }
  });
}

export async function sysAccountModify({ data }: any) {
  return request(`${API.upms}/sysAccount/updateById`, {
    method: 'put',
    data: {
      data
    }
  });
}

export async function sysAccountDeleteById(id: any) {
  return request(`${API.upms}/sysAccount/deleteById/${id}`, {
    method: 'delete'
  });
}
export async function sysUserModify({ data }: any) {
  return request(`${API.upms}/sysUser/updateById`, {
    method: 'put',
    data: {
      data
    }
  });
}
export async function sysIpDeleteById(ip: any) {
  return request(`${API.upms}/sysBlankip/deleteById/${ip}`, {
    method: `delete`
  });
}
export async function sysLoginLogDeleteById(ip: any) {
  return request(`${API.upms}/sysLoginLog/deleteById/${ip}`, {
    method: `delete`
  });
}
export async function sysBlankipSave({ data }: any) {
  return request(`${API.upms}/sysBlankip/save`, {
    method: 'post',
    data: {
      data
    }
  });
}
