import { postVehicleInfoList, deleteVehicleInfoById, postVehicleInfoAdd, putVehicleInfoEdit } from '@/services/VehicleField/api';
const VehicleField = {
  state: {
    params: {},
    list: [],
    total: 0
  },
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async postVehicleInfoList(payload: any) {
      const { data } = payload;
      const res: any = await postVehicleInfoList(data);
      const { content, totalElements } = res?.data || {};
      const { result } = this as any;
      result({
        list: content || [],
        total: totalElements || 0
      });
      return res?.data || {};
    },
    async deleteVehicleInfoById(payload: any) {
      const { id } = payload;
      return await deleteVehicleInfoById(id);
    },
    async postVehicleInfoAdd(payload: any) {
      const { data } = payload;
      return await postVehicleInfoAdd(data);
    },
    async putVehicleInfoEdit(payload: any) {
      const { data } = payload;
      return await putVehicleInfoEdit(data);
    }
  }
};
export default VehicleField;
