import moment from 'moment';
const covertAddressText = (area: string, text: string) => {
  let areaShort = area || '';
  const details = text || '';
  const arr = ['北京城区', '天津城区', '重庆城区', '重庆郊县', '上海城区'];
  arr.forEach(item => {
    if (areaShort.includes(item)) {
      areaShort = areaShort.split(item).join('');
    }
  });
  return details.includes(area) || details.includes(areaShort) ? details : area + details;
};
const covertAdministrativeAreaText = (text: string) => {
  let res = text?.startsWith?.('中国') ? text?.slice?.(2) : text;
  res = res?.startsWith?.('北京市北京市') ? res?.slice?.(3) : res;
  res = res?.startsWith?.('天津市天津市') ? res?.slice?.(3) : res;
  res = res?.startsWith?.('上海市上海市') ? res?.slice?.(3) : res;
  res = res?.startsWith?.('重庆市重庆市') ? res?.slice?.(3) : res;
  return res;
};
const covertAddressData = (item: any) => {
  const textMap = {
    1: '发货',
    2: '收货'
  };
  const { administrativeAreaCode, type, details, longitude, latitude } = item;
  item.administrativeArea = covertAdministrativeAreaText(item.administrativeArea);
  item.administrativeAreaText = item.administrativeArea;
  item.administrativeArea = (administrativeAreaCode || '').split(',');
  const typeArr = (String(type) || '').split(',');
  item.typeArr = typeArr.length ? typeArr : ['1'];
  item.typeText = item.typeArr
    .map((inner: string) => textMap[inner])
    .filter((inner: string) => inner)
    .join(',');
  item.contactList = item.contactList || [];
  item.contactListTextArr = item.contactList.map((innner: any) => `${innner.name}&nbsp;${innner.phone}`);
  item.detailsText = covertAddressText(item.administrativeAreaText, details);
  item.location = longitude && latitude ? `${longitude},${latitude}` : '';
  return item;
};
const covertAddressObj = (item: any, type: string) => {
  item.administrativeArea = covertAdministrativeAreaText(item.administrativeArea);
  item.administrativeAreaText = item.administrativeArea;
  item.administrativeArea = (item.administrativeAreaCode || '').split(',');
  item.detailsText = covertAddressText(item.administrativeAreaText, item.details);
  item.location = item?.longitude && item?.latitude ? `${item?.longitude},${item?.latitude}` : '';
  item.name = item.contactList?.[0]?.name;
  item.phone = item.contactList?.[0]?.phone;
  item.type = type;
  item.typeArr = [type];
  return item;
};
const covertLineData = (item: any) => {
  const { lineId, name, remark, isUsual, createTime, receiptAddressId, sendAddressId, distance } = item;
  const sendAddress = covertAddressObj(item?.sendAddress || {}, '1');
  const receiptAddress = covertAddressObj(item?.receiptAddress || {}, '2');
  const viaList = (item?.viaList || []).map((inner: any) => ({
    ...inner,
    address: covertAddressObj(inner?.address || {}, '3'),
    cargoText: inner.type === '1' ? '卸货' : inner.type === '2' ? '装货' : ''
  }));
  const obj = {
    lineId,
    distance,
    name,
    remark,
    remarkText: remark || '-',
    sendAddress,
    viaList,
    receiptAddress,
    isUsual,
    createTime: createTime ? moment(createTime).format('YYYY-MM-DD HH:mm') : '-',
    receiptAddressId,
    sendAddressId
  };
  return obj;
};
export { covertAddressText, covertLineData, covertAddressData };
