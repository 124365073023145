import { API } from '@/const';
import request from '@/utils/request';

export async function sysRoleSave({ data }: any) {
  return request(`${API.upms}/sysRole/save`, {
    method: 'post',
    data: {
      data
    }
  });
}

export async function sysRoleModify({ data }: any) {
  return request(`${API.upms}/sysRole/updateById`, {
    method: 'put',
    data: {
      data
    }
  });
}

export async function sysRoleDeleteById(id: any) {
  return request(`${API.upms}/sysRole/deleteById/${id}`, {
    method: 'delete'
  });
}


export async function getRoleMenuList(roleId: string) {
  return request(`${API.upms}/sysResourceRole/getResourceIdMap/${roleId}`, {
    method: 'get'
  });
}

export async function getMenuList(data: any) {
  return request(`${API.upms}/sysResource/list`, {
    method: 'post',
    data: {
      data
    }
  });
}

export async function sysResourceRoleBindResource(data: any, roleId: string) {
  return request(`${API.upms}/sysResourceRole/bindResource/${roleId}`, {
    method: 'post',
    data
  });
}

export async function sysbindAllResource(roleId: string) {
  return request(`${API.upms}/sysResourceRole/bindAllResource/${roleId}`, {
    method: 'post'
  });
}

export async function sysdeleteAllResource(roleId: string) {
  return request(`${API.upms}/sysResourceRole/deleteAllResource/${roleId}`, {
    method: 'post'
  });
}
