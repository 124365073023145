import { API } from '@/const';
import request from '@/utils/request';

export const postContractTemplateInfoList = async (data: any) =>
  request(`${API.business}/businessContractTemplate/page`, {
    method: 'post',
    data
  });

export const deleteContractTemplateInfoById = async (id: number) =>
  request(`${API.business}/businessContractTemplate/deleteById/${id}`, {
    method: 'delete'
  });

export const postContractTemplateInfoAdd = async (data: any) =>
  request(`${API.business}/businessContractTemplate/save`, {
    method: 'post',
    data
  });

export const putContractTemplateInfoEdit = async (data: any) =>
  request(`${API.business}/businessContractTemplate/updateById`, {
    method: 'put',
    data
  });

export const postContractInfoAdd = async (data: any) =>
  request(`${API.business}/businessContract/save`, {
    method: 'post',
    data
  });

export const putContractEdit = async (data: any) =>
  request(`${API.business}/businessContract/updateById`, {
    method: 'put',
    data
  });

export const postContractView = async (data: any) =>
  request(`${API.business}/businessContract/view`, {
    method: 'post',
    data
  });

export const postContractInviteInfoList = async (data: any) =>
  request(`${API.business}/businessContractInvite/page`, {
    method: 'post',
    data
  });

export const postContractInviteInfoAdd = async (data: any) =>
  request(`${API.business}/businessContractInvite/save`, {
    method: 'post',
    data
  });

export const putContractInviteInfoEdit = async (data: any) =>
  request(`${API.business}/businessContractInvite/updateById`, {
    method: 'put',
    data
  });

export const deleteContractFreightById = async (id: number) =>
  request(`${API.business}/businessContractFreight/deleteById/${id}`, {
    method: 'delete'
  });

export const postContractFreightAdd = async (data: any) =>
  request(`${API.business}/businessContractFreight/save`, {
    method: 'post',
    data
  });
export const postContractSign = async (data: any) =>
  request(`${API.business}/businessContract/sign`, {
    method: 'put',
    data
  });
export const postContractQuerySign = async (data: any) =>
  request(`${API.business}/businessContract/querySign`, {
    method: 'put',
    data
  });
export const postContractQueryFaceState = async (data: any) =>
  request(`${API.business}/businessContract/queryFaceState`, {
    method: 'post',
    data
  });
