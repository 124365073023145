import {
  getDataList,
  getLevelDataList,
  dataLevelSave,
  updateLevelById,
  DeleteLevelById,
  updateByIds,
  updateById,
  updateWalletAuthenticationById,
  putWalletAuthenticationEdit,
  walletAuthenticationGetById,
  postOcrIdentify
} from '@/services/Wallet/CertificationManagement';
const CertificationManagement = {
  state: {
    activityTab: '0',
    loading: false,
    dataSource: [],
    total: 0,
    requireParameter: {
      current: 1,
      data: {},
      size: 10
    },
    levelLoading: false,
    levelDataSource: [],
    levelTotal: 0,
    levelRequireParameter: {
      current: 1,
      data: {},
      size: 5
    }
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getDataList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      });
      const res: any = await getDataList(data);
      await result({
        loading: false,
        dataSource: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      });
    },
    async updateByIds({ data }: any) {
      return await updateByIds(data);
    },
    async updateById({ data }: any) {
      return await updateById(data);
    },
    async getLevelDataList({ data }: any) {
      const { result } = this as any;
      result({
        levelLoading: true
      });
      const res: any = await getLevelDataList(data);
      await result({
        levelLoading: false,
        levelDataSource: res?.data?.content || [],
        levelTotal: res?.data?.totalElements || 0
      });
    },

    async dataLevelSave({ data }: any) {
      const res = await dataLevelSave(data);
      return res;
    },
    async DeleteLevelById(id: any) {
      return await DeleteLevelById(id);
    },
    async updateLevelById({ data }: any) {
      await updateLevelById(data);
    },
    async updateWalletAuthenticationById({ data }: any) {
      return await updateWalletAuthenticationById(data);
    },
    async putWalletAuthenticationEdit({ data }: any) {
      return await putWalletAuthenticationEdit(data);
    },
    async walletAuthenticationGetById(id: any) {
      return await walletAuthenticationGetById(id);
    },
    async postOcrIdentify({ data }: any) {
      return await postOcrIdentify(data);
    }
  }
};

export default CertificationManagement;
