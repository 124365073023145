import { API } from '@/const';
import request from '@/utils/request';
export async function getFakeCaptchaBank(data?: any) {
  return request(`${API.basis}/basisAccreditMessage/smsSend`, {
    method: 'POST',
    data
  });
}
export const dataDeleteById = async (id: string) => {
  return request(`${API.basis}/basisAccreditMessage/deleteById/${id}`, {
    method: 'delete'
  });
};
export async function postBasisAccreditMessageSave(data?: any) {
  return request(`${API.basis}/basisAccreditMessage/save`, {
    method: 'POST',
    data
  });
}
export async function postBasisDriverPage(data?: any) {
  return request(`${API.basis}/basisAccreditMessage/driverPage`, {
    method: 'POST',
    data
  });
}
