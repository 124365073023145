import { postInfoList, changeInfoById, postInfoAdd } from '@/services/AppManager/api';
const AppManager = {
  namespace: 'AppManager',
  state: {
    params: {},
    list: [],
    total: 0
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async postInfoList(payload: any) {
      const { data } = payload;
      const res: any = await postInfoList(data);
      const { result } = this as any;
      result({
        list: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      });
    },
    async postInfoAdd(payload: any) {
      const { formData } = payload;
      return await postInfoAdd(formData);
    },
    async changeInfoById(payload: any) {
      const { data } = payload;
      return await changeInfoById(data);
    }
  }
};
export default AppManager;
