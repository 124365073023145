import { API } from '@/const';
import request from '@/utils/request';

export const postBankAccount = async (data: any) =>
  request(`${API.bank}/bankAccount/getAccount`, {
    method: 'post',
    data
  });

export const putBankDealUp = async (data: any) =>
  request(`${API.bank}/bankDeal/updateById`, {
    method: 'put',
    data
  });

export const putBankWithdrawalUp = async (data: any) =>
  request(`${API.bank}/bankWithdrawal/updateById`, {
    method: 'put',
    data
  });
export const putBankAccountUp = async (data: any) =>
  request(`${API.bank}/bankAccount/updateById`, {
    method: 'put',
    data
  });
export const putBankAccountSave = async (data: any) =>
  request(`${API.bank}/bankAccount/save`, {
    method: 'post',
    data
  });
export const putBankWithdrawalSave = async (data: any) =>
  request(`${API.bank}/bankWithdrawal/save`, {
    method: 'post',
    data
  });
export const deleteBankDealById = async (id: number) =>
  request(`${API.basis}/bankDeal/deleteById/${id}`, {
    method: 'delete'
  });
export const deleteBankWithdrawalById = async (id: number) =>
  request(`${API.basis}/bankWithdrawal/deleteById/${id}`, {
    method: 'delete'
  });
export const deleteBankAccountById = async (id: number) =>
  request(`${API.basis}/bankAccount/deleteById/${id}`, {
    method: 'delete'
  });
export const putBankBackSave = async (data: any) =>
  request(`${API.bank}/bankBack/save`, {
    method: 'post',
    data
  });
export const getDataList = async (data: any) =>
  request(`${API.wallet}/walletAccount/page`, {
    method: 'post',
    data
  });
export const getWithAmount = async (data: any) =>
  request(`${API.bank}/bankAccount/getWithAmount`, {
    method: 'post',
    data
  });
