import request from '@/utils/request';
import { API, CONTENT_TYPE } from '@/const';
import { exportList } from '@/const/export';

export async function basisColumnGetOne(data: any) {
  return request(`${API.basis}/basisColumn/getOne`, {
    method: `post`,
    data: {
      data
    }
  });
}

export async function basisColumnSave(data: any) {
  return request(`${API.basis}/basisColumn/save`, {
    method: `post`,
    data: {
      data
    }
  });
}

export async function basisTysjUserGetOne(params: any) {
  return request(`${API.basis}/basisTysjUser/getOne`, {
    method: `post`,
    data: {
      data: { ...params }
    }
  });
}

export async function getDepartTree() {
  const ROLE_DATA = localStorage.getItem(`ROLE_DATA`) || `{}`;
  return request(`${API.upms}/sysDept/getTree`, {
    method: `post`,
    data: {
      data: { deptId: JSON.parse(ROLE_DATA)?.deptId }
    }
  });
}

export async function sysAccountList() {
  return request(`${API.upms}/sysAccount/list`, {
    method: `post`,
    data: {
      data: {}
    }
  });
}

export async function sysUserGetByIds(params: any) {
  return request(`${API.upms}/sysAccount/getByIds`, {
    method: `post`,
    ...params
  });
}

export async function getList({ pid }: any) {
  return request(`${API.custom}/customType/getTree`, {
    method: `post`,
    data: {
      data: {
        pid
      }
    }
  });
}

export async function sysAccountGetByIds(contactIds: any) {
  return request(`${API.upms}/sysAccount/getByIds`, {
    method: `post`,
    data: contactIds
  });
}

export async function sysAccountSave(data: any) {
  return request(`${API.upms}/sysAccount/saveAccount`, {
    method: `post`,
    data: {
      ...data
    }
  });
}

export async function sysAccountDeleteById(accountId: any) {
  return request(`${API.upms}/sysAccount/deleteById/${accountId}`, {
    method: `delete`
  });
}

export async function customAccountGetAccountList(customId: any) {
  return request(`${API.custom}/customAccount/getAccountList?customId=${customId}`, {
    method: `get`
  });
}

export async function customAccountSaveAccount(data: any, param: any) {
  return request(`${API.custom}/customAccount/saveAccount`, {
    method: `post`,
    data: {
      data,
      param
    }
  });
}

export async function customAccountDeleteAccount(customId: any, accountId: any) {
  return request(`${API.custom}/customAccount/deleteAccount?customId=${customId}&accountId=${accountId}`, {
    method: `delete`
  });
}

export async function getApp() {
  return request(`${API.upms}/sysTenant/getTenant`, {
    method: `get`
  });
}
export async function getAddressArea() {
  return request(`${API.wallet}/walletAddress/getDefault`, {
    method: `get`
  });
}
export const saveAddressArea = async (data: any) =>
  request(`${API.wallet}/walletConfig/save`, {
    method: 'post',
    data
  });
export async function basisColumnExport(data: any) {
  let api = data.export_api || exportList[window.location.pathname];
  data.export_Url ? (api = exportList[data.export_Url]) : '';
  delete data?.export_api;
  delete data?.export_Url;
  return request(api, {
    method: `post`,
    data,
    responseType: `blob`
  });
}

export async function basisUpload({ formData, url }: any) {
  return request(url, {
    method: `post`,
    data: formData,
    headers: {
      'Content-Type': CONTENT_TYPE.FORM_DATA
    }
  });
}

export const postVehicleDictDictionaryList = async (data: any) =>
  request(`${API.basis}/basisDict/getList`, {
    method: 'post',
    data
  });

export async function getDataList({ url, reqType, query, headers }: any) {
  return request(url, {
    method: reqType,
    data: {
      ...query
    },
    headers
  });
}

export async function getSchemaDataList({ url, type, parameter, headers }: any) {
  return request(url, {
    method: type,
    data: {
      ...parameter
    },
    headers
  });
}

export async function getRateById() {
  return request(`${API.custom}/customRate/getDefault`, {
    method: 'get'
  });
}

export const postVehicleBrandInfoList = async (data: any) =>
  request(`${API.basis}/basisVehicleBrand/list`, {
    method: 'post',
    data: { data: {} }
  });

export const customExpGetById = async (id: any) => {
  return request(`${API.auth}/customExp/getById/${id}`, {
    method: 'get'
  });
};

export const postCustomInfo = async (data: any) =>
  request(`${API.custom}/custom/list`, {
    method: 'post',
    data
  });
export const postCustomInfoById = async (id: any) => {
  return request(`${API.auth}/custom/getById/${id}`, {
    method: 'get'
  });
};
export const postCustomDepartTree = async (data: any) =>
  request(`${API.custom}/sysDept/getTree`, {
    method: 'post',
    data
  });

export const postWalletPaymentById = async (data: any) => {
  return request(`${API.wallet}/walletPayment/updateById`, {
    method: 'put',
    data
  });
};

export const getCustomById = async (id: string) => {
  return request(`${API.auth}/custom/getById/${id}`, {
    method: 'get'
  });
};
