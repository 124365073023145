import {
  postBankAccount,
  putBankAccountSave,
  putBankDealUp,
  putBankWithdrawalUp,
  putBankAccountUp,
  deleteBankDealById,
  deleteBankWithdrawalById,
  deleteBankAccountById,
  putBankWithdrawalSave,
  putBankBackSave,
  getDataList,
  getWithAmount
} from '@/services/BankStatement/api';
const WaybillList = {
  namespace: 'BankStatement',
  state: {},
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async postBankAccount(payload: any) {
      const { data } = payload;
      return await postBankAccount({ data });
    },
    async putBankDealUp(payload: any) {
      const { data } = payload;
      return await putBankDealUp({ data });
    },
    async putBankAccountSave(payload: any) {
      const { data } = payload;
      return await putBankAccountSave({ data });
    },
    async putBankWithdrawalSave(payload: any) {
      const { data } = payload;
      return await putBankWithdrawalSave({ data });
    },
    async putBankBackSave(payload: any) {
      const { data } = payload;
      return await putBankBackSave({ data });
    },
    async putBankWithdrawalUp(payload: any) {
      const { data } = payload;
      return await putBankWithdrawalUp({ data });
    },
    async putBankAccountUp(payload: any) {
      const { data } = payload;
      return await putBankAccountUp({ data });
    },
    async deleteBankDealById(payload: any) {
      const { id } = payload;
      return await deleteBankDealById(id);
    },
    async deleteBankWithdrawalById(payload: any) {
      const { id } = payload;
      return await deleteBankWithdrawalById(id);
    },
    async deleteBankAccountById(payload: any) {
      const { id } = payload;
      return await deleteBankAccountById(id);
    },
    async getDataList(payload: any) {
      const { data } = payload;
      const res: any = await getDataList(data);
      return res?.data;
    },
    async getWithAmount(payload: any) {
      const { data } = payload;
      return await getWithAmount(data);
    }
  }
};

export default WaybillList;
