import { getDataList } from '@/services/Wallet/walletRecharge';
import { getDataList as getDataListConsumptionRecord } from '@/services/Wallet/ConsumptionRecord';

const WalletRecord = {
  state: {
    dataSource: [],
    total: 0,
    loading: false
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getRechargeRecordDataList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      });
      const res: any = await getDataList(data);
      await result({
        loading: false,
        dataSource: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      });
    },
    async getConsumptionRecordDataList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      });
      const res: any = await getDataListConsumptionRecord(data);

      await result({
        loading: false,
        dataSource: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      });
    }
  }
};

export default WalletRecord;
