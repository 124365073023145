import {
  postContractTemplateInfoList,
  deleteContractTemplateInfoById,
  postContractTemplateInfoAdd,
  putContractTemplateInfoEdit,
} from '@/services/contract/api';
const ContractTemplate = {
  state: {
    searchParams: {
      current: 1,
      pageSize: 10,
      data: {},
    },
    list: [],
    total: 0,
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    },
  },
  effects: {
    async postContractTemplateInfoList(payload: any) {
      const { data } = payload;
      const res: any = await postContractTemplateInfoList(data);
      const { content, totalElements } = res?.data || {};
      return {
        data: content || [],
        total: totalElements || 0,
      };
    },
    async deleteContractTemplateInfoById(payload: any) {
      const { id } = payload;
      return await deleteContractTemplateInfoById(id);
    },
    async postContractTemplateInfoAdd(payload: any) {
      const { data } = payload;
      return await postContractTemplateInfoAdd(data);
    },
    async putContractTemplateInfoEdit(payload: any) {
      const { data } = payload;
      return await putContractTemplateInfoEdit(data);
    },
  },
};
export default ContractTemplate;
