import { basisOperationSlideshowList, basisOperationManual, basisOperationType, deleteOperationSlideshowById, deleteOperationManualById, postOperationSlideshowAdd, postOperationTypeAdd, postOperationManualAdd, deleteOperationTypeById, putOperationTypeEdit, putOperationManualEdit } from '@/services/ClassRoom/api';
import { message } from 'lieyun_design';
const ClassRoom = {
  namespace: 'ClassRoom',
  state: {
    dataSource: [],
    TypeDataSource: [],
    TypeDataSourceSub: [],
    visible: false,
    activityTab: '1',
    typeId: '',
    typePId: '',
    editData: false,
    pid: '0'
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: () => ({
    async getList(_: any, rootState: any) {
      const activityTab = rootState.ClassRoom.activityTab
      let data: any = []
      if (activityTab == '1') {
        const res: any = await basisOperationSlideshowList();
        data = res.data
      }
      if (activityTab == '2') {
        const res: any = await basisOperationType();
        data = res.data
      }
      if (activityTab == '3') {
        const TypeRes: any = await basisOperationType();
        const typeId = rootState.ClassRoom.typeId
        const typePId = rootState.ClassRoom.typePId
        const TypeDataSourceSub = rootState.ClassRoom.TypeDataSourceSub
        if (activityTab == '3') {
          await (this as any).result({
            TypeDataSource: TypeRes.data,
            TypeDataSourceSub: TypeDataSourceSub.length ? TypeDataSourceSub : TypeRes.data[0].list,
            typePId: typePId || TypeRes.data[0].operationTypeId,
          })
        }


        const res: any = await basisOperationManual({
          typeId,
          typePId
        });
        data = res.data
      }
      (this as any).result({
        dataSource: data
      })
    },

    async addData({ data }: any, rootState: any) {
      const activityTab = rootState.ClassRoom.activityTab
      let res: any
      if (activityTab == '1') {
        res = await postOperationSlideshowAdd({
          data
        })
      }

      if (activityTab == '2') {
        if (!data.pid) {
          data.pid = '0'
        }
        res = await postOperationTypeAdd({
          data
        })
      }

      if (activityTab == '3') {
        const extend = { type: data.type }
        delete data.type
        data.extend = JSON.stringify(extend)
        res = await postOperationManualAdd({
          data
        })
      }

      if (res.code === 0) {
        message.success("添加成功");
        (this as any).result({
          visible: false
        })
      }
      this.getList('', rootState);
    },

    async editData({ data }: any, rootState: any) {
      const activityTab = rootState.ClassRoom.activityTab
      let res: any
      if (activityTab == '2') {
        if (!data.pid) {
          data.pid = '0'
        }
        res = await putOperationTypeEdit({
          data
        })
      }

      if (activityTab == '3') {
        const extend = { type: data.type }
        delete data.type
        data.extend = JSON.stringify(extend)

        res = await putOperationManualEdit({
          data
        })
      }
      if (res.code === 0) {
        message.success("修改成功");
        (this as any).result({
          visible: false
        })
      }
      this.getList('', rootState);
    },

    async deleteData(id: any, rootState: any) {
      const activityTab = rootState.ClassRoom.activityTab
      if (activityTab == '1') {
        await deleteOperationSlideshowById(id)
      }
      if (activityTab == '2') {
        await deleteOperationTypeById(id)
      }
      if (activityTab == '3') {
        await deleteOperationManualById(id)
      }

      this.getList('', rootState);
    }
  })
};

export default ClassRoom;
