import {
  getDataList,
  updateByIds,
  WithdrawalList,
  walletConvergeWithdrawalList,
  walletBankcardSave,
  walletWithdrawalSave,
  walletConvergeWithdrawalSave,
  walletBankcardPage,
  basisTysjBankList,
  walletWithdrawalUpdateById,
  walletBankcardSaveUpdate,
  walletConvergeWithdrawalUpdateById
} from '@/services/Wallet/WithdrawalAudit';

const WithdrawalAudit = {
  state: {
    dataSource: [],
    bankCardData: [],
    total: 0,
    loading: false,
    requireParameter: {
      current: 1,
      size: 10,
      data: {}
    }
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getDataList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      });
      const res: any = await getDataList(data);

      await result({
        loading: false,
        dataSource: (res?.data?.content || []).map((item: any) => ({ ...item, random: Math.random() })),
        total: res?.data?.totalElements || 0
      });
    },
    async updateByIds({ data }: any) {
      await updateByIds(data);
    },
    async walletConvergeWithdrawalUpdateById({ data }: any) {
      await walletConvergeWithdrawalUpdateById(data);
    },
    async walletWithdrawalUpdateById({ data }: any) {
      return await walletWithdrawalUpdateById(data);
    },

    async walletBankcardSave({ data }: any) {
      await walletBankcardSave({ data });
    },
    async walletBankcardSaveUpdate({ data }: any) {
      await walletBankcardSaveUpdate({ data });
    },
    async walletBankcardPage({ data }: any) {
      const { result } = this as any;
      const res: any = await walletBankcardPage(data);
      result({
        bankCardData: res?.data?.content
      });
    },
    async walletWithdrawalSave({ data }: any) {
      await walletWithdrawalSave({ data });
    },
    async walletConvergeWithdrawalSave({ data }: any) {
      await walletConvergeWithdrawalSave(data);
    },
    async basisTysjBankList({ data }: any) {
      return await basisTysjBankList({ data });
    },
    async WithdrawalList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      });
      const res: any = await WithdrawalList(data);
      await result({
        loading: false,
        dataSource: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      });
    },
    async walletConvergeWithdrawalList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      });
      const res: any = await walletConvergeWithdrawalList(data);
      await result({
        loading: false,
        dataSource: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      });
    }
  }
};

export default WithdrawalAudit;
