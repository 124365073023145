const API_ENV = process.env.API_ENV;
export const API = {
  auth: `https://${API_ENV}.${API_ENV === 'lyt' ? 'lieyuntong.cn' : 'ailieyun.com'}/api/auth`,
  custom: `https://${API_ENV}.${API_ENV === 'lyt' ? 'lieyuntong.cn' : 'ailieyun.com'}/api/custom`,
  track: `https://${API_ENV}.${API_ENV === 'lyt' ? 'lieyuntong.cn' : 'ailieyun.com'}/api/track`,
  wallet: `https://${API_ENV}.${API_ENV === 'lyt' ? 'lieyuntong.cn' : 'ailieyun.com'}/api/wallet`,
  business: `https://${API_ENV}.${API_ENV === 'lyt' ? 'lieyuntong.cn' : 'ailieyun.com'}/api/business`,
  basis: `https://${API_ENV}.${API_ENV === 'lyt' ? 'lieyuntong.cn' : 'ailieyun.com'}/api/basis`,
  upms: `https://${API_ENV}.${API_ENV === 'lyt' ? 'lieyuntong.cn' : 'ailieyun.com'}/api/upms`,
  analysis: `https://${API_ENV}.${API_ENV === 'lyt' ? 'lieyuntong.cn' : 'ailieyun.com'}/api/analysis`,
  datacentre: `https://${API_ENV}.${API_ENV === 'lyt' ? 'lieyuntong.cn' : 'ailieyun.com'}/api/datacentre`,
  insurance: `https://${API_ENV}.${API_ENV === 'lyt' ? 'lieyuntong.cn' : 'ailieyun.com'}/api/insurance`,
  bank: `https://${API_ENV}.${API_ENV === 'lyt' ? 'lieyuntong.cn' : 'ailieyun.com'}/api/bank`,
  message: `wss://${API_ENV}.${API_ENV === 'lyt' ? 'lieyuntong.cn' : 'ailieyun.com'}/api/message`
};

export const RESOURCETYPE = {
  group: 0,
  menu: 1,
  tab: 2,
  button: 3
};

export const DEFAULT_ID = {
  APPID: '1001',
  TENANT_ID: '1001'
};

export const EXPEROENCEPAGETYPE = {
  ADD: 'add',
  EDIT: 'edit',
  CHANGE: 'change'
};

export const EXPEROENCETYPE = {
  PERSONAL: '2',
  ENTERPRISE: '1'
};

export const TAXESTYPE = {
  ADD: 1,
  REDUCE: 2
};

export const SERVICEFEETYPE = {
  PROPORTION: 1,
  FIXEDVALUE: 2,
  BYORDER: 3
};

export const CONTENT_TYPE = {
  URLENCODED: 'application/x-www-form-urlencoded',
  JSON: 'application/json',
  FORM_DATA: 'multipart/form-data'
};

export const UNIT_TYPE = {
  TAXPAYER: '1',
  SMALL_SCALE: '2',
  2: '小规模',
  1: '一般纳税人'
};
export const VEHICLE_FIELDS = [
  {
    label: '车牌颜色',
    value: 'LICENSE_PLATE_COLOR',
    role: 'VEHICLEMANAGMENT:VEHICLEFIELD:LICENSE_PLATE_COLOR',
    editRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:LICENSE_PLATE_COLOR:EDIT',
    deleteRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:LICENSE_PLATE_COLOR:DELETE',
    addRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:LICENSE_PLATE_COLOR:ADD'
  },
  {
    label: '品牌管理',
    filedLabel: '品牌名称',
    value: 'BRAND_MANAGEMENT',
    role: 'VEHICLEMANAGMENT:VEHICLEFIELD:BRAND_MANAGEMENT',
    editRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:BRAND_MANAGEMENT:EDIT',
    deleteRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:BRAND_MANAGEMENT:DELETE',
    addRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:BRAND_MANAGEMENT:ADD',
    addSubdataRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:BRAND_MANAGEMENT:ADDSUBDATA',
    editSubRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:BRAND_MANAGEMENT:EDITSUB',
    deleteSubRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:BRAND_MANAGEMENT:DELETESUB'
  },
  {
    label: '车辆类型',
    filedLabel: '车辆类型',
    value: 'TYPE_MANAGEMENT',
    role: 'VEHICLEMANAGMENT:VEHICLEFIELD:TYPE_MANAGEMENT',
    editRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:TYPE_MANAGEMENT:EDIT',
    deleteRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:TYPE_MANAGEMENT:DELETE',
    addRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:TYPE_MANAGEMENT:ADD'
  },
  {
    label: '能源类型',
    value: 'ENERGY_TYPES',
    role: 'VEHICLEMANAGMENT:VEHICLEFIELD:ENERGY_TYPES',
    editRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:ENERGY_TYPES:EDIT',
    deleteRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:ENERGY_TYPES:DELETE',
    addRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:ENERGY_TYPES:ADD'
  },
  {
    label: '车辆产权',
    value: 'PROPERTY_RIGHTS',
    role: 'VEHICLEMANAGMENT:VEHICLEFIELD:PROPERTY_RIGHTS',
    editRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:PROPERTY_RIGHTS:EDIT',
    deleteRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:PROPERTY_RIGHTS:DELETE',
    addRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:PROPERTY_RIGHTS:ADD'
  },
  {
    label: '车辆底盘',
    value: 'VEHICLE_CHASSIS',
    role: 'VEHICLEMANAGMENT:VEHICLEFIELD:VEHICLE_CHASSIS',
    editRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:VEHICLE_CHASSIS:EDIT',
    deleteRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:VEHICLE_CHASSIS:DELETE',
    addRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:VEHICLE_CHASSIS:ADD'
  },
  {
    label: '排放标准',
    value: 'EMISSION_STANDARDS',
    role: 'VEHICLEMANAGMENT:VEHICLEFIELD:EMISSION_STANDARDS',
    editRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:EMISSION_STANDARDS:EDIT',
    deleteRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:EMISSION_STANDARDS:DELETE',
    addRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:EMISSION_STANDARDS:ADD'
  },
  {
    label: '车辆状态',
    value: 'STATE_OF_THE_VEHICLE',
    role: 'VEHICLEMANAGMENT:VEHICLEFIELD:STATE_OF_THE_VEHICLE',
    editRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:STATE_OF_THE_VEHICLE:EDIT',
    deleteRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:STATE_OF_THE_VEHICLE:DELETE',
    addRole: 'VEHICLEMANAGMENT:VEHICLEFIELD:STATE_OF_THE_VEHICLE:ADD'
  }
];
export const NATIONAL = {
  '01': '汉',
  '02': '蒙古',
  '03': '回',
  '04': '藏',
  '05': '维吾尔',
  '06': '苗',
  '07': '彝',
  '08': '壮',
  '09': '布依',
  '10': '朝鲜',
  '11': '满',
  '12': '侗',
  '13': '瑶',
  '14': '白',
  '15': '土家',
  '16': '哈尼',
  '17': '哈萨克',
  '18': '傣',
  '19': '黎',
  '20': '僳僳',
  '21': '佤',
  '22': '畲',
  '23': '高山',
  '24': '拉祜',
  '25': '水',
  '26': '东乡',
  '27': '纳西',
  '28': '景颇',
  '29': '柯尔克孜',
  '30': '土',
  '31': '达斡尔',
  '32': '仫佬',
  '33': '羌',
  '34': '布朗',
  '35': '撒拉',
  '36': '毛难',
  '37': '仡佬',
  '38': '锡伯',
  '39': '阿昌',
  '40': '普米',
  '41': '塔吉克',
  '42': '怒',
  '43': '乌孜别克',
  '44': '俄罗斯',
  '45': '鄂温克',
  '46': '崩龙',
  '47': '保安',
  '48': '裕固',
  '49': '京',
  '50': '塔塔尔',
  '51': '独龙',
  '52': '鄂伦春',
  '53': '赫哲',
  '54': '门巴',
  '55': '珞巴',
  '56': '基诺'
};

export const SYSYTYPE = {
  PLATFORM: '1',
  CUSTOMER: '2'
};
export const WAYBILL_WEIGHT_TYPE = [
  {
    label: '实际装货吨位',
    value: 1
  },
  {
    label: '实际卸货吨位',
    value: 2
  },
  {
    label: '两者（装货和卸货）最小',
    value: 3
  },
  {
    label: '两者（装货和卸货）最大',
    value: 4
  }
];
export const USER_STATUS = {
  0: '未实名用户',
  1: '未人脸用户',
  2: 'V1认证用户',
  3: 'V2认证用户'
};
export const DEMAND_MODEL = [
  {
    label: '不限',
    value: '不限'
  },
  {
    label: '货车',
    value: '货车',
    children: [
      {
        label: '平板',
        value: '平板'
      },
      {
        label: '高低板',
        value: '高低板'
      },
      {
        label: '高栏',
        value: '高栏'
      },
      {
        label: '厢式',
        value: '厢式'
      },
      {
        label: '冷藏',
        value: '冷藏'
      },
      {
        label: '集装箱',
        value: '集装箱'
      },
      {
        label: '保湿车',
        value: '保湿车'
      },
      {
        label: '棉被车',
        value: '棉被车'
      },
      {
        label: '爬梯车',
        value: '爬梯车'
      },
      {
        label: '飞翼',
        value: '飞翼'
      }
    ]
  },
  {
    label: '面包车',
    value: '面包车',
    children: [
      {
        label: '依维柯',
        value: '依维柯'
      },
      {
        label: '微型面包',
        value: '微型面包'
      }
    ]
  },
  {
    label: '特种车',
    value: '特种车',
    children: [
      {
        label: '自卸车',
        value: '自卸车'
      },
      {
        label: '大件牵引',
        value: '大件牵引'
      },
      {
        label: '搅拌车',
        value: '搅拌车'
      },
      {
        label: '粉粒物料',
        value: '粉粒物料'
      },
      {
        label: '干混砂浆',
        value: '干混砂浆'
      },
      {
        label: '背罐车',
        value: '背罐车'
      }
    ]
  }
];
export const DISTRIBUTE_STATUS_RECORDTYPE_MAP = {
  1: '已出发',
  2: '已装货',
  3: '在途中',
  4: '已卸货',
  5: '问题上报',
  6: '已签收',
  7: '已停运',
  8: '回单审核'
};
export const WAYBILL_LIST_TAB = [
  {
    label: '全部',
    value: '',
    hideIn: true,
    role: 'WAYBILLLIST_ALL'
  },
  {
    label: '待审核',
    value: '0',
    role: 'WAYBILLLIST_AUDIT'
  },
  {
    label: '待派单',
    role: 'WAYBILLLIST_WAITINGLIST',
    value: '1'
  },
  {
    label: '待接单',
    role: 'WAYBILLLIST_PENDINGORDERS',
    value: '2'
  },
  {
    label: '待出发',
    role: 'WAYBILLLIST_WAITINGTODEPART',
    value: '3'
  },
  {
    label: '配送中',
    role: 'WAYBILLLIST_DELIVERYINPROGRESS',
    value: '4'
  },
  {
    label: '回单审核',
    role: 'WAYBILLLIST_RECEIPTREVIEW',
    value: '501'
  },
  {
    label: '确认到货',
    role: 'WAYBILLLIST_CONFIRMED',
    value: '6'
  },
  {
    label: '账单确认',
    role: 'WAYBILLLIST_OBLIGATION',
    value: '7'
  },
  {
    label: '已付款',
    role: 'WAYBILLLIST_PAID',
    value: '401'
  },
  {
    label: '已到卡',
    role: 'WAYBILLLIST_PAID',
    value: '8'
  },
  {
    label: '异常单',
    role: 'WAYBILLLIST_ABNORMALORDER',
    value: '201',
    hideIn: true
  },
  {
    label: '已驳回',
    role: 'WAYBILLLIST_REJECTED',
    value: '101',
    hide: true
  },
  {
    label: '已作废',
    role: 'WAYBILLLIST_VOIDED',
    value: '301',
    hideIn: true
  }
];
export const TRANSPORT_STATUS_MAP = {
  0: '待审核',
  1: '待派单',
  2: '待接单',
  3: '待出发',
  4: '配送中',
  6: '确认到货',
  7: '账单确认',
  8: '已到卡',
  401: '已付款',
  501: '回单审核',
  101: '已驳回',
  301: '已作废'
};
export const WAYBILLERRORTYPE = {
  1: '涉嫌资金回流',
  2: '司机时空重复',
  3: '车辆时空重复',
  4: '打卡位置与计划装卸位置不一致',
  5: '打卡异常',
  6: '车辆基础信息异常',
  7: '司机基础信息异常',
  8: '回单异常',
  9: '车机轨迹不合格',
  1001: '未上传单据异常提醒',
  1002: '超吨异常提醒',
  1003: '运费过高异常提醒',
  1004: '打卡位置与计划装卸位置不一致',
  Z: '自定义',
  A1: '涉嫌资金回流',
  A2: '司机时空重复',
  A3: '车辆时空重复',
  A4: '打卡位置与计划装卸位置不一致',
  A5: '打卡异常',
  A6: '车辆基础信息异常',
  A7: '司机基础信息异常',
  A9: '车机轨迹不合格',
  A11: '运单不是实时单',
  A1005: '未签订代收协议异常提醒',
  A1006: '合同信息缺失异常提醒',
};
export const QUESTIONKNOWLEDGETYPE = {
  1: '问题',
  2: '引导',
  3: '其他'
};
