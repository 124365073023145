import { API } from '@/const';
import request from '@/utils/request';

export async function getDataList(data: any) {
  return request(`${API.wallet}/walletUser/page`, {
    method: 'post',
    data
  });
}

export async function walletCenterSave({ data }: any) {
  return request(`${API.wallet}/walletUser/save`, {
    method: 'post',
    data: {
      data
    }
  });
}

export async function walletCenterModify({ data }: any) {
  return request(`${API.wallet}/walletBankcard/updateById`, {
    method: 'put',
    data: {
      data
    }
  });
}

export async function walletCenterDeleteById(id: any) {
  return request(`${API.wallet}/walletBankcard/deleteById/${id}`, {
    method: 'delete'
  });
}
