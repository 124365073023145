import { getLocaleStorage, setLocaleStorage, setSessionStorage } from '@/utils/storage';
import { isNil } from 'lodash';
import { getRoleList, getTenantList, getUserInfo } from '@/services/login';
import { DeepKeySearchObjDel, TreeToArray } from '@/utils';
import { getResourceListApi } from '@/services/common/sysResource';
import { sysResourceList } from '@/services/system/menuManagement';
import { getAddressArea } from '@/services/common/api';
import { postBasisAllConfigList } from '@/services/SystemConfiguration/api';
import { getAppFun } from '../../init';
const initialState = {
  state: {
    collapsed: false,
    TenantList: [],
    ResourceList: [],
    RoleList: [],
    settings: null,
    currentUser: null,
    loading: null,
    fetchUserInfo: null,
    addressInfo: null
  },
  reducers: {
    updateState(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async getRoleListFun() {
      const res: any = await getRoleList();
      if (res?.code === 0 && res?.data) {
        let status = 0;
        for (const i of res?.data) {
          const role_data = JSON.parse(getLocaleStorage('ROLE_DATA') || '{}');
          if (i?.roleId && i.roleId === role_data?.roleId) {
            status = 1;
            break;
          }
        }
        if (status === 0) {
          setLocaleStorage('ROLE_DATA', JSON.stringify(res?.data?.[0] || {}));
        }
        const RoleList = res?.data || [];
        const { updateState } = this as any;
        updateState({ RoleList });
        return res?.data;
      }
    },
    async getAppFun() {
      return await getAppFun();
    },
    async getAddressArea() {
      const res: any = await getAddressArea();
      if (res?.code === 0 && res?.data) {
        const addressInfo = res?.data || {};
        setLocaleStorage('ADDRESS_INFO', JSON.stringify(addressInfo));
        const { updateState } = this as any;
        updateState({ addressInfo });
        return res?.data;
      }
    },

    async getTenantListFun() {
      const res: any = await getTenantList();
      setLocaleStorage('TENANTIDLIST', JSON.stringify(res?.data));
      if (res?.code === 0 && res?.data) {
        let status = 0;
        const TENANTID = getLocaleStorage('TENANTID');
        for (const i of res?.data) {
          if (i.tenantId === TENANTID && !isNil(TENANTID)) {
            status = 1;
            break;
          }
        }
        if (res?.data?.length === 1 && status === 0) {
          setLocaleStorage('TENANTID', res?.data[0]?.tenantId);
        }
      }
      const TenantList = res?.data || [];
      const { updateState } = this as any;
      updateState({ TenantList });
      return TenantList;
    },

    async getResourceList() {
      try {
        const res: any = await getResourceListApi();
        const res2: any = await sysResourceList({
          type: 0,
          pc: true
        });
        const Routes = res2?.data || [];
        const ResourceList = res?.data || [];
        const { updateState } = this as any;
        const { arr, keyArr }: any = DeepKeySearchObjDel(ResourceList, 'routes', 'type', 3);
        const MenuList = ResourceList ? TreeToArray(ResourceList, 'routes') : [];
        const MenuListPath = MenuList?.map((item: any) => item.path);
        const RoutesPath: any = TreeToArray(Routes, 'routes')?.map((item: any) => item?.path) || [];
        setSessionStorage('ROUTES_ALL', JSON.stringify([...new Set(RoutesPath)]));
        setSessionStorage('ROUTES_ROLE', JSON.stringify([...new Set(MenuListPath)]));
        setSessionStorage('BUTTON_ROLE', JSON.stringify(keyArr));
        updateState({ ResourceList: arr });
      } catch (error) {}
    },
    async getSysConfig() {
      try {
        const res: any = await postBasisAllConfigList({
          size: -1,
          current: -1,
          data: {
            field: 'priceMax,finishedCarMax'
          }
        });
        const _map: any = {};
        (res?.data?.content || []).map((item: any) => {
          _map[item.field] = item.dataType === 2 ? (item.value === 'true' ? true : false) : item.dataType === 1 ? +item.value : item.value;
        });
        setSessionStorage('SYSCONFIG_MAP', JSON.stringify(_map));
      } catch (error) {
        console.log(error);
      }
    },

    async fetchUserInfo() {
      try {
        const msg: any = await getUserInfo();
        const currentUser = msg.data;
        const { updateState } = this as any;
        localStorage.setItem('CURRENTUSER', JSON.stringify(currentUser));
        updateState({ currentUser });
      } catch (error) {}
    }
  }
};

export default initialState;
