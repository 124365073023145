import {
  postListInfo,
  deleteInfoById,
  getBusinessFreightTemplateById,
  postBusinessFreightTemplateSchemeSave,
  getBusinessFreightTemplateSchemeOne,
  postBusinessFreightTemplateSchemeUpdateById
} from '@/services/WaybillTemplateList/api';

const WaybillTemplateList = {
  namespace: 'WaybillTemplateList',
  state: {
    total: 0,
    list: []
  },
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async postListInfo(payload: any) {
      const { params } = payload;
      const res: any = await postListInfo(params);
      const { data } = res || {};
      let content = data?.content || [];
      const { result } = this as any;
      result({
        list: content,
        total: data?.totalElements || 0
      });
      return content;
    },
    async deleteInfoById(payload: any) {
      const { id } = payload;
      return await deleteInfoById(id);
    },
    async getBusinessFreightTemplateById(payload: any) {
      const { id } = payload;
      return await getBusinessFreightTemplateById(id);
    },
    async postBusinessFreightTemplateSchemeSave(payload: any) {
      const { data } = payload;
      return await postBusinessFreightTemplateSchemeSave({ data });
    },
    async getBusinessFreightTemplateSchemeOne(payload: any) {
      const { data } = payload;
      return await getBusinessFreightTemplateSchemeOne({ data });
    },
    async postBusinessFreightTemplateSchemeUpdateById(payload: any) {
      const { data } = payload;
      return await postBusinessFreightTemplateSchemeUpdateById({ data });
    }
  }
};

export default WaybillTemplateList;
