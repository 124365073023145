import {
  postVehicleBrandInfoList,
  postVehicleBrandInfoAdd,
  postVehicleBrandInfoEdit,
  deleteVehicleBrandInfoById,
  postVehicleBrandTypeInfoAdd,
  postVehicleBrandTypeInfoEdit,
  deleteVehicleBrandTypeInfoById
} from '@/services/VehicleField/api';
const BrandField = {
  state: {
    list: []
  },
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async postVehicleBrandInfoList(payload: any) {
      const { data } = payload;
      const res: any = await postVehicleBrandInfoList(data);
      const { result } = this as any;
      result({
        list: res?.data || []
      });
    },
    async postVehicleBrandInfoAdd(payload: any) {
      const { data } = payload;
      return await postVehicleBrandInfoAdd(data);
    },
    async postVehicleBrandInfoEdit(payload: any) {
      const { data } = payload;
      return await postVehicleBrandInfoEdit(data);
    },
    async deleteVehicleBrandInfoById(payload: any) {
      const { id } = payload;
      return await deleteVehicleBrandInfoById(id);
    },
    async postVehicleBrandTypeInfoAdd(payload: any) {
      const { data } = payload;
      return await postVehicleBrandTypeInfoAdd(data);
    },
    async postVehicleBrandTypeInfoEdit(payload: any) {
      const { data } = payload;
      return await postVehicleBrandTypeInfoEdit(data);
    },
    async deleteVehicleBrandTypeInfoById(payload: any) {
      const { id } = payload;
      return await deleteVehicleBrandTypeInfoById(id);
    }
  }
};
export default BrandField;
