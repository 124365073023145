import { walletCenterSave, walletCenterModify, walletCenterDeleteById, getDataList } from '@/services/Wallet/VoucherCenter';

const VoucherCenter = {
  state: {
    expandedKeys: ['0'],
    editMenuData: {},
    loading: false
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getDataList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      });
      const res: any = await getDataList(data);
      await result({
        loading: false,
        dataSource: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      });
      return res;
    },

    async walletCenterSave({ data }: any) {
      const res: any = await walletCenterSave({ data });
      return res;
    },
    async walletCenterModify({ data }: any) {
      const res: any = await walletCenterModify({ data });
      return res;
    },
    async walletCenterDeleteById({ id }: any) {
      const res: any = await walletCenterDeleteById(id);
      return res;
    }
  }
};

export default VoucherCenter;
