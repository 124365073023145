import {
  postContractInviteInfoAdd,
  postContractInviteInfoList,
  putContractEdit,
  postContractView,
  putContractInviteInfoEdit,
  postContractInfoAdd,
  deleteContractFreightById,
  postContractFreightAdd,
  postContractSign,
  postContractQuerySign,
  postContractQueryFaceState
} from '@/services/contract/api';
const ContractList = {
  state: {
    searchParams: {
      current: 1,
      pageSize: 10,
      data: {}
    },
    list: [],
    total: 0
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async postContractInviteInfoList(payload: any) {
      const { data } = payload;
      const res: any = await postContractInviteInfoList(data);
      const { content, totalElements } = res?.data || {};
      return {
        data: content || [],
        total: totalElements || 0
      };
    },
    async postContractInviteInfoAdd(payload: any) {
      const { data } = payload;
      return await postContractInviteInfoAdd(data);
    },
    async putContractInviteInfoEdit(payload: any) {
      const { data } = payload;
      return await putContractInviteInfoEdit(data);
    },
    async postContractInfoAdd(payload: any) {
      const { data } = payload;
      return await postContractInfoAdd(data);
    },
    async putContractEdit(payload: any) {
      const { data } = payload;
      return await putContractEdit(data);
    },
    async postContractView(payload: any) {
      const { data } = payload;
      return await postContractView(data);
    },
    async postContractFreightAdd(payload: any) {
      const { data } = payload;
      return await postContractFreightAdd(data);
    },
    async deleteContractFreightById(payload: any) {
      const { id } = payload;
      return await deleteContractFreightById(id);
    },
    async postContractSign(payload: any) {
      const { data } = payload;
      return await postContractSign(data);
    },
    async postContractQuerySign(payload: any) {
      const { data } = payload;
      return await postContractQuerySign(data);
    },
    async postContractQueryFaceState(payload: any) {
      const { data } = payload;
      return await postContractQueryFaceState(data);
    }
  }
};
export default ContractList;
