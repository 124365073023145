const personalStore = {
  state: {
    activityItem: {
      title: '基础设置',
      index: 0
    },
    leftData: [
      {
        title: '基础设置',
        index: 0
      },
      {
        title: '安全设置',
        index: 1
      },
      {
        title: '登录日志',
        index: 2
      }
    ]
  },
  reducers: {
    updateState(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {}
};

export default personalStore;
