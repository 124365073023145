import { API } from '@/const';
import request from '@/utils/request';

export const postGoodsInfoList = async (data: any) =>
  request(`${API.business}/businessGoodsKind/page`, {
    method: 'post',
    data,
  });

export const deleteGoodsInfoById = async (id: number) =>
  request(`${API.business}/businessGoodsKind/deleteById/${id}`, {
    method: 'delete',
  });

export const postGoodsInfoAdd = async (data: any) =>
  request(`${API.business}/businessGoodsKind/save`, {
    method: 'post',
    data,
  });

export const putGoodsInfoEdit = async (data: any) =>
  request(`${API.business}/businessGoodsKind/updateById`, {
    method: 'put',
    data,
  });
