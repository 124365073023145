import {
  customAccountDeleteAccount,
  customAccountGetAccountList,
  customAccountSaveAccount,
  getList,
  sysAccountDeleteById,
  sysAccountGetByIds,
  sysAccountSave
} from '@/services/common/api';
import { customExpdeleteById, customExpModify, customExpUpdateById, getDataList, getSearchList } from '@/services/ExperienceCustomer/api';

const ExperienceCustomer = {
  state: {
    dataSourceList: [],
    pageListQuery: {
      data: {
        type: '1'
      },
      pageSize: 10,
      current: 1
    },
    paginationTotal: 0
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getDataListModels(payload: any) {
      const { params } = payload;
      const res: any = await getDataList({
        params
      });
      if ((res.code === 0 && res.data) || res.data === null) {
        return {
          data: res?.data?.content || [],
          success: true,
          total: res?.data?.totalElements || 0
        };
      } else {
        return res;
      }
    },

    async getList({ pid }: any) {
      const res: any = await getList({ pid });
      if (res.code === 0 && res.data) {
        return {
          data: res.data[0].childList
        };
      } else {
        return {
          data: []
        };
      }
    },

    async getSearchListModals(querydata: any) {
      return await getSearchList({ ...querydata });
    },

    async customExpdeleteByIdModals({ customId }: any) {
      return await customExpdeleteById(customId);
    },

    async customExpModifyModals({ data }: any) {
      return await customExpModify({ data });
    },
    async customExpUpdateByIdModals({ data }: any) {
      return await customExpUpdateById({ data });
    },
    async sysAccountGetByIdsModals({ contactIds }: any) {
      if (contactIds.length) {
        return await sysAccountGetByIds(contactIds);
      } else {
        return null;
      }
    },
    async sysAccountSaveModals({ data }: any) {
      return await sysAccountSave({ data });
    },
    async sysAccountDeleteByIdModals({ accountId }: any) {
      return await sysAccountDeleteById(accountId);
    },
    async customAccountGetAccountListModals({ customId }: any) {
      return await customAccountGetAccountList(customId);
    },
    async customAccountSaveAccountModals({ data, param }: any) {
      return await customAccountSaveAccount(data, param);
    },
    async customAccountDeleteAccountModals({ customId, accountId }: any) {
      return await customAccountDeleteAccount(customId, accountId);
    }
  }
};

export default ExperienceCustomer;
