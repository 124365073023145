import {
  postBasisProblemBaseList,
  deleteBasisProblemBaseById,
  postBasisProblemBaseAdd,
  putBasisProblemBaseEdit,
  getBasisProblemBaseById,
  getProblemTopTen,
  getContributorRanking
} from '@/services/QuestionKnowledge/api';
const QuestionKnowledge = {
  state: {
    params: {},
    list: [],
    total: 0
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async postBasisProblemBaseList(payload: any) {
      const { data } = payload;
      const res: any = await postBasisProblemBaseList(data);
      const { result } = this as any;
      const obj: any = {
        list: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      };
      result(obj);
      return obj;
    },
    async deleteBasisProblemBaseById(payload: any) {
      const { id } = payload;
      return await deleteBasisProblemBaseById(id);
    },
    async getBasisProblemBaseById(payload: any) {
      const { id } = payload;
      return await getBasisProblemBaseById(id);
    },
    async postBasisProblemBaseAdd(payload: any) {
      const { data } = payload;
      return await postBasisProblemBaseAdd({ data });
    },
    async putBasisProblemBaseEdit(payload: any) {
      const { data } = payload;
      return await putBasisProblemBaseEdit({ data });
    },
    async getProblemTopTen(payload: any) {
      const { data } = payload;
      return await getProblemTopTen({ data });
    },
    async getContributorRanking(payload: any) {
      const { data } = payload;
      return await getContributorRanking({ data });
    }
  }
};
export default QuestionKnowledge;
