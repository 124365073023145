import { API } from '@/const';
import request from '@/utils/request';

export const dataSave = async (data: any) =>
  request(`${API.wallet}/walletAccount/save`, {
    method: 'post',
    data
  });

export const getDataList = async (data: any) => {
  return request(`${API.wallet}/walletAccount/page`, {
    method: 'post',
    data
  });
};

export const dataDeleteById = async (id: string) => {
  return request(`${API.wallet}/walletAccount/deleteById/${id}`, {
    method: 'delete'
  });
};

export const updateByIds = async (data: any) => {
  return request(`${API.wallet}/walletAccount/updateByIds`, {
    method: 'put',
    data
  });
};

export const walletConfigGetById = async () => {
  return request(`${API.wallet}/walletConfig/getById/${1}`, {
    method: 'get'
  });
};

export const walletConfigUpdateById = async (data: any) => {
  return request(`${API.wallet}/walletConfig/updateById`, {
    method: 'put',
    data
  });
};

export const getWallet = async (data: any) => {
  return request(`${API.wallet}/walletCustomWallet/list`, {
    method: 'post',
    data
  });
};
export const getBankcardDataList = async (data: any) => {
  return request(`${API.wallet}/walletBankcard/page`, {
    method: 'post',
    data
  });
};
export const getCompanyMoneyByCategory = async () => {
  return request(`${API.wallet}/walletWjyResource/getCompanyMoneyByCategory`, {
    method: 'post',
    data: {
      data: {}
    }
  });
};
