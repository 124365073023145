import { getRouteHistroy, getRouteRecent, getTrackList, postRepairRecord, whetherTrackList, addTrackList } from '@/services/VehicleManagmentCenter/api';
const VehicleManagmentCenter = {
  namespace: 'VehicleManagmentCenter',
  state: {
    searchLinkParams: {
      current: 1,
      pageSize: 5,
      queryKeys: [],
      data: {}
    },
    linkTotal: 0,
    linkList: []
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getRouteHistroy(payload: any) {
      const { params } = payload;
      const res: any = await getRouteHistroy(params);
      const { data } = res || {};
      return data || [];
    },
    async getRouteRecent(payload: any) {
      const { params } = payload;
      const res: any = await getRouteRecent(params);
      const { data } = res || {};
      return data.gpsInfoList || [];
    },
    async getTrackList(payload: any) {
      const { params } = payload;
      const res: any = await getTrackList(params);
      const { data } = res || {};
      return data || [];
    },
    async whetherTrackList(payload: any) {
      const { params } = payload;
      const res: any = await whetherTrackList(params);
      const { data } = res || {};
      return data || [];
    },
    async addTrackList(payload: any) {
      const { params } = payload;
      const res: any = await addTrackList(params);
      const { data } = res || {};
      return data || [];
    },

    async postRepairRecord(payload: any) {
      const { data } = payload;
      return await postRepairRecord({ data });
    }
  }
};

export default VehicleManagmentCenter;
