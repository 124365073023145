export const getLocaleStorage = name => localStorage.getItem(name);

export const setLocaleStorage = (name, values) => localStorage.setItem(name, values);

export const removeLocaleStorage = name => localStorage.removeItem(name);

export const getSessionStorage = name => sessionStorage.getItem(name);

export const setSessionStorage = (name, values) => sessionStorage.setItem(name, values);
export const removeSessionStorage = name => {
  if (Array.isArray(name)) {
    for (const i of name) {
      sessionStorage.removeItem(i);
    }
  } else {
    sessionStorage.removeItem(name);
  }
};
