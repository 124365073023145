import { postVehicleDictDictionaryList, getRateById, postVehicleBrandInfoList, customExpGetById, postCustomInfo, postCustomDepartTree, getCustomById } from '@/services/common/api';
const CommonDictionary = {
  namespace: 'CommonDictionary',
  state: {
    CommonDictionary: {},
    PRESETSDATA: null,
    vehicleInfo: {},
    CustomBalance: 0,
    customList: []
  },
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async getVehicleInfo() {
      const queryKeys = ['PROPERTY_RIGHTS', 'VEHICLE_CHASSIS', 'EMISSION_STANDARDS', 'STATE_OF_THE_VEHICLE', 'ENERGY_TYPES', 'LICENSE_PLATE_COLOR', 'TYPE_MANAGEMENT'];

      const vehicleInfo = {};
      const res: any = await Promise.all([
        postVehicleDictDictionaryList({
          queryKeys
        }),
        postVehicleBrandInfoList({})
      ]);
      const data = res[0]?.data || {};
      const typeData = res[1]?.data || [];
      queryKeys.forEach((item: any) => {
        vehicleInfo[item] = data[item] || [];
      });
      vehicleInfo['BRAND_MANAGEMENT'] = typeData.map((item: any) => ({
        label: item.name,
        value: item.vehicleBrandId,
        children: (item.basisBrandTypeList || []).map((inner: any) => ({
          label: inner.name,
          value: inner.brandTypeId
        }))
      }));
      const { result } = this as any;
      result({
        vehicleInfo
      });
    },
    async getRateById() {
      const res: any = await getRateById();
      if (res?.code === 0 && res?.data) {
        return res.data;
      } else {
        return null;
      }
    },
    async customExpGetById(id: any) {
      const res: any = await customExpGetById(id);
      const { result } = this as any;
      result({
        CustomBalance: res?.data?.balance ? Number(res?.data?.balance) : 0
      });
    },
    async getCustomInfo(payload: any) {
      const { keyword } = payload;
      const res: any = await postCustomInfo({
        data: {
          name: keyword
        }
      });
      const { data } = res || {};
      const { result } = this as any;
      result({
        customList: data || []
      });
      return data || [];
    },
    async postCustomDepartTree(payload: any) {
      const { tenantId } = payload;
      const res: any = await postCustomDepartTree({
        data: {
          tenantId
        }
      });
      const { data } = res || {};
      const { result } = this as any;
      result({
        customList: data || []
      });
      return data || [];
    },
    async getCustomById(id: string) {
      return await getCustomById(id)
    }
  }
};
export default CommonDictionary;
