import { getDataList, dataSave, updateById, DeleteById, reset, postWalletCustomAddress, postWalletCustomSaveBatch } from '@/services/Wallet/VoucherAddress';
import { saveAddressArea } from '@/services/common/api';
const VoucherAddress = {
  state: {
    expandedKeys: ['0'],
    editMenuData: {},
    loading: false
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getDataList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      });
      const res: any = await getDataList(data);
      await result({
        loading: false,
        dataSource: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      });
      return res;
    },

    async dataSave({ data }: any) {
      const res: any = await dataSave({ data });
      return res;
    },
    async saveAddressArea({ data }: any) {
      const res: any = await saveAddressArea({ data });
      return res;
    },
    async updateById({ data }: any) {
      const res: any = await updateById({ data });
      return res;
    },
    async deleteById({ id }: any) {
      const res: any = await DeleteById(id);
      return res;
    },
    async reset({ id }: any) {
      const res: any = await reset(id);
      return res;
    },
    async postWalletCustomAddress(data: any) {
      const res: any = await postWalletCustomAddress({ data });
      return res;
    },
    async postWalletCustomSaveBatch(data: any) {
      const res: any = await postWalletCustomSaveBatch({ data });
      return res;
    }
  }
};

export default VoucherAddress;
