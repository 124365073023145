const ExpenditureStore = {
  state: {
    pageProps: {},
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    },
  },
  effects: {},
};

export default ExpenditureStore;
