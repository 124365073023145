import { API } from '@/const';
import request from '@/utils/request';

export const postGoodsInfoList = async (data: any) =>
  request(`${API.business}/businessGoodsPack/page`, {
    method: 'post',
    data
  });

export const deleteGoodsInfoById = async (id: number) =>
  request(`${API.business}/businessGoodsPack/deleteById/${id}`, {
    method: 'delete'
  });

export const postGoodsInfoAdd = async (data: any) =>
  request(`${API.business}/businessGoodsPack/save`, {
    method: 'post',
    data
  });

export const putGoodsInfoEdit = async (data: any) =>
  request(`${API.business}/businessGoodsPack/updateById`, {
    method: 'put',
    data
  });
