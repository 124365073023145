import { API } from '@/const';
import request from '@/utils/request';


export async function walletRechargeSave({ data }: any) {
  return request(`${API.wallet}/walletRecharge/save`, {
    method: 'post',
    data: {
      data,
    },
  });
}

export async function getDataList(data: any) {
  return request(`${API.wallet}/walletRecharge/page`, {
    method: "post",
    data
  })
}



export const updateByIds = async (data: any) => {
  return request(`${API.wallet}/walletRecharge/updateByIds`, {
    method: 'put',
    data
  })
}

export async function walletRechargeDeleteById(id: any) {
  return request(`${API.wallet}/walletRecharge/deleteById/${id}`, {
    method: 'delete',
  });
}