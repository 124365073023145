import { API } from '@/const';
import request from '@/utils/request';

export async function getDataList({ params }: any) {
  return request(`${API.custom}/customExp/page`, {
    method: 'post',
    data: {
      ...params,
    },
  });
}

export async function getSearchList({ querydata }: any) {
  return request(`${API.custom}/customExp/page`, {
    method: 'post',
    data: {
      ...querydata,
    },
  });
}


export async function customExpdeleteById(customId: any) {
  return request(`${API.custom}/customExp/deleteById/${customId}`, {
    method: 'delete',
  });
}


export async function customExpModify(params: any) {
  return request(`${API.custom}/customExp/modify`, {
    method: 'put',
    data: {
      ...params,
    },
  });
}


export async function customExpUpdateById(params: any) {
  return request(`${API.custom}/customExp/updateById`, {
    method: 'put',
    data: {
      ...params,
    },
  });
}
