import { getCommonInfo, postSaveFreight, postUpdateFreight } from '@/services/WaybillTemplateList/api';
import { businessRateConfigGetById } from '@/services/RateManagement/api';
import { postBusinessLineInfoList } from '@/services/DeliveryRouteList/api';
import { postDriverInfoList } from '@/services/DriverManagement/api';
import { postVehicleInfoList } from '@/services/VehicleList/api';
import { postCustomInfo, postCustomInfoById } from '@/services/common/api';
import { covertLineData } from '@/utils';
const WaybillCommon = {
  namespace: 'WaybillCommon',
  state: {
    goodsInfo: {},
    versionRateInfo: {},
    customInfo: {}
  },
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async getGoodsInfo() {
      const param: any = await getCommonInfo();
      const { result } = this as any;
      result({
        goodsInfo: param?.data || {}
      });
    },
    async businessRateConfigGetById(payload: any) {
      const { id } = payload;
      const res: any = await businessRateConfigGetById(id);
      const versionRateInfo: any = res?.data || {};
      const { result } = this as any;
      result({
        versionRateInfo
      });
      return versionRateInfo;
    },
    async getCustomInfoById(payload: any) {
      const { id } = payload;
      const res: any = await postCustomInfoById(id);
      const customInfo: any = res?.data || {};
      const { result } = this as any;
      result({
        customInfo
      });
      return customInfo;
    },
    async getCustomInfo(payload: any) {
      const { keyword } = payload;
      const res: any = await postCustomInfo({
        data: {
          name: keyword
        }
      });
      const { data } = res || {};
      return data || [];
    },
    async postDriverInfoList(payload: any) {
      const { name } = payload;
      const res: any = await postDriverInfoList({
        data: {
          name
        }
      });
      const { data } = res || {};
      return data || [];
    },
    async postVehicleInfoList(payload: any) {
      const { name } = payload;
      const res: any = await postVehicleInfoList({
        data: {
          vehicleNo: name,
          vehicleType: '0'
        }
      });
      const { data } = res || {};
      return data || [];
    },
    async postBusinessLineInfoList(payload: any) {
      const { params } = payload;
      const res: any = await postBusinessLineInfoList(params);
      const { data } = res || {};
      let content = data?.content || [];
      content = content.map((item: any) => covertLineData(item));
      return content;
    },
    async postSaveFreight(payload: any) {
      const { data } = payload;
      return await postSaveFreight({ data });
    },
    async postUpdateFreight(payload: any) {
      const { data } = payload;
      return await postUpdateFreight({ data });
    }
  }
};

export default WaybillCommon;
