import { Component } from 'react';
import { ConfigProvider, message } from 'lieyun_design';
import 'moment/locale/zh-cn';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Routes from './routes';
import store from './store';
import 'lieyun_design/dist/lieyun_design.min.css';
import './App.less';
import './kx.less';
import './tree.less';
import './table.less';
import './modal_Form.less';
import zh_CN from 'lieyun_design/src/lib/locale/zh_CN';
import LyLog from 'lkfe_log';
import { API_ENV } from './const/env';
import { ROBOTAPPID } from './const/robot';

message.config({
  prefixCls: `${systemName}-message`
});

ConfigProvider.config({
  prefixCls: systemName,
  rootPrefixCls: systemName
});
LyLog.init({
  app_id: ROBOTAPPID,
  env: API_ENV,
  project: 'lieyuntong'
});

class App extends Component {
  componentDidCatch() {}
  render() {
    return (
      <ConfigProvider locale={zh_CN} prefixCls={systemName}>
        <Provider store={store}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    );
  }
}
export default App;
