import {
  postListInfo,
  getBusinessFreightById,
  putEditInfo,
  postAuditInfo,
  deleteSubInfoById,
  putUpdateSubStatus,
  getBusinessFreightPay,
  putUpdateBusinessFreightSub,
  putUpdateBusinessFreight,
  postBusinessFreightSubDriverVehicleLog,
  calcFreightAmount,
  calcHistoryFreightAmount,
  getWalletBatchList,
  getWalletBatchSave,
  getWalletPayMentClose,
  getWalletPayMentList,
  getBusinessPayMentList,
  postPayMentById,
  postBusinessFreightRecord,
  putBusinessFreightRecord,
  putBusinessbusinessFreightSubCancel,
  putAnalysisDataUpdateByIds,
  putBusinessFreightErrorUpdateByIds,
  putAnalysisDataUpdateById,
  postBusinessFreightBatchSave,
  putBusinessFreightBatchUpdate,
  putBusinessFreightSubForward,
  getBusinessFreightSubById,
  postWalletPaymentSave,
  deleteWalletPaymentById,
  putWalletPaymentModify,
  getBusinessWorkOrderById,
  getBusinessPaymentOrderById,
  putBusinessWorkOrderUpdateById,
  putBusinessPaymentOrderUpdateById,
  postWalletPaymentSaveBatch,
  postConfirmArrivalOfGoods,
  postWalletPayBatch,
  postConfirmArrivalOfGoodsBatch,
  postBusinessWorkOrderUpdateByIds,
  putBusinessFreightSubModify,
  postBusinessShareBenefitBatch,
  postBusinessShareBenefit,
  putBusinessShareBenefitUpdateById,
  postWalletUserPage,
  postWalletUserGetPage,
  postBusinessShareBenefitList,
  getBusinessCustomRateOne,
  postWalletSaveAndGet,
  postBusinessPaymentChangeRecordList,
  postBusinessPaymentChangeRecord,
  postBusinessPaymentChangeRecordSaveBatch,
  putBusinessFreightSubRepair,
  putBusinessFreightSubRepairLocation,
  postBusinessFreightSubRepeat,
  postBusinessFreightSubCheckBatch,
  postWalletUpdateById,
  postValidatePayoffPattern,
  postBusinessProceedsSettle,
  postBusinessProceedsSave,
  postBusinessProceedsInvoiceSave,
  postBusinessProceedsInvoicePage,
  putBusinessFreightModify,
  putBusinessFreightProceedsModify,
  putBusinessFreightProceedsUpdateById,
  getBusinessFreightProceedsOne,
  postSaveDefinedError,
  postSaveDefinedErrorLogRepair,
  putSaveRecallConfirm,
  putBusinessFreightSubUpdateCustomWaybill,
  postBusinessReceiptExaminePass,
  postBusinessReceiptExamineReject,
  businessOwnerPayment,
  updateFreightPayment,
  businessOwnerPaymentPayment,
  putBusinessFreightProceedsUpdateFreight,
  businessOwnerPaymentBatch,
  repairAnomaly
} from '@/services/WaybillList/api';

const WaybillList = {
  namespace: 'WaybillList',
  state: {},
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async postListInfo(payload: any) {
      const { params } = payload;
      const res: any = await postListInfo(params);
      const { data } = res || {};
      let content = data?.content || [];
      return {
        data: content,
        total: data?.totalElements || 0
      };
    },
    async getBusinessFreightById(payload: any) {
      const { id } = payload;
      return await getBusinessFreightById(id);
    },
    async getBusinessFreightSubById(payload: any) {
      const { id } = payload;
      return await getBusinessFreightSubById(id);
    },
    async deleteSubInfoById(payload: any) {
      const { id } = payload;
      return await deleteSubInfoById(id);
    },
    async putEditInfo(payload: any) {
      const { data } = payload;
      return await putEditInfo({ data });
    },
    async postAuditInfo(payload: any) {
      const { data } = payload;
      let query = '?';
      for (const key in data) {
        const element = data[key];
        query += `${key}=${element}&`;
      }
      return await postAuditInfo(query.slice(0, -1));
    },
    async putUpdateSubStatus(payload: any) {
      const { data } = payload;
      return await putUpdateSubStatus({ data });
    },
    async putUpdateBusinessFreight(payload: any) {
      const { data } = payload;
      return await putUpdateBusinessFreight({ data });
    },
    async putUpdateBusinessFreightSub(payload: any) {
      const { data } = payload;
      return await putUpdateBusinessFreightSub({ data });
    },
    async getBusinessFreightPay(payload: any) {
      const { id } = payload;
      const res: any = await getBusinessFreightPay(id);
      const data = res?.data || {};
      return data;
    },
    async postBusinessFreightSubDriverVehicleLog(payload: any) {
      const { data } = payload;
      return await postBusinessFreightSubDriverVehicleLog({ data });
    },
    async calcFreightAmount(payload: any) {
      const { data } = payload;
      return await calcFreightAmount({ data });
    },
    async calcHistoryFreightAmount(payload: any) {
      const { data } = payload;
      return await calcHistoryFreightAmount({ data });
    },
    async getWalletBatchList(payload: any) {
      const { data } = payload;
      return await getWalletBatchList(data);
    },
    async getWalletBatchSave(payload: any) {
      const { data } = payload;
      return await getWalletBatchSave(data);
    },
    async getWalletPayMentClose(payload: any) {
      const { data } = payload;
      return await getWalletPayMentClose(data);
    },
    async postPayMentById(payload: any) {
      const { data } = payload;
      return await postPayMentById({ data });
    },
    async postBusinessFreightRecord(payload: any) {
      const { data } = payload;
      return await postBusinessFreightRecord(data);
    },
    async putBusinessFreightRecord(payload: any) {
      const { data } = payload;
      return await putBusinessFreightRecord(data);
    },
    async putAnalysisDataUpdateByIds(payload: any) {
      const { data } = payload;
      return await putAnalysisDataUpdateByIds(data);
    },
    async putAnalysisDataUpdateById(payload: any) {
      const { data } = payload;
      return await putAnalysisDataUpdateById(data);
    },
    async postBusinessFreightBatchSave(payload: any) {
      const { data } = payload;
      return await postBusinessFreightBatchSave(data);
    },
    async putBusinessFreightBatchUpdate(payload: any) {
      const { data } = payload;
      return await putBusinessFreightBatchUpdate(data);
    },
    async putBusinessFreightSubForward(payload: any) {
      const { data } = payload;
      return await putBusinessFreightSubForward({ data });
    },

    async putBusinessbusinessFreightSubCancel(payload: any) {
      const { data } = payload;
      return await putBusinessbusinessFreightSubCancel(data);
    },
    async deleteWalletPaymentById(payload: any) {
      const { id } = payload;
      return await deleteWalletPaymentById(id);
    },
    async postWalletPaymentSave(payload: any) {
      const { data } = payload;
      return await postWalletPaymentSave({ data });
    },
    async putWalletPaymentModify(payload: any) {
      const { data } = payload;
      return await putWalletPaymentModify({ data });
    },
    async getBusinessWorkOrderById(payload: any) {
      const { id } = payload;
      return await getBusinessWorkOrderById(id);
    },
    async getBusinessPaymentOrderById(payload: any) {
      const { id } = payload;
      return await getBusinessPaymentOrderById(id);
    },
    async putBusinessWorkOrderUpdateById(payload: any) {
      const { data } = payload;
      return await putBusinessWorkOrderUpdateById({ data });
    },
    async putBusinessPaymentOrderUpdateById(payload: any) {
      const { data } = payload;
      return await putBusinessPaymentOrderUpdateById({ data });
    },
    async postWalletPaymentSaveBatch(payload: any) {
      const { data } = payload;
      return await postWalletPaymentSaveBatch({ data });
    },
    async postConfirmArrivalOfGoods(payload: any) {
      const { data } = payload;
      return await postConfirmArrivalOfGoods({ data });
    },
    async getWalletPayMentList(payload: any) {
      const { data } = payload;
      return await getWalletPayMentList({ data });
    },
    async getBusinessPayMentList(payload: any) {
      const { data } = payload;
      return await getBusinessPayMentList({ data });
    },
    async postWalletPayBatch(payload: any) {
      const { data } = payload;
      return await postWalletPayBatch(data);
    },
    async postConfirmArrivalOfGoodsBatch(payload: any) {
      const { data } = payload;
      return await postConfirmArrivalOfGoodsBatch(data);
    },
    async postBusinessWorkOrderUpdateByIds(payload: any) {
      const { data } = payload;
      return await postBusinessWorkOrderUpdateByIds(data);
    },
    async putBusinessFreightSubModify(payload: any) {
      const { data } = payload;
      return await putBusinessFreightSubModify(data);
    },
    async putBusinessFreightModify(payload: any) {
      const { data } = payload;
      return await putBusinessFreightModify(data);
    },
    async postBusinessShareBenefitBatch(payload: any) {
      const { data } = payload;
      return await postBusinessShareBenefitBatch(data);
    },
    async postWalletUserPage(payload: any) {
      const { data } = payload;
      return await postWalletUserPage(data);
    },
    async postWalletUserGetPage(payload: any) {
      const { data } = payload;
      return await postWalletUserGetPage(data);
    },
    async postWalletSaveAndGet(payload: any) {
      const { data } = payload;
      return await postWalletSaveAndGet(data);
    },
    async postBusinessShareBenefitList(payload: any) {
      const { data } = payload;
      return await postBusinessShareBenefitList(data);
    },
    async putBusinessShareBenefitUpdateById(payload: any) {
      const { data } = payload;
      return await putBusinessShareBenefitUpdateById(data);
    },
    async postBusinessShareBenefit(payload: any) {
      const { data } = payload;
      return await postBusinessShareBenefit(data);
    },
    async getBusinessCustomRateOne(payload: any) {
      const { data } = payload;
      return await getBusinessCustomRateOne(data);
    },
    async postBusinessPaymentChangeRecordList(payload: any) {
      const { data } = payload;
      return await postBusinessPaymentChangeRecordList(data);
    },
    async postBusinessPaymentChangeRecord(payload: any) {
      const { data } = payload;
      return await postBusinessPaymentChangeRecord(data);
    },
    async postBusinessPaymentChangeRecordSaveBatch(payload: any) {
      const { data } = payload;
      return await postBusinessPaymentChangeRecordSaveBatch(data);
    },
    async putBusinessFreightErrorUpdateByIds(payload: any) {
      const { data } = payload;
      return await putBusinessFreightErrorUpdateByIds(data);
    },
    async putBusinessFreightSubRepair(payload: any) {
      const { data } = payload;
      return await putBusinessFreightSubRepair(data);
    },
    async putBusinessFreightSubRepairLocation(payload: any) {
      const { data, ids } = payload;
      return await putBusinessFreightSubRepairLocation({ data, ids });
    },
    async postBusinessFreightSubRepeat(payload: any) {
      const { data } = payload;
      return await postBusinessFreightSubRepeat(data);
    },
    async postBusinessFreightSubCheckBatch(payload: any) {
      const { data } = payload;
      return await postBusinessFreightSubCheckBatch(data);
    },
    async postWalletUpdateById(payload: any) {
      const { data } = payload;
      return await postWalletUpdateById(data);
    },
    async postValidatePayoffPattern(payload: any) {
      const { data } = payload;
      return await postValidatePayoffPattern({ data });
    },
    async postBusinessProceedsSettle(payload: any) {
      const { data } = payload;
      return await postBusinessProceedsSettle({ data });
    },
    async postBusinessProceedsSave(payload: any) {
      const { data } = payload;
      return await postBusinessProceedsSave({ data });
    },
    async postBusinessProceedsInvoiceSave(payload: any) {
      const { data } = payload;
      return await postBusinessProceedsInvoiceSave(data);
    },
    async postBusinessProceedsInvoicePage(payload: any) {
      const { data } = payload;
      const res: any = await postBusinessProceedsInvoicePage(data);
      return res?.data;
    },
    async putBusinessFreightProceedsModify(payload: any) {
      const { data } = payload;
      return await putBusinessFreightProceedsModify(data);
    },
    async putBusinessFreightProceedsUpdateById(payload: any) {
      const { data } = payload;
      return await putBusinessFreightProceedsUpdateById(data);
    },
    async getBusinessFreightProceedsOne(payload: any) {
      const { data } = payload;
      return await getBusinessFreightProceedsOne(data);
    },
    async postSaveDefinedError(payload: any) {
      const { data } = payload;
      return await postSaveDefinedError(data);
    },
    async postSaveDefinedErrorLogRepair(payload: any) {
      const { data } = payload;
      return await postSaveDefinedErrorLogRepair(data);
    },
    async putSaveRecallConfirm(payload: any) {
      const { data } = payload;
      return await putSaveRecallConfirm(data);
    },
    async putBusinessFreightSubUpdateCustomWaybill(payload: any) {
      const { data } = payload;
      return await putBusinessFreightSubUpdateCustomWaybill({ data });
    },
    async postBusinessReceiptExaminePass(payload: any) {
      const { data } = payload;
      return await postBusinessReceiptExaminePass({ data });
    },
    async postBusinessReceiptExamineReject(payload: any) {
      const { data } = payload;
      return await postBusinessReceiptExamineReject(data);
    },
    async businessOwnerPayment(payload: any) {
      const { data } = payload;
      return await businessOwnerPayment(data);
    },
    async updateFreightPayment(payload: any) {
      const { data } = payload;
      return await updateFreightPayment(data);
    },
    async businessOwnerPaymentPayment(payload: any) {
      const { data } = payload;
      return await businessOwnerPaymentPayment({ data });
    },
    async putBusinessFreightProceedsUpdateFreight(payload: any) {
      const { data } = payload;
      return await putBusinessFreightProceedsUpdateFreight(data);
    },
    async businessOwnerPaymentBatch(payload: any) {
      const { data } = payload;
      return await businessOwnerPaymentBatch(data);
    },
    async repairAnomaly(payload: any) {
      const { data } = payload;
      return await repairAnomaly(data);
    }
  }
};

export default WaybillList;
