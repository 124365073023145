import { basisColumnGetOne, basisColumnSave } from '@/services/common/api';
const basusColumns = {
  state: {
    '/workBench/CustomerManagement/FormalCustomers': '',
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    },
  },
  effects: {
    async basisColumnGetOne({ data }: any) {
      const res = await basisColumnGetOne(data);
      return res;
    },

    async basisColumnSave({ data }: any) {
      const res = await basisColumnSave(data);
      return res;
    },
  },
};

export default basusColumns;
