import { API } from '@/const';
import request from '@/utils/request';


export async function getPageNoRateCustom({ current, name }: any) {
  return request(`${API.custom}/custom/pageNoRateCustom`, {
    method: 'post',
    data: {
      current,
      size: 50,
      data: {
        name,
      },
    },
  });
}


export async function businessRateSave({ data }: any) {
  return request(`${API.custom}/customRate/save`, {
    method: 'post',
    data: {
      data,
    },
  });
}


export async function businessRateModify({ data }: any) {
  return request(`${API.custom}/customRate/modify`, {
    method: 'put',
    data: {
      data,
    },
  });
}


export async function businessRatedeleteById({ id }: any) {
  return request(`${API.custom}/customRate/deleteById/${id}`, {
    method: 'delete',
  });
}


export async function businessRateConfigSave({ data }: any) {
  return request(`${API.custom}/customRate/save`, {
    method: 'post',
    data: {
      data,
    },
  });
}


export async function businessRateConfigModify({ data }: any) {
  return request(`${API.custom}/customRate/modify`, {
    method: 'put',
    data: {
      data,
    },
  });
}


export async function businessRateConfigGetById(id: any) {
  return request(`${API.custom}/customRate/getByCustomId/${id}`, {
    method: 'get',
  });
}


export async function businessFeeConfigSave(data: any) {
  return request(`${API.custom}/customFee/save`, {
    method: 'post',
    data: {
      data,
    },
  });
}

export async function businessFeeConfigModify(data: any) {
  return request(`${API.custom}/customFee/modify`, {
    method: 'put',
    data: {
      data,
    },
  });
}


export async function businessFeeConfigDeleteById(id: any) {
  return request(`${API.custom}/customFee/deleteById/${id}`, {
    method: 'delete',
  });
}


export async function businessFeeConfigPage({ data, size, current }: any) {
  return request(`${API.custom}/customFee/page`, {
    method: 'post',
    data: {
      data,
      size,
      current,
    },
  });
}
