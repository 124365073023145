import { API } from '@/const';
import request from '@/utils/request';

export const postListInfo = async (data: any) =>
  request(`${API.business}/businessFreightSub/getPage`, {
    method: 'post',
    data
  });

export const getBusinessFreightById = async (id: string) =>
  request(`${API.business}/businessFreight/getFreight?freightId=${id}`, {
    method: 'get'
  });

export const getBusinessFreightSubById = async (id: string) =>
  request(`${API.business}/businessFreightSub/getById/${id}`, {
    method: 'get'
  });

export const deleteInfoById = async (id: number) =>
  request(`${API.business}/businessFreight/deleteById/${id}`, {
    method: 'delete'
  });

export const deleteSubInfoById = async (id: number) =>
  request(`${API.business}/businessFreightSub/deleteById/${id}`, {
    method: 'delete'
  });

export const postAuditInfo = async (path: any) =>
  request(`${API.business}/businessFreight/auditFreight${path}`, {
    method: 'post',
    data: {
      data: {}
    }
  });

export const putEditInfo = async (data: any) =>
  request(`${API.business}/businessFreight/updateFreight`, {
    method: 'post',
    data
  });

export const putUpdateSubStatus = async (data: any) =>
  request(`${API.business}/businessFreight/forwardFreightStatus`, {
    method: 'put',
    data
  });

export const getBusinessFreightPay = async (id: any) =>
  request(`${API.business}/businessFreightPay/list`, {
    method: 'post',
    data: {
      data: {
        freightId: id
      }
    }
  });

export const putUpdateBusinessFreightSub = async (data: any) =>
  request(`${API.business}/businessFreightSub/updateById`, {
    method: 'put',
    data
  });

export const putUpdateBusinessFreight = async (data: any) =>
  request(`${API.business}/businessFreight/updateById`, {
    method: 'put',
    data
  });

export const postBusinessFreightSubDriverVehicleLog = async (data: any) =>
  request(`${API.business}/businessFreightSubRecord/list`, {
    method: 'post',
    data
  });

export const calcFreightAmount = async (data: any) =>
  request(`${API.business}/businessFreight/calcFreightAmount`, {
    method: 'post',
    data
  });
export const calcHistoryFreightAmount = async (data: any) =>
  request(`${API.business}/businessFreight/calcHistoryFreightAmount`, {
    method: 'post',
    data
  });

export async function getWalletBatchList(data: any) {
  return request(`${API.wallet}/walletPlan/getOne`, {
    method: 'post',
    data
  });
}

export async function getWalletBatchSave(data: any) {
  return request(`${API.wallet}/walletPlan/save`, {
    method: 'post',
    data
  });
}

export async function getWalletPayMentClose(data: any) {
  return request(`${API.wallet}/walletPayment/page`, {
    method: 'post',
    data
  });
}

export async function postPayMentById(data: any) {
  return request(`${API.business}/businessFreightSub/payment`, {
    method: 'post',
    data
  });
}

export async function postBusinessFreightRecord(data: any) {
  return request(`${API.business}/businessFreightRecord/save`, {
    method: 'post',
    data
  });
}

export async function putBusinessFreightRecord(data: any) {
  return request(`${API.business}/businessFreightRecord/updateById`, {
    method: 'put',
    data
  });
}

export async function putAnalysisDataUpdateByIds(data: any) {
  return request(`${API.business}/analysisData/updateByIds`, {
    method: 'put',
    data
  });
}
export async function putAnalysisDataUpdateById(data: any) {
  return request(`${API.business}/analysisData/updateById`, {
    method: 'put',
    data
  });
}

export async function postBusinessFreightBatchSave(data: any) {
  return request(`${API.business}/businessFreightBatch/save`, {
    method: 'post',
    data
  });
}
export const putBusinessFreightBatchUpdate = async (data: any) =>
  request(`${API.business}/businessFreightBatch/updateById`, {
    method: 'put',
    data
  });

export const putBusinessFreightSubForward = async (data: any) =>
  request(`${API.business}/businessFreightSub/forward`, {
    method: 'put',
    data
  });
export const putBusinessbusinessFreightSubCancel = async (data: any) =>
  request(`${API.business}/businessFreightSub/cancel`, {
    method: 'put',
    data
  });

export async function postWalletPaymentSave(data: any) {
  return request(`${API.wallet}/walletPayment/save`, {
    method: 'post',
    data
  });
}
export const deleteWalletPaymentById = async (id: number) =>
  request(`${API.wallet}/walletPayment/deleteById/${id}`, {
    method: 'delete'
  });
export async function putWalletPaymentModify(data: any) {
  return request(`${API.wallet}/walletPayment/modify`, {
    method: 'put',
    data
  });
}
export const getBusinessWorkOrderById = async (id: string) =>
  request(`${API.business}/businessWorkOrder/getById/${id}`, {
    method: 'get'
  });
export const getBusinessPaymentOrderById = async (id: string) =>
  request(`${API.business}/businessPaymentOrder/getById/${id}`, {
    method: 'get'
  });
export async function putBusinessWorkOrderUpdateById(data: any) {
  return request(`${API.business}/businessWorkOrder/updateById`, {
    method: 'put',
    data
  });
}
export async function putBusinessPaymentOrderUpdateById(data: any) {
  return request(`${API.business}/businessPaymentOrder/updateById`, {
    method: 'put',
    data
  });
}
export async function postWalletPaymentSaveBatch(data: any) {
  return request(`${API.wallet}/walletPayment/saveBatchPayment`, {
    method: 'post',
    data
  });
}
export async function postConfirmArrivalOfGoods(data: any) {
  return request(`${API.business}/businessWorkOrder/confirmArrivalOfGoods`, {
    method: 'post',
    data
  });
}
export async function getWalletPayMentList(data: any) {
  return request(`${API.wallet}/walletPayment/list`, {
    method: 'post',
    data
  });
}
export async function getBusinessPayMentList(data: any) {
  return request(`${API.business}/businessPayment/list`, {
    method: 'post',
    data
  });
}
export async function postWalletPayBatch(data: any) {
  return request(`${API.business}/businessFreightSub/payBatch`, {
    method: 'post',
    data
  });
}
export async function postConfirmArrivalOfGoodsBatch(data: any) {
  return request(`${API.business}/businessWorkOrder/confirmArrivalOfGoodsBatch`, {
    method: 'post',
    data
  });
}
export async function postBusinessWorkOrderUpdateByIds(data: any) {
  return request(`${API.business}/businessWorkOrder/updateByIds`, {
    method: 'put',
    data
  });
}
export async function putBusinessFreightSubModify(data: any) {
  return request(`${API.business}/businessFreightSub/updateByIds`, {
    method: 'put',
    data
  });
}
export async function putBusinessFreightModify(data: any) {
  return request(`${API.business}/businessFreightSub/modify`, {
    method: 'put',
    data
  });
}
export async function postBusinessShareBenefitBatch(data: any) {
  return request(`${API.business}/businessShareBenefit/saveShareBenefitBatch`, {
    method: 'post',
    data
  });
}
export async function postBusinessShareBenefit(data: any) {
  return request(`${API.business}/businessShareBenefit/save`, {
    method: 'post',
    data
  });
}
export async function postWalletUserPage(data: any) {
  return request(`${API.wallet}/walletUser/page`, {
    method: 'post',
    data
  });
}
export async function postWalletUserGetPage(data: any) {
  return request(`${API.wallet}/walletUser/getPage`, {
    method: 'post',
    data
  });
}
export async function postWalletSaveAndGet(data: any) {
  return request(`${API.wallet}/walletUser/saveAndGet`, {
    method: 'post',
    data
  });
}
export async function postWalletUpdateById(data: any) {
  return request(`${API.wallet}/walletUser/updateById`, {
    method: 'put',
    data
  });
}

export async function postBusinessShareBenefitList(data: any) {
  return request(`${API.business}/businessShareBenefit/list`, {
    method: 'post',
    data
  });
}
export async function putBusinessShareBenefitUpdateById(data: any) {
  return request(`${API.business}/businessShareBenefit/updateById`, {
    method: 'put',
    data
  });
}
export async function getBusinessCustomRateOne(data: any) {
  return request(`${API.business}/businessCustomRate/getOne`, {
    method: 'post',
    data
  });
}
export async function postBusinessPaymentChangeRecordList(data: any) {
  return request(`${API.business}/businessPaymentChangeRecord/list`, {
    method: 'post',
    data
  });
}
export async function postBusinessPaymentChangeRecord(data: any) {
  return request(`${API.business}/businessPaymentChangeRecord/save`, {
    method: 'post',
    data
  });
}
export async function postBusinessPaymentChangeRecordSaveBatch(data: any) {
  return request(`${API.business}/businessPaymentChangeRecord/updateFreightPayment`, {
    method: 'post',
    data
  });
}

export async function putBusinessFreightErrorUpdateByIds(data: any) {
  return request(`${API.business}/businessFreightError/updateByIds`, {
    method: 'put',
    data
  });
}

export async function putBusinessFreightSubRepair(data: any) {
  return request(`${API.business}/businessFreightSub/repair`, {
    method: 'put',
    data
  });
}
export async function putBusinessFreightSubRepairLocation(data: any) {
  return request(`${API.business}/businessFreightSub/repairLocation`, {
    method: 'put',
    data
  });
}
export async function postBusinessFreightSubRepeat(data: any) {
  return request(`${API.business}/businessFreightSub/repeat`, {
    method: 'post',
    data
  });
}
export async function postBusinessFreightSubCheckBatch(data: any) {
  return request(`${API.business}/businessFreightSub/checkBatch`, {
    method: 'post',
    data
  });
}
export async function postValidatePayoffPattern(data?: any) {
  return request(`${API.wallet}/walletUser/validatePayoffPattern`, {
    method: 'POST',
    data
  });
}
export async function postBusinessProceedsSettle(data?: any) {
  return request(`${API.business}/businessProceeds/settle`, {
    method: 'POST',
    data
  });
}
export async function postBusinessProceedsSave(data?: any) {
  return request(`${API.business}/businessProceeds/save`, {
    method: 'POST',
    data
  });
}
export async function postBusinessProceedsInvoiceSave(data?: any) {
  return request(`${API.business}/businessProceedsInvoice/saveBatch`, {
    method: 'POST',
    data
  });
}
export async function postBusinessProceedsInvoicePage(data: any) {
  return request(`${API.business}/businessProceedsInvoice/page`, {
    method: 'POST',
    data
  });
}
export async function putBusinessFreightProceedsModify(data: any) {
  return request(`${API.business}/businessFreightProceeds/modify`, {
    method: 'put',
    data
  });
}
export async function getBusinessFreightProceedsOne(data: any) {
  return request(`${API.business}/businessFreightProceeds/getOne`, {
    method: 'post',
    data
  });
}
export async function putBusinessFreightProceedsUpdateById(data: any) {
  return request(`${API.business}/businessFreightProceeds/updateById`, {
    method: 'put',
    data
  });
}
export async function postSaveDefinedError(data: any) {
  return request(`${API.business}/businessFreightErrorLog/saveDefinedError`, {
    method: 'post',
    data
  });
}
export async function postSaveDefinedErrorLogRepair(data: any) {
  return request(`${API.business}/businessFreightErrorLog/repair`, {
    method: 'post',
    data
  });
}
export async function putSaveRecallConfirm(data: any) {
  return request(`${API.business}/businessFreightSub/recallConfirm`, {
    method: 'put',
    data
  });
}
export const putBusinessFreightSubUpdateCustomWaybill = async (data: any) =>
  request(`${API.business}/businessFreightSub/updateCustomWaybill`, {
    method: 'put',
    data
  });
export async function postBusinessReceiptExaminePass(data: any) {
  return request(`${API.business}/businessReceiptExamine/passVerification`, {
    method: 'post',
    data
  });
}
export async function postBusinessReceiptExamineReject(data: any) {
  return request(`${API.business}/businessReceiptExamine/rejectVerification`, {
    method: 'put',
    data
  });
}
export async function businessOwnerPayment(data: any) {
  return request(`${API.business}/businessOwnerPayment/page`, {
    method: 'post',
    data
  });
}
export async function updateFreightPayment(data: any) {
  return request(`${API.business}/businessOwnerPayment/updateFreightPayment`, {
    method: 'post',
    data
  });
}
export async function businessOwnerPaymentPayment(data: any) {
  return request(`${API.business}/businessOwnerPayment/payment`, {
    method: 'post',
    data
  });
}
export async function putBusinessFreightProceedsUpdateFreight(data: any) {
  return request(`${API.business}/businessFreightProceeds/updateFreight`, {
    method: 'post',
    data
  });
}
export async function businessOwnerPaymentBatch(data: any) {
  return request(`${API.business}/businessOwnerPayment/paymentBatch`, {
    method: 'post',
    data
  });
}
export async function repairAnomaly(data: any) {
  return request(`${API.business}/businessFreightSub/repairAnomaly`, {
    method: 'post',
    data
  });
}
