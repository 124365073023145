import { API, EXPEROENCEPAGETYPE, EXPEROENCETYPE } from '@/const';
import { basisTycCorpList, customConvert, customExpModify, customExpSave, getList } from '@/services/AddExperienceCustomer/api';
import { basisTysjUserGetOne, getDepartTree, sysAccountList, sysUserGetByIds } from '@/services/common/api';
import { message } from 'lieyun_design';

const AddExperienceCustomer = {
  namespace: 'AddExperienceCustomer',
  state: {
    treeData: [],
    formProps: {
      layout: {
        labelCol: {
          span: '6'
        },
        wrapperCol: {
          span: '19'
        }
      }
    },
    personalInfo: {
      title: '个人信息',
      valueType: 'group',
      formItem: [
        [
          {
            valueType: 'select',
            itemProps: {
              name: 'type',
              label: '客户类型'
            },
            valueName: 'typeId',
            control: {
              options: []
            },
            rules: {
              required: true
            },
            innerStyle: {
              width: '70%'
            }
          },
          {
            valueType: 'text',
            itemProps: {
              name: 'name',
              label: '客户名称'
            },
            rules: {
              required: true,
              min: null,
              max: null
            },
            innerStyle: {
              width: '70%'
            }
          }
        ],
        [
          {
            valueType: 'phone',
            itemProps: {
              name: 'phone',
              label: '手机号'
            },
            rules: {
              required: true
            },
            innerStyle: {
              width: '70%'
            }
          },
          {
            valueType: 'text',
            itemProps: {
              name: 'idCardNo',
              label: '身份证号'
            },
            rules: {
              required: true,
              min: null,
              max: null
            },
            innerStyle: {
              width: '70%'
            }
          }
        ]
      ]
    },
    enterpriseInfo: {
      title: '企业信息',
      valueType: 'group',
      formItem: [
        [
          {
            valueType: 'select',
            itemProps: {
              name: 'type',
              label: '客户类型'
            },
            placeholder: '请选择客户类型',
            valueName: 'typeId',
            control: {
              options: []
            },
            rules: {
              required: true
            },
            innerStyle: {
              width: '70%'
            }
          },
          {
            valueType: 'selectSearch',
            itemProps: {
              name: 'name',
              label: '客户名称'
            },
            placeholder: '请输入客户名称',
            onSearch: (s: any) => {},
            control: {
              options: []
            },
            rules: {
              required: true,
              min: null,
              max: null
            },
            innerStyle: {
              width: '70%'
            }
          }
        ],
        [
          {
            valueType: 'text',
            itemProps: {
              name: 'uscc',
              label: '统一社会信用代码'
            },
            enterButtonText: '校验',
            placeholder: '统一社会信用代码',
            disabled: true,
            rules: {
              required: true,
              min: null,
              max: null
            },
            innerStyle: {
              width: '70%'
            }
          },
          {
            valueType: 'text',
            itemProps: {
              name: 'legalPersonName',
              label: '法人姓名'
            },
            placeholder: '法人姓名',
            disabled: true,
            rules: {
              required: true,
              min: null,
              max: null
            },
            innerStyle: {
              width: '70%'
            }
          },
          {
            valueType: 'text',
            itemProps: {
              name: 'businessScope',
              label: '经营范围'
            },
            placeholder: '经营范围',
            disabled: true,
            innerStyle: {
              width: '70%'
            }
          }
        ]
      ]
    },
    mangegementInfo: {
      title: '管理信息',
      valueType: 'group'
    }
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getList({ pid }: any) {
      const res = await getList({ pid });
      return res;
    },

    async basisTycCorpListModals({ name, pageType }: any) {
      let url = `${API.basis}/basisTycCorp/list`;
      if (pageType === EXPEROENCEPAGETYPE.CHANGE) url = `${API.basis}/basisTycCorp/getOne`;
      const res = await basisTycCorpList(name, url);
      return res;
    },
    async postSave({ pid, formVal, ContactList, AccountList, pageType, customId, invoiceList }: any) {
      let data: any;
      if (pid === EXPEROENCETYPE.PERSONAL) {
        const res: any = await basisTysjUserGetOne({
          name: formVal.name,
          idcard: formVal.idCardNo
        });
        if (res?.code !== 0 || !res?.data) {
          message.error('姓名和身份证号不匹配！');
          return {
            code: -1,
            msg: '姓名和身份证号不匹配！'
          };
        }
        data = {
          ownUserList: AccountList.map((item: any) => {
            return {
              userId: JSON.parse(item).accountId,
              deptId: JSON.parse(item).deptId
            };
          }),
          ownDeptList: formVal.ownDeptList,
          phone: formVal.phone,
          name: formVal.name,
          type: pid,
          identity: formVal.type,
          idCardNo: formVal.idCardNo,
          customId
        };
      } else {
        data = {
          contactList: ContactList,
          ownUserList: AccountList.map((item: any) => {
            return {
              userId: JSON.parse(item).accountId,
              deptId: JSON.parse(item).deptId
            };
          }),
          ownDeptList: formVal.ownDeptList,
          legalPersonName: formVal.legalPersonName,
          businessScope: formVal.businessScope,
          name: JSON.parse(formVal.name).name,
          type: pid,
          identity: formVal.type,
          socialCreditCode: formVal.uscc,
          customId
        };
      }

      if (pageType === EXPEROENCEPAGETYPE.EDIT) {
        return await customExpModify({ data });
      } else if (pageType === EXPEROENCEPAGETYPE.CHANGE) {
        data.invoiceList = invoiceList;
        if (pid === EXPEROENCETYPE.PERSONAL) {
          data.certificates = `${formVal.IDCardNo1 || ''},${formVal.IDCardNo2 || ''}`;
        } else {
          data.certificates = formVal.certificates || '';
        }
        return await customConvert(data);
      } else {
        delete data.customId;
        return await customExpSave(data);
      }
    },
    async getDepartTreeModals() {
      const res = await getDepartTree();
      return res;
    },
    async sysAccountListModals() {
      const res = await sysAccountList();
      return res;
    },

    async customExpGetByIdsModals({ data }: any) {
      return await sysUserGetByIds({ data });
    }
  }
};

export default AddExperienceCustomer;
