import { etcPush, etcEnd, gainInvoice } from '@/services/Wallet/ETC';
import { dataSave, getWallet, getDataList, updateByIds, walletConfigGetById, walletConfigUpdateById, getBankcardDataList, getCompanyMoneyByCategory } from '@/services/Wallet/api';
const Wallet = {
  state: {
    WorkBenchData: {}
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async dataSave({ data }: any) {
      return await dataSave({ data });
    },
    async getWallet(data: any) {
      const res: any = await getWallet({ data });
      const { result } = this as any;
      const _data: any = res?.data?.[0];
      let SettleInStatus = {
        text: '立即入驻',
        status: 1
      };
      if (_data?.account) {
        SettleInStatus = {
          text: '对公账号',
          status: 2
        };
      }
      result({
        WorkBenchData: {
          ..._data,
          SettleInStatus
        }
      });
      return _data;
    },
    async getCompanyMoneyByCategory() {
      const res: any = await getCompanyMoneyByCategory();
      return res?.data;
    },
    async getDataList({ data }: any) {
      return await getDataList(data);
    },

    async updateByIds({ data }: any) {
      await updateByIds(data);
    },

    async walletConfigGetById() {
      const res: any = await walletConfigGetById();
      const { result } = this as any;
      result({
        walletConfig: res?.data
      });
    },
    async walletConfigUpdateById({ data }: any) {
      const res: any = await walletConfigUpdateById({ data });
      const { result } = this as any;
      result({
        walletConfig: res?.data
      });
    },
    async getBankcardDataList({ data }: any) {
      const res: any = await getBankcardDataList(data);
      return res?.data;
    },
    async etcPush({ data }: any) {
      return await etcPush(data);
    },
    async etcEnd({ data }: any) {
      return await etcEnd(data);
    },
    async gainInvoice({ data }: any) {
      return await gainInvoice(data);
    }
  }
};

export default Wallet;
