import { notification } from 'lieyun_design';
import { isDevEnv, isTestEnv } from '@/consts/env';

const logUtils = {
  info: (...arg) => {
    if (isDevEnv || isTestEnv) {
    }
  },
  error: data => {
    if (isDevEnv || isTestEnv) {
      data &&
        notification.error({
          message: data?.message
        });
    }
  }
};

export default logUtils;
