import { getDataList, updateById } from '@/services/Wallet/WalletDetailed';
const AddressList = {
  state: {
    dataSource: [],
    total: 0,
    loading: true
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    },
  },
  effects: {
    async getDataList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      });
      const res: any = await getDataList(data);
      await result({
        loading: false,
        dataSource: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      });
    },
    async updateById({ data }: any) {
      return await updateById(data)
    }
  }
};

export default AddressList;
