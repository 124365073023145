import { getDataList } from '@/services/Wallet/CapitalFlowManagement';

const CapitalFlowManagement = {
  state: {
    dataSource: [],
    total: 0,
    loading: false,
    requireParameter: {
      current: 1,
      data: {},
      size: 10
    }
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    },
  },
  effects: {
    async getDataList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      })
      const res: any = await getDataList(data)
      await result({
        loading: false,
        dataSource: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      })
    },
  },
};

export default CapitalFlowManagement;
