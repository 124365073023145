import { API } from '@/const';
import request from '@/utils/request';

export async function getDataList(data: any) {
  let url = `${API.wallet}/walletUser/page`;
  if (data?.data?.reviewState) {
    url = `${API.wallet}/walletCertification/page`;
  } else {
    data.data.reviewState = data.data.reviewStateSearch;
    delete data.data.reviewStateSearch;
  }
  return request(url, {
    method: 'post',
    data
  });
}

export const updateByIds = async (data: any) => {
  return request(`${API.wallet}/walletCertification/updateByIds`, {
    method: 'put',
    data
  });
};

export const updateById = async (data: any) => {
  return request(`${API.wallet}/walletCertification/updateById`, {
    method: 'put',
    data
  });
};

export async function getLevelDataList(data: any) {
  return request(`${API.wallet}/walletLevel/page`, {
    method: 'post',
    data
  });
}

export async function dataLevelSave(data: any) {
  return request(`${API.wallet}/walletLevel/save`, {
    method: 'post',
    data: {
      data
    }
  });
}

export const updateLevelById = async (data: any) => {
  return request(`${API.wallet}/walletLevel/updateById`, {
    method: 'put',
    data: {
      data
    }
  });
};

export async function DeleteLevelById(id: any) {
  return request(`${API.wallet}/walletLevel/deleteById/${id}`, {
    method: 'delete'
  });
}
export const updateWalletAuthenticationById = async (data: any) => {
  return request(`${API.wallet}/walletAuthentication/updateById`, {
    method: 'put',
    data: {
      data
    }
  });
};
export const putWalletAuthenticationEdit = async (data: any) =>
  request(`${API.wallet}/walletAuthentication/modify`, {
    method: 'put',
    data
  });
export const walletAuthenticationGetById = async (id: any) => {
  return request(`${API.wallet}/walletAuthentication/getById/${id}`, {
    method: 'get'
  });
};
export const postOcrIdentify = async (data: any) => {
  return request(`${API.custom}/basisOcr/identify`, {
    method: 'post',
    data: {
      data
    }
  });
};
