import { getDataList } from '@/services/Wallet/BalanceManagement';
import { getDataList as walletRecordGetDataList, dataSave, dataModify, walletFreezePage } from '@/services/Wallet/walletRecord';
const BalanceManagement = {
  state: {
    walletRecordDataSource: [],
    walletRecordTotal: 0,
    walletRecordloading: false,
    walletRecordRequireParameter: {
      current: 1,
      data: {},
      size: 10
    },
    walletFreezeloading: false,
    walletFreezeDataSource: [],
    walletFreezeTotal: 0,
    walletFreezeRequireParameter: {
      current: 1,
      data: {},
      size: 10
    },
    dataSource: [],
    loading: false,
    requireParameter: {
      current: 1,
      data: {},
      size: 10
    }
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getDataList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      });
      const res: any = await getDataList(data);
      await result({
        loading: false,
        dataSource: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      });
    },

    async dataSave({ data }: any) {
      const res: any = await dataSave({ data });
      return res;
    },
    async walletRecordGetDataList({ data }: any) {
      const { result } = this as any;
      result({
        walletRecordloading: true
      });
      const res: any = await walletRecordGetDataList(data);

      await result({
        walletRecordloading: false,
        walletRecordDataSource: res?.data?.content || [],
        walletRecordTotal: res?.data?.totalElements || 0
      });
    },
    async walletFreezePage({ data }: any) {
      const { result } = this as any;
      result({
        walletFreezeloading: true
      });
      const res: any = await walletFreezePage(data);
      await result({
        walletFreezeloading: false,
        walletFreezeDataSource: res?.data?.content || [],
        walletFreezeTotal: res?.data?.totalElements || 0
      });
    },
    async dataModify({ data }: any) {
      return await dataModify(data);
    }
  }
};

export default BalanceManagement;
