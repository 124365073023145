const covertTempCustom = (item: any, rate: any) => {
  const { customId, name: customName, type: customType, idCardNo, socialCreditCode } = item || {};
  return {
    customId,
    customName,
    customType,
    idCardNo,
    socialCreditCode,
    businessRate: rate,
    customNameText: customName || '-',
    socialCreditCodeText: socialCreditCode || idCardNo || '-'
  };
};
const covertTempGoods = (item: any, params: any) => {
  const { goodsName, goodsKindId, goodsPackId } = item || {};
  const goodsKindList = params?.goodsKindList || [];
  const goodsPackList = params?.goodsPackList || [];
  const kindDescObj = goodsKindList.find((inner: any) => inner.kindId === goodsKindId);
  const packDescObj = goodsPackList.find((inner: any) => inner.packId === goodsPackId);
  return {
    goodsName,
    kindDescObj: {
      kindId: kindDescObj?.kindId,
      kindDesc: kindDescObj?.kindDesc
    },
    packDescObj: {
      packId: packDescObj?.packId,
      packDesc: packDescObj?.packDesc
    },
    goodsNameText: goodsName || '-',
    kindDescText: kindDescObj?.kindDesc || '-',
    packDescText: packDescObj?.packDesc || '-'
  };
};
export { covertTempCustom, covertTempGoods };
