import { postGoodsInfoList, deleteGoodsInfoById, postGoodsInfoAdd, putGoodsInfoEdit } from '@/services/FieldType/api';
const FieldType = {
  state: {
    params: {},
    list: [],
    total: 0
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async postGoodsInfoList(payload: any) {
      const { data } = payload;
      const res: any = await postGoodsInfoList(data);
      const { result } = this as any;
      result({
        list: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      });
    },
    async deleteGoodsInfoById(payload: any) {
      const { id } = payload;
      return await deleteGoodsInfoById(id);
    },
    async postGoodsInfoAdd(payload: any) {
      const { data } = payload;
      return await postGoodsInfoAdd(data);
    },
    async putGoodsInfoEdit(payload: any) {
      const { data } = payload;
      return await putGoodsInfoEdit(data);
    }
  }
};
export default FieldType;
