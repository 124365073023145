import {
  sysResourceSave,
  sysResourceModify,
  sysResourcedeleteById,
  sysResourceDeleteByIds,
  sysResourceList,
  updateBatch
} from '@/services/system/menuManagement';

const menuManagement = {
  state: {
    pageType: "",
    TreeType: 'PC',
    title: "PC",
    selectedKeys: '0',
    rightTitle: ['PC'],
    tabs: [],
    treeData: []
  },
  reducers: {
    updateState(state: any, payload: any) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    async sysResourceSave({ data }: any) {
      const res: any = await sysResourceSave({ data });
      return res;
    },
    async sysResourceModify({ data }: any) {
      const res: any = await sysResourceModify({ data });
      return res;
    },
    async sysResourcedeleteById({ id }: any) {
      const res: any = await sysResourcedeleteById(id);
      return res;
    },
    async sysResourceDeleteByIds({ data }: any) {
      const res: any = await sysResourceDeleteByIds(data);
      return res;
    },
    async sysResourceList(data: any) {
      const res: any = await sysResourceList(data);
      return res;
    },
    async updateBatch(data: any) {
      const res: any = await updateBatch(data);
      return res
    }
  },
};

export default menuManagement;
