import { API } from '@/const';
import request from '@/utils/request';

export async function getDataList(data: any) {
  return request(`${API.wallet}/walletAddress/page`, {
    method: 'post',
    data
  });
}

export async function dataSave({ data }: any) {
  return request(`${API.wallet}/walletAddress/save`, {
    method: 'post',
    data: {
      data
    }
  });
}

export async function updateById({ data }: any) {
  return request(`${API.wallet}/walletAddress/updateById`, {
    method: 'put',
    data: {
      data
    }
  });
}

export async function DeleteById(id: any) {
  return request(`${API.wallet}/walletAddress/deleteById/${id}`, {
    method: 'delete'
  });
}
export async function reset(id: any) {
  return request(`${API.wallet}/walletAddress/reset/${id}`, {
    method: 'put'
  });
}
export async function postWalletCustomAddress({ data }: any) {
  return request(`${API.wallet}/walletCustomAddress/page`, {
    method: 'post',
    data
  });
}
export async function postWalletCustomSaveBatch({ data }: any) {
  return request(`${API.wallet}/walletCustomAddress/updateByIds`, {
    method: 'put',
    data
  });
}
