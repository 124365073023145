
import ReactDOM from 'react-dom/client';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';
import init from './init';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
init(() => {
  root.render(<App />);
});

reportWebVitals();
