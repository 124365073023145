import { API } from '@/const';
import request from '@/utils/request';
export async function getList({ pid }: any) {
  return request(`${API.custom}/customType/getList`, {
    method: `post`,
    data: {
      data: {
        pid
      }
    }
  });
}

export async function customSave(data: any) {
  return request(`${API.custom}/custom/save`, {
    method: `post`,
    data: {
      data
    }
  });
}

export async function basisTycCorpList(params: any) {
  return request(`${API.basis}/basisTycCorp/getOne`, {
    method: `post`,
    data: {
      data: { ...params }
    }
  });
}
export async function customModify(params: any) {
  return request(`${API.custom}/custom/modify`, {
    method: `put`,
    data: {
      ...params
    }
  });
}
export async function customFranchiseeLink(data: any) {
  return request(`${API.custom}/customLink/page`, {
    method: `post`,
    data
  });
}
export async function customFranchiseeLinkSave(data: any) {
  return request(`${API.custom}/customLink/save`, {
    method: `post`,
    data: {
      data
    }
  });
}
export async function customFranchiseeLinkDeleteById(id: any) {
  return request(`${API.custom}/customLink/deleteById/${id}`, {
    method: `delete`
  });
}
export async function deleteBusinessOrderLeague(id: any) {
  return request(`${API.business}/businessOrderLeague/deleteById/${id}`, {
    method: `delete`
  });
}
export async function businessOrderLeagueSave(data: any) {
  return request(`${API.business}/businessOrderLeague/save`, {
    method: `post`,
    data: {
      data
    }
  });
}
export async function leagueAccountSave(data: any) {
  return request(`${API.business}/leagueAccount/save`, {
    method: `post`,
    data: {
      data
    }
  });
}
export async function leagueAccountPage(data: any) {
  return request(`${API.business}/leagueAccount/page`, {
    method: `post`,
    data
  });
}
export async function deleteLeagueAccount(id: any) {
  return request(`${API.business}/leagueAccount/deleteById/${id}`, {
    method: `delete`
  });
}
export async function leagueSettleUpdateById(data: any) {
  return request(`${API.business}/businessFreightSub/settleApprove`, {
    method: `post`,
    data
  });
}
export async function leagueSettleFreightUpdateById(data: any) {
  return request(`${API.business}/leagueSettleFreight/updateById`, {
    method: `put`,
    data
  });
}
export async function leagueSettleRecordPage(data: any) {
  return request(`${API.business}/leagueSettleRecord/page`, {
    method: `post`,
    data
  });
}
export async function withdrawalApprove(data: any) {
  return request(`${API.business}/businessFreightSub/withdrawalApprove`, {
    method: `post`,
    data
  });
}
export async function leagueWithdrawalFreightUpdateById(data: any) {
  return request(`${API.business}/leagueWithdrawalFreight/updateById`, {
    method: `put`,
    data
  });
}
export async function businessOrderDriverSave(data: any) {
  return request(`${API.business}/businessOrderDriver/save`, {
    method: `post`,
    data: {
      data
    }
  });
}
export async function businessOrderLeaderSave(data: any) {
  return request(`${API.business}/businessOrderLeader/save`, {
    method: `post`,
    data: {
      data
    }
  });
}
export async function deleteBusinessOrderDriver(id: any) {
  return request(`${API.business}/businessOrderDriver/deleteById/${id}`, {
    method: `delete`
  });
}
export async function deleteBusinessOrderLeader(id: any) {
  return request(`${API.business}/businessOrderLeader/deleteById/${id}`, {
    method: `delete`
  });
}
