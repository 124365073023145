import { API, CONTENT_TYPE } from '@/const';
import request from '@/utils/request';

export const postInfoList = async (data: any) =>
  request(`${API.basis}/basisAppVersions/page`, {
    method: 'post',
    data,
  });

export const postInfoAdd = async (data: any) =>
  request(`${API.basis}/basisAppVersions/uploadApk`, {
    method: 'post',
    headers: {
      'Content-Type': CONTENT_TYPE.FORM_DATA
    },
    data,
  });

export const changeInfoById = async (data: any) => {
  let url = `${API.basis}/basisAppVersions/modify`
  const keys = Object.keys(data?.data);
  let method = 'put'
  if (keys.includes("putawayStatus")) {
    url = `${API.basis}/basisAppVersions/updatePutaway`
    method = "post"
  }
  return request(url, {
    method,
    data,
  });
}
