import { useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import NoMatch from '../components/Layout/404';
import LazyComponent from './LazyComponent';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeLocaleStorage } from '@/utils/storage';
const RoutesData = [
  {
    path: '/',
    redirect: '/workBench/page'
  },
  {
    path: '/workBench',
    component: () => import('@/components/Layout/BaseLayout')
  },
  {
    path: '/login',
    component: () => import('@/views/Login')
  },
  {
    path: '/user/protocol',
    component: () => import('@/views/Login/components/protocol')
  },
  {
    path: '/privacy/policy',
    component: () => import('@/views/Login/components/policy')
  },
  {
    path: '/KnowledgeManagement/QuestionKnowledgeSearch',
    name: '知识库查询',
    component: () => import('@/views/QuestionKnowledge/search')
  }
];

const Routes = () => {
  const history = useHistory();
  const { initialState } = useDispatch();

  const init = async () => {
    const TenantListData = await initialState?.getTenantListFun?.();
    if (TenantListData?.length) {
      await initialState?.getRoleListFun?.();
      await Promise.all([initialState?.getAddressArea?.(), , initialState?.getResourceList?.(), , initialState?.fetchUserInfo?.(), , initialState?.getSysConfig?.()]);
    } else {
      removeLocaleStorage('AILIEYUN_ACCESS_TOKEN');
    }
  };

  useEffect(() => {
    if (!['/privacy/policy', '/login', '/user/protocol', '/KnowledgeManagement/QuestionKnowledgeSearch'].includes(history.location.pathname)) {
      init();
    }
  }, [window.location.pathname]);

  return (
    <Switch>
      {RoutesData.map(item => {
        if (item.redirect) {
          return <Route path={item.path} render={() => <Redirect to={item.redirect} />} exact key={item.path} />;
        }

        return <Route path={item.path} component={LazyComponent(item.component)} exact={item.exact} key={item.path} />;
      })}
      <Route component={NoMatch} key='noMatch' />
    </Switch>
  );
};

export default Routes;
