import {
  sysRoleSave,
  sysRoleModify,
  sysRoleDeleteById,
  getRoleMenuList,
  getMenuList,
  sysResourceRoleBindResource,
  sysbindAllResource,
  sysdeleteAllResource
} from '@/services/system/roleManagement';

const roleManagement = {
  state: {
    expandedKeys: ['0'],
    editMenuData: {},
    selectedKeys: null,
    RoleMenuList: [],
    tabs: [
      {
        title: 'PC',
        key: 'PC'
      }
    ],
    rightTitle: ['PC'],
    pageType: 'PC',
    init: false,
    OpenButton: {
      PC: [],
      APP: []
    },
    OpenMenu: {
      PC: [],
      APP: []
    }
  },
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async sysRoleSave({ data }: any) {
      const res: any = await sysRoleSave({ data });
      return res;
    },
    async sysRoleModify({ data }: any) {
      const res: any = await sysRoleModify({ data });
      return res;
    },
    async sysRoleDeleteById({ id }: any) {
      const res: any = await sysRoleDeleteById(id);
      return res;
    },
    async getRoleMenuList({ roleId }: any) {
      return await getRoleMenuList(roleId);
    },
    async getMenuList({ data }: any) {
      return await getMenuList(data);
    },
    async sysResourceRoleBindResource({ data, roleId }: any) {
      return await sysResourceRoleBindResource(data, roleId);
    },
    async sysbindAllResource({ roleId }: any) {
      return await sysbindAllResource(roleId);
    },
    async sysdeleteAllResource({ roleId }: any) {
      return await sysdeleteAllResource(roleId);
    }
  }
};

export default roleManagement;
