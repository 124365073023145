import { API } from '@/const';
import request from '@/utils/request';
export async function getFakeCaptchaSafe(phone?: string) {
  return request(`${API.auth}/sms/accreditPhone/${phone}`, {
    method: 'POST'
  });
}
export async function postBasisAccreditMessageSave(data?: any) {
  return request(`${API.wallet}/walletUser/setTransactionCode`, {
    method: 'POST',
    data
  });
}

export const postWalletUserGetOne = async (id: string) => {
  return request(`${API.wallet}/walletUser/getById/${id}`, {
    method: 'get'
  });
};
export async function updateById({ data }: any) {
  return request(`${API.wallet}/walletUser/updateById`, {
    method: 'put',
    data: {
      data
    }
  });
}
export async function getUserAccount() {
  return request(`${API.upms}/sysUser/getUser`, { method: 'GET' });
}
export async function getFakeCaptchaAccount(phone?: string) {
  return request(`${API.auth}/sms/resetAccount/${phone}`, {
    method: 'POST'
  });
}
export async function resetAccount(data?: any) {
  return request(`${API.upms}/sysAccount/resetAccount`, {
    method: 'PUT',
    data
  });
}
