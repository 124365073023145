import { getDataList, dataDeleteById, updateById, dataSave } from '@/services/Wallet/InvoicingInfo';
const InvoicingInfo = {
  state: {
    loading: false,
    dataSource: [],
    total: 0
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getDataList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      });
      const res: any = await getDataList(data);
      await result({
        loading: false,
        dataSource: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      });
      return res?.data?.content || [];
    },
    async dataDeleteById(id: string) {
      await dataDeleteById(id);
    },
    async updateById(data: any) {
      return await updateById(data);
    },
    async dataSave(data: any) {
      return await dataSave(data);
    }
  }
};

export default InvoicingInfo;
