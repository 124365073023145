import { API } from '@/const';
import request from '@/utils/request';

export const postDriverInfoList = async (data: any) =>
  request(`${API.basis}/basisDriver/page`, {
    method: 'post',
    data
  });

export const deleteDriverInfoById = async (id: number) =>
  request(`${API.basis}/basisDriver/deleteById/${id}`, {
    method: 'delete'
  });

export const postDriverInfoAdd = async (data: any) =>
  request(`${API.basis}/basisDriver/save`, {
    method: 'post',
    data
  });

export const putDriverInfoEdit = async (data: any) =>
  request(`${API.basis}/basisDriver/modify`, {
    method: 'put',
    data
  });

export const putDriverInfoUpdata = async (data: any) =>
  request(`${API.basis}/basisDriver/updateById`, {
    method: 'put',
    data
  });
export const putDriverInfoUnlock = async (data: any) =>
  request(`${API.basis}/basisDriver/unlock`, {
    method: 'put',
    data
  });

export const postDriverOne = async (data: any) =>
  request(`${API.basis}/basisDriver/getOne`, {
    method: 'post',
    data
  });
export const getDriverById = async (id: string) => {
  return request(`${API.basis}/basisDriver/getById/${id}`, {
    method: 'get'
  });
};
export const createApiMember = async (data: any) =>
  request(`${API.wallet}/walletWjyResource/createApiMember`, {
    method: 'post',
    data
  });
export const walletWjyResourceIndex = async (data: any) =>
  request(`${API.wallet}/walletWjyResource/index`, {
    method: 'post',
    data
  });
export const walletWjyResourceByUsernameGetBackMoney = async (data: any) =>
  request(`${API.wallet}/walletWjyResource/byUsernameGetBackMoney`, {
    method: 'post',
    data
  });
export const walletWjyResourceBackMoneyByUsername = async (data: any) =>
  request(`${API.wallet}/walletWjyResource/backMoneyByUsername`, {
    method: 'post',
    data
  });
export const walletKinsfolkManagementSave = async (data: any) =>
  request(`${API.wallet}/walletKinsfolkManagement/save`, {
    method: 'post',
    data
  });
export const walletKinsfolkManagementPage = async (data: any) =>
  request(`${API.wallet}/walletKinsfolkManagement/page`, {
    method: 'post',
    data
  });
export const deleteKinsfolkManagementById = async (id: number) =>
  request(`${API.wallet}/walletKinsfolkManagement/deleteById/${id}`, {
    method: 'delete'
  });
