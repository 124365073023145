import { API } from '@/const';
import request from '@/utils/request';

export const postListInfo = async (data: any) =>
  request(`${API.business}/businessFreightTemplate/getPage`, {
    method: 'post',
    data
  });

export const deleteInfoById = async (id: number) =>
  request(`${API.business}/businessFreightTemplate/deleteById/${id}`, {
    method: 'delete'
  });

export const postAddInfo = async (data: any) =>
  request(`${API.business}/businessFreightTemplate/save`, {
    method: 'post',
    data
  });

export const putEditInfo = async (data: any) =>
  request(`${API.business}/businessFreightTemplate/updateById`, {
    method: 'put',
    data
  });

export const getCommonInfo = async () =>
  request(`${API.business}/businessFreightTemplate/getParams`, {
    method: 'GET'
  });

export const postCustomInfo = async (data: any) =>
  request(`${API.custom}/custom/list`, {
    method: 'post',
    data
  });

export const postSaveFreight = async (data: any) =>
  request(`${API.business}/businessFreight/save`, {
    method: 'post',
    data
  });

export const postUpdateFreight = async (data: any) =>
  request(`${API.business}/businessFreight/updateFreight`, {
    method: 'post',
    data
  });

export const getBusinessFreightTemplateById = async (id: string) =>
  request(`${API.business}/businessFreightTemplate/getById/${id}`, {
    method: 'get'
  });

export const postBusinessFreightTemplateSchemeSave = async (data: any) =>
  request(`${API.business}/businessFreightTemplateScheme/save`, {
    method: 'post',
    data
  });

export async function getBusinessFreightTemplateSchemeOne(data: any) {
  return request(`${API.business}/businessFreightTemplateScheme/getOne`, {
    method: 'post',
    data
  });
}
export const postBusinessFreightTemplateSchemeUpdateById = async (data: any) =>
  request(`${API.business}/businessFreightTemplateScheme/updateById`, {
    method: 'put',
    data
  });
