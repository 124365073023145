import {
  postDriverInfoSend,
  postUpdateDriverInfo,
  postVehicleInfoSend,
  postUpdateVehicleInfo,
  postWayBillSend,
  postUpdateWayBill,
  postUpdateDataReport,
  postFlowFundsSend,
  postSynchronousData,
  postSynchronousConverge,
  postUpdateFlowFunds,
  postUpdateReport,
  postSyncVehicleInfo,
  postSyncDriverInfo,
  postActualCarrierInfoSend,
  postUpdateActualCarrierInfo,
  reportWaybillFirst,
  reportWaybillSecond,
  reportWaybillThirdly,
  reporDataFirst,
  reporDataSecond,
  reporDataThirdly,
  createReportSecond,
  createReportThirdly,
  updateOwnerStatements,
  reportContract,
  dataReportRepair,
  dataReportStatementSave,
  putDataInvoiceModify,
  createReportInvoice,
  reportThirdlyIds,
  syncActualCarrierBusinessLicense,
  dataReportSynCustomSequence
} from '@/services/DataReport/api';
const WaybillList = {
  namespace: 'DataReport',
  state: {},
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async postDriverInfoSend(payload: any) {
      const { data } = payload;
      return await postDriverInfoSend({ ...data });
    },
    async postUpdateDriverInfo(payload: any) {
      const { data } = payload;
      return await postUpdateDriverInfo({ ...data });
    },
    async postVehicleInfoSend(payload: any) {
      const { data } = payload;
      return await postVehicleInfoSend({ ...data });
    },
    async postUpdateVehicleInfo(payload: any) {
      const { data } = payload;
      return await postUpdateVehicleInfo({ ...data });
    },
    async postWayBillSend(payload: any) {
      const { data } = payload;
      return await postWayBillSend({ ...data });
    },
    async postUpdateWayBill(payload: any) {
      const { data } = payload;
      return await postUpdateWayBill({ ...data });
    },
    async postUpdateDataReport(payload: any) {
      const { data } = payload;
      return await postUpdateDataReport({ ...data });
    },
    async postFlowFundsSend(payload: any) {
      const { data } = payload;
      return await postFlowFundsSend({ ...data });
    },
    async postSynchronousData(payload: any) {
      const { data } = payload;
      return await postSynchronousData({ ...data });
    },
    async postSynchronousConverge(payload: any) {
      const { data } = payload;
      return await postSynchronousConverge({ ...data });
    },
    async postUpdateFlowFunds(payload: any) {
      const { data } = payload;
      return await postUpdateFlowFunds({ ...data });
    },
    async postUpdateReport(payload: any) {
      const { data } = payload;
      return await postUpdateReport({ ...data });
    },
    async postSyncVehicleInfo(payload: any) {
      const { data } = payload;
      return await postSyncVehicleInfo({ ...data });
    },
    async postSyncDriverInfo(payload: any) {
      const { data } = payload;
      return await postSyncDriverInfo({ ...data });
    },
    async postActualCarrierInfoSend(payload: any) {
      const { data } = payload;
      return await postActualCarrierInfoSend({ ...data });
    },
    async postUpdateActualCarrierInfo(payload: any) {
      const { data } = payload;
      return await postUpdateActualCarrierInfo({ ...data });
    },
    async reportWaybillFirst(payload: any) {
      const { data } = payload;
      return await reportWaybillFirst({ ...data });
    },
    async reportWaybillSecond(payload: any) {
      const { data } = payload;
      return await reportWaybillSecond({ ...data });
    },
    async reportWaybillThirdly(payload: any) {
      const { data } = payload;
      return await reportWaybillThirdly({ ...data });
    },
    async reporDataFirst(payload: any) {
      const { data } = payload;
      return await reporDataFirst({ ...data });
    },
    async reporDataSecond(payload: any) {
      const { data } = payload;
      return await reporDataSecond({ ...data });
    },
    async reporDataThirdly(payload: any) {
      const { data } = payload;
      return await reporDataThirdly({ ...data });
    },
    async createReportSecond(payload: any) {
      const { data } = payload;
      return await createReportSecond({ ...data });
    },
    async createReportThirdly(payload: any) {
      const { data } = payload;
      return await createReportThirdly({ ...data });
    },
    async updateOwnerStatements(payload: any) {
      const { data } = payload;
      return await updateOwnerStatements({ ...data });
    },
    async reportContract(payload: any) {
      const { data } = payload;
      return await reportContract({ ...data });
    },
    async dataReportRepair(payload: any) {
      const { data } = payload;
      return await dataReportRepair({ ...data });
    },
    async dataReportStatementSave(payload: any) {
      const { data } = payload;
      return await dataReportStatementSave({ ...data });
    },
    async putDataInvoiceModify(payload: any) {
      const { data } = payload;
      return await putDataInvoiceModify({ ...data });
    },
    async createReportInvoice(payload: any) {
      const { data } = payload;
      return await createReportInvoice({ ...data });
    },
    async reportThirdlyIds(payload: any) {
      const { data } = payload;
      return await reportThirdlyIds({ ...data });
    },
    async syncActualCarrierBusinessLicense(payload: any) {
      const { data } = payload;
      return await syncActualCarrierBusinessLicense(data);
    },
    async dataReportSynCustomSequence(payload: any) {
      const { data } = payload;
      return await dataReportSynCustomSequence(data);
    }
  }
};

export default WaybillList;
