import { API } from '@/const';
import request from '@/utils/request';

export async function getDataList(data: any) {
  return request(`${API.wallet}/walletCustomWallet/page`, {
    method: 'post',
    data
  });
}

export async function dataSave({ data }: any) {
  return request(`${API.wallet}/walletCustom/save`, {
    method: 'post',
    data: {
      data
    }
  });
}

export async function dataModify({ data }: any) {
  return request(`${API.wallet}/walletCustom/updateById`, {
    method: 'put',
    data: {
      data
    }
  });
}
