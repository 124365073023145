import { API } from '@/const';
import request from '@/utils/request';

export const postVehicleInfoList = async (data: any) =>
  request(`${API.basis}/basisDict/page`, {
    method: 'post',
    data
  });

export const deleteVehicleInfoById = async (id: number) =>
  request(`${API.basis}/basisDict/deleteById/${id}`, {
    method: 'delete'
  });

export const postVehicleInfoAdd = async (data: any) =>
  request(`${API.basis}/basisDict/save`, {
    method: 'post',
    data
  });

export const putVehicleInfoEdit = async (data: any) =>
  request(`${API.basis}/basisDict/updateById`, {
    method: 'put',
    data
  });

export const postVehicleAllList = async (data: any) =>
  request(`${API.basis}/basisDict/getList`, {
    method: 'post',
    data
  });

export const postVehicleBrandInfoList = async (data: any) =>
  request(`${API.basis}/basisVehicleBrand/list`, {
    method: 'post',
    data
  });

export const postVehicleBrandInfoAdd = async (data: any) =>
  request(`${API.basis}/basisVehicleBrand/save`, {
    method: 'post',
    data
  });

export const postVehicleBrandInfoEdit = async (data: any) =>
  request(`${API.basis}/basisVehicleBrand/updateById`, {
    method: 'put',
    data
  });

export const deleteVehicleBrandInfoById = async (id: number) =>
  request(`${API.basis}/basisVehicleBrand/deleteById/${id}`, {
    method: 'delete'
  });

export const postVehicleBrandTypeInfoAdd = async (data: any) =>
  request(`${API.basis}/basisBrandType/save`, {
    method: 'post',
    data
  });

export const postVehicleBrandTypeInfoEdit = async (data: any) =>
  request(`${API.basis}/basisBrandType/updateById`, {
    method: 'put',
    data
  });

export const deleteVehicleBrandTypeInfoById = async (id: number) =>
  request(`${API.basis}/basisBrandType/deleteById/${id}`, {
    method: 'delete'
  });
