import { postDriverInfoAdd, putDriverInfoEdit, postDriverOne } from '@/services/DriverManagement/api';
import moment from 'moment';
const covertData = (e: any) => {
  const data = JSON.parse(JSON.stringify(e));
  data.driverLicenseStart = data?.driverLicenseRange?.[0];
  data.driverLicenseEnd = data?.driverLicenseRange?.[1];
  data.certificateStart = data?.certificateRange?.[0];
  data.certificateEnd = data?.certificateRange?.[1];
  data.idcardStart = data?.idcardRange?.[0];
  data.idcardEnd = data?.idcardRange?.[1];
  delete data?.driverLicenseRange;
  delete data?.idcardRange;
  delete data?.certificateRange;
  for (const key in data) {
    if (
      [
        'certificateStart',
        'certificateEnd',
        'driverLicenseStart',
        'driverLicenseEnd',
        'idcardStart',
        'idcardEnd',
        'firstReceiveDate',
        'certificateReleaseData',
        'birthday'
      ].indexOf(key) !== -1
    ) {
      data[key] = data[key] ? moment(data[key]).format('YYYY-MM-DD 00:00:00') : '';
    }
    data.vehicleList = (data.vehicleList || []).map((item: any) => ({ vehicleId: item.vehicleId }));
  }
  return data;
};
const DriverListEdit = {
  state: {},
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async postDriverInfoAdd(payload: any) {
      const { data } = payload;
      return await postDriverInfoAdd({ data: covertData(data) });
    },
    async putDriverInfoEdit(payload: any) {
      const { data } = payload;
      return await putDriverInfoEdit({ data: covertData(data) });
    },
    async postDriverOne(payload: any) {
      const { data } = payload;
      return await postDriverOne({ data });
    }
  }
};
export default DriverListEdit;
