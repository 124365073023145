import { API } from '@/const';
import request from '@/utils/request';

export const postBusinessLineInfoList = async (data: any) =>
  request(`${API.business}/businessLine/page`, {
    method: 'post',
    data
  });

export const deleteBusinessLineInfoById = async (id: string) => {
  const _id = id.includes('-') ? id.split('-')[0] : id;
  const tenant_id = id.includes('-') ? id.split('-')[1] : '';
  return request(`${API.business}/businessLine/deleteById/${_id}`, {
    method: 'delete',
    tenant_id
  });
};

export const postBusinessLineInfoAdd = async (data: any) =>
  request(`${API.business}/businessLine/save`, {
    method: 'post',
    data
  });
export const postBusinessLineInfoSaveAndGet = async (data: any) =>
  request(`${API.business}/businessLine/saveAndGet`, {
    method: 'post',
    data
  });
export const putBusinessLineInfoEdit = async (data: any) =>
  request(`${API.business}/businessLine/modify`, {
    method: 'put',
    data
  });

export const putBusinessLineInfoUpdata = async (data: any) =>
  request(`${API.business}/businessLine/updateById`, {
    method: 'put',
    data
  });

export const getBusinessLineInfoById = async (id: string) => {
  const _id = id.includes('-') ? id.split('-')[0] : id;
  const tenant_id = id.includes('-') ? id.split('-')[1] : '';
  return request(`${API.business}/businessLine/listByAddressId/${_id}`, {
    method: 'get',
    tenant_id
  });
};
export async function getBusinessLineGetById(id: string) {
  return request(`${API.business}/businessLine/getById/${id}`, {
    method: 'get'
  });
}
