import { API } from '@/const';
import request from '@/utils/request';

export async function dataSave({ data }: any) {
  return request(`${API.wallet}/walletPayment/save`, {
    method: 'post',
    data: {
      data
    }
  });
}

export async function getDataList(data: any) {
  return request(`${API.wallet}/walletPayment/page`, {
    method: 'post',
    data
  });
}


export const updateByIds = async (data: any) => {
  return request(`${API.wallet}/walletPayment/updateByIds`, {
    method: 'put',
    data
  });
};

export async function walletPaymentDeleteById(id: any) {
  return request(`${API.wallet}/walletPayment/deleteById/${id}`, {
    method: 'delete'
  });
}

export const putRepealById = async (id: string) => {
  return request(`${API.wallet}/walletPayment/repeal/${id}`, {
    method: 'put'
  });
};
