import { API } from '@/const';
import request from '@/utils/request';

export async function getDataList({ params }: any) {
  return request(`${API.custom}/custom/page`, {
    method: 'post',
    data: {
      ...params
    }
  });
}

export async function getSearchList({ querydata }: any) {
  return request(`${API.custom}/custom/page`, {
    method: 'post',
    data: {
      ...querydata
    }
  });
}

export async function customdeleteById(customId: any) {
  return request(`${API.custom}/custom/deleteById/${customId}`, {
    method: 'delete'
  });
}

export async function customModify(params: any) {
  return request(`${API.custom}/custom/modify`, {
    method: 'put',
    data: {
      ...params
    }
  });
}

export async function customUpdateById(params: any) {
  return request(`${API.custom}/custom/updateById`, {
    method: 'put',
    data: {
      ...params
    }
  });
}
export async function customUpdateByIds(params: any) {
  return request(`${API.custom}/custom/updateByIds`, {
    method: 'put',
    data: {
      ...params
    }
  });
}
export async function walletPaymentAccountPage(data: any) {
  return request(`${API.wallet}/walletPaymentAccount/page`, {
    method: 'post',
    data
  });
}
export async function walletPaymentAccountDeleteById(id: any) {
  return request(`${API.wallet}/walletPaymentAccount/deleteById/${id}`, {
    method: 'delete'
  });
}
export async function walletPaymentAccountSave(data: any) {
  return request(`${API.wallet}/walletPaymentAccount/save`, {
    method: 'post',
    data
  });
}
