import { API } from '@/const';
import request from '@/utils/request';
export const postAddInfo = async (data: any) =>
  request(`${API.business}/businessOrderManagement/save`, {
    method: 'post',
    data
  });
export const putEditInfo = async (data: any) =>
  request(`${API.business}/businessOrderManagement/updateById`, {
    method: 'put',
    data
  });
export const putModifyInfo = async (data: any) =>
  request(`${API.business}/businessOrderManagement/modify`, {
    method: 'put',
    data
  });
export const getInfoById = async (id: string) =>
  request(`${API.business}/businessOrderManagement/getById/${id}`, {
    method: 'get'
  });
export const postApproveInfo = async (data: any) =>
  request(`${API.business}/businessOrderManagement/approve`, {
    method: 'put',
    data
  });
export const deleteInfoById = async (id: number) =>
  request(`${API.business}/businessOrderManagement/deleteById/${id}`, {
    method: 'delete'
  });
