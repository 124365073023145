import { API } from '@/const';
import request from '@/utils/request';

export const postBasisAllConfigAdd = async (data: any) =>
  request(`${API.basis}/basisAllConfig/save`, {
    method: 'post',
    data
  });

export const putBasisAllConfigEdit = async (data: any) =>
  request(`${API.basis}/basisAllConfig/updateById`, {
    method: 'put',
    data
  });

export const deleteBasisAllConfigById = async (id: number) =>
  request(`${API.basis}/basisAllConfig/deleteById/${id}`, {
    method: 'delete'
  });
export const postBasisAllConfigList = async (data: any) =>
  request(`${API.basis}/basisAllConfig/page`, {
    method: 'post',
    data
  });
