import { postVehicleInfoList } from '@/services/VehicleList/api';
const LinkVehicle = {
  namespace: 'LinkVehicle',
  state: {
    params: {},
    toal: 0,
    list: []
  },
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async postVehicleInfoList(payload: any) {
      const { params } = payload;
      const res: any = await postVehicleInfoList(params);
      const { data } = res || {};
      const { result } = this as any;
      result({
        list: data?.content || [],
        total: data?.totalElements || 0
      });
    }
  }
};
export default LinkVehicle;
