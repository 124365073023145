import { modifyConsultationComplaint, businessCreditRatingSave } from '@/services/ServiceCentre/api';
const ConsultationComplaint = {
  state: {},
  reducers: {},
  effects: {
    async modify({ data }: any) {
      const res: any = await modifyConsultationComplaint({ data });
      return res;
    },
    async businessCreditRatingSave({ data }: any) {
      return await businessCreditRatingSave({ data });
    }
  }
};

export default ConsultationComplaint;
