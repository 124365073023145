import { API } from '@/const';
import request from '@/utils/request';

export const getRouteHistroy = async (data: any) =>
  request(`${API.track}/track/gpsInfos`, {
    method: 'post',
    data
  });

export const getRouteRecent = async (data: any) =>
  request(`${API.track}/track/gpsInfo/recent`, {
    method: 'post',
    data
  });

export const getTrackList = async (data: any) =>
  request(`${API.track}/track/trackList`, {
    method: 'post',
    data
  });

export const postRepairRecord = async (data: any) =>
  request(`${API.analysis}/businessFreightRecord/repairRecord`, {
    method: 'post',
    data
  });
export const whetherTrackList = async (data: any) =>
  request(`${API.track}/track/whetherTrackList`, {
    method: 'post',
    data
  });
export const addTrackList = async (data: any) =>
  request(`${API.track}/track/addTrackList`, {
    method: 'post',
    data
  });
