import { walletRechargeSave, updateByIds, walletRechargeDeleteById, getDataList } from '@/services/Wallet/walletRecharge';

const walletRecharge = {
  state: {
    expandedKeys: ['0'],
    editMenuData: {},
    loading: false
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getDataList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      });
      const res: any = await getDataList(data);

      await result({
        loading: false,
        dataSource: (res?.data?.content || []).map((item: any) => ({ ...item, randomId: Math.random() })),
        total: res?.data?.totalElements || 0
      });
    },
    async updateByIds({ data }: any) {
      const res: any = await updateByIds(data);
      return res;
    },
    async walletRechargeDeleteById({ id }: any) {
      const res: any = await walletRechargeDeleteById(id);
      return res;
    },
    async walletRechargeSave({ data }: any) {
      const res: any = await walletRechargeSave({ data });
      return res;
    }
  }
};

export default walletRecharge;
