import {
  deleteDriverInfoById,
  putDriverInfoUpdata,
  getDriverById,
  createApiMember,
  walletWjyResourceIndex,
  walletWjyResourceByUsernameGetBackMoney,
  walletWjyResourceBackMoneyByUsername,
  walletKinsfolkManagementSave,
  walletKinsfolkManagementPage,
  deleteKinsfolkManagementById,
  putDriverInfoUnlock
} from '@/services/DriverManagement/api';
const DriverList = {
  namespace: 'DriverList',
  state: {},
  reducers: {},
  effects: {
    async deleteDriverInfoById(payload: any) {
      const { id } = payload;
      return await deleteDriverInfoById(id);
    },
    async putDriverInfoUpdata(payload: any) {
      const { data } = payload;
      return await putDriverInfoUpdata({ data });
    },
    async putDriverInfoUnlock(payload: any) {
      const { data } = payload;
      return await putDriverInfoUnlock({ data });
    },
    async getDriverById(payload: any) {
      const { id } = payload;
      return await getDriverById(id);
    },
    async createApiMember(payload: any) {
      const { data } = payload;
      return await createApiMember({ data });
    },
    async walletWjyResourceIndex(payload: any) {
      const { data } = payload;
      return await walletWjyResourceIndex({ data });
    },
    async walletWjyResourceByUsernameGetBackMoney(payload: any) {
      const { data } = payload;
      return await walletWjyResourceByUsernameGetBackMoney({ data });
    },
    async walletWjyResourceBackMoneyByUsername(payload: any) {
      const { data } = payload;
      return await walletWjyResourceBackMoneyByUsername({ data });
    },
    async walletKinsfolkManagementSave(payload: any) {
      const { data } = payload;
      return await walletKinsfolkManagementSave({ data });
    },
    async walletKinsfolkManagementPage(payload: any) {
      const { data } = payload;
      return await walletKinsfolkManagementPage({ data });
    },
    async deleteKinsfolkManagementById(payload: any) {
      const { id } = payload;
      return await deleteKinsfolkManagementById(id);
    }
  }
};

export default DriverList;
