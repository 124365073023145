import { API } from ".";
export const CustomImport = {
  text: "导入客户",
  img: `${BASE_IMG}09170340-b527-4188-ac40-5ac89192c819.png`,
  path: "/workBench/CustomerManagement/FormalCustomers",
  role: "CUSTOM:FORMALCUSTOMERS:IMPORT",
  API: `${API.custom}/custom/upload`,
  workBenchKey: "客户列表",
  link: `${BASE_MUBAN}excel/%E5%AE%A2%E6%88%B7%E6%A8%A1%E6%9D%BF.xlsx?v=${Math.ceil(Math.random() * 10)}`
}
export const DriverImport = {
  text: "导入司机",
  role: "DRIVERMANAGEMENT:DRIVERLIST:IMPORT",
  img: `${BASE_IMG}b9e100e0-f982-46e3-9d3c-55a576bd6ef2.png`,
  workBenchKey: "司机列表",
  path: "/workBench/DriverManagement/DriverList",
  API: `${API.basis}/basisDriver/upload`,
  link: `${BASE_MUBAN}excel/%E5%8F%B8%E6%9C%BA%E6%A8%A1%E6%9D%BF.xlsx?v=${Math.ceil(Math.random() * 10)}`
}
export const VehicleImport = {
  text: "导入车辆",
  role: "VEHICLEMANAGMENT:VEHICLELIST:IMPORT",
  workBenchKey: "车辆列表",
  img: `${BASE_IMG}9ba7cfaf-7c24-4055-9649-c0aefdae1470.png`,
  path: "/workBench/VehicleManagment/VehicleList",
  API: `${API.basis}/basisVehicle/upload`,
  link: `${BASE_MUBAN}excel/%E8%BD%A6%E8%BE%86%E6%A8%A1%E6%9D%BF.xlsx?v=${Math.ceil(Math.random() * 10)}`
}
export const WaybillImport = {
  text: "导入运单",
  img: `${BASE_IMG}950c2bbd-e6b7-4d23-988f-9e2f63fbbefb.png`,
  workBenchKey: "运单列表",
  icon: "#kx-yundanmoban",
  path: "/workBench/WaybillManage/WaybillList",
  API: `${API.analysis}/analysisData/upload`,
  link: `${BASE_MUBAN}excel/%E8%BF%90%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx?v=${Math.ceil(Math.random() * 10)}`
}