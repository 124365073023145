import { API } from '@/const';
import request from '@/utils/request';
export const basisOperationSlideshowList = async () =>
  request(`${API.basis}/basisOperationSlideshow/list`, {
    method: 'post',
    data: {
      data: {}
    }
  });
export const basisOperationManual = async (data: any) =>
  request(`${API.basis}/basisOperationManual/list`, {
    method: 'post',
    data: {
      data
    }
  });
export const basisOperationType = async () =>
  request(`${API.basis}/basisOperationType/getTreeList`, {
    method: 'post',
    data: { data: {} }
  });
export const deleteOperationSlideshowById = async (id: any) =>
  request(`${API.basis}/basisOperationSlideshow/deleteById/${id}`, {
    method: 'delete'
  });
export const postOperationSlideshowAdd = async (data: any) =>
  request(`${API.basis}/basisOperationSlideshow/save`, {
    method: 'post',
    data
  });
export const deleteOperationTypeById = async (id: any) =>
  request(`${API.basis}/basisOperationType/deleteById/${id}`, {
    method: 'delete'
  });
export const postOperationTypeAdd = async (data: any) =>
  request(`${API.basis}/basisOperationType/save`, {
    method: 'post',
    data
  });
export const putOperationTypeEdit = async (data: any) =>
  request(`${API.basis}/basisOperationType/updateById`, {
    method: 'put',
    data
  });
export const postOperationManualAdd = async (data: any) =>
  request(`${API.basis}/basisOperationManual/save`, {
    method: 'post',
    data
  });
export const putOperationManualEdit = async (data: any) =>
  request(`${API.basis}/basisOperationManual/updateById`, {
    method: 'put',
    data
  });
export const deleteOperationManualById = async (id: any) =>
  request(`${API.basis}/basisOperationManual/deleteById/${id}`, {
    method: 'delete'
  });
