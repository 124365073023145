import {
  sysDeptSave,
  sysDeptModify,
  sysUserRelationDeleteById,
  sysDeptDeleteById,
  sysDeptGetTree,
  sysUserRelationSave,
  sysgetUserList,
  sysUserRelationUpdateByIds
} from '@/services/system/departmentManagement';

const departmentManagement = {
  state: {
    pageType: '',
    rightTitle: [],
    tabs: [],
    selectedKeys: '',
    expandedKeys: '',
    editMenuData: {},
    deptlist: [],
    allMember: [],
  },
  reducers: {
    updateState(state: any, payload: any) {
      return Object.assign({}, state, payload);
    },
  },
  effects: {
    async sysDeptGetTree({ data }: any) {
      const res: any = await sysDeptGetTree({ data });
      return res;
    },
    async sysDeptSave({ data }: any) {
      const res: any = await sysDeptSave({ data });
      return res;
    },
    async sysDeptModify({ data }: any) {
      const res: any = await sysDeptModify({ data });
      return res;
    },
    async sysDeptDeleteById({ id }: any) {
      const res: any = await sysDeptDeleteById(id);
      return res;
    },
    async sysUserRelationDeleteById({ id }: any) {
      const res: any = await sysUserRelationDeleteById(id);
      return res;
    },
    async sysUserRelationSave({ data }: any) {
      const res = await sysUserRelationSave(data);
      return res;
    },
    async sysgetUserList({ deptId }: any) {
      const res: any = await sysgetUserList(deptId);
      return res;
    },
    async sysUserRelationUpdateByIds({ data }: any) {
      return await sysUserRelationUpdateByIds(data)
    }
  },
};

export default departmentManagement;
