import { getLocaleStorage } from './utils/storage';
import { SYSYTYPE } from '@/const/index';
import { getApp } from '@/services/common/api';
import { setLocaleStorage } from '@/utils/storage';
const loginPath = '/login';

export const getAppFun = async () => {
  const res: any = await getApp();
  if (res?.code === 0) {
    if (!res?.data?.tenantId) {
      return;
    }
    let tenantId = res?.data?.tenantId;
    let type = res?.data?.type;
    if (getLocaleStorage('TENANTID')) {
      tenantId = getLocaleStorage('TENANTID');
    }
    if (res?.data?.appId === tenantId) {
      setLocaleStorage('SYSTYPE', SYSYTYPE.PLATFORM);
    } else {
      setLocaleStorage('SYSTYPE', SYSYTYPE.CUSTOMER);
    }
    setLocaleStorage('APPID', res?.data?.appId);
    setLocaleStorage('TENANTTYPE', type);
    if (!getLocaleStorage('TENANTID')) {
      setLocaleStorage('TENANTID', tenantId);
    }
    return res;
  }
  return false;
};

export default async (callback: () => void) => {
  if (window.location.pathname !== loginPath) {
    await getAppFun();
  }
  callback();
};
