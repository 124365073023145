import { sysAccountSave, sysBlankipSave, sysAccountModify, sysUserModify, sysAccountDeleteById, sysIpDeleteById, sysLoginLogDeleteById } from '@/services/system/userManagement';

const userManagement = {
  state: {
    expandedKeys: ['0'],
    editMenuData: {}
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async sysAccountSave({ data }: any) {
      const res: any = await sysAccountSave({ data });
      return res;
    },
    async sysBlankipSave({ data }: any) {
      const res: any = await sysBlankipSave({ data });
      return res;
    },
    async sysAccountModify({ data }: any) {
      const res: any = await sysAccountModify({ data });
      return res;
    },
    async sysAccountDeleteById({ id }: any) {
      const res: any = await sysAccountDeleteById(id);
      return res;
    },
    async sysIpDeleteById({ id }: any) {
      const res: any = await sysIpDeleteById(id);
      return res;
    },
    async sysLoginLogDeleteById({ id }: any) {
      const res: any = await sysLoginLogDeleteById(id);
      return res;
    },

    async sysUserModify({ data }: any) {
      const res: any = await sysUserModify({ data });
      return res;
    }
  }
};

export default userManagement;
