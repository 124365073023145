import axios from 'axios';
import { message } from 'lieyun_design';
import { getHeaders } from '@/utils';
import { HTTP_STATUS } from '../consts/statusCode';
import logUtils from './tools/logUtils';
import { getLocaleStorage } from './storage';
import { ApiErrorRobot } from './lylog';
axios.defaults.withCredentials = false;
axios.defaults.timeout = 600000;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const loginPath = '/login';

axios.interceptors.request.use(
  (config: any) => {
    const headers = {
      ...getHeaders(),
      ...config.headers
    };
    config.headers = config.url.indexOf('restapi.amap.com') === -1 ? headers : {};
    if (config?.tenant_id) {
      config.headers.tenant_id = config.tenant_id;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  async (response: any) => {
    if (response?.status === HTTP_STATUS.AUTHENTICATE && location.pathname !== loginPath) {
      window.location.href = '/login';
      return;
    }

    if (
      response?.config?.url?.indexOf('custom/basisOcr/identify') !== -1 ||
      response?.config?.url?.indexOf('auth/oauth') !== -1 ||
      response?.config?.url?.indexOf('restapi.amap') !== -1
    ) {
      return response;
    }

    if (response?.config?.url?.indexOf('/export') !== -1) {
      const resText = await response.data.text();
      try {
        const resData = JSON.parse(resText || '{}');
        if (resData.code !== 0) {
          message.error(resData?.msg);
          return false;
        }
        return response;
      } catch (error) {
        return response;
      }
    }
    try {
      const res: any = response?.data;
      const { code, data, msg } = res;
      if ((code < -1 || data?.error === 'invalid_token') && location.pathname !== loginPath) {
        if (code < -1) {
          message.error('权限不足或存在异常，请联系管理员');
        }
        ApiErrorRobot(response);
        window.location.href = '/login';
        return;
      } else if (code === 0 && data !== false) {
        return response;
      } else {
        ApiErrorRobot(response);
        message.error(msg === 'MybatisPlusException' || msg === 'NullPointerException' ? '系统异常，请联系管理员' : msg || '操作失败');
        return false;
      }
    } catch (error) {}

    return response;
  },
  err => {
    if (!err.response) {
      return;
    }
    const res = err.response;
    ApiErrorRobot(res);
    if (res?.status === HTTP_STATUS.AUTHENTICATE && location.pathname !== loginPath) {
      window.location.href = '/login';
      Promise.reject(err);
      return;
    }
    if (res?.status === HTTP_STATUS.AUTHENTICATE && location.pathname == loginPath) {
      message.error(res?.data?.error_description || '出现异常，请联系客服！');
      return;
    }
    message.error(`${res?.data?.message || ''}`);
    return Promise.reject(err);
  }
);

const clearBlankAll = (e: any) => {
  for (const key in e) {
    const element = e[key];
    if (Object.prototype.toString.call(element) !== '[object Object]' && Object.prototype.toString.call(element) !== '[object Array]') {
      e[key] = clearBlank(element, key);
    } else {
      clearBlankAll(element);
    }
  }
  return e;
};
const clearBlank = (e: string, key: any) => {
  let value: string;
  if (Object.prototype.toString.call(e) === '[object String]') {
    if ((e?.includes?.(':') && e?.includes?.('-')) || ['businessNo', 'freightId', 'shippingNoteNumber', 'freightSubId', 'contractFreightSubId'].includes(key)) {
      value = e?.replace?.(/^\s+|\s+$/g, '');
    } else {
      value = e?.replace?.(/\s+/g, '');
    }
  } else {
    value = e;
  }
  return value;
};
const safeRequest = (url: string, options: any) => {
  if (options.method && (options.method !== 'GET' || options.method !== 'get')) {
    if (options?.data) {
      options.data.data = clearBlankAll(options.data.data || {});
      if (!options.data.data.addressId) {
        const addressInfo = getLocaleStorage('ADDRESS_INFO');
        if (addressInfo) {
          options.data.data.addressId = JSON.parse(addressInfo)?.addressId;
        }
      }
    }
  }
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      ...options,
      url
    }).then(
      res => {
        if (res) {
          resolve(res.data);
        } else {
          reject(res);
        }
      },
      err => {
        logUtils.error(err);
        reject(err);
      }
    );
  });
};

export default safeRequest;
