import { API } from '@/const';
import request from '@/utils/request';

export async function getDataList(data: any) {
  return request(`${API.wallet}/walletServiceFee/page`, {
    method: "post",
    data
  })
}

export async function updateById(data: any) {
  return request(`${API.wallet}/walletServiceFee/updateById`, {
    method: "put",
    data: {
      data
    }
  })
}