export default [
  {
    adcode: '410000',
    name: '河南省',
    districts: [
      {
        adcode: '410300',
        name: '洛阳市',
        districts: [
          {
            adcode: '410323',
            name: '新安县',
            districts: [],
            isLeaf: true,
            center: '112.13246,34.728909'
          },
          {
            adcode: '410325',
            name: '嵩县',
            districts: [],
            isLeaf: true,
            center: '112.085432,34.134639'
          },
          {
            adcode: '410326',
            name: '汝阳县',
            districts: [],
            isLeaf: true,
            center: '112.473013,34.154283'
          },
          {
            adcode: '410327',
            name: '宜阳县',
            districts: [],
            isLeaf: true,
            center: '112.179187,34.51462'
          },
          {
            adcode: '410328',
            name: '洛宁县',
            districts: [],
            isLeaf: true,
            center: '111.652958,34.389371'
          },
          {
            adcode: '410324',
            name: '栾川县',
            districts: [],
            isLeaf: true,
            center: '111.615729,33.7857'
          },
          {
            adcode: '410303',
            name: '西工区',
            districts: [],
            isLeaf: true,
            center: '112.428183,34.659959'
          },
          {
            adcode: '410304',
            name: '瀍河回族区',
            districts: [],
            isLeaf: true,
            center: '112.500131,34.679773'
          },
          {
            adcode: '410302',
            name: '老城区',
            districts: [],
            isLeaf: true,
            center: '112.44671,34.698617'
          },
          {
            adcode: '410329',
            name: '伊川县',
            districts: [],
            isLeaf: true,
            center: '112.425751,34.421741'
          },
          {
            adcode: '410307',
            name: '偃师区',
            districts: [],
            isLeaf: true,
            center: '112.789375,34.72715'
          },
          {
            adcode: '410308',
            name: '孟津区',
            districts: [],
            isLeaf: true,
            center: '112.44525,34.825587'
          },
          {
            adcode: '410311',
            name: '洛龙区',
            districts: [],
            isLeaf: true,
            center: '112.463833,34.619711'
          },
          {
            adcode: '410305',
            name: '涧西区',
            districts: [],
            isLeaf: true,
            center: '112.395766,34.658177'
          }
        ]
      },
      {
        adcode: '411200',
        name: '三门峡市',
        districts: [
          {
            adcode: '411202',
            name: '湖滨区',
            districts: [],
            isLeaf: true,
            center: '111.188552,34.771296'
          },
          {
            adcode: '411221',
            name: '渑池县',
            districts: [],
            isLeaf: true,
            center: '111.761753,34.767937'
          },
          {
            adcode: '411281',
            name: '义马市',
            districts: [],
            isLeaf: true,
            center: '111.874726,34.747563'
          },
          {
            adcode: '411203',
            name: '陕州区',
            districts: [],
            isLeaf: true,
            center: '111.103252,34.72066'
          },
          {
            adcode: '411224',
            name: '卢氏县',
            districts: [],
            isLeaf: true,
            center: '111.04522,34.051866'
          },
          {
            adcode: '411282',
            name: '灵宝市',
            districts: [],
            isLeaf: true,
            center: '110.894225,34.517734'
          }
        ]
      },
      {
        adcode: '411100',
        name: '漯河市',
        districts: [
          {
            adcode: '411122',
            name: '临颍县',
            districts: [],
            isLeaf: true,
            center: '113.931121,33.828096'
          },
          {
            adcode: '411104',
            name: '召陵区',
            districts: [],
            isLeaf: true,
            center: '114.093879,33.586509'
          },
          {
            adcode: '411102',
            name: '源汇区',
            districts: [],
            isLeaf: true,
            center: '113.984164,33.572319'
          },
          {
            adcode: '411103',
            name: '郾城区',
            districts: [],
            isLeaf: true,
            center: '114.006596,33.587697'
          },
          {
            adcode: '411121',
            name: '舞阳县',
            districts: [],
            isLeaf: true,
            center: '113.609327,33.438259'
          }
        ]
      },
      {
        adcode: '411000',
        name: '许昌市',
        districts: [
          {
            adcode: '411003',
            name: '建安区',
            districts: [],
            isLeaf: true,
            center: '113.822869,34.124729'
          },
          {
            adcode: '411025',
            name: '襄城县',
            districts: [],
            isLeaf: true,
            center: '113.506904,33.851609'
          },
          {
            adcode: '411002',
            name: '魏都区',
            districts: [],
            isLeaf: true,
            center: '113.830826,34.047189'
          },
          {
            adcode: '411024',
            name: '鄢陵县',
            districts: [],
            isLeaf: true,
            center: '114.177329,34.102985'
          },
          {
            adcode: '411081',
            name: '禹州市',
            districts: [],
            isLeaf: true,
            center: '113.488715,34.142442'
          },
          {
            adcode: '411082',
            name: '长葛市',
            districts: [],
            isLeaf: true,
            center: '113.813972,34.195458'
          }
        ]
      },
      {
        adcode: '411500',
        name: '信阳市',
        districts: [
          {
            adcode: '411502',
            name: '浉河区',
            districts: [],
            isLeaf: true,
            center: '114.05867,32.116885'
          },
          {
            adcode: '411525',
            name: '固始县',
            districts: [],
            isLeaf: true,
            center: '115.654066,32.169239'
          },
          {
            adcode: '411527',
            name: '淮滨县',
            districts: [],
            isLeaf: true,
            center: '115.420101,32.474772'
          },
          {
            adcode: '411523',
            name: '新县',
            districts: [],
            isLeaf: true,
            center: '114.879309,31.643914'
          },
          {
            adcode: '411522',
            name: '光山县',
            districts: [],
            isLeaf: true,
            center: '114.91934,32.010736'
          },
          {
            adcode: '411528',
            name: '息县',
            districts: [],
            isLeaf: true,
            center: '114.740392,32.34312'
          },
          {
            adcode: '411521',
            name: '罗山县',
            districts: [],
            isLeaf: true,
            center: '114.512838,32.20436'
          },
          {
            adcode: '411503',
            name: '平桥区',
            districts: [],
            isLeaf: true,
            center: '114.125595,32.101005'
          },
          {
            adcode: '411524',
            name: '商城县',
            districts: [],
            isLeaf: true,
            center: '115.406894,31.79832'
          },
          {
            adcode: '411526',
            name: '潢川县',
            districts: [],
            isLeaf: true,
            center: '115.051683,32.131426'
          }
        ]
      },
      {
        adcode: '411300',
        name: '南阳市',
        districts: [
          {
            adcode: '411303',
            name: '卧龙区',
            districts: [],
            isLeaf: true,
            center: '112.528524,32.990313'
          },
          {
            adcode: '411328',
            name: '唐河县',
            districts: [],
            isLeaf: true,
            center: '112.80829,32.682464'
          },
          {
            adcode: '411323',
            name: '西峡县',
            districts: [],
            isLeaf: true,
            center: '111.473644,33.307272'
          },
          {
            adcode: '411321',
            name: '南召县',
            districts: [],
            isLeaf: true,
            center: '112.429277,33.489804'
          },
          {
            adcode: '411324',
            name: '镇平县',
            districts: [],
            isLeaf: true,
            center: '112.234571,33.033826'
          },
          {
            adcode: '411326',
            name: '淅川县',
            districts: [],
            isLeaf: true,
            center: '111.490902,33.137831'
          },
          {
            adcode: '411330',
            name: '桐柏县',
            districts: [],
            isLeaf: true,
            center: '113.428702,32.379164'
          },
          {
            adcode: '411325',
            name: '内乡县',
            districts: [],
            isLeaf: true,
            center: '111.849354,33.044698'
          },
          {
            adcode: '411381',
            name: '邓州市',
            districts: [],
            isLeaf: true,
            center: '112.08745,32.687732'
          },
          {
            adcode: '411329',
            name: '新野县',
            districts: [],
            isLeaf: true,
            center: '112.3601,32.521282'
          },
          {
            adcode: '411327',
            name: '社旗县',
            districts: [],
            isLeaf: true,
            center: '112.948183,33.056229'
          },
          {
            adcode: '411302',
            name: '宛城区',
            districts: [],
            isLeaf: true,
            center: '112.539506,33.003564'
          },
          {
            adcode: '411322',
            name: '方城县',
            districts: [],
            isLeaf: true,
            center: '113.012849,33.255387'
          }
        ]
      },
      {
        adcode: '411700',
        name: '驻马店市',
        districts: [
          {
            adcode: '411728',
            name: '遂平县',
            districts: [],
            isLeaf: true,
            center: '114.013323,33.146316'
          },
          {
            adcode: '411725',
            name: '确山县',
            districts: [],
            isLeaf: true,
            center: '114.016818,32.82134'
          },
          {
            adcode: '411727',
            name: '汝南县',
            districts: [],
            isLeaf: true,
            center: '114.362477,33.006808'
          },
          {
            adcode: '411723',
            name: '平舆县',
            districts: [],
            isLeaf: true,
            center: '114.618537,32.96344'
          },
          {
            adcode: '411721',
            name: '西平县',
            districts: [],
            isLeaf: true,
            center: '114.021492,33.387658'
          },
          {
            adcode: '411726',
            name: '泌阳县',
            districts: [],
            isLeaf: true,
            center: '113.327144,32.723974'
          },
          {
            adcode: '411702',
            name: '驿城区',
            districts: [],
            isLeaf: true,
            center: '113.993753,32.97455'
          },
          {
            adcode: '411722',
            name: '上蔡县',
            districts: [],
            isLeaf: true,
            center: '114.264381,33.262439'
          },
          {
            adcode: '411729',
            name: '新蔡县',
            districts: [],
            isLeaf: true,
            center: '114.965519,32.74488'
          },
          {
            adcode: '411724',
            name: '正阳县',
            districts: [],
            isLeaf: true,
            center: '114.392774,32.605697'
          }
        ]
      },
      {
        adcode: '411600',
        name: '周口市',
        districts: [
          {
            adcode: '411623',
            name: '商水县',
            districts: [],
            isLeaf: true,
            center: '114.608135,33.562072'
          },
          {
            adcode: '411602',
            name: '川汇区',
            districts: [],
            isLeaf: true,
            center: '114.650551,33.647679'
          },
          {
            adcode: '411628',
            name: '鹿邑县',
            districts: [],
            isLeaf: true,
            center: '115.484525,33.860698'
          },
          {
            adcode: '411603',
            name: '淮阳区',
            districts: [],
            isLeaf: true,
            center: '114.852604,33.731543'
          },
          {
            adcode: '411622',
            name: '西华县',
            districts: [],
            isLeaf: true,
            center: '114.529709,33.768277'
          },
          {
            adcode: '411627',
            name: '太康县',
            districts: [],
            isLeaf: true,
            center: '114.837946,34.064513'
          },
          {
            adcode: '411624',
            name: '沈丘县',
            districts: [],
            isLeaf: true,
            center: '115.098541,33.409805'
          },
          {
            adcode: '411681',
            name: '项城市',
            districts: [],
            isLeaf: true,
            center: '114.875128,33.465609'
          },
          {
            adcode: '411621',
            name: '扶沟县',
            districts: [],
            isLeaf: true,
            center: '114.394666,34.060062'
          },
          {
            adcode: '411625',
            name: '郸城县',
            districts: [],
            isLeaf: true,
            center: '115.177141,33.64601'
          }
        ]
      },
      {
        adcode: '410500',
        name: '安阳市',
        districts: [
          {
            adcode: '410527',
            name: '内黄县',
            districts: [],
            isLeaf: true,
            center: '114.90146,35.972016'
          },
          {
            adcode: '410503',
            name: '北关区',
            districts: [],
            isLeaf: true,
            center: '114.355742,36.10766'
          },
          {
            adcode: '410523',
            name: '汤阴县',
            districts: [],
            isLeaf: true,
            center: '114.357686,35.925155'
          },
          {
            adcode: '410581',
            name: '林州市',
            districts: [],
            isLeaf: true,
            center: '113.819275,36.084127'
          },
          {
            adcode: '410526',
            name: '滑县',
            districts: [],
            isLeaf: true,
            center: '114.517977,35.576128'
          },
          {
            adcode: '410522',
            name: '安阳县',
            districts: [],
            isLeaf: true,
            center: '114.462953,36.09211'
          },
          {
            adcode: '410502',
            name: '文峰区',
            districts: [],
            isLeaf: true,
            center: '114.356967,36.090593'
          },
          {
            adcode: '410506',
            name: '龙安区',
            districts: [],
            isLeaf: true,
            center: '114.301037,36.076937'
          },
          {
            adcode: '410505',
            name: '殷都区',
            districts: [],
            isLeaf: true,
            center: '114.303454,36.109938'
          }
        ]
      },
      {
        adcode: '410600',
        name: '鹤壁市',
        districts: [
          {
            adcode: '410622',
            name: '淇县',
            districts: [],
            isLeaf: true,
            center: '114.208851,35.622571'
          },
          {
            adcode: '410603',
            name: '山城区',
            districts: [],
            isLeaf: true,
            center: '114.184391,35.897862'
          },
          {
            adcode: '410602',
            name: '鹤山区',
            districts: [],
            isLeaf: true,
            center: '114.163133,35.954647'
          },
          {
            adcode: '410611',
            name: '淇滨区',
            districts: [],
            isLeaf: true,
            center: '114.298748,35.741763'
          },
          {
            adcode: '410621',
            name: '浚县',
            districts: [],
            isLeaf: true,
            center: '114.550611,35.677249'
          }
        ]
      },
      {
        adcode: '410900',
        name: '濮阳市',
        districts: [
          {
            adcode: '410927',
            name: '台前县',
            districts: [],
            isLeaf: true,
            center: '115.871671,35.970286'
          },
          {
            adcode: '410922',
            name: '清丰县',
            districts: [],
            isLeaf: true,
            center: '115.104602,35.886694'
          },
          {
            adcode: '410926',
            name: '范县',
            districts: [],
            isLeaf: true,
            center: '115.504081,35.85302'
          },
          {
            adcode: '410923',
            name: '南乐县',
            districts: [],
            isLeaf: true,
            center: '115.205876,36.070219'
          },
          {
            adcode: '410928',
            name: '濮阳县',
            districts: [],
            isLeaf: true,
            center: '115.029165,35.713113'
          },
          {
            adcode: '410902',
            name: '华龙区',
            districts: [],
            isLeaf: true,
            center: '115.074453,35.778289'
          }
        ]
      },
      {
        adcode: '419001',
        name: '济源市',
        districts: [],
        isLeaf: true,
        center: '112.602347,35.069057'
      },
      {
        adcode: '410400',
        name: '平顶山市',
        districts: [
          {
            adcode: '410403',
            name: '卫东区',
            districts: [],
            isLeaf: true,
            center: '113.335316,33.735388'
          },
          {
            adcode: '410423',
            name: '鲁山县',
            districts: [],
            isLeaf: true,
            center: '112.908052,33.738434'
          },
          {
            adcode: '410411',
            name: '湛河区',
            districts: [],
            isLeaf: true,
            center: '113.3208727,33.72568115'
          },
          {
            adcode: '410481',
            name: '舞钢市',
            districts: [],
            isLeaf: true,
            center: '113.516427,33.314147'
          },
          {
            adcode: '410402',
            name: '新华区',
            districts: [],
            isLeaf: true,
            center: '116.866309,38.314094'
          },
          {
            adcode: '410482',
            name: '汝州市',
            districts: [],
            isLeaf: true,
            center: '112.844696,34.16717'
          },
          {
            adcode: '410422',
            name: '叶县',
            districts: [],
            isLeaf: true,
            center: '113.357476,33.627437'
          },
          {
            adcode: '410425',
            name: '郏县',
            districts: [],
            isLeaf: true,
            center: '113.21261,33.971787'
          },
          {
            adcode: '410421',
            name: '宝丰县',
            districts: [],
            isLeaf: true,
            center: '113.054538,33.869159'
          },
          {
            adcode: '410404',
            name: '石龙区',
            districts: [],
            isLeaf: true,
            center: '112.898845,33.899247'
          }
        ]
      },
      {
        adcode: '410200',
        name: '开封市',
        districts: [
          {
            adcode: '410221',
            name: '杞县',
            districts: [],
            isLeaf: true,
            center: '114.782629,34.549731'
          },
          {
            adcode: '410205',
            name: '禹王台区',
            districts: [],
            isLeaf: true,
            center: '114.348105,34.777052'
          },
          {
            adcode: '410222',
            name: '通许县',
            districts: [],
            isLeaf: true,
            center: '114.47315,34.502053'
          },
          {
            adcode: '410223',
            name: '尉氏县',
            districts: [],
            isLeaf: true,
            center: '114.19305,34.411437'
          },
          {
            adcode: '410204',
            name: '鼓楼区',
            districts: [],
            isLeaf: true,
            center: '117.185643,34.288736'
          },
          {
            adcode: '410225',
            name: '兰考县',
            districts: [],
            isLeaf: true,
            center: '114.818228,34.856372'
          },
          {
            adcode: '410203',
            name: '顺河回族区',
            districts: [],
            isLeaf: true,
            center: '114.360824,34.795933'
          },
          {
            adcode: '410212',
            name: '祥符区',
            districts: [],
            isLeaf: true,
            center: '114.441406,34.757546'
          },
          {
            adcode: '410202',
            name: '龙亭区',
            districts: [],
            isLeaf: true,
            center: '114.355378,34.815515'
          }
        ]
      },
      {
        adcode: '410100',
        name: '郑州市',
        districts: [
          {
            adcode: '410185',
            name: '登封市',
            districts: [],
            isLeaf: true,
            center: '113.05056,34.454877'
          },
          {
            adcode: '410183',
            name: '新密市',
            districts: [],
            isLeaf: true,
            center: '113.390932,34.540216'
          },
          {
            adcode: '410102',
            name: '中原区',
            districts: [],
            isLeaf: true,
            center: '113.612966,34.74828'
          },
          {
            adcode: '410108',
            name: '惠济区',
            districts: [],
            isLeaf: true,
            center: '113.617055,34.867994'
          },
          {
            adcode: '410105',
            name: '金水区',
            districts: [],
            isLeaf: true,
            center: '113.6603,34.800415'
          },
          {
            adcode: '410181',
            name: '巩义市',
            districts: [],
            isLeaf: true,
            center: '113.022354,34.748207'
          },
          {
            adcode: '410106',
            name: '上街区',
            districts: [],
            isLeaf: true,
            center: '113.309185,34.803514'
          },
          {
            adcode: '410184',
            name: '新郑市',
            districts: [],
            isLeaf: true,
            center: '113.74024,34.396479'
          },
          {
            adcode: '410104',
            name: '管城回族区',
            districts: [],
            isLeaf: true,
            center: '113.677446,34.754436'
          },
          {
            adcode: '410122',
            name: '中牟县',
            districts: [],
            isLeaf: true,
            center: '113.975798,34.720535'
          },
          {
            adcode: '410103',
            name: '二七区',
            districts: [],
            isLeaf: true,
            center: '113.640177,34.724138'
          },
          {
            adcode: '410182',
            name: '荥阳市',
            districts: [],
            isLeaf: true,
            center: '113.383385,34.7866'
          }
        ]
      },
      {
        adcode: '411400',
        name: '商丘市',
        districts: [
          {
            adcode: '411421',
            name: '民权县',
            districts: [],
            isLeaf: true,
            center: '115.175019,34.648384'
          },
          {
            adcode: '411423',
            name: '宁陵县',
            districts: [],
            isLeaf: true,
            center: '115.314221,34.461226'
          },
          {
            adcode: '411403',
            name: '睢阳区',
            districts: [],
            isLeaf: true,
            center: '115.653258,34.389048'
          },
          {
            adcode: '411402',
            name: '梁园区',
            districts: [],
            isLeaf: true,
            center: '115.613609,34.444519'
          },
          {
            adcode: '411422',
            name: '睢县',
            districts: [],
            isLeaf: true,
            center: '115.071957,34.446572'
          },
          {
            adcode: '411424',
            name: '柘城县',
            districts: [],
            isLeaf: true,
            center: '115.305182,34.092015'
          },
          {
            adcode: '411425',
            name: '虞城县',
            districts: [],
            isLeaf: true,
            center: '115.828519,34.400868'
          },
          {
            adcode: '411481',
            name: '永城市',
            districts: [],
            isLeaf: true,
            center: '116.449864,33.929927'
          },
          {
            adcode: '411426',
            name: '夏邑县',
            districts: [],
            isLeaf: true,
            center: '116.131422,34.237801'
          }
        ]
      },
      {
        adcode: '410800',
        name: '焦作市',
        districts: [
          {
            adcode: '410822',
            name: '博爱县',
            districts: [],
            isLeaf: true,
            center: '113.064701,35.170332'
          },
          {
            adcode: '410803',
            name: '中站区',
            districts: [],
            isLeaf: true,
            center: '113.182847,35.237227'
          },
          {
            adcode: '410802',
            name: '解放区',
            districts: [],
            isLeaf: true,
            center: '113.230555,35.240403'
          },
          {
            adcode: '410811',
            name: '山阳区',
            districts: [],
            isLeaf: true,
            center: '113.254581,35.215054'
          },
          {
            adcode: '410821',
            name: '修武县',
            districts: [],
            isLeaf: true,
            center: '113.447862,35.22286'
          },
          {
            adcode: '410883',
            name: '孟州市',
            districts: [],
            isLeaf: true,
            center: '112.791372,34.907229'
          },
          {
            adcode: '410804',
            name: '马村区',
            districts: [],
            isLeaf: true,
            center: '113.32212,35.257084'
          },
          {
            adcode: '410823',
            name: '武陟县',
            districts: [],
            isLeaf: true,
            center: '113.401848,35.099458'
          },
          {
            adcode: '410882',
            name: '沁阳市',
            districts: [],
            isLeaf: true,
            center: '112.950873,35.087569'
          },
          {
            adcode: '410825',
            name: '温县',
            districts: [],
            isLeaf: true,
            center: '113.08067,34.93961'
          }
        ]
      },
      {
        adcode: '410700',
        name: '新乡市',
        districts: [
          {
            adcode: '410704',
            name: '凤泉区',
            districts: [],
            isLeaf: true,
            center: '113.916183,35.384428'
          },
          {
            adcode: '410724',
            name: '获嘉县',
            districts: [],
            isLeaf: true,
            center: '113.657551,35.260153'
          },
          {
            adcode: '410727',
            name: '封丘县',
            districts: [],
            isLeaf: true,
            center: '114.418907,35.041237'
          },
          {
            adcode: '410781',
            name: '卫辉市',
            districts: [],
            isLeaf: true,
            center: '114.064584,35.398357'
          },
          {
            adcode: '410782',
            name: '辉县市',
            districts: [],
            isLeaf: true,
            center: '113.80572,35.462964'
          },
          {
            adcode: '410711',
            name: '牧野区',
            districts: [],
            isLeaf: true,
            center: '113.908561,35.315614'
          },
          {
            adcode: '410703',
            name: '卫滨区',
            districts: [],
            isLeaf: true,
            center: '113.865666,35.301796'
          },
          {
            adcode: '410783',
            name: '长垣市',
            districts: [],
            isLeaf: true,
            center: '114.66885,35.201628'
          },
          {
            adcode: '410721',
            name: '新乡县',
            districts: [],
            isLeaf: true,
            center: '113.813884,35.170066'
          },
          {
            adcode: '410725',
            name: '原阳县',
            districts: [],
            isLeaf: true,
            center: '113.939829,35.066873'
          },
          {
            adcode: '410726',
            name: '延津县',
            districts: [],
            isLeaf: true,
            center: '114.205624,35.165876'
          },
          {
            adcode: '410702',
            name: '红旗区',
            districts: [],
            isLeaf: true,
            center: '113.875211,35.302952'
          }
        ]
      }
    ]
  },
  {
    adcode: '440000',
    name: '广东省',
    districts: [
      {
        adcode: '440500',
        name: '汕头市',
        districts: [
          {
            adcode: '440515',
            name: '澄海区',
            districts: [],
            isLeaf: true,
            center: '116.755945,23.466314'
          },
          {
            adcode: '440523',
            name: '南澳县',
            districts: [],
            isLeaf: true,
            center: '117.023482,23.421658'
          },
          {
            adcode: '440512',
            name: '濠江区',
            districts: [],
            isLeaf: true,
            center: '116.726701,23.286605'
          },
          {
            adcode: '440514',
            name: '潮南区',
            districts: [],
            isLeaf: true,
            center: '116.439105,23.239196'
          },
          {
            adcode: '440513',
            name: '潮阳区',
            districts: [],
            isLeaf: true,
            center: '116.601677,23.264923'
          },
          {
            adcode: '440507',
            name: '龙湖区',
            districts: [],
            isLeaf: true,
            center: '116.716464,23.372211'
          },
          {
            adcode: '440511',
            name: '金平区',
            districts: [],
            isLeaf: true,
            center: '116.70341,23.365716'
          }
        ]
      },
      {
        adcode: '440600',
        name: '佛山市',
        districts: [
          {
            adcode: '440608',
            name: '高明区',
            districts: [],
            isLeaf: true,
            center: '112.892573,22.900047'
          },
          {
            adcode: '440607',
            name: '三水区',
            districts: [],
            isLeaf: true,
            center: '112.897271,23.156675'
          },
          {
            adcode: '440606',
            name: '顺德区',
            districts: [],
            isLeaf: true,
            center: '113.293197,22.805413'
          },
          {
            adcode: '440605',
            name: '南海区',
            districts: [],
            isLeaf: true,
            center: '113.143246,23.028875'
          },
          {
            adcode: '440604',
            name: '禅城区',
            districts: [],
            isLeaf: true,
            center: '113.122532,23.009475'
          }
        ]
      },
      {
        adcode: '441200',
        name: '肇庆市',
        districts: [
          {
            adcode: '441224',
            name: '怀集县',
            districts: [],
            isLeaf: true,
            center: '112.166908,23.920806'
          },
          {
            adcode: '441225',
            name: '封开县',
            districts: [],
            isLeaf: true,
            center: '111.512177,23.423928'
          },
          {
            adcode: '441223',
            name: '广宁县',
            districts: [],
            isLeaf: true,
            center: '112.440694,23.634808'
          },
          {
            adcode: '441284',
            name: '四会市',
            districts: [],
            isLeaf: true,
            center: '112.734309,23.326991'
          },
          {
            adcode: '441226',
            name: '德庆县',
            districts: [],
            isLeaf: true,
            center: '111.785544,23.145035'
          },
          {
            adcode: '441203',
            name: '鼎湖区',
            districts: [],
            isLeaf: true,
            center: '112.56738,23.159062'
          },
          {
            adcode: '441202',
            name: '端州区',
            districts: [],
            isLeaf: true,
            center: '112.485577,23.051847'
          },
          {
            adcode: '441204',
            name: '高要区',
            districts: [],
            isLeaf: true,
            center: '112.458055,23.02474'
          }
        ]
      },
      {
        adcode: '441300',
        name: '惠州市',
        districts: [
          {
            adcode: '441324',
            name: '龙门县',
            districts: [],
            isLeaf: true,
            center: '114.254898,23.727873'
          },
          {
            adcode: '441322',
            name: '博罗县',
            districts: [],
            isLeaf: true,
            center: '114.289602,23.172587'
          },
          {
            adcode: '441323',
            name: '惠东县',
            districts: [],
            isLeaf: true,
            center: '114.720136,22.984831'
          },
          {
            adcode: '441303',
            name: '惠阳区',
            districts: [],
            isLeaf: true,
            center: '114.456107,22.789431'
          },
          {
            adcode: '441302',
            name: '惠城区',
            districts: [],
            isLeaf: true,
            center: '114.382526,23.084657'
          }
        ]
      },
      {
        adcode: '440300',
        name: '深圳市',
        districts: [
          {
            adcode: '440306',
            name: '宝安区',
            districts: [],
            isLeaf: true,
            center: '113.883831,22.554986'
          },
          {
            adcode: '440305',
            name: '南山区',
            districts: [],
            isLeaf: true,
            center: '130.285991,47.315121'
          },
          {
            adcode: '440304',
            name: '福田区',
            districts: [],
            isLeaf: true,
            center: '114.055198,22.520922'
          },
          {
            adcode: '440307',
            name: '龙岗区',
            districts: [],
            isLeaf: true,
            center: '114.246884,22.720889'
          },
          {
            adcode: '440311',
            name: '光明区',
            districts: [],
            isLeaf: true,
            center: '113.936059,22.74875'
          },
          {
            adcode: '440308',
            name: '盐田区',
            districts: [],
            isLeaf: true,
            center: '114.236739,22.557001'
          },
          {
            adcode: '440310',
            name: '坪山区',
            districts: [],
            isLeaf: true,
            center: '114.350844,22.708786'
          },
          {
            adcode: '440309',
            name: '龙华区',
            districts: [],
            isLeaf: true,
            center: '110.328628,20.030843'
          },
          {
            adcode: '440303',
            name: '罗湖区',
            districts: [],
            isLeaf: true,
            center: '114.131611,22.548309'
          }
        ]
      },
      {
        adcode: '440400',
        name: '珠海市',
        districts: [
          {
            adcode: '440403',
            name: '斗门区',
            districts: [],
            isLeaf: true,
            center: '113.296228,22.209134'
          },
          {
            adcode: '440402',
            name: '香洲区',
            districts: [],
            isLeaf: true,
            center: '113.543372,22.265635'
          },
          {
            adcode: '440404',
            name: '金湾区',
            districts: [],
            isLeaf: true,
            center: '113.363224,22.146717'
          },
          {
            adcode: '440499',
            name: '澳门大学横琴校区(由澳门实施管辖)',
            districts: [],
            isLeaf: true,
            center: '113.548956,22.126197'
          }
        ]
      },
      {
        adcode: '440800',
        name: '湛江市',
        districts: [
          {
            adcode: '440881',
            name: '廉江市',
            districts: [],
            isLeaf: true,
            center: '110.286109,21.609988'
          },
          {
            adcode: '440883',
            name: '吴川市',
            districts: [],
            isLeaf: true,
            center: '110.779361,21.440763'
          },
          {
            adcode: '440882',
            name: '雷州市',
            districts: [],
            isLeaf: true,
            center: '110.097011,20.914548'
          },
          {
            adcode: '440811',
            name: '麻章区',
            districts: [],
            isLeaf: true,
            center: '110.333833,21.26437'
          },
          {
            adcode: '440825',
            name: '徐闻县',
            districts: [],
            isLeaf: true,
            center: '110.17595,20.325969'
          },
          {
            adcode: '440804',
            name: '坡头区',
            districts: [],
            isLeaf: true,
            center: '110.455192,21.244405'
          },
          {
            adcode: '440803',
            name: '霞山区',
            districts: [],
            isLeaf: true,
            center: '110.397721,21.192463'
          },
          {
            adcode: '440823',
            name: '遂溪县',
            districts: [],
            isLeaf: true,
            center: '110.25043,21.378371'
          },
          {
            adcode: '440802',
            name: '赤坎区',
            districts: [],
            isLeaf: true,
            center: '110.365592,21.265948'
          }
        ]
      },
      {
        adcode: '440700',
        name: '江门市',
        districts: [
          {
            adcode: '440784',
            name: '鹤山市',
            districts: [],
            isLeaf: true,
            center: '112.964203,22.765912'
          },
          {
            adcode: '440783',
            name: '开平市',
            districts: [],
            isLeaf: true,
            center: '112.698113,22.377378'
          },
          {
            adcode: '440781',
            name: '台山市',
            districts: [],
            isLeaf: true,
            center: '112.793812,22.251947'
          },
          {
            adcode: '440785',
            name: '恩平市',
            districts: [],
            isLeaf: true,
            center: '112.304904,22.183743'
          },
          {
            adcode: '440704',
            name: '江海区',
            districts: [],
            isLeaf: true,
            center: '113.111029,22.561301'
          },
          {
            adcode: '440705',
            name: '新会区',
            districts: [],
            isLeaf: true,
            center: '113.034454,22.458519'
          },
          {
            adcode: '440703',
            name: '蓬江区',
            districts: [],
            isLeaf: true,
            center: '113.078914,22.595285'
          }
        ]
      },
      {
        adcode: '441700',
        name: '阳江市',
        districts: [
          {
            adcode: '441781',
            name: '阳春市',
            districts: [],
            isLeaf: true,
            center: '111.791587,22.17041'
          },
          {
            adcode: '441721',
            name: '阳西县',
            districts: [],
            isLeaf: true,
            center: '111.617696,21.753935'
          },
          {
            adcode: '441702',
            name: '江城区',
            districts: [],
            isLeaf: true,
            center: '111.95486,21.862451'
          },
          {
            adcode: '441704',
            name: '阳东区',
            districts: [],
            isLeaf: true,
            center: '112.005586,21.869081'
          }
        ]
      },
      {
        adcode: '440900',
        name: '茂名市',
        districts: [
          {
            adcode: '440982',
            name: '化州市',
            districts: [],
            isLeaf: true,
            center: '110.639581,21.664483'
          },
          {
            adcode: '440904',
            name: '电白区',
            districts: [],
            isLeaf: true,
            center: '111.013368,21.513946'
          },
          {
            adcode: '440983',
            name: '信宜市',
            districts: [],
            isLeaf: true,
            center: '110.946866,22.354887'
          },
          {
            adcode: '440902',
            name: '茂南区',
            districts: [],
            isLeaf: true,
            center: '110.918566,21.641661'
          },
          {
            adcode: '440981',
            name: '高州市',
            districts: [],
            isLeaf: true,
            center: '110.853169,21.918017'
          }
        ]
      },
      {
        adcode: '441500',
        name: '汕尾市',
        districts: [
          {
            adcode: '441523',
            name: '陆河县',
            districts: [],
            isLeaf: true,
            center: '115.659978,23.301557'
          },
          {
            adcode: '441502',
            name: '城区',
            districts: [],
            isLeaf: true,
            center: '112.853452,35.50248'
          },
          {
            adcode: '441581',
            name: '陆丰市',
            districts: [],
            isLeaf: true,
            center: '115.652142,22.91874'
          },
          {
            adcode: '441521',
            name: '海丰县',
            districts: [],
            isLeaf: true,
            center: '115.322974,22.967212'
          }
        ]
      },
      {
        adcode: '445300',
        name: '云浮市',
        districts: [
          {
            adcode: '445322',
            name: '郁南县',
            districts: [],
            isLeaf: true,
            center: '111.535387,23.234561'
          },
          {
            adcode: '445381',
            name: '罗定市',
            districts: [],
            isLeaf: true,
            center: '111.569788,22.768345'
          },
          {
            adcode: '445321',
            name: '新兴县',
            districts: [],
            isLeaf: true,
            center: '112.225174,22.695915'
          },
          {
            adcode: '445303',
            name: '云安区',
            districts: [],
            isLeaf: true,
            center: '112.002947,23.070334'
          },
          {
            adcode: '445302',
            name: '云城区',
            districts: [],
            isLeaf: true,
            center: '112.043945,22.92815'
          }
        ]
      },
      {
        adcode: '441600',
        name: '河源市',
        districts: [
          {
            adcode: '441623',
            name: '连平县',
            districts: [],
            isLeaf: true,
            center: '114.488358,24.369552'
          },
          {
            adcode: '441621',
            name: '紫金县',
            districts: [],
            isLeaf: true,
            center: '115.183814,23.635597'
          },
          {
            adcode: '441625',
            name: '东源县',
            districts: [],
            isLeaf: true,
            center: '114.7466,23.790079'
          },
          {
            adcode: '441602',
            name: '源城区',
            districts: [],
            isLeaf: true,
            center: '114.703305,23.734055'
          },
          {
            adcode: '441624',
            name: '和平县',
            districts: [],
            isLeaf: true,
            center: '114.935634,24.469177'
          },
          {
            adcode: '441622',
            name: '龙川县',
            districts: [],
            isLeaf: true,
            center: '115.26002,24.100599'
          }
        ]
      },
      {
        adcode: '445100',
        name: '潮州市',
        districts: [
          {
            adcode: '445122',
            name: '饶平县',
            districts: [],
            isLeaf: true,
            center: '117.0045,23.663294'
          },
          {
            adcode: '445102',
            name: '湘桥区',
            districts: [],
            isLeaf: true,
            center: '116.628343,23.675104'
          },
          {
            adcode: '445103',
            name: '潮安区',
            districts: [],
            isLeaf: true,
            center: '116.676971,23.463598'
          }
        ]
      },
      {
        adcode: '445200',
        name: '揭阳市',
        districts: [
          {
            adcode: '445224',
            name: '惠来县',
            districts: [],
            isLeaf: true,
            center: '116.295038,23.033889'
          },
          {
            adcode: '445222',
            name: '揭西县',
            districts: [],
            isLeaf: true,
            center: '115.841742,23.431314'
          },
          {
            adcode: '445202',
            name: '榕城区',
            districts: [],
            isLeaf: true,
            center: '116.36714,23.525918'
          },
          {
            adcode: '445281',
            name: '普宁市',
            districts: [],
            isLeaf: true,
            center: '116.165646,23.297742'
          },
          {
            adcode: '445203',
            name: '揭东区',
            districts: [],
            isLeaf: true,
            center: '116.411928,23.567252'
          }
        ]
      },
      {
        adcode: '440200',
        name: '韶关市',
        districts: [
          {
            adcode: '440224',
            name: '仁化县',
            districts: [],
            isLeaf: true,
            center: '113.749175,25.085764'
          },
          {
            adcode: '440232',
            name: '乳源瑶族自治县',
            districts: [],
            isLeaf: true,
            center: '113.275875,24.775856'
          },
          {
            adcode: '440204',
            name: '浈江区',
            districts: [],
            isLeaf: true,
            center: '113.611077,24.804898'
          },
          {
            adcode: '440282',
            name: '南雄市',
            districts: [],
            isLeaf: true,
            center: '114.31184,25.117653'
          },
          {
            adcode: '440222',
            name: '始兴县',
            districts: [],
            isLeaf: true,
            center: '114.061998,24.953908'
          },
          {
            adcode: '440229',
            name: '翁源县',
            districts: [],
            isLeaf: true,
            center: '114.129986,24.350581'
          },
          {
            adcode: '440203',
            name: '武江区',
            districts: [],
            isLeaf: true,
            center: '113.58792,24.7929'
          },
          {
            adcode: '440205',
            name: '曲江区',
            districts: [],
            isLeaf: true,
            center: '113.604591,24.682826'
          },
          {
            adcode: '440233',
            name: '新丰县',
            districts: [],
            isLeaf: true,
            center: '114.206641,24.059909'
          },
          {
            adcode: '440281',
            name: '乐昌市',
            districts: [],
            isLeaf: true,
            center: '113.347669,25.129892'
          }
        ]
      },
      {
        adcode: '441800',
        name: '清远市',
        districts: [
          {
            adcode: '441882',
            name: '连州市',
            districts: [],
            isLeaf: true,
            center: '112.377255,24.780873'
          },
          {
            adcode: '441825',
            name: '连山壮族瑶族自治县',
            districts: [],
            isLeaf: true,
            center: '112.093726,24.570553'
          },
          {
            adcode: '441826',
            name: '连南瑶族自治县',
            districts: [],
            isLeaf: true,
            center: '112.287212,24.725953'
          },
          {
            adcode: '441881',
            name: '英德市',
            districts: [],
            isLeaf: true,
            center: '113.401827,24.20716'
          },
          {
            adcode: '441823',
            name: '阳山县',
            districts: [],
            isLeaf: true,
            center: '112.641199,24.465234'
          },
          {
            adcode: '441803',
            name: '清新区',
            districts: [],
            isLeaf: true,
            center: '113.017747,23.734677'
          },
          {
            adcode: '441802',
            name: '清城区',
            districts: [],
            isLeaf: true,
            center: '113.062612,23.697889'
          },
          {
            adcode: '441821',
            name: '佛冈县',
            districts: [],
            isLeaf: true,
            center: '113.531559,23.879455'
          }
        ]
      },
      {
        adcode: '440100',
        name: '广州市',
        districts: [
          {
            adcode: '440115',
            name: '南沙区',
            districts: [],
            isLeaf: true,
            center: '112.896229,9.548531'
          },
          {
            adcode: '440105',
            name: '海珠区',
            districts: [],
            isLeaf: true,
            center: '113.317412,23.084003'
          },
          {
            adcode: '440113',
            name: '番禺区',
            districts: [],
            isLeaf: true,
            center: '113.383917,22.93756'
          },
          {
            adcode: '440104',
            name: '越秀区',
            districts: [],
            isLeaf: true,
            center: '113.267065,23.128673'
          },
          {
            adcode: '440103',
            name: '荔湾区',
            districts: [],
            isLeaf: true,
            center: '113.218998,23.107123'
          },
          {
            adcode: '440114',
            name: '花都区',
            districts: [],
            isLeaf: true,
            center: '113.220125,23.404326'
          },
          {
            adcode: '440117',
            name: '从化区',
            districts: [],
            isLeaf: true,
            center: '113.586329,23.54915'
          },
          {
            adcode: '440118',
            name: '增城区',
            districts: [],
            isLeaf: true,
            center: '113.810734,23.261452'
          },
          {
            adcode: '440106',
            name: '天河区',
            districts: [],
            isLeaf: true,
            center: '113.361597,23.124817'
          },
          {
            adcode: '440112',
            name: '黄埔区',
            districts: [],
            isLeaf: true,
            center: '113.480613,23.181355'
          },
          {
            adcode: '440111',
            name: '白云区',
            districts: [],
            isLeaf: true,
            center: '106.623069,26.677932'
          }
        ]
      },
      {
        adcode: '442000',
        name: '中山市',
        districts: [],
        isLeaf: true,
        center: '113.392517,22.517024'
      },
      {
        adcode: '441900',
        name: '东莞市',
        districts: [],
        isLeaf: true,
        center: '113.751884,23.021016'
      },
      {
        adcode: '441400',
        name: '梅州市',
        districts: [
          {
            adcode: '441426',
            name: '平远县',
            districts: [],
            isLeaf: true,
            center: '115.891235,24.566928'
          },
          {
            adcode: '441427',
            name: '蕉岭县',
            districts: [],
            isLeaf: true,
            center: '116.171477,24.658963'
          },
          {
            adcode: '441481',
            name: '兴宁市',
            districts: [],
            isLeaf: true,
            center: '115.731133,24.137296'
          },
          {
            adcode: '441422',
            name: '大埔县',
            districts: [],
            isLeaf: true,
            center: '116.694825,24.347037'
          },
          {
            adcode: '441424',
            name: '五华县',
            districts: [],
            isLeaf: true,
            center: '115.775751,23.932568'
          },
          {
            adcode: '441403',
            name: '梅县区',
            districts: [],
            isLeaf: true,
            center: '116.081395,24.266191'
          },
          {
            adcode: '441402',
            name: '梅江区',
            districts: [],
            isLeaf: true,
            center: '116.116686,24.31065'
          },
          {
            adcode: '441423',
            name: '丰顺县',
            districts: [],
            isLeaf: true,
            center: '116.181895,23.739364'
          }
        ]
      }
    ]
  },
  {
    adcode: '150000',
    name: '内蒙古自治区',
    districts: [
      {
        adcode: '150300',
        name: '乌海市',
        districts: [
          {
            adcode: '150304',
            name: '乌达区',
            districts: [],
            isLeaf: true,
            center: '106.726043,39.505609'
          },
          {
            adcode: '150302',
            name: '海勃湾区',
            districts: [],
            isLeaf: true,
            center: '106.8228,39.690873'
          },
          {
            adcode: '150303',
            name: '海南区',
            districts: [],
            isLeaf: true,
            center: '106.891472,39.441803'
          }
        ]
      },
      {
        adcode: '150800',
        name: '巴彦淖尔市',
        districts: [
          {
            adcode: '150824',
            name: '乌拉特中旗',
            districts: [],
            isLeaf: true,
            center: '108.513653,41.58813'
          },
          {
            adcode: '150821',
            name: '五原县',
            districts: [],
            isLeaf: true,
            center: '108.267466,41.088446'
          },
          {
            adcode: '150822',
            name: '磴口县',
            districts: [],
            isLeaf: true,
            center: '107.008288,40.330534'
          },
          {
            adcode: '150823',
            name: '乌拉特前旗',
            districts: [],
            isLeaf: true,
            center: '108.652114,40.737018'
          },
          {
            adcode: '150825',
            name: '乌拉特后旗',
            districts: [],
            isLeaf: true,
            center: '107.074748,41.08412'
          },
          {
            adcode: '150802',
            name: '临河区',
            districts: [],
            isLeaf: true,
            center: '107.363536,40.750969'
          },
          {
            adcode: '150826',
            name: '杭锦后旗',
            districts: [],
            isLeaf: true,
            center: '107.151296,40.886596'
          }
        ]
      },
      {
        adcode: '150200',
        name: '包头市',
        districts: [
          {
            adcode: '150221',
            name: '土默特右旗',
            districts: [],
            isLeaf: true,
            center: '110.527831,40.578253'
          },
          {
            adcode: '150223',
            name: '达尔罕茂明安联合旗',
            districts: [],
            isLeaf: true,
            center: '110.432626,41.698992'
          },
          {
            adcode: '150206',
            name: '白云鄂博矿区',
            districts: [],
            isLeaf: true,
            center: '109.973195,41.769956'
          },
          {
            adcode: '150203',
            name: '昆都仑区',
            districts: [],
            isLeaf: true,
            center: '109.840249,40.658075'
          },
          {
            adcode: '150222',
            name: '固阳县',
            districts: [],
            isLeaf: true,
            center: '110.060055,41.034757'
          },
          {
            adcode: '150205',
            name: '石拐区',
            districts: [],
            isLeaf: true,
            center: '110.060783,40.676736'
          },
          {
            adcode: '150202',
            name: '东河区',
            districts: [],
            isLeaf: true,
            center: '110.044008,40.57626'
          },
          {
            adcode: '150204',
            name: '青山区',
            districts: [],
            isLeaf: true,
            center: '114.385424,30.639842'
          },
          {
            adcode: '150207',
            name: '九原区',
            districts: [],
            isLeaf: true,
            center: '109.968082,40.611346'
          }
        ]
      },
      {
        adcode: '150700',
        name: '呼伦贝尔市',
        districts: [
          {
            adcode: '150785',
            name: '根河市',
            districts: [],
            isLeaf: true,
            center: '121.520165,50.779758'
          },
          {
            adcode: '150782',
            name: '牙克石市',
            districts: [],
            isLeaf: true,
            center: '120.711639,49.285947'
          },
          {
            adcode: '150725',
            name: '陈巴尔虎旗',
            districts: [],
            isLeaf: true,
            center: '119.424026,49.328916'
          },
          {
            adcode: '150702',
            name: '海拉尔区',
            districts: [],
            isLeaf: true,
            center: '119.824542,49.234715'
          },
          {
            adcode: '150724',
            name: '鄂温克族自治旗',
            districts: [],
            isLeaf: true,
            center: '119.755253,49.146692'
          },
          {
            adcode: '150721',
            name: '阿荣旗',
            districts: [],
            isLeaf: true,
            center: '123.458943,48.126553'
          },
          {
            adcode: '150783',
            name: '扎兰屯市',
            districts: [],
            isLeaf: true,
            center: '122.708475,48.028396'
          },
          {
            adcode: '150703',
            name: '扎赉诺尔区',
            districts: [],
            isLeaf: true,
            center: '117.669939,49.511213'
          },
          {
            adcode: '150781',
            name: '满洲里市',
            districts: [],
            isLeaf: true,
            center: '117.379134,49.59862'
          },
          {
            adcode: '150727',
            name: '新巴尔虎右旗',
            districts: [],
            isLeaf: true,
            center: '116.8237,48.671865'
          },
          {
            adcode: '150726',
            name: '新巴尔虎左旗',
            districts: [],
            isLeaf: true,
            center: '118.269782,48.21827'
          },
          {
            adcode: '150723',
            name: '鄂伦春自治旗',
            districts: [],
            isLeaf: true,
            center: '123.725643,50.591604'
          },
          {
            adcode: '150784',
            name: '额尔古纳市',
            districts: [],
            isLeaf: true,
            center: '120.180506,50.243102'
          },
          {
            adcode: '150722',
            name: '莫力达瓦达斡尔族自治旗',
            districts: [],
            isLeaf: true,
            center: '124.519023,48.477728'
          }
        ]
      },
      {
        adcode: '152900',
        name: '阿拉善盟',
        districts: [
          {
            adcode: '152923',
            name: '额济纳旗',
            districts: [],
            isLeaf: true,
            center: '101.055861,41.953963'
          },
          {
            adcode: '152922',
            name: '阿拉善右旗',
            districts: [],
            isLeaf: true,
            center: '101.666917,39.216185'
          },
          {
            adcode: '152921',
            name: '阿拉善左旗',
            districts: [],
            isLeaf: true,
            center: '105.666208,38.833589'
          }
        ]
      },
      {
        adcode: '152200',
        name: '兴安盟',
        districts: [
          {
            adcode: '152202',
            name: '阿尔山市',
            districts: [],
            isLeaf: true,
            center: '119.943577,47.17744'
          },
          {
            adcode: '152224',
            name: '突泉县',
            districts: [],
            isLeaf: true,
            center: '121.593972,45.381918'
          },
          {
            adcode: '152222',
            name: '科尔沁右翼中旗',
            districts: [],
            isLeaf: true,
            center: '121.476357,45.060728'
          },
          {
            adcode: '152223',
            name: '扎赉特旗',
            districts: [],
            isLeaf: true,
            center: '122.899627,46.723154'
          },
          {
            adcode: '152221',
            name: '科尔沁右翼前旗',
            districts: [],
            isLeaf: true,
            center: '121.952638,46.079664'
          },
          {
            adcode: '152201',
            name: '乌兰浩特市',
            districts: [],
            isLeaf: true,
            center: '122.093309,46.072233'
          }
        ]
      },
      {
        adcode: '150100',
        name: '呼和浩特市',
        districts: [
          {
            adcode: '150104',
            name: '玉泉区',
            districts: [],
            isLeaf: true,
            center: '111.674157,40.753054'
          },
          {
            adcode: '150103',
            name: '回民区',
            districts: [],
            isLeaf: true,
            center: '111.623485,40.808906'
          },
          {
            adcode: '150125',
            name: '武川县',
            districts: [],
            isLeaf: true,
            center: '111.451226,41.096511'
          },
          {
            adcode: '150122',
            name: '托克托县',
            districts: [],
            isLeaf: true,
            center: '111.193879,40.277703'
          },
          {
            adcode: '150121',
            name: '土默特左旗',
            districts: [],
            isLeaf: true,
            center: '111.16373,40.729044'
          },
          {
            adcode: '150102',
            name: '新城区',
            districts: [],
            isLeaf: true,
            center: '108.960707,34.266601'
          },
          {
            adcode: '150105',
            name: '赛罕区',
            districts: [],
            isLeaf: true,
            center: '111.701166,40.792508'
          },
          {
            adcode: '150124',
            name: '清水河县',
            districts: [],
            isLeaf: true,
            center: '111.647351,39.921224'
          },
          {
            adcode: '150123',
            name: '和林格尔县',
            districts: [],
            isLeaf: true,
            center: '111.821695,40.379226'
          }
        ]
      },
      {
        adcode: '150900',
        name: '乌兰察布市',
        districts: [
          {
            adcode: '150929',
            name: '四子王旗',
            districts: [],
            isLeaf: true,
            center: '111.706663,41.533542'
          },
          {
            adcode: '150922',
            name: '化德县',
            districts: [],
            isLeaf: true,
            center: '114.01029,41.904123'
          },
          {
            adcode: '150923',
            name: '商都县',
            districts: [],
            isLeaf: true,
            center: '113.577778,41.562176'
          },
          {
            adcode: '150928',
            name: '察哈尔右翼后旗',
            districts: [],
            isLeaf: true,
            center: '113.191505,41.435937'
          },
          {
            adcode: '150981',
            name: '丰镇市',
            districts: [],
            isLeaf: true,
            center: '113.109875,40.437022'
          },
          {
            adcode: '150927',
            name: '察哈尔右翼中旗',
            districts: [],
            isLeaf: true,
            center: '112.635348,41.277449'
          },
          {
            adcode: '150921',
            name: '卓资县',
            districts: [],
            isLeaf: true,
            center: '112.577716,40.894618'
          },
          {
            adcode: '150924',
            name: '兴和县',
            districts: [],
            isLeaf: true,
            center: '113.834176,40.8723'
          },
          {
            adcode: '150926',
            name: '察哈尔右翼前旗',
            districts: [],
            isLeaf: true,
            center: '113.212409,40.783551'
          },
          {
            adcode: '150902',
            name: '集宁区',
            districts: [],
            isLeaf: true,
            center: '113.168973,41.000742'
          },
          {
            adcode: '150925',
            name: '凉城县',
            districts: [],
            isLeaf: true,
            center: '112.504243,40.532358'
          }
        ]
      },
      {
        adcode: '150600',
        name: '鄂尔多斯市',
        districts: [
          {
            adcode: '150625',
            name: '杭锦旗',
            districts: [],
            isLeaf: true,
            center: '108.736011,39.833243'
          },
          {
            adcode: '150621',
            name: '达拉特旗',
            districts: [],
            isLeaf: true,
            center: '110.034133,40.412766'
          },
          {
            adcode: '150624',
            name: '鄂托克旗',
            districts: [],
            isLeaf: true,
            center: '107.975948,39.089549'
          },
          {
            adcode: '150626',
            name: '乌审旗',
            districts: [],
            isLeaf: true,
            center: '108.819485,38.60453'
          },
          {
            adcode: '150623',
            name: '鄂托克前旗',
            districts: [],
            isLeaf: true,
            center: '107.47733,38.182402'
          },
          {
            adcode: '150622',
            name: '准格尔旗',
            districts: [],
            isLeaf: true,
            center: '111.239436,39.864923'
          },
          {
            adcode: '150627',
            name: '伊金霍洛旗',
            districts: [],
            isLeaf: true,
            center: '109.747435,39.565064'
          },
          {
            adcode: '150603',
            name: '康巴什区',
            districts: [],
            isLeaf: true,
            center: '109.858586,39.60793'
          },
          {
            adcode: '150602',
            name: '东胜区',
            districts: [],
            isLeaf: true,
            center: '109.963403,39.823066'
          }
        ]
      },
      {
        adcode: '152500',
        name: '锡林郭勒盟',
        districts: [
          {
            adcode: '152525',
            name: '东乌珠穆沁旗',
            districts: [],
            isLeaf: true,
            center: '116.97526,45.497329'
          },
          {
            adcode: '152522',
            name: '阿巴嘎旗',
            districts: [],
            isLeaf: true,
            center: '114.950185,44.022831'
          },
          {
            adcode: '152526',
            name: '西乌珠穆沁旗',
            districts: [],
            isLeaf: true,
            center: '117.608668,44.587746'
          },
          {
            adcode: '152523',
            name: '苏尼特左旗',
            districts: [],
            isLeaf: true,
            center: '113.667171,43.859718'
          },
          {
            adcode: '152502',
            name: '锡林浩特市',
            districts: [],
            isLeaf: true,
            center: '116.085814,43.933417'
          },
          {
            adcode: '152501',
            name: '二连浩特市',
            districts: [],
            isLeaf: true,
            center: '111.949594,43.64285'
          },
          {
            adcode: '152524',
            name: '苏尼特右旗',
            districts: [],
            isLeaf: true,
            center: '112.641806,42.742417'
          },
          {
            adcode: '152530',
            name: '正蓝旗',
            districts: [],
            isLeaf: true,
            center: '115.991648,42.241815'
          },
          {
            adcode: '152529',
            name: '正镶白旗',
            districts: [],
            isLeaf: true,
            center: '115.029748,42.287493'
          },
          {
            adcode: '152528',
            name: '镶黄旗',
            districts: [],
            isLeaf: true,
            center: '113.847287,42.232371'
          },
          {
            adcode: '152531',
            name: '多伦县',
            districts: [],
            isLeaf: true,
            center: '116.485549,42.203454'
          },
          {
            adcode: '152527',
            name: '太仆寺旗',
            districts: [],
            isLeaf: true,
            center: '115.283173,41.876889'
          }
        ]
      },
      {
        adcode: '150500',
        name: '通辽市',
        districts: [
          {
            adcode: '150581',
            name: '霍林郭勒市',
            districts: [],
            isLeaf: true,
            center: '119.681242,45.533598'
          },
          {
            adcode: '150525',
            name: '奈曼旗',
            districts: [],
            isLeaf: true,
            center: '120.657813,42.867433'
          },
          {
            adcode: '150523',
            name: '开鲁县',
            districts: [],
            isLeaf: true,
            center: '121.319216,43.601446'
          },
          {
            adcode: '150524',
            name: '库伦旗',
            districts: [],
            isLeaf: true,
            center: '121.810629,42.735556'
          },
          {
            adcode: '150526',
            name: '扎鲁特旗',
            districts: [],
            isLeaf: true,
            center: '120.917953,44.567093'
          },
          {
            adcode: '150521',
            name: '科尔沁左翼中旗',
            districts: [],
            isLeaf: true,
            center: '123.312202,44.126606'
          },
          {
            adcode: '150522',
            name: '科尔沁左翼后旗',
            districts: [],
            isLeaf: true,
            center: '122.35677,42.935105'
          },
          {
            adcode: '150502',
            name: '科尔沁区',
            districts: [],
            isLeaf: true,
            center: '122.25545,43.623133'
          }
        ]
      },
      {
        adcode: '150400',
        name: '赤峰市',
        districts: [
          {
            adcode: '150423',
            name: '巴林右旗',
            districts: [],
            isLeaf: true,
            center: '118.664235,43.534171'
          },
          {
            adcode: '150422',
            name: '巴林左旗',
            districts: [],
            isLeaf: true,
            center: '119.362832,43.961547'
          },
          {
            adcode: '150424',
            name: '林西县',
            districts: [],
            isLeaf: true,
            center: '118.05545,43.61812'
          },
          {
            adcode: '150430',
            name: '敖汉旗',
            districts: [],
            isLeaf: true,
            center: '119.921413,42.290731'
          },
          {
            adcode: '150402',
            name: '红山区',
            districts: [],
            isLeaf: true,
            center: '118.953989,42.296317'
          },
          {
            adcode: '150404',
            name: '松山区',
            districts: [],
            isLeaf: true,
            center: '118.916156,42.299795'
          },
          {
            adcode: '150426',
            name: '翁牛特旗',
            districts: [],
            isLeaf: true,
            center: '119.006187,42.936529'
          },
          {
            adcode: '150425',
            name: '克什克腾旗',
            districts: [],
            isLeaf: true,
            center: '117.545413,43.265057'
          },
          {
            adcode: '150429',
            name: '宁城县',
            districts: [],
            isLeaf: true,
            center: '119.320056,41.600193'
          },
          {
            adcode: '150421',
            name: '阿鲁科尔沁旗',
            districts: [],
            isLeaf: true,
            center: '120.065533,43.872398'
          },
          {
            adcode: '150403',
            name: '元宝山区',
            districts: [],
            isLeaf: true,
            center: '119.288475,42.039602'
          },
          {
            adcode: '150428',
            name: '喀喇沁旗',
            districts: [],
            isLeaf: true,
            center: '118.701934,41.927103'
          }
        ]
      }
    ]
  },
  {
    adcode: '230000',
    name: '黑龙江省',
    districts: [
      {
        adcode: '232700',
        name: '大兴安岭地区',
        districts: [
          {
            adcode: '232701',
            name: '漠河市',
            districts: [],
            isLeaf: true,
            center: '122.539313,52.972465'
          },
          {
            adcode: '232722',
            name: '塔河县',
            districts: [],
            isLeaf: true,
            center: '124.709733,52.334512'
          },
          {
            adcode: '232721',
            name: '呼玛县',
            districts: [],
            isLeaf: true,
            center: '126.651856,51.725924'
          },
          {
            adcode: '232718',
            name: '加格达奇区',
            districts: [],
            isLeaf: true,
            center: '124.139742,50.408822'
          }
        ]
      },
      {
        adcode: '230400',
        name: '鹤岗市',
        districts: [
          {
            adcode: '230422',
            name: '绥滨县',
            districts: [],
            isLeaf: true,
            center: '131.852863,47.289522'
          },
          {
            adcode: '230403',
            name: '工农区',
            districts: [],
            isLeaf: true,
            center: '130.27472,47.318971'
          },
          {
            adcode: '230407',
            name: '兴山区',
            districts: [],
            isLeaf: true,
            center: '130.303598,47.357698'
          },
          {
            adcode: '230404',
            name: '南山区',
            districts: [],
            isLeaf: true,
            center: '130.285991,47.315121'
          },
          {
            adcode: '230402',
            name: '向阳区',
            districts: [],
            isLeaf: true,
            center: '130.365263,46.807808'
          },
          {
            adcode: '230405',
            name: '兴安区',
            districts: [],
            isLeaf: true,
            center: '130.239289,47.252805'
          },
          {
            adcode: '230406',
            name: '东山区',
            districts: [],
            isLeaf: true,
            center: '130.317151,47.338571'
          },
          {
            adcode: '230421',
            name: '萝北县',
            districts: [],
            isLeaf: true,
            center: '130.820566,47.578979'
          }
        ]
      },
      {
        adcode: '230900',
        name: '七台河市',
        districts: [
          {
            adcode: '230903',
            name: '桃山区',
            districts: [],
            isLeaf: true,
            center: '131.020046,45.765733'
          },
          {
            adcode: '230921',
            name: '勃利县',
            districts: [],
            isLeaf: true,
            center: '130.592009,45.755856'
          },
          {
            adcode: '230904',
            name: '茄子河区',
            districts: [],
            isLeaf: true,
            center: '131.067545,45.785755'
          },
          {
            adcode: '230902',
            name: '新兴区',
            districts: [],
            isLeaf: true,
            center: '130.93226,45.816579'
          }
        ]
      },
      {
        adcode: '230100',
        name: '哈尔滨市',
        districts: [
          {
            adcode: '230126',
            name: '巴彦县',
            districts: [],
            isLeaf: true,
            center: '127.403521,46.086182'
          },
          {
            adcode: '230123',
            name: '依兰县',
            districts: [],
            isLeaf: true,
            center: '129.567777,46.325536'
          },
          {
            adcode: '230128',
            name: '通河县',
            districts: [],
            isLeaf: true,
            center: '128.74629,45.990214'
          },
          {
            adcode: '230127',
            name: '木兰县',
            districts: [],
            isLeaf: true,
            center: '128.043194,45.950649'
          },
          {
            adcode: '230129',
            name: '延寿县',
            districts: [],
            isLeaf: true,
            center: '128.311071,45.445956'
          },
          {
            adcode: '230125',
            name: '宾县',
            districts: [],
            isLeaf: true,
            center: '127.466372,45.746313'
          },
          {
            adcode: '230124',
            name: '方正县',
            districts: [],
            isLeaf: true,
            center: '128.829513,45.851673'
          },
          {
            adcode: '230108',
            name: '平房区',
            districts: [],
            isLeaf: true,
            center: '126.63675,45.597529'
          },
          {
            adcode: '230183',
            name: '尚志市',
            districts: [],
            isLeaf: true,
            center: '128.009218,45.209199'
          },
          {
            adcode: '230184',
            name: '五常市',
            districts: [],
            isLeaf: true,
            center: '127.167501,44.932164'
          },
          {
            adcode: '230112',
            name: '阿城区',
            districts: [],
            isLeaf: true,
            center: '126.957984,45.548671'
          },
          {
            adcode: '230111',
            name: '呼兰区',
            districts: [],
            isLeaf: true,
            center: '126.587704,45.889557'
          },
          {
            adcode: '230109',
            name: '松北区',
            districts: [],
            isLeaf: true,
            center: '126.516602,45.794991'
          },
          {
            adcode: '230113',
            name: '双城区',
            districts: [],
            isLeaf: true,
            center: '126.31279,45.382873'
          },
          {
            adcode: '230102',
            name: '道里区',
            districts: [],
            isLeaf: true,
            center: '126.616912,45.755832'
          },
          {
            adcode: '230110',
            name: '香坊区',
            districts: [],
            isLeaf: true,
            center: '126.662959,45.707871'
          },
          {
            adcode: '230104',
            name: '道外区',
            districts: [],
            isLeaf: true,
            center: '126.649094,45.792351'
          },
          {
            adcode: '230103',
            name: '南岗区',
            districts: [],
            isLeaf: true,
            center: '126.66882,45.759967'
          }
        ]
      },
      {
        adcode: '230800',
        name: '佳木斯市',
        districts: [
          {
            adcode: '230826',
            name: '桦川县',
            districts: [],
            isLeaf: true,
            center: '130.71908,47.023001'
          },
          {
            adcode: '230828',
            name: '汤原县',
            districts: [],
            isLeaf: true,
            center: '129.904747,46.730623'
          },
          {
            adcode: '230805',
            name: '东风区',
            districts: [],
            isLeaf: true,
            center: '130.403588,46.822609'
          },
          {
            adcode: '230804',
            name: '前进区',
            districts: [],
            isLeaf: true,
            center: '130.375254,46.81405'
          },
          {
            adcode: '230803',
            name: '向阳区',
            districts: [],
            isLeaf: true,
            center: '130.365263,46.807808'
          },
          {
            adcode: '230811',
            name: '郊区',
            districts: [],
            isLeaf: true,
            center: '113.593998,37.943703'
          },
          {
            adcode: '230883',
            name: '抚远市',
            districts: [],
            isLeaf: true,
            center: '134.30786,48.365027'
          },
          {
            adcode: '230822',
            name: '桦南县',
            districts: [],
            isLeaf: true,
            center: '130.553472,46.239314'
          },
          {
            adcode: '230882',
            name: '富锦市',
            districts: [],
            isLeaf: true,
            center: '132.037659,47.250144'
          },
          {
            adcode: '230881',
            name: '同江市',
            districts: [],
            isLeaf: true,
            center: '132.510113,47.642475'
          }
        ]
      },
      {
        adcode: '230500',
        name: '双鸭山市',
        districts: [
          {
            adcode: '230522',
            name: '友谊县',
            districts: [],
            isLeaf: true,
            center: '131.807815,46.767299'
          },
          {
            adcode: '230502',
            name: '尖山区',
            districts: [],
            isLeaf: true,
            center: '131.158372,46.646083'
          },
          {
            adcode: '230503',
            name: '岭东区',
            districts: [],
            isLeaf: true,
            center: '131.165067,46.592663'
          },
          {
            adcode: '230506',
            name: '宝山区',
            districts: [],
            isLeaf: true,
            center: '121.489431,31.405242'
          },
          {
            adcode: '230523',
            name: '宝清县',
            districts: [],
            isLeaf: true,
            center: '132.196907,46.327335'
          },
          {
            adcode: '230524',
            name: '饶河县',
            districts: [],
            isLeaf: true,
            center: '134.014125,46.79726'
          },
          {
            adcode: '230505',
            name: '四方台区',
            districts: [],
            isLeaf: true,
            center: '131.337636,46.597143'
          },
          {
            adcode: '230521',
            name: '集贤县',
            districts: [],
            isLeaf: true,
            center: '131.141452,46.728448'
          }
        ]
      },
      {
        adcode: '231100',
        name: '黑河市',
        districts: [
          {
            adcode: '231102',
            name: '爱辉区',
            districts: [],
            isLeaf: true,
            center: '127.500703,50.252361'
          },
          {
            adcode: '231181',
            name: '北安市',
            districts: [],
            isLeaf: true,
            center: '126.490989,48.241168'
          },
          {
            adcode: '231124',
            name: '孙吴县',
            districts: [],
            isLeaf: true,
            center: '127.336163,49.425645'
          },
          {
            adcode: '231123',
            name: '逊克县',
            districts: [],
            isLeaf: true,
            center: '128.478698,49.56429'
          },
          {
            adcode: '231182',
            name: '五大连池市',
            districts: [],
            isLeaf: true,
            center: '126.205446,48.517342'
          },
          {
            adcode: '231183',
            name: '嫩江市',
            districts: [],
            isLeaf: true,
            center: '125.221192,49.185766'
          }
        ]
      },
      {
        adcode: '231000',
        name: '牡丹江市',
        districts: [
          {
            adcode: '231002',
            name: '东安区',
            districts: [],
            isLeaf: true,
            center: '129.626651,44.58125'
          },
          {
            adcode: '231004',
            name: '爱民区',
            districts: [],
            isLeaf: true,
            center: '129.591227,44.595897'
          },
          {
            adcode: '231081',
            name: '绥芬河市',
            districts: [],
            isLeaf: true,
            center: '131.151805,44.412487'
          },
          {
            adcode: '231003',
            name: '阳明区',
            districts: [],
            isLeaf: true,
            center: '129.635836,44.595851'
          },
          {
            adcode: '231085',
            name: '穆棱市',
            districts: [],
            isLeaf: true,
            center: '130.52446,44.918842'
          },
          {
            adcode: '231025',
            name: '林口县',
            districts: [],
            isLeaf: true,
            center: '130.282286,45.279754'
          },
          {
            adcode: '231083',
            name: '海林市',
            districts: [],
            isLeaf: true,
            center: '129.380756,44.594017'
          },
          {
            adcode: '231086',
            name: '东宁市',
            districts: [],
            isLeaf: true,
            center: '131.124839,44.090361'
          },
          {
            adcode: '231084',
            name: '宁安市',
            districts: [],
            isLeaf: true,
            center: '129.482505,44.340617'
          },
          {
            adcode: '231005',
            name: '西安区',
            districts: [],
            isLeaf: true,
            center: '125.149488,42.927252'
          }
        ]
      },
      {
        adcode: '231200',
        name: '绥化市',
        districts: [
          {
            adcode: '231226',
            name: '绥棱县',
            districts: [],
            isLeaf: true,
            center: '127.114928,47.235814'
          },
          {
            adcode: '231283',
            name: '海伦市',
            districts: [],
            isLeaf: true,
            center: '126.930191,47.450661'
          },
          {
            adcode: '231224',
            name: '庆安县',
            districts: [],
            isLeaf: true,
            center: '127.507586,46.880628'
          },
          {
            adcode: '231202',
            name: '北林区',
            districts: [],
            isLeaf: true,
            center: '126.984995,46.638114'
          },
          {
            adcode: '231221',
            name: '望奎县',
            districts: [],
            isLeaf: true,
            center: '126.486333,46.832185'
          },
          {
            adcode: '231223',
            name: '青冈县',
            districts: [],
            isLeaf: true,
            center: '126.099314,46.70398'
          },
          {
            adcode: '231225',
            name: '明水县',
            districts: [],
            isLeaf: true,
            center: '125.90601,47.173649'
          },
          {
            adcode: '231222',
            name: '兰西县',
            districts: [],
            isLeaf: true,
            center: '126.287734,46.252966'
          },
          {
            adcode: '231282',
            name: '肇东市',
            districts: [],
            isLeaf: true,
            center: '125.962419,46.050664'
          },
          {
            adcode: '231281',
            name: '安达市',
            districts: [],
            isLeaf: true,
            center: '125.307629,46.451882'
          }
        ]
      },
      {
        adcode: '230700',
        name: '伊春市',
        districts: [
          {
            adcode: '230722',
            name: '嘉荫县',
            districts: [],
            isLeaf: true,
            center: '130.402406,48.888613'
          },
          {
            adcode: '230724',
            name: '丰林县',
            districts: [],
            isLeaf: true,
            center: '129.533661,48.290566'
          },
          {
            adcode: '230717',
            name: '伊美区',
            districts: [],
            isLeaf: true,
            center: '128.907156,47.728257'
          },
          {
            adcode: '230723',
            name: '汤旺县',
            districts: [],
            isLeaf: true,
            center: '129.570935,48.454639'
          },
          {
            adcode: '230718',
            name: '乌翠区',
            districts: [],
            isLeaf: true,
            center: '128.669458,47.726499'
          },
          {
            adcode: '230719',
            name: '友好区',
            districts: [],
            isLeaf: true,
            center: '128.836341,47.840801'
          },
          {
            adcode: '230751',
            name: '金林区',
            districts: [],
            isLeaf: true,
            center: '129.428839,47.413019'
          },
          {
            adcode: '230781',
            name: '铁力市',
            districts: [],
            isLeaf: true,
            center: '128.032554,46.986604'
          },
          {
            adcode: '230725',
            name: '大箐山县',
            districts: [],
            isLeaf: true,
            center: '129.020519,47.028313'
          },
          {
            adcode: '230726',
            name: '南岔县',
            districts: [],
            isLeaf: true,
            center: '129.283584,47.139009'
          }
        ]
      },
      {
        adcode: '230600',
        name: '大庆市',
        districts: [
          {
            adcode: '230605',
            name: '红岗区',
            districts: [],
            isLeaf: true,
            center: '124.891041,46.398567'
          },
          {
            adcode: '230606',
            name: '大同区',
            districts: [],
            isLeaf: true,
            center: '124.812221,46.039972'
          },
          {
            adcode: '230623',
            name: '林甸县',
            districts: [],
            isLeaf: true,
            center: '124.863628,47.171677'
          },
          {
            adcode: '230621',
            name: '肇州县',
            districts: [],
            isLeaf: true,
            center: '125.268449,45.699543'
          },
          {
            adcode: '230604',
            name: '让胡路区',
            districts: [],
            isLeaf: true,
            center: '124.889858,46.642567'
          },
          {
            adcode: '230624',
            name: '杜尔伯特蒙古族自治县',
            districts: [],
            isLeaf: true,
            center: '124.442829,46.862348'
          },
          {
            adcode: '230622',
            name: '肇源县',
            districts: [],
            isLeaf: true,
            center: '125.078311,45.518982'
          },
          {
            adcode: '230602',
            name: '萨尔图区',
            districts: [],
            isLeaf: true,
            center: '125.135269,46.629281'
          },
          {
            adcode: '230603',
            name: '龙凤区',
            districts: [],
            isLeaf: true,
            center: '125.135093,46.562369'
          }
        ]
      },
      {
        adcode: '230300',
        name: '鸡西市',
        districts: [
          {
            adcode: '230304',
            name: '滴道区',
            districts: [],
            isLeaf: true,
            center: '130.844193,45.349068'
          },
          {
            adcode: '230302',
            name: '鸡冠区',
            districts: [],
            isLeaf: true,
            center: '130.981342,45.303892'
          },
          {
            adcode: '230306',
            name: '城子河区',
            districts: [],
            isLeaf: true,
            center: '131.011504,45.338342'
          },
          {
            adcode: '230307',
            name: '麻山区',
            districts: [],
            isLeaf: true,
            center: '130.477691,45.211863'
          },
          {
            adcode: '230305',
            name: '梨树区',
            districts: [],
            isLeaf: true,
            center: '130.69699,45.092046'
          },
          {
            adcode: '230303',
            name: '恒山区',
            districts: [],
            isLeaf: true,
            center: '130.90466,45.21119'
          },
          {
            adcode: '230321',
            name: '鸡东县',
            districts: [],
            isLeaf: true,
            center: '131.124083,45.260413'
          },
          {
            adcode: '230381',
            name: '虎林市',
            districts: [],
            isLeaf: true,
            center: '132.937002,45.763262'
          },
          {
            adcode: '230382',
            name: '密山市',
            districts: [],
            isLeaf: true,
            center: '131.846824,45.529482'
          }
        ]
      },
      {
        adcode: '230200',
        name: '齐齐哈尔市',
        districts: [
          {
            adcode: '230229',
            name: '克山县',
            districts: [],
            isLeaf: true,
            center: '125.875103,48.037748'
          },
          {
            adcode: '230225',
            name: '甘南县',
            districts: [],
            isLeaf: true,
            center: '123.507935,47.921698'
          },
          {
            adcode: '230230',
            name: '克东县',
            districts: [],
            isLeaf: true,
            center: '126.249124,48.041562'
          },
          {
            adcode: '230223',
            name: '依安县',
            districts: [],
            isLeaf: true,
            center: '125.306161,47.893442'
          },
          {
            adcode: '230227',
            name: '富裕县',
            districts: [],
            isLeaf: true,
            center: '124.474411,47.774038'
          },
          {
            adcode: '230208',
            name: '梅里斯达斡尔族区',
            districts: [],
            isLeaf: true,
            center: '123.752482,47.309755'
          },
          {
            adcode: '230207',
            name: '碾子山区',
            districts: [],
            isLeaf: true,
            center: '122.887548,47.516614'
          },
          {
            adcode: '230221',
            name: '龙江县',
            districts: [],
            isLeaf: true,
            center: '123.204953,47.338512'
          },
          {
            adcode: '230203',
            name: '建华区',
            districts: [],
            isLeaf: true,
            center: '123.95555,47.354212'
          },
          {
            adcode: '230204',
            name: '铁锋区',
            districts: [],
            isLeaf: true,
            center: '123.978079,47.340719'
          },
          {
            adcode: '230206',
            name: '富拉尔基区',
            districts: [],
            isLeaf: true,
            center: '123.628926,47.20873'
          },
          {
            adcode: '230202',
            name: '龙沙区',
            districts: [],
            isLeaf: true,
            center: '123.958447,47.317103'
          },
          {
            adcode: '230205',
            name: '昂昂溪区',
            districts: [],
            isLeaf: true,
            center: '123.822137,47.154806'
          },
          {
            adcode: '230224',
            name: '泰来县',
            districts: [],
            isLeaf: true,
            center: '123.416631,46.393694'
          },
          {
            adcode: '230231',
            name: '拜泉县',
            districts: [],
            isLeaf: true,
            center: '126.09905,47.59586'
          },
          {
            adcode: '230281',
            name: '讷河市',
            districts: [],
            isLeaf: true,
            center: '124.88293,48.466829'
          }
        ]
      }
    ]
  },
  {
    adcode: '650000',
    name: '新疆维吾尔自治区',
    districts: [
      {
        adcode: '659005',
        name: '北屯市',
        districts: [],
        isLeaf: true,
        center: '87.834419,47.326733'
      },
      {
        adcode: '659006',
        name: '铁门关市',
        districts: [],
        isLeaf: true,
        center: '85.670291,41.862997'
      },
      {
        adcode: '659008',
        name: '可克达拉市',
        districts: [],
        isLeaf: true,
        center: '80.994153,43.940381'
      },
      {
        adcode: '659007',
        name: '双河市',
        districts: [],
        isLeaf: true,
        center: '82.35365587,44.84052409'
      },
      {
        adcode: '652700',
        name: '博尔塔拉蒙古自治州',
        districts: [
          {
            adcode: '652723',
            name: '温泉县',
            districts: [],
            isLeaf: true,
            center: '81.024827,44.968857'
          },
          {
            adcode: '652702',
            name: '阿拉山口市',
            districts: [],
            isLeaf: true,
            center: '82.554609,45.173274'
          },
          {
            adcode: '652701',
            name: '博乐市',
            districts: [],
            isLeaf: true,
            center: '82.051433,44.853882'
          },
          {
            adcode: '652722',
            name: '精河县',
            districts: [],
            isLeaf: true,
            center: '82.890709,44.599202'
          }
        ]
      },
      {
        adcode: '654200',
        name: '塔城地区',
        districts: [
          {
            adcode: '654226',
            name: '和布克赛尔蒙古自治县',
            districts: [],
            isLeaf: true,
            center: '85.728328,46.793235'
          },
          {
            adcode: '654221',
            name: '额敏县',
            districts: [],
            isLeaf: true,
            center: '83.628303,46.524673'
          },
          {
            adcode: '654225',
            name: '裕民县',
            districts: [],
            isLeaf: true,
            center: '82.982252,46.201049'
          },
          {
            adcode: '654201',
            name: '塔城市',
            districts: [],
            isLeaf: true,
            center: '82.986923,46.751669'
          },
          {
            adcode: '654224',
            name: '托里县',
            districts: [],
            isLeaf: true,
            center: '83.606372,45.948935'
          },
          {
            adcode: '654203',
            name: '沙湾市',
            districts: [],
            isLeaf: true,
            center: '85.619483,44.326267'
          },
          {
            adcode: '654202',
            name: '乌苏市',
            districts: [],
            isLeaf: true,
            center: '84.713762,44.418693'
          }
        ]
      },
      {
        adcode: '653200',
        name: '和田地区',
        districts: [
          {
            adcode: '653226',
            name: '于田县',
            districts: [],
            isLeaf: true,
            center: '81.677417,36.85708'
          },
          {
            adcode: '653227',
            name: '民丰县',
            districts: [],
            isLeaf: true,
            center: '82.695946,37.064428'
          },
          {
            adcode: '653201',
            name: '和田市',
            districts: [],
            isLeaf: true,
            center: '79.912881,37.112246'
          },
          {
            adcode: '653221',
            name: '和田县',
            districts: [],
            isLeaf: true,
            center: '79.81906982,37.12003092'
          },
          {
            adcode: '653224',
            name: '洛浦县',
            districts: [],
            isLeaf: true,
            center: '80.179911,37.066226'
          },
          {
            adcode: '653223',
            name: '皮山县',
            districts: [],
            isLeaf: true,
            center: '78.28548,37.578264'
          },
          {
            adcode: '653222',
            name: '墨玉县',
            districts: [],
            isLeaf: true,
            center: '79.728434,37.277518'
          },
          {
            adcode: '653225',
            name: '策勒县',
            districts: [],
            isLeaf: true,
            center: '80.801433,36.99785'
          }
        ]
      },
      {
        adcode: '659009',
        name: '昆玉市',
        districts: [],
        isLeaf: true,
        center: '79.270193,37.215372'
      },
      {
        adcode: '654300',
        name: '阿勒泰地区',
        districts: [
          {
            adcode: '654321',
            name: '布尔津县',
            districts: [],
            isLeaf: true,
            center: '86.875043,47.701892'
          },
          {
            adcode: '654324',
            name: '哈巴河县',
            districts: [],
            isLeaf: true,
            center: '86.418621,48.060846'
          },
          {
            adcode: '654322',
            name: '富蕴县',
            districts: [],
            isLeaf: true,
            center: '89.525463,46.994046'
          },
          {
            adcode: '654326',
            name: '吉木乃县',
            districts: [],
            isLeaf: true,
            center: '85.874096,47.443101'
          },
          {
            adcode: '654325',
            name: '青河县',
            districts: [],
            isLeaf: true,
            center: '90.375159,46.678532'
          },
          {
            adcode: '654323',
            name: '福海县',
            districts: [],
            isLeaf: true,
            center: '87.486703,47.111918'
          },
          {
            adcode: '654301',
            name: '阿勒泰市',
            districts: [],
            isLeaf: true,
            center: '88.131842,47.827308'
          }
        ]
      },
      {
        adcode: '659001',
        name: '石河子市',
        districts: [],
        isLeaf: true,
        center: '86.080397,44.305368'
      },
      {
        adcode: '652300',
        name: '昌吉回族自治州',
        districts: [
          {
            adcode: '652325',
            name: '奇台县',
            districts: [],
            isLeaf: true,
            center: '89.593879,44.022001'
          },
          {
            adcode: '652324',
            name: '玛纳斯县',
            districts: [],
            isLeaf: true,
            center: '86.20488,44.284601'
          },
          {
            adcode: '652323',
            name: '呼图壁县',
            districts: [],
            isLeaf: true,
            center: '86.871532,44.179362'
          },
          {
            adcode: '652328',
            name: '木垒哈萨克自治县',
            districts: [],
            isLeaf: true,
            center: '90.286013,43.834631'
          },
          {
            adcode: '652302',
            name: '阜康市',
            districts: [],
            isLeaf: true,
            center: '87.953826,44.164525'
          },
          {
            adcode: '652327',
            name: '吉木萨尔县',
            districts: [],
            isLeaf: true,
            center: '89.179698,44.000404'
          },
          {
            adcode: '652301',
            name: '昌吉市',
            districts: [],
            isLeaf: true,
            center: '87.267432,44.014468'
          }
        ]
      },
      {
        adcode: '659004',
        name: '五家渠市',
        districts: [],
        isLeaf: true,
        center: '87.542852,44.166489'
      },
      {
        adcode: '652800',
        name: '巴音郭楞蒙古自治州',
        districts: [
          {
            adcode: '652827',
            name: '和静县',
            districts: [],
            isLeaf: true,
            center: '86.384065,42.323625'
          },
          {
            adcode: '652828',
            name: '和硕县',
            districts: [],
            isLeaf: true,
            center: '86.877688,42.28361'
          },
          {
            adcode: '652826',
            name: '焉耆回族自治县',
            districts: [],
            isLeaf: true,
            center: '86.57434,42.059509'
          },
          {
            adcode: '652829',
            name: '博湖县',
            districts: [],
            isLeaf: true,
            center: '86.632116,41.980745'
          },
          {
            adcode: '652824',
            name: '若羌县',
            districts: [],
            isLeaf: true,
            center: '88.167226,39.022626'
          },
          {
            adcode: '652825',
            name: '且末县',
            districts: [],
            isLeaf: true,
            center: '85.529619,38.145749'
          },
          {
            adcode: '652801',
            name: '库尔勒市',
            districts: [],
            isLeaf: true,
            center: '86.174633,41.725891'
          },
          {
            adcode: '652822',
            name: '轮台县',
            districts: [],
            isLeaf: true,
            center: '84.251508,41.778043'
          },
          {
            adcode: '652823',
            name: '尉犁县',
            districts: [],
            isLeaf: true,
            center: '86.261613,41.343871'
          }
        ]
      },
      {
        adcode: '654000',
        name: '伊犁哈萨克自治州',
        districts: [
          {
            adcode: '654021',
            name: '伊宁县',
            districts: [],
            isLeaf: true,
            center: '81.52745,43.977119'
          },
          {
            adcode: '654028',
            name: '尼勒克县',
            districts: [],
            isLeaf: true,
            center: '82.511884,43.798627'
          },
          {
            adcode: '654024',
            name: '巩留县',
            districts: [],
            isLeaf: true,
            center: '82.231219,43.48313'
          },
          {
            adcode: '654027',
            name: '特克斯县',
            districts: [],
            isLeaf: true,
            center: '81.835732,43.218435'
          },
          {
            adcode: '654025',
            name: '新源县',
            districts: [],
            isLeaf: true,
            center: '83.233002,43.434803'
          },
          {
            adcode: '654026',
            name: '昭苏县',
            districts: [],
            isLeaf: true,
            center: '81.130974,43.157293'
          },
          {
            adcode: '654022',
            name: '察布查尔锡伯自治县',
            districts: [],
            isLeaf: true,
            center: '81.151337,43.840726'
          },
          {
            adcode: '654023',
            name: '霍城县',
            districts: [],
            isLeaf: true,
            center: '80.878845,44.056329'
          },
          {
            adcode: '654004',
            name: '霍尔果斯市',
            districts: [],
            isLeaf: true,
            center: '80.414614,44.218381'
          },
          {
            adcode: '654002',
            name: '伊宁市',
            districts: [],
            isLeaf: true,
            center: '81.277715,43.908021'
          },
          {
            adcode: '654003',
            name: '奎屯市',
            districts: [],
            isLeaf: true,
            center: '84.903216,44.426452'
          }
        ]
      },
      {
        adcode: '659002',
        name: '阿拉尔市',
        districts: [],
        isLeaf: true,
        center: '81.280532,40.547205'
      },
      {
        adcode: '653000',
        name: '克孜勒苏柯尔克孜自治州',
        districts: [
          {
            adcode: '653001',
            name: '阿图什市',
            districts: [],
            isLeaf: true,
            center: '76.167538,39.716586'
          },
          {
            adcode: '653024',
            name: '乌恰县',
            districts: [],
            isLeaf: true,
            center: '75.259228,39.7205'
          },
          {
            adcode: '653023',
            name: '阿合奇县',
            districts: [],
            isLeaf: true,
            center: '78.402073,40.937581'
          },
          {
            adcode: '653022',
            name: '阿克陶县',
            districts: [],
            isLeaf: true,
            center: '75.947396,39.147785'
          }
        ]
      },
      {
        adcode: '653100',
        name: '喀什地区',
        districts: [
          {
            adcode: '653129',
            name: '伽师县',
            districts: [],
            isLeaf: true,
            center: '76.723719,39.488181'
          },
          {
            adcode: '653125',
            name: '莎车县',
            districts: [],
            isLeaf: true,
            center: '77.223197,38.390881'
          },
          {
            adcode: '653122',
            name: '疏勒县',
            districts: [],
            isLeaf: true,
            center: '76.047169,39.401173'
          },
          {
            adcode: '653131',
            name: '塔什库尔干塔吉克自治县',
            districts: [],
            isLeaf: true,
            center: '75.229889,37.772094'
          },
          {
            adcode: '653126',
            name: '叶城县',
            districts: [],
            isLeaf: true,
            center: '77.413669,37.882994'
          },
          {
            adcode: '653127',
            name: '麦盖提县',
            districts: [],
            isLeaf: true,
            center: '77.609099,38.898986'
          },
          {
            adcode: '653124',
            name: '泽普县',
            districts: [],
            isLeaf: true,
            center: '77.260375,38.184639'
          },
          {
            adcode: '653130',
            name: '巴楚县',
            districts: [],
            isLeaf: true,
            center: '78.54799,39.79923'
          },
          {
            adcode: '653123',
            name: '英吉沙县',
            districts: [],
            isLeaf: true,
            center: '76.175729,38.930381'
          },
          {
            adcode: '653128',
            name: '岳普湖县',
            districts: [],
            isLeaf: true,
            center: '76.821269,39.219857'
          },
          {
            adcode: '653101',
            name: '喀什市',
            districts: [],
            isLeaf: true,
            center: '75.993936,39.46823'
          },
          {
            adcode: '653121',
            name: '疏附县',
            districts: [],
            isLeaf: true,
            center: '75.862839,39.375052'
          }
        ]
      },
      {
        adcode: '650200',
        name: '克拉玛依市',
        districts: [
          {
            adcode: '650205',
            name: '乌尔禾区',
            districts: [],
            isLeaf: true,
            center: '85.692518,46.085819'
          },
          {
            adcode: '650202',
            name: '独山子区',
            districts: [],
            isLeaf: true,
            center: '84.886973,44.328096'
          },
          {
            adcode: '650204',
            name: '白碱滩区',
            districts: [],
            isLeaf: true,
            center: '85.119047,45.685416'
          },
          {
            adcode: '650203',
            name: '克拉玛依区',
            districts: [],
            isLeaf: true,
            center: '84.867862,45.602347'
          }
        ]
      },
      {
        adcode: '650400',
        name: '吐鲁番市',
        districts: [
          {
            adcode: '650402',
            name: '高昌区',
            districts: [],
            isLeaf: true,
            center: '89.185891,42.942728'
          },
          {
            adcode: '650421',
            name: '鄯善县',
            districts: [],
            isLeaf: true,
            center: '90.242843,42.900575'
          },
          {
            adcode: '650422',
            name: '托克逊县',
            districts: [],
            isLeaf: true,
            center: '88.643739,42.802585'
          }
        ]
      },
      {
        adcode: '650500',
        name: '哈密市',
        districts: [
          {
            adcode: '650522',
            name: '伊吾县',
            districts: [],
            isLeaf: true,
            center: '94.697074,43.254978'
          },
          {
            adcode: '650521',
            name: '巴里坤哈萨克自治县',
            districts: [],
            isLeaf: true,
            center: '93.016563,43.598756'
          },
          {
            adcode: '650502',
            name: '伊州区',
            districts: [],
            isLeaf: true,
            center: '93.515085,42.827376'
          }
        ]
      },
      {
        adcode: '659011',
        name: '新星市',
        districts: [],
        isLeaf: true,
        center: '93.74831,42.797043'
      },
      {
        adcode: '659003',
        name: '图木舒克市',
        districts: [],
        isLeaf: true,
        center: '79.074965,39.867776'
      },
      {
        adcode: '652900',
        name: '阿克苏地区',
        districts: [
          {
            adcode: '652926',
            name: '拜城县',
            districts: [],
            isLeaf: true,
            center: '81.851289,41.795289'
          },
          {
            adcode: '652902',
            name: '库车市',
            districts: [],
            isLeaf: true,
            center: '82.998544,41.719073'
          },
          {
            adcode: '652924',
            name: '沙雅县',
            districts: [],
            isLeaf: true,
            center: '82.781774,41.221413'
          },
          {
            adcode: '652925',
            name: '新和县',
            districts: [],
            isLeaf: true,
            center: '82.620371,41.552097'
          },
          {
            adcode: '652901',
            name: '阿克苏市',
            districts: [],
            isLeaf: true,
            center: '80.263387,41.16755'
          },
          {
            adcode: '652927',
            name: '乌什县',
            districts: [],
            isLeaf: true,
            center: '79.22118,41.222706'
          },
          {
            adcode: '652929',
            name: '柯坪县',
            districts: [],
            isLeaf: true,
            center: '79.054019,40.501645'
          },
          {
            adcode: '652922',
            name: '温宿县',
            districts: [],
            isLeaf: true,
            center: '80.243273,41.272995'
          },
          {
            adcode: '652928',
            name: '阿瓦提县',
            districts: [],
            isLeaf: true,
            center: '80.375053,40.643647'
          }
        ]
      },
      {
        adcode: '650100',
        name: '乌鲁木齐市',
        districts: [
          {
            adcode: '650107',
            name: '达坂城区',
            districts: [],
            isLeaf: true,
            center: '88.311099,43.363668'
          },
          {
            adcode: '650102',
            name: '天山区',
            districts: [],
            isLeaf: true,
            center: '87.631986,43.794312'
          },
          {
            adcode: '650105',
            name: '水磨沟区',
            districts: [],
            isLeaf: true,
            center: '87.64208,43.832714'
          },
          {
            adcode: '650109',
            name: '米东区',
            districts: [],
            isLeaf: true,
            center: '87.655122,43.97444'
          },
          {
            adcode: '650121',
            name: '乌鲁木齐县',
            districts: [],
            isLeaf: true,
            center: '87.464257,43.509016'
          },
          {
            adcode: '650103',
            name: '沙依巴克区',
            districts: [],
            isLeaf: true,
            center: '87.598043,43.800994'
          },
          {
            adcode: '650106',
            name: '头屯河区',
            districts: [],
            isLeaf: true,
            center: '87.42582303,43.87605225'
          },
          {
            adcode: '650104',
            name: '新市区',
            districts: [],
            isLeaf: true,
            center: '87.592684,43.893023'
          }
        ]
      },
      {
        adcode: '659010',
        name: '胡杨河市',
        districts: [],
        isLeaf: true,
        center: '84.827592,44.692894'
      }
    ]
  },
  {
    adcode: '420000',
    name: '湖北省',
    districts: [
      {
        adcode: '420600',
        name: '襄阳市',
        districts: [
          {
            adcode: '420682',
            name: '老河口市',
            districts: [],
            isLeaf: true,
            center: '111.683861,32.359068'
          },
          {
            adcode: '420625',
            name: '谷城县',
            districts: [],
            isLeaf: true,
            center: '111.653077,32.26339'
          },
          {
            adcode: '420683',
            name: '枣阳市',
            districts: [],
            isLeaf: true,
            center: '112.772723,32.128968'
          },
          {
            adcode: '420626',
            name: '保康县',
            districts: [],
            isLeaf: true,
            center: '111.261308,31.87831'
          },
          {
            adcode: '420684',
            name: '宜城市',
            districts: [],
            isLeaf: true,
            center: '112.256486,31.720278'
          },
          {
            adcode: '420624',
            name: '南漳县',
            districts: [],
            isLeaf: true,
            center: '111.838997,31.774649'
          },
          {
            adcode: '420607',
            name: '襄州区',
            districts: [],
            isLeaf: true,
            center: '112.211365,32.088651'
          },
          {
            adcode: '420602',
            name: '襄城区',
            districts: [],
            isLeaf: true,
            center: '112.134025,32.009932'
          },
          {
            adcode: '420606',
            name: '樊城区',
            districts: [],
            isLeaf: true,
            center: '112.135241,32.045065'
          }
        ]
      },
      {
        adcode: '420300',
        name: '十堰市',
        districts: [
          {
            adcode: '420304',
            name: '郧阳区',
            districts: [],
            isLeaf: true,
            center: '110.812101,32.834847'
          },
          {
            adcode: '420303',
            name: '张湾区',
            districts: [],
            isLeaf: true,
            center: '110.769132,32.652297'
          },
          {
            adcode: '420302',
            name: '茅箭区',
            districts: [],
            isLeaf: true,
            center: '110.813478,32.591847'
          },
          {
            adcode: '420324',
            name: '竹溪县',
            districts: [],
            isLeaf: true,
            center: '109.715322,32.318257'
          },
          {
            adcode: '420325',
            name: '房县',
            districts: [],
            isLeaf: true,
            center: '110.726754,32.04017'
          },
          {
            adcode: '420323',
            name: '竹山县',
            districts: [],
            isLeaf: true,
            center: '110.229696,32.225039'
          },
          {
            adcode: '420322',
            name: '郧西县',
            districts: [],
            isLeaf: true,
            center: '110.426005,32.993095'
          },
          {
            adcode: '420381',
            name: '丹江口市',
            districts: [],
            isLeaf: true,
            center: '111.513318,32.540287'
          }
        ]
      },
      {
        adcode: '420500',
        name: '宜昌市',
        districts: [
          {
            adcode: '420526',
            name: '兴山县',
            districts: [],
            isLeaf: true,
            center: '110.746831,31.348065'
          },
          {
            adcode: '420527',
            name: '秭归县',
            districts: [],
            isLeaf: true,
            center: '110.97793,30.825882'
          },
          {
            adcode: '420504',
            name: '点军区',
            districts: [],
            isLeaf: true,
            center: '111.268338,30.69343'
          },
          {
            adcode: '420582',
            name: '当阳市',
            districts: [],
            isLeaf: true,
            center: '111.78836,30.820893'
          },
          {
            adcode: '420528',
            name: '长阳土家族自治县',
            districts: [],
            isLeaf: true,
            center: '111.20697,30.473038'
          },
          {
            adcode: '420581',
            name: '宜都市',
            districts: [],
            isLeaf: true,
            center: '111.450128,30.378444'
          },
          {
            adcode: '420525',
            name: '远安县',
            districts: [],
            isLeaf: true,
            center: '111.640329,31.060904'
          },
          {
            adcode: '420529',
            name: '五峰土家族自治县',
            districts: [],
            isLeaf: true,
            center: '111.07374,30.156741'
          },
          {
            adcode: '420503',
            name: '伍家岗区',
            districts: [],
            isLeaf: true,
            center: '111.360882,30.644048'
          },
          {
            adcode: '420505',
            name: '猇亭区',
            districts: [],
            isLeaf: true,
            center: '111.434378,30.531133'
          },
          {
            adcode: '420583',
            name: '枝江市',
            districts: [],
            isLeaf: true,
            center: '111.760616,30.425758'
          },
          {
            adcode: '420502',
            name: '西陵区',
            districts: [],
            isLeaf: true,
            center: '111.285696,30.710765'
          },
          {
            adcode: '420506',
            name: '夷陵区',
            districts: [],
            isLeaf: true,
            center: '111.326371,30.769875'
          }
        ]
      },
      {
        adcode: '420100',
        name: '武汉市',
        districts: [
          {
            adcode: '420116',
            name: '黄陂区',
            districts: [],
            isLeaf: true,
            center: '114.375698,30.882305'
          },
          {
            adcode: '420117',
            name: '新洲区',
            districts: [],
            isLeaf: true,
            center: '114.801284,30.841304'
          },
          {
            adcode: '420113',
            name: '汉南区',
            districts: [],
            isLeaf: true,
            center: '114.084582,30.308776'
          },
          {
            adcode: '420114',
            name: '蔡甸区',
            districts: [],
            isLeaf: true,
            center: '114.087279,30.536395'
          },
          {
            adcode: '420115',
            name: '江夏区',
            districts: [],
            isLeaf: true,
            center: '114.320884,30.37558'
          },
          {
            adcode: '420106',
            name: '武昌区',
            districts: [],
            isLeaf: true,
            center: '114.316464,30.55418'
          },
          {
            adcode: '420102',
            name: '江岸区',
            districts: [],
            isLeaf: true,
            center: '114.309317,30.600008'
          },
          {
            adcode: '420112',
            name: '东西湖区',
            districts: [],
            isLeaf: true,
            center: '114.136295,30.620079'
          },
          {
            adcode: '420107',
            name: '青山区',
            districts: [],
            isLeaf: true,
            center: '114.385424,30.639842'
          },
          {
            adcode: '420111',
            name: '洪山区',
            districts: [],
            isLeaf: true,
            center: '114.342664,30.500642'
          },
          {
            adcode: '420103',
            name: '江汉区',
            districts: [],
            isLeaf: true,
            center: '114.270763,30.601129'
          },
          {
            adcode: '420104',
            name: '硚口区',
            districts: [],
            isLeaf: true,
            center: '114.214746,30.582559'
          },
          {
            adcode: '420105',
            name: '汉阳区',
            districts: [],
            isLeaf: true,
            center: '114.21859,30.554287'
          }
        ]
      },
      {
        adcode: '421100',
        name: '黄冈市',
        districts: [
          {
            adcode: '421181',
            name: '麻城市',
            districts: [],
            isLeaf: true,
            center: '115.008011,31.172917'
          },
          {
            adcode: '421102',
            name: '黄州区',
            districts: [],
            isLeaf: true,
            center: '114.880104,30.434354'
          },
          {
            adcode: '421125',
            name: '浠水县',
            districts: [],
            isLeaf: true,
            center: '115.265155,30.452212'
          },
          {
            adcode: '421124',
            name: '英山县',
            districts: [],
            isLeaf: true,
            center: '115.680953,30.734989'
          },
          {
            adcode: '421121',
            name: '团风县',
            districts: [],
            isLeaf: true,
            center: '114.872364,30.643225'
          },
          {
            adcode: '421122',
            name: '红安县',
            districts: [],
            isLeaf: true,
            center: '114.618134,31.288167'
          },
          {
            adcode: '421126',
            name: '蕲春县',
            districts: [],
            isLeaf: true,
            center: '115.436423,30.226146'
          },
          {
            adcode: '421123',
            name: '罗田县',
            districts: [],
            isLeaf: true,
            center: '115.399149,30.783047'
          },
          {
            adcode: '421182',
            name: '武穴市',
            districts: [],
            isLeaf: true,
            center: '115.595451,29.869602'
          },
          {
            adcode: '421127',
            name: '黄梅县',
            districts: [],
            isLeaf: true,
            center: '115.944219,30.070453'
          }
        ]
      },
      {
        adcode: '429006',
        name: '天门市',
        districts: [],
        isLeaf: true,
        center: '113.166545,30.663706'
      },
      {
        adcode: '429004',
        name: '仙桃市',
        districts: [],
        isLeaf: true,
        center: '113.442973,30.328407'
      },
      {
        adcode: '420800',
        name: '荆门市',
        districts: [
          {
            adcode: '420882',
            name: '京山市',
            districts: [],
            isLeaf: true,
            center: '113.119561,31.018414'
          },
          {
            adcode: '420822',
            name: '沙洋县',
            districts: [],
            isLeaf: true,
            center: '112.588675,30.709167'
          },
          {
            adcode: '420804',
            name: '掇刀区',
            districts: [],
            isLeaf: true,
            center: '112.2079,30.973397'
          },
          {
            adcode: '420802',
            name: '东宝区',
            districts: [],
            isLeaf: true,
            center: '112.201657,31.05187'
          },
          {
            adcode: '420881',
            name: '钟祥市',
            districts: [],
            isLeaf: true,
            center: '112.588032,31.167933'
          }
        ]
      },
      {
        adcode: '420900',
        name: '孝感市',
        districts: [
          {
            adcode: '420923',
            name: '云梦县',
            districts: [],
            isLeaf: true,
            center: '113.752958,31.021736'
          },
          {
            adcode: '420981',
            name: '应城市',
            districts: [],
            isLeaf: true,
            center: '113.571864,30.928592'
          },
          {
            adcode: '420902',
            name: '孝南区',
            districts: [],
            isLeaf: true,
            center: '113.910769,30.91695'
          },
          {
            adcode: '420984',
            name: '汉川市',
            districts: [],
            isLeaf: true,
            center: '113.838068,30.673219'
          },
          {
            adcode: '420921',
            name: '孝昌县',
            districts: [],
            isLeaf: true,
            center: '113.997794,31.258225'
          },
          {
            adcode: '420922',
            name: '大悟县',
            districts: [],
            isLeaf: true,
            center: '114.127122,31.561179'
          },
          {
            adcode: '420982',
            name: '安陆市',
            districts: [],
            isLeaf: true,
            center: '113.688955,31.25565'
          }
        ]
      },
      {
        adcode: '422800',
        name: '恩施土家族苗族自治州',
        districts: [
          {
            adcode: '422822',
            name: '建始县',
            districts: [],
            isLeaf: true,
            center: '109.722333,30.602195'
          },
          {
            adcode: '422801',
            name: '恩施市',
            districts: [],
            isLeaf: true,
            center: '109.479572,30.295148'
          },
          {
            adcode: '422802',
            name: '利川市',
            districts: [],
            isLeaf: true,
            center: '108.936456,30.290974'
          },
          {
            adcode: '422825',
            name: '宣恩县',
            districts: [],
            isLeaf: true,
            center: '109.489918,29.986856'
          },
          {
            adcode: '422823',
            name: '巴东县',
            districts: [],
            isLeaf: true,
            center: '110.340921,31.042854'
          },
          {
            adcode: '422826',
            name: '咸丰县',
            districts: [],
            isLeaf: true,
            center: '109.140163,29.665215'
          },
          {
            adcode: '422827',
            name: '来凤县',
            districts: [],
            isLeaf: true,
            center: '109.407697,29.493474'
          },
          {
            adcode: '422828',
            name: '鹤峰县',
            districts: [],
            isLeaf: true,
            center: '110.034011,29.890073'
          }
        ]
      },
      {
        adcode: '429005',
        name: '潜江市',
        districts: [],
        isLeaf: true,
        center: '112.900279,30.401954'
      },
      {
        adcode: '421000',
        name: '荆州市',
        districts: [
          {
            adcode: '421024',
            name: '江陵县',
            districts: [],
            isLeaf: true,
            center: '112.424581,30.042227'
          },
          {
            adcode: '421081',
            name: '石首市',
            districts: [],
            isLeaf: true,
            center: '112.42538,29.720944'
          },
          {
            adcode: '421083',
            name: '洪湖市',
            districts: [],
            isLeaf: true,
            center: '113.475984,29.827256'
          },
          {
            adcode: '421087',
            name: '松滋市',
            districts: [],
            isLeaf: true,
            center: '111.756455,30.174717'
          },
          {
            adcode: '421088',
            name: '监利市',
            districts: [],
            isLeaf: true,
            center: '112.904634,29.840229'
          },
          {
            adcode: '421002',
            name: '沙市区',
            districts: [],
            isLeaf: true,
            center: '112.251975,30.326693'
          },
          {
            adcode: '421022',
            name: '公安县',
            districts: [],
            isLeaf: true,
            center: '112.229742,30.058519'
          },
          {
            adcode: '421003',
            name: '荆州区',
            districts: [],
            isLeaf: true,
            center: '112.190282,30.352984'
          }
        ]
      },
      {
        adcode: '421200',
        name: '咸宁市',
        districts: [
          {
            adcode: '421221',
            name: '嘉鱼县',
            districts: [],
            isLeaf: true,
            center: '113.939249,29.970575'
          },
          {
            adcode: '421224',
            name: '通山县',
            districts: [],
            isLeaf: true,
            center: '114.482832,29.606468'
          },
          {
            adcode: '421202',
            name: '咸安区',
            districts: [],
            isLeaf: true,
            center: '114.299002,29.852726'
          },
          {
            adcode: '421281',
            name: '赤壁市',
            districts: [],
            isLeaf: true,
            center: '113.900385,29.725122'
          },
          {
            adcode: '421222',
            name: '通城县',
            districts: [],
            isLeaf: true,
            center: '113.816811,29.2454'
          },
          {
            adcode: '421223',
            name: '崇阳县',
            districts: [],
            isLeaf: true,
            center: '114.039484,29.556866'
          }
        ]
      },
      {
        adcode: '429021',
        name: '神农架林区',
        districts: [],
        isLeaf: true,
        center: '110.675879,31.745103'
      },
      {
        adcode: '421300',
        name: '随州市',
        districts: [
          {
            adcode: '421321',
            name: '随县',
            districts: [],
            isLeaf: true,
            center: '113.29097,31.883233'
          },
          {
            adcode: '421303',
            name: '曾都区',
            districts: [],
            isLeaf: true,
            center: '113.371052,31.716423'
          },
          {
            adcode: '421381',
            name: '广水市',
            districts: [],
            isLeaf: true,
            center: '113.825977,31.617015'
          }
        ]
      },
      {
        adcode: '420200',
        name: '黄石市',
        districts: [
          {
            adcode: '420205',
            name: '铁山区',
            districts: [],
            isLeaf: true,
            center: '114.891603,30.203152'
          },
          {
            adcode: '420204',
            name: '下陆区',
            districts: [],
            isLeaf: true,
            center: '114.960914,30.174148'
          },
          {
            adcode: '420203',
            name: '西塞山区',
            districts: [],
            isLeaf: true,
            center: '115.110067,30.205114'
          },
          {
            adcode: '420281',
            name: '大冶市',
            districts: [],
            isLeaf: true,
            center: '114.98046,30.096263'
          },
          {
            adcode: '420202',
            name: '黄石港区',
            districts: [],
            isLeaf: true,
            center: '115.065653,30.223194'
          },
          {
            adcode: '420222',
            name: '阳新县',
            districts: [],
            isLeaf: true,
            center: '115.215211,29.830236'
          }
        ]
      },
      {
        adcode: '420700',
        name: '鄂州市',
        districts: [
          {
            adcode: '420703',
            name: '华容区',
            districts: [],
            isLeaf: true,
            center: '114.729945,30.53515'
          },
          {
            adcode: '420704',
            name: '鄂城区',
            districts: [],
            isLeaf: true,
            center: '114.89157,30.400535'
          },
          {
            adcode: '420702',
            name: '梁子湖区',
            districts: [],
            isLeaf: true,
            center: '114.684805,30.100146'
          }
        ]
      }
    ]
  },
  {
    adcode: '210000',
    name: '辽宁省',
    districts: [
      {
        adcode: '211400',
        name: '葫芦岛市',
        districts: [
          {
            adcode: '211422',
            name: '建昌县',
            districts: [],
            isLeaf: true,
            center: '119.836669,40.824484'
          },
          {
            adcode: '211403',
            name: '龙港区',
            districts: [],
            isLeaf: true,
            center: '120.894054,40.735717'
          },
          {
            adcode: '211481',
            name: '兴城市',
            districts: [],
            isLeaf: true,
            center: '120.756661,40.60999'
          },
          {
            adcode: '211421',
            name: '绥中县',
            districts: [],
            isLeaf: true,
            center: '120.34432,40.325576'
          },
          {
            adcode: '211404',
            name: '南票区',
            districts: [],
            isLeaf: true,
            center: '120.74971,41.10721'
          },
          {
            adcode: '211402',
            name: '连山区',
            districts: [],
            isLeaf: true,
            center: '120.869157,40.774784'
          }
        ]
      },
      {
        adcode: '210200',
        name: '大连市',
        districts: [
          {
            adcode: '210283',
            name: '庄河市',
            districts: [],
            isLeaf: true,
            center: '122.965881,39.680825'
          },
          {
            adcode: '210281',
            name: '瓦房店市',
            districts: [],
            isLeaf: true,
            center: '121.979463,39.62701'
          },
          {
            adcode: '210214',
            name: '普兰店区',
            districts: [],
            isLeaf: true,
            center: '121.938299,39.391565'
          },
          {
            adcode: '210213',
            name: '金州区',
            districts: [],
            isLeaf: true,
            center: '121.782513,39.050211'
          },
          {
            adcode: '210224',
            name: '长海县',
            districts: [],
            isLeaf: true,
            center: '122.588487,39.272779'
          },
          {
            adcode: '210211',
            name: '甘井子区',
            districts: [],
            isLeaf: true,
            center: '121.525469,38.952998'
          },
          {
            adcode: '210212',
            name: '旅顺口区',
            districts: [],
            isLeaf: true,
            center: '121.261982,38.851742'
          },
          {
            adcode: '210202',
            name: '中山区',
            districts: [],
            isLeaf: true,
            center: '121.644996,38.918357'
          },
          {
            adcode: '210204',
            name: '沙河口区',
            districts: [],
            isLeaf: true,
            center: '121.594201,38.904771'
          },
          {
            adcode: '210203',
            name: '西岗区',
            districts: [],
            isLeaf: true,
            center: '121.612461,38.914751'
          }
        ]
      },
      {
        adcode: '210600',
        name: '丹东市',
        districts: [
          {
            adcode: '210682',
            name: '凤城市',
            districts: [],
            isLeaf: true,
            center: '124.066873,40.45218'
          },
          {
            adcode: '210604',
            name: '振安区',
            districts: [],
            isLeaf: true,
            center: '124.470005,40.20154'
          },
          {
            adcode: '210681',
            name: '东港市',
            districts: [],
            isLeaf: true,
            center: '124.152581,39.863193'
          },
          {
            adcode: '210603',
            name: '振兴区',
            districts: [],
            isLeaf: true,
            center: '124.383382,40.129706'
          },
          {
            adcode: '210624',
            name: '宽甸满族自治县',
            districts: [],
            isLeaf: true,
            center: '124.783674,40.731319'
          },
          {
            adcode: '210602',
            name: '元宝区',
            districts: [],
            isLeaf: true,
            center: '124.395577,40.136549'
          }
        ]
      },
      {
        adcode: '210700',
        name: '锦州市',
        districts: [
          {
            adcode: '210727',
            name: '义县',
            districts: [],
            isLeaf: true,
            center: '121.239558,41.533553'
          },
          {
            adcode: '210711',
            name: '太和区',
            districts: [],
            isLeaf: true,
            center: '121.103445,41.109232'
          },
          {
            adcode: '210781',
            name: '凌海市',
            districts: [],
            isLeaf: true,
            center: '121.35499,41.160636'
          },
          {
            adcode: '210782',
            name: '北镇市',
            districts: [],
            isLeaf: true,
            center: '121.777503,41.588657'
          },
          {
            adcode: '210702',
            name: '古塔区',
            districts: [],
            isLeaf: true,
            center: '121.128414,41.117481'
          },
          {
            adcode: '210703',
            name: '凌河区',
            districts: [],
            isLeaf: true,
            center: '121.151069,41.115058'
          },
          {
            adcode: '210726',
            name: '黑山县',
            districts: [],
            isLeaf: true,
            center: '122.126645,41.653552'
          }
        ]
      },
      {
        adcode: '210100',
        name: '沈阳市',
        districts: [
          {
            adcode: '210114',
            name: '于洪区',
            districts: [],
            isLeaf: true,
            center: '123.308062,41.793925'
          },
          {
            adcode: '210123',
            name: '康平县',
            districts: [],
            isLeaf: true,
            center: '123.343701,42.727249'
          },
          {
            adcode: '210103',
            name: '沈河区',
            districts: [],
            isLeaf: true,
            center: '123.45884,41.795688'
          },
          {
            adcode: '210104',
            name: '大东区',
            districts: [],
            isLeaf: true,
            center: '123.469828,41.805292'
          },
          {
            adcode: '210115',
            name: '辽中区',
            districts: [],
            isLeaf: true,
            center: '122.766159,41.517449'
          },
          {
            adcode: '210106',
            name: '铁西区',
            districts: [],
            isLeaf: true,
            center: '124.345994,43.146288'
          },
          {
            adcode: '210113',
            name: '沈北新区',
            districts: [],
            isLeaf: true,
            center: '123.584209,41.913071'
          },
          {
            adcode: '210105',
            name: '皇姑区',
            districts: [],
            isLeaf: true,
            center: '123.441468,41.824574'
          },
          {
            adcode: '210102',
            name: '和平区',
            districts: [],
            isLeaf: true,
            center: '117.214713,39.116884'
          },
          {
            adcode: '210181',
            name: '新民市',
            districts: [],
            isLeaf: true,
            center: '122.835964,41.985757'
          },
          {
            adcode: '210124',
            name: '法库县',
            districts: [],
            isLeaf: true,
            center: '123.440294,42.50108'
          },
          {
            adcode: '210112',
            name: '浑南区',
            districts: [],
            isLeaf: true,
            center: '123.449597,41.714729'
          },
          {
            adcode: '210111',
            name: '苏家屯区',
            districts: [],
            isLeaf: true,
            center: '123.343937,41.665153'
          }
        ]
      },
      {
        adcode: '211200',
        name: '铁岭市',
        districts: [
          {
            adcode: '211282',
            name: '开原市',
            districts: [],
            isLeaf: true,
            center: '124.051886,42.577645'
          },
          {
            adcode: '211281',
            name: '调兵山市',
            districts: [],
            isLeaf: true,
            center: '123.567039,42.468007'
          },
          {
            adcode: '211204',
            name: '清河区',
            districts: [],
            isLeaf: true,
            center: '124.159232,42.54714'
          },
          {
            adcode: '211221',
            name: '铁岭县',
            districts: [],
            isLeaf: true,
            center: '123.728933,42.223395'
          },
          {
            adcode: '211202',
            name: '银州区',
            districts: [],
            isLeaf: true,
            center: '123.842472,42.286575'
          },
          {
            adcode: '211223',
            name: '西丰县',
            districts: [],
            isLeaf: true,
            center: '124.727491,42.738178'
          },
          {
            adcode: '211224',
            name: '昌图县',
            districts: [],
            isLeaf: true,
            center: '124.086628,42.779333'
          }
        ]
      },
      {
        adcode: '210900',
        name: '阜新市',
        districts: [
          {
            adcode: '210905',
            name: '清河门区',
            districts: [],
            isLeaf: true,
            center: '121.416105,41.7831'
          },
          {
            adcode: '210904',
            name: '太平区',
            districts: [],
            isLeaf: true,
            center: '121.678637,42.010689'
          },
          {
            adcode: '210903',
            name: '新邱区',
            districts: [],
            isLeaf: true,
            center: '121.792067,42.087549'
          },
          {
            adcode: '210921',
            name: '阜新蒙古族自治县',
            districts: [],
            isLeaf: true,
            center: '121.757901,42.065175'
          },
          {
            adcode: '210922',
            name: '彰武县',
            districts: [],
            isLeaf: true,
            center: '122.538563,42.386264'
          },
          {
            adcode: '210902',
            name: '海州区',
            districts: [],
            isLeaf: true,
            center: '119.163492,34.572506'
          },
          {
            adcode: '210911',
            name: '细河区',
            districts: [],
            isLeaf: true,
            center: '121.680157,42.025412'
          }
        ]
      },
      {
        adcode: '210500',
        name: '本溪市',
        districts: [
          {
            adcode: '210522',
            name: '桓仁满族自治县',
            districts: [],
            isLeaf: true,
            center: '125.361061,41.267048'
          },
          {
            adcode: '210505',
            name: '南芬区',
            districts: [],
            isLeaf: true,
            center: '123.744822,41.100273'
          },
          {
            adcode: '210504',
            name: '明山区',
            districts: [],
            isLeaf: true,
            center: '123.861747,41.343624'
          },
          {
            adcode: '210521',
            name: '本溪满族自治县',
            districts: [],
            isLeaf: true,
            center: '124.119545,41.301553'
          },
          {
            adcode: '210503',
            name: '溪湖区',
            districts: [],
            isLeaf: true,
            center: '123.767817,41.32931'
          },
          {
            adcode: '210502',
            name: '平山区',
            districts: [],
            isLeaf: true,
            center: '123.769081,41.299658'
          }
        ]
      },
      {
        adcode: '211000',
        name: '辽阳市',
        districts: [
          {
            adcode: '211081',
            name: '灯塔市',
            districts: [],
            isLeaf: true,
            center: '123.339398,41.426537'
          },
          {
            adcode: '211005',
            name: '弓长岭区',
            districts: [],
            isLeaf: true,
            center: '123.419567,41.151787'
          },
          {
            adcode: '211011',
            name: '太子河区',
            districts: [],
            isLeaf: true,
            center: '123.1814403,41.29502257'
          },
          {
            adcode: '211003',
            name: '文圣区',
            districts: [],
            isLeaf: true,
            center: '123.231262,41.282378'
          },
          {
            adcode: '211021',
            name: '辽阳县',
            districts: [],
            isLeaf: true,
            center: '123.106426,41.206246'
          },
          {
            adcode: '211004',
            name: '宏伟区',
            districts: [],
            isLeaf: true,
            center: '123.196725,41.217852'
          },
          {
            adcode: '211002',
            name: '白塔区',
            districts: [],
            isLeaf: true,
            center: '123.174347,41.270397'
          }
        ]
      },
      {
        adcode: '210400',
        name: '抚顺市',
        districts: [
          {
            adcode: '210422',
            name: '新宾满族自治县',
            districts: [],
            isLeaf: true,
            center: '125.039994,41.734031'
          },
          {
            adcode: '210411',
            name: '顺城区',
            districts: [],
            isLeaf: true,
            center: '123.94504,41.883375'
          },
          {
            adcode: '210404',
            name: '望花区',
            districts: [],
            isLeaf: true,
            center: '123.784235,41.853484'
          },
          {
            adcode: '210402',
            name: '新抚区',
            districts: [],
            isLeaf: true,
            center: '123.91293,41.861922'
          },
          {
            adcode: '210421',
            name: '抚顺县',
            districts: [],
            isLeaf: true,
            center: '123.8999229,41.73009376'
          },
          {
            adcode: '210403',
            name: '东洲区',
            districts: [],
            isLeaf: true,
            center: '124.038491,41.853001'
          },
          {
            adcode: '210423',
            name: '清原满族自治县',
            districts: [],
            isLeaf: true,
            center: '124.924028,42.100804'
          }
        ]
      },
      {
        adcode: '210800',
        name: '营口市',
        districts: [
          {
            adcode: '210803',
            name: '西市区',
            districts: [],
            isLeaf: true,
            center: '122.206431,40.666707'
          },
          {
            adcode: '210882',
            name: '大石桥市',
            districts: [],
            isLeaf: true,
            center: '122.509006,40.644482'
          },
          {
            adcode: '210804',
            name: '鲅鱼圈区',
            districts: [],
            isLeaf: true,
            center: '122.121764,40.226708'
          },
          {
            adcode: '210881',
            name: '盖州市',
            districts: [],
            isLeaf: true,
            center: '122.34845,40.400259'
          },
          {
            adcode: '210802',
            name: '站前区',
            districts: [],
            isLeaf: true,
            center: '122.259058,40.673063'
          },
          {
            adcode: '210811',
            name: '老边区',
            districts: [],
            isLeaf: true,
            center: '122.307956,40.671279'
          }
        ]
      },
      {
        adcode: '211300',
        name: '朝阳市',
        districts: [
          {
            adcode: '211324',
            name: '喀喇沁左翼蒙古族自治县',
            districts: [],
            isLeaf: true,
            center: '119.741223,41.12815'
          },
          {
            adcode: '211382',
            name: '凌源市',
            districts: [],
            isLeaf: true,
            center: '119.401574,41.245445'
          },
          {
            adcode: '211381',
            name: '北票市',
            districts: [],
            isLeaf: true,
            center: '120.771389,41.801117'
          },
          {
            adcode: '211303',
            name: '龙城区',
            districts: [],
            isLeaf: true,
            center: '120.4133759,41.57674859'
          },
          {
            adcode: '211322',
            name: '建平县',
            districts: [],
            isLeaf: true,
            center: '119.64328,41.402891'
          },
          {
            adcode: '211302',
            name: '双塔区',
            districts: [],
            isLeaf: true,
            center: '120.453717,41.565814'
          },
          {
            adcode: '211321',
            name: '朝阳县',
            districts: [],
            isLeaf: true,
            center: '120.390001,41.497923'
          }
        ]
      },
      {
        adcode: '210300',
        name: '鞍山市',
        districts: [
          {
            adcode: '210323',
            name: '岫岩满族自治县',
            districts: [],
            isLeaf: true,
            center: '123.280933,40.290995'
          },
          {
            adcode: '210302',
            name: '铁东区',
            districts: [],
            isLeaf: true,
            center: '124.409852,43.162664'
          },
          {
            adcode: '210303',
            name: '铁西区',
            districts: [],
            isLeaf: true,
            center: '124.345994,43.146288'
          },
          {
            adcode: '210304',
            name: '立山区',
            districts: [],
            isLeaf: true,
            center: '123.029091,41.150401'
          },
          {
            adcode: '210311',
            name: '千山区',
            districts: [],
            isLeaf: true,
            center: '122.9447506,41.0689014'
          },
          {
            adcode: '210381',
            name: '海城市',
            districts: [],
            isLeaf: true,
            center: '122.685176,40.882548'
          },
          {
            adcode: '210321',
            name: '台安县',
            districts: [],
            isLeaf: true,
            center: '122.436037,41.412866'
          }
        ]
      },
      {
        adcode: '211100',
        name: '盘锦市',
        districts: [
          {
            adcode: '211122',
            name: '盘山县',
            districts: [],
            isLeaf: true,
            center: '121.996408,41.243367'
          },
          {
            adcode: '211102',
            name: '双台子区',
            districts: [],
            isLeaf: true,
            center: '122.07597,41.212401'
          },
          {
            adcode: '211103',
            name: '兴隆台区',
            districts: [],
            isLeaf: true,
            center: '122.070685,41.120261'
          },
          {
            adcode: '211104',
            name: '大洼区',
            districts: [],
            isLeaf: true,
            center: '122.082227,41.002679'
          }
        ]
      }
    ]
  },
  {
    adcode: '370000',
    name: '山东省',
    districts: [
      {
        adcode: '370300',
        name: '淄博市',
        districts: [
          {
            adcode: '370321',
            name: '桓台县',
            districts: [],
            isLeaf: true,
            center: '118.097923,36.959794'
          },
          {
            adcode: '370303',
            name: '张店区',
            districts: [],
            isLeaf: true,
            center: '118.017877,36.807011'
          },
          {
            adcode: '370305',
            name: '临淄区',
            districts: [],
            isLeaf: true,
            center: '118.309398,36.826882'
          },
          {
            adcode: '370322',
            name: '高青县',
            districts: [],
            isLeaf: true,
            center: '117.826743,37.171042'
          },
          {
            adcode: '370304',
            name: '博山区',
            districts: [],
            isLeaf: true,
            center: '117.8627,36.495014'
          },
          {
            adcode: '370302',
            name: '淄川区',
            districts: [],
            isLeaf: true,
            center: '117.966978,36.644211'
          },
          {
            adcode: '370323',
            name: '沂源县',
            districts: [],
            isLeaf: true,
            center: '118.170856,36.185402'
          },
          {
            adcode: '370306',
            name: '周村区',
            districts: [],
            isLeaf: true,
            center: '117.869686,36.80309'
          }
        ]
      },
      {
        adcode: '370500',
        name: '东营市',
        districts: [
          {
            adcode: '370522',
            name: '利津县',
            districts: [],
            isLeaf: true,
            center: '118.255084,37.490919'
          },
          {
            adcode: '370523',
            name: '广饶县',
            districts: [],
            isLeaf: true,
            center: '118.419437,37.053915'
          },
          {
            adcode: '370502',
            name: '东营区',
            districts: [],
            isLeaf: true,
            center: '118.582182,37.449035'
          },
          {
            adcode: '370503',
            name: '河口区',
            districts: [],
            isLeaf: true,
            center: '118.525519,37.886217'
          },
          {
            adcode: '370505',
            name: '垦利区',
            districts: [],
            isLeaf: true,
            center: '118.575234,37.573412'
          }
        ]
      },
      {
        adcode: '371600',
        name: '滨州市',
        districts: [
          {
            adcode: '371602',
            name: '滨城区',
            districts: [],
            isLeaf: true,
            center: '118.019567,37.431997'
          },
          {
            adcode: '371625',
            name: '博兴县',
            districts: [],
            isLeaf: true,
            center: '118.110715,37.154529'
          },
          {
            adcode: '371603',
            name: '沾化区',
            districts: [],
            isLeaf: true,
            center: '118.098962,37.698952'
          },
          {
            adcode: '371622',
            name: '阳信县',
            districts: [],
            isLeaf: true,
            center: '117.603407,37.632773'
          },
          {
            adcode: '371623',
            name: '无棣县',
            districts: [],
            isLeaf: true,
            center: '117.625846,37.770579'
          },
          {
            adcode: '371621',
            name: '惠民县',
            districts: [],
            isLeaf: true,
            center: '117.593744,37.47528'
          },
          {
            adcode: '371681',
            name: '邹平市',
            districts: [],
            isLeaf: true,
            center: '117.74313,36.863393'
          }
        ]
      },
      {
        adcode: '370600',
        name: '烟台市',
        districts: [
          {
            adcode: '370683',
            name: '莱州市',
            districts: [],
            isLeaf: true,
            center: '119.942057,37.178187'
          },
          {
            adcode: '370682',
            name: '莱阳市',
            districts: [],
            isLeaf: true,
            center: '120.711555,36.979757'
          },
          {
            adcode: '370687',
            name: '海阳市',
            districts: [],
            isLeaf: true,
            center: '121.173506,36.688316'
          },
          {
            adcode: '370602',
            name: '芝罘区',
            districts: [],
            isLeaf: true,
            center: '121.400303,37.541312'
          },
          {
            adcode: '370681',
            name: '龙口市',
            districts: [],
            isLeaf: true,
            center: '120.47614,37.645281'
          },
          {
            adcode: '370612',
            name: '牟平区',
            districts: [],
            isLeaf: true,
            center: '121.601015,37.387454'
          },
          {
            adcode: '370614',
            name: '蓬莱区',
            districts: [],
            isLeaf: true,
            center: '120.829141,37.795697'
          },
          {
            adcode: '370613',
            name: '莱山区',
            districts: [],
            isLeaf: true,
            center: '121.444949,37.511322'
          },
          {
            adcode: '370611',
            name: '福山区',
            districts: [],
            isLeaf: true,
            center: '121.267638,37.49833'
          },
          {
            adcode: '370686',
            name: '栖霞市',
            districts: [],
            isLeaf: true,
            center: '120.849595,37.335993'
          },
          {
            adcode: '370685',
            name: '招远市',
            districts: [],
            isLeaf: true,
            center: '120.433904,37.354517'
          }
        ]
      },
      {
        adcode: '371000',
        name: '威海市',
        districts: [
          {
            adcode: '371003',
            name: '文登区',
            districts: [],
            isLeaf: true,
            center: '122.08832,37.196032'
          },
          {
            adcode: '371083',
            name: '乳山市',
            districts: [],
            isLeaf: true,
            center: '121.539557,36.920329'
          },
          {
            adcode: '371082',
            name: '荣成市',
            districts: [],
            isLeaf: true,
            center: '122.487036,37.165698'
          },
          {
            adcode: '371002',
            name: '环翠区',
            districts: [],
            isLeaf: true,
            center: '122.123115,37.501735'
          }
        ]
      },
      {
        adcode: '371500',
        name: '聊城市',
        districts: [
          {
            adcode: '371503',
            name: '茌平区',
            districts: [],
            isLeaf: true,
            center: '116.254865,36.580814'
          },
          {
            adcode: '371521',
            name: '阳谷县',
            districts: [],
            isLeaf: true,
            center: '115.791822,36.114392'
          },
          {
            adcode: '371526',
            name: '高唐县',
            districts: [],
            isLeaf: true,
            center: '116.230126,36.84715'
          },
          {
            adcode: '371522',
            name: '莘县',
            districts: [],
            isLeaf: true,
            center: '115.669843,36.233711'
          },
          {
            adcode: '371525',
            name: '冠县',
            districts: [],
            isLeaf: true,
            center: '115.441684,36.484011'
          },
          {
            adcode: '371581',
            name: '临清市',
            districts: [],
            isLeaf: true,
            center: '115.704982,36.83828'
          },
          {
            adcode: '371502',
            name: '东昌府区',
            districts: [],
            isLeaf: true,
            center: '116.006399,36.412737'
          },
          {
            adcode: '371524',
            name: '东阿县',
            districts: [],
            isLeaf: true,
            center: '116.247655,36.335198'
          }
        ]
      },
      {
        adcode: '371100',
        name: '日照市',
        districts: [
          {
            adcode: '371121',
            name: '五莲县',
            districts: [],
            isLeaf: true,
            center: '119.213673,35.760154'
          },
          {
            adcode: '371103',
            name: '岚山区',
            districts: [],
            isLeaf: true,
            center: '119.31881,35.121985'
          },
          {
            adcode: '371102',
            name: '东港区',
            districts: [],
            isLeaf: true,
            center: '119.462287,35.426037'
          },
          {
            adcode: '371122',
            name: '莒县',
            districts: [],
            isLeaf: true,
            center: '118.869059,35.59125'
          }
        ]
      },
      {
        adcode: '370700',
        name: '潍坊市',
        districts: [
          {
            adcode: '370702',
            name: '潍城区',
            districts: [],
            isLeaf: true,
            center: '119.02491,36.728077'
          },
          {
            adcode: '370783',
            name: '寿光市',
            districts: [],
            isLeaf: true,
            center: '118.791062,36.857133'
          },
          {
            adcode: '370782',
            name: '诸城市',
            districts: [],
            isLeaf: true,
            center: '119.410051,35.996404'
          },
          {
            adcode: '370784',
            name: '安丘市',
            districts: [],
            isLeaf: true,
            center: '119.21892,36.479642'
          },
          {
            adcode: '370725',
            name: '昌乐县',
            districts: [],
            isLeaf: true,
            center: '118.841035,36.688035'
          },
          {
            adcode: '370786',
            name: '昌邑市',
            districts: [],
            isLeaf: true,
            center: '119.403185,36.843256'
          },
          {
            adcode: '370781',
            name: '青州市',
            districts: [],
            isLeaf: true,
            center: '118.479637,36.685178'
          },
          {
            adcode: '370785',
            name: '高密市',
            districts: [],
            isLeaf: true,
            center: '119.755271,36.382973'
          },
          {
            adcode: '370724',
            name: '临朐县',
            districts: [],
            isLeaf: true,
            center: '118.543274,36.51246'
          },
          {
            adcode: '370705',
            name: '奎文区',
            districts: [],
            isLeaf: true,
            center: '119.132288,36.707459'
          },
          {
            adcode: '370704',
            name: '坊子区',
            districts: [],
            isLeaf: true,
            center: '119.166343,36.653671'
          },
          {
            adcode: '370703',
            name: '寒亭区',
            districts: [],
            isLeaf: true,
            center: '119.211251,36.755925'
          }
        ]
      },
      {
        adcode: '371700',
        name: '菏泽市',
        districts: [
          {
            adcode: '371724',
            name: '巨野县',
            districts: [],
            isLeaf: true,
            center: '116.062585,35.388543'
          },
          {
            adcode: '371723',
            name: '成武县',
            districts: [],
            isLeaf: true,
            center: '115.889775,34.95278'
          },
          {
            adcode: '371728',
            name: '东明县',
            districts: [],
            isLeaf: true,
            center: '115.107402,35.275664'
          },
          {
            adcode: '371726',
            name: '鄄城县',
            districts: [],
            isLeaf: true,
            center: '115.544507,35.534088'
          },
          {
            adcode: '371722',
            name: '单县',
            districts: [],
            isLeaf: true,
            center: '116.106396,34.779282'
          },
          {
            adcode: '371725',
            name: '郓城县',
            districts: [],
            isLeaf: true,
            center: '115.938939,35.575276'
          },
          {
            adcode: '371721',
            name: '曹县',
            districts: [],
            isLeaf: true,
            center: '115.556393,34.85875'
          },
          {
            adcode: '371702',
            name: '牡丹区',
            districts: [],
            isLeaf: true,
            center: '115.417021,35.251911'
          },
          {
            adcode: '371703',
            name: '定陶区',
            districts: [],
            isLeaf: true,
            center: '115.583172,35.105742'
          }
        ]
      },
      {
        adcode: '371300',
        name: '临沂市',
        districts: [
          {
            adcode: '371321',
            name: '沂南县',
            districts: [],
            isLeaf: true,
            center: '118.465259,35.550078'
          },
          {
            adcode: '371312',
            name: '河东区',
            districts: [],
            isLeaf: true,
            center: '117.251584,39.128294'
          },
          {
            adcode: '371327',
            name: '莒南县',
            districts: [],
            isLeaf: true,
            center: '118.835166,35.206252'
          },
          {
            adcode: '371325',
            name: '费县',
            districts: [],
            isLeaf: true,
            center: '117.9776,35.265851'
          },
          {
            adcode: '371324',
            name: '兰陵县',
            districts: [],
            isLeaf: true,
            center: '118.070912,34.858235'
          },
          {
            adcode: '371323',
            name: '沂水县',
            districts: [],
            isLeaf: true,
            center: '118.628142,35.790919'
          },
          {
            adcode: '371311',
            name: '罗庄区',
            districts: [],
            isLeaf: true,
            center: '118.284693,34.997061'
          },
          {
            adcode: '371302',
            name: '兰山区',
            districts: [],
            isLeaf: true,
            center: '118.347332,35.052797'
          },
          {
            adcode: '371329',
            name: '临沭县',
            districts: [],
            isLeaf: true,
            center: '118.650828,34.920171'
          },
          {
            adcode: '371328',
            name: '蒙阴县',
            districts: [],
            isLeaf: true,
            center: '117.953367,35.720128'
          },
          {
            adcode: '371326',
            name: '平邑县',
            districts: [],
            isLeaf: true,
            center: '117.615201,35.516849'
          },
          {
            adcode: '371322',
            name: '郯城县',
            districts: [],
            isLeaf: true,
            center: '118.36727,34.614077'
          }
        ]
      },
      {
        adcode: '370100',
        name: '济南市',
        districts: [
          {
            adcode: '370112',
            name: '历城区',
            districts: [],
            isLeaf: true,
            center: '117.065233,36.680731'
          },
          {
            adcode: '370102',
            name: '历下区',
            districts: [],
            isLeaf: true,
            center: '117.07632,36.666395'
          },
          {
            adcode: '370117',
            name: '钢城区',
            districts: [],
            isLeaf: true,
            center: '117.811604,36.059393'
          },
          {
            adcode: '370103',
            name: '市中区',
            districts: [],
            isLeaf: true,
            center: '103.761329,29.555374'
          },
          {
            adcode: '370104',
            name: '槐荫区',
            districts: [],
            isLeaf: true,
            center: '116.901057,36.652264'
          },
          {
            adcode: '370115',
            name: '济阳区',
            districts: [],
            isLeaf: true,
            center: '117.173722,36.978299'
          },
          {
            adcode: '370105',
            name: '天桥区',
            districts: [],
            isLeaf: true,
            center: '116.987106,36.678665'
          },
          {
            adcode: '370114',
            name: '章丘区',
            districts: [],
            isLeaf: true,
            center: '117.526228,36.681258'
          },
          {
            adcode: '370126',
            name: '商河县',
            districts: [],
            isLeaf: true,
            center: '117.157165,37.309694'
          },
          {
            adcode: '370113',
            name: '长清区',
            districts: [],
            isLeaf: true,
            center: '116.752101,36.554251'
          },
          {
            adcode: '370116',
            name: '莱芜区',
            districts: [],
            isLeaf: true,
            center: '117.675828,36.214895'
          },
          {
            adcode: '370124',
            name: '平阴县',
            districts: [],
            isLeaf: true,
            center: '116.455865,36.289813'
          }
        ]
      },
      {
        adcode: '371400',
        name: '德州市',
        districts: [
          {
            adcode: '371481',
            name: '乐陵市',
            districts: [],
            isLeaf: true,
            center: '117.232085,37.729769'
          },
          {
            adcode: '371422',
            name: '宁津县',
            districts: [],
            isLeaf: true,
            center: '116.800279,37.652456'
          },
          {
            adcode: '371402',
            name: '德城区',
            districts: [],
            isLeaf: true,
            center: '116.299558,37.451051'
          },
          {
            adcode: '371428',
            name: '武城县',
            districts: [],
            isLeaf: true,
            center: '116.069312,37.213398'
          },
          {
            adcode: '371403',
            name: '陵城区',
            districts: [],
            isLeaf: true,
            center: '116.576148,37.336444'
          },
          {
            adcode: '371426',
            name: '平原县',
            districts: [],
            isLeaf: true,
            center: '116.434016,37.165453'
          },
          {
            adcode: '371482',
            name: '禹城市',
            districts: [],
            isLeaf: true,
            center: '116.638558,36.934198'
          },
          {
            adcode: '371423',
            name: '庆云县',
            districts: [],
            isLeaf: true,
            center: '117.384163,37.774376'
          },
          {
            adcode: '371427',
            name: '夏津县',
            districts: [],
            isLeaf: true,
            center: '116.001796,36.948013'
          },
          {
            adcode: '371425',
            name: '齐河县',
            districts: [],
            isLeaf: true,
            center: '116.762767,36.784164'
          },
          {
            adcode: '371424',
            name: '临邑县',
            districts: [],
            isLeaf: true,
            center: '116.866568,37.190139'
          }
        ]
      },
      {
        adcode: '370200',
        name: '青岛市',
        districts: [
          {
            adcode: '370285',
            name: '莱西市',
            districts: [],
            isLeaf: true,
            center: '120.517581,36.889167'
          },
          {
            adcode: '370211',
            name: '黄岛区',
            districts: [],
            isLeaf: true,
            center: '120.19777,35.960688'
          },
          {
            adcode: '370281',
            name: '胶州市',
            districts: [],
            isLeaf: true,
            center: '120.033382,36.26468'
          },
          {
            adcode: '370283',
            name: '平度市',
            districts: [],
            isLeaf: true,
            center: '119.970192,36.753895'
          },
          {
            adcode: '370214',
            name: '城阳区',
            districts: [],
            isLeaf: true,
            center: '120.396255,36.307624'
          },
          {
            adcode: '370213',
            name: '李沧区',
            districts: [],
            isLeaf: true,
            center: '120.432652,36.145987'
          },
          {
            adcode: '370202',
            name: '市南区',
            districts: [],
            isLeaf: true,
            center: '120.412002,36.075867'
          },
          {
            adcode: '370203',
            name: '市北区',
            districts: [],
            isLeaf: true,
            center: '120.374675,36.08765'
          },
          {
            adcode: '370212',
            name: '崂山区',
            districts: [],
            isLeaf: true,
            center: '120.469282,36.107069'
          },
          {
            adcode: '370215',
            name: '即墨区',
            districts: [],
            isLeaf: true,
            center: '120.447691,36.390217'
          }
        ]
      },
      {
        adcode: '370900',
        name: '泰安市',
        districts: [
          {
            adcode: '370902',
            name: '泰山区',
            districts: [],
            isLeaf: true,
            center: '117.135215,36.192833'
          },
          {
            adcode: '370921',
            name: '宁阳县',
            districts: [],
            isLeaf: true,
            center: '116.805578,35.758919'
          },
          {
            adcode: '370982',
            name: '新泰市',
            districts: [],
            isLeaf: true,
            center: '117.768069,35.90953'
          },
          {
            adcode: '370911',
            name: '岱岳区',
            districts: [],
            isLeaf: true,
            center: '117.041651,36.188088'
          },
          {
            adcode: '370983',
            name: '肥城市',
            districts: [],
            isLeaf: true,
            center: '116.769098,36.181975'
          },
          {
            adcode: '370923',
            name: '东平县',
            districts: [],
            isLeaf: true,
            center: '116.470211,35.937049'
          }
        ]
      },
      {
        adcode: '370800',
        name: '济宁市',
        districts: [
          {
            adcode: '370829',
            name: '嘉祥县',
            districts: [],
            isLeaf: true,
            center: '116.342308,35.40794'
          },
          {
            adcode: '370828',
            name: '金乡县',
            districts: [],
            isLeaf: true,
            center: '116.311549,35.06658'
          },
          {
            adcode: '370826',
            name: '微山县',
            districts: [],
            isLeaf: true,
            center: '117.129188,34.806657'
          },
          {
            adcode: '370827',
            name: '鱼台县',
            districts: [],
            isLeaf: true,
            center: '116.650526,35.012706'
          },
          {
            adcode: '370832',
            name: '梁山县',
            districts: [],
            isLeaf: true,
            center: '116.131779,35.765957'
          },
          {
            adcode: '370830',
            name: '汶上县',
            districts: [],
            isLeaf: true,
            center: '116.497277,35.711891'
          },
          {
            adcode: '370812',
            name: '兖州区',
            districts: [],
            isLeaf: true,
            center: '116.78365,35.551938'
          },
          {
            adcode: '370811',
            name: '任城区',
            districts: [],
            isLeaf: true,
            center: '116.605763,35.444226'
          },
          {
            adcode: '370881',
            name: '曲阜市',
            districts: [],
            isLeaf: true,
            center: '116.986212,35.581933'
          },
          {
            adcode: '370831',
            name: '泗水县',
            districts: [],
            isLeaf: true,
            center: '117.250824,35.664719'
          },
          {
            adcode: '370883',
            name: '邹城市',
            districts: [],
            isLeaf: true,
            center: '117.007406,35.402536'
          }
        ]
      },
      {
        adcode: '370400',
        name: '枣庄市',
        districts: [
          {
            adcode: '370405',
            name: '台儿庄区',
            districts: [],
            isLeaf: true,
            center: '117.734287,34.563103'
          },
          {
            adcode: '370402',
            name: '市中区',
            districts: [],
            isLeaf: true,
            center: '103.761329,29.555374'
          },
          {
            adcode: '370404',
            name: '峄城区',
            districts: [],
            isLeaf: true,
            center: '117.590738,34.772931'
          },
          {
            adcode: '370406',
            name: '山亭区',
            districts: [],
            isLeaf: true,
            center: '117.461866,35.099638'
          },
          {
            adcode: '370403',
            name: '薛城区',
            districts: [],
            isLeaf: true,
            center: '117.286371,34.783282'
          },
          {
            adcode: '370481',
            name: '滕州市',
            districts: [],
            isLeaf: true,
            center: '117.165881,35.11412'
          }
        ]
      }
    ]
  },
  {
    adcode: '610000',
    name: '陕西省',
    districts: [
      {
        adcode: '611000',
        name: '商洛市',
        districts: [
          {
            adcode: '611022',
            name: '丹凤县',
            districts: [],
            isLeaf: true,
            center: '110.327542,33.696254'
          },
          {
            adcode: '611023',
            name: '商南县',
            districts: [],
            isLeaf: true,
            center: '110.881741,33.531071'
          },
          {
            adcode: '611021',
            name: '洛南县',
            districts: [],
            isLeaf: true,
            center: '110.148526,34.090815'
          },
          {
            adcode: '611002',
            name: '商州区',
            districts: [],
            isLeaf: true,
            center: '109.941452,33.862979'
          },
          {
            adcode: '611026',
            name: '柞水县',
            districts: [],
            isLeaf: true,
            center: '109.114006,33.686048'
          },
          {
            adcode: '611025',
            name: '镇安县',
            districts: [],
            isLeaf: true,
            center: '109.152833,33.423764'
          },
          {
            adcode: '611024',
            name: '山阳县',
            districts: [],
            isLeaf: true,
            center: '109.882289,33.532172'
          }
        ]
      },
      {
        adcode: '610700',
        name: '汉中市',
        districts: [
          {
            adcode: '610727',
            name: '略阳县',
            districts: [],
            isLeaf: true,
            center: '106.15658,33.327293'
          },
          {
            adcode: '610730',
            name: '佛坪县',
            districts: [],
            isLeaf: true,
            center: '107.990551,33.524261'
          },
          {
            adcode: '610723',
            name: '洋县',
            districts: [],
            isLeaf: true,
            center: '107.545678,33.222808'
          },
          {
            adcode: '610729',
            name: '留坝县',
            districts: [],
            isLeaf: true,
            center: '106.920781,33.617637'
          },
          {
            adcode: '610726',
            name: '宁强县',
            districts: [],
            isLeaf: true,
            center: '106.257636,32.830032'
          },
          {
            adcode: '610725',
            name: '勉县',
            districts: [],
            isLeaf: true,
            center: '106.673217,33.153636'
          },
          {
            adcode: '610702',
            name: '汉台区',
            districts: [],
            isLeaf: true,
            center: '107.03201,33.067523'
          },
          {
            adcode: '610703',
            name: '南郑区',
            districts: [],
            isLeaf: true,
            center: '106.936235,33.000034'
          },
          {
            adcode: '610728',
            name: '镇巴县',
            districts: [],
            isLeaf: true,
            center: '107.895015,32.536706'
          },
          {
            adcode: '610722',
            name: '城固县',
            districts: [],
            isLeaf: true,
            center: '107.333787,33.156937'
          },
          {
            adcode: '610724',
            name: '西乡县',
            districts: [],
            isLeaf: true,
            center: '107.766477,32.983282'
          }
        ]
      },
      {
        adcode: '610200',
        name: '铜川市',
        districts: [
          {
            adcode: '610222',
            name: '宜君县',
            districts: [],
            isLeaf: true,
            center: '109.117063,35.398624'
          },
          {
            adcode: '610203',
            name: '印台区',
            districts: [],
            isLeaf: true,
            center: '109.099848,35.11454'
          },
          {
            adcode: '610204',
            name: '耀州区',
            districts: [],
            isLeaf: true,
            center: '108.980095,34.909685'
          },
          {
            adcode: '610202',
            name: '王益区',
            districts: [],
            isLeaf: true,
            center: '109.075615,35.068925'
          }
        ]
      },
      {
        adcode: '610600',
        name: '延安市',
        districts: [
          {
            adcode: '610681',
            name: '子长市',
            districts: [],
            isLeaf: true,
            center: '109.675284,37.142462'
          },
          {
            adcode: '610626',
            name: '吴起县',
            districts: [],
            isLeaf: true,
            center: '108.176501,36.92746'
          },
          {
            adcode: '610603',
            name: '安塞区',
            districts: [],
            isLeaf: true,
            center: '109.329236,36.864571'
          },
          {
            adcode: '610625',
            name: '志丹县',
            districts: [],
            isLeaf: true,
            center: '108.767816,36.822232'
          },
          {
            adcode: '610622',
            name: '延川县',
            districts: [],
            isLeaf: true,
            center: '110.193503,36.878324'
          },
          {
            adcode: '610602',
            name: '宝塔区',
            districts: [],
            isLeaf: true,
            center: '109.489726,36.585138'
          },
          {
            adcode: '610621',
            name: '延长县',
            districts: [],
            isLeaf: true,
            center: '110.012455,36.579354'
          },
          {
            adcode: '610627',
            name: '甘泉县',
            districts: [],
            isLeaf: true,
            center: '109.351046,36.276645'
          },
          {
            adcode: '610630',
            name: '宜川县',
            districts: [],
            isLeaf: true,
            center: '110.168963,36.050178'
          },
          {
            adcode: '610628',
            name: '富县',
            districts: [],
            isLeaf: true,
            center: '109.37884,35.988111'
          },
          {
            adcode: '610629',
            name: '洛川县',
            districts: [],
            isLeaf: true,
            center: '109.43249,35.762626'
          },
          {
            adcode: '610632',
            name: '黄陵县',
            districts: [],
            isLeaf: true,
            center: '109.262919,35.579421'
          },
          {
            adcode: '610631',
            name: '黄龙县',
            districts: [],
            isLeaf: true,
            center: '109.84029,35.584655'
          }
        ]
      },
      {
        adcode: '610100',
        name: '西安市',
        districts: [
          {
            adcode: '610115',
            name: '临潼区',
            districts: [],
            isLeaf: true,
            center: '109.214249,34.367181'
          },
          {
            adcode: '610111',
            name: '灞桥区',
            districts: [],
            isLeaf: true,
            center: '109.064675,34.273111'
          },
          {
            adcode: '610116',
            name: '长安区',
            districts: [],
            isLeaf: true,
            center: '114.538955,38.03682'
          },
          {
            adcode: '610122',
            name: '蓝田县',
            districts: [],
            isLeaf: true,
            center: '109.323473,34.151256'
          },
          {
            adcode: '610118',
            name: '鄠邑区',
            districts: [],
            isLeaf: true,
            center: '108.604772,34.108707'
          },
          {
            adcode: '610124',
            name: '周至县',
            districts: [],
            isLeaf: true,
            center: '108.222219,34.163592'
          },
          {
            adcode: '610114',
            name: '阎良区',
            districts: [],
            isLeaf: true,
            center: '109.226124,34.662232'
          },
          {
            adcode: '610104',
            name: '莲湖区',
            districts: [],
            isLeaf: true,
            center: '108.944161,34.26535'
          },
          {
            adcode: '610113',
            name: '雁塔区',
            districts: [],
            isLeaf: true,
            center: '108.948592,34.222517'
          },
          {
            adcode: '610117',
            name: '高陵区',
            districts: [],
            isLeaf: true,
            center: '109.088269,34.53502'
          },
          {
            adcode: '610103',
            name: '碑林区',
            districts: [],
            isLeaf: true,
            center: '108.940681,34.256727'
          },
          {
            adcode: '610112',
            name: '未央区',
            districts: [],
            isLeaf: true,
            center: '108.946665,34.293109'
          },
          {
            adcode: '610102',
            name: '新城区',
            districts: [],
            isLeaf: true,
            center: '108.960707,34.266601'
          }
        ]
      },
      {
        adcode: '610500',
        name: '渭南市',
        districts: [
          {
            adcode: '610581',
            name: '韩城市',
            districts: [],
            isLeaf: true,
            center: '110.44295,35.477145'
          },
          {
            adcode: '610527',
            name: '白水县',
            districts: [],
            isLeaf: true,
            center: '109.590501,35.177497'
          },
          {
            adcode: '610522',
            name: '潼关县',
            districts: [],
            isLeaf: true,
            center: '110.246105,34.544294'
          },
          {
            adcode: '610525',
            name: '澄城县',
            districts: [],
            isLeaf: true,
            center: '109.932439,35.190256'
          },
          {
            adcode: '610526',
            name: '蒲城县',
            districts: [],
            isLeaf: true,
            center: '109.586263,34.955755'
          },
          {
            adcode: '610524',
            name: '合阳县',
            districts: [],
            isLeaf: true,
            center: '110.149412,35.237881'
          },
          {
            adcode: '610503',
            name: '华州区',
            districts: [],
            isLeaf: true,
            center: '109.775765,34.497019'
          },
          {
            adcode: '610582',
            name: '华阴市',
            districts: [],
            isLeaf: true,
            center: '110.092286,34.566552'
          },
          {
            adcode: '610523',
            name: '大荔县',
            districts: [],
            isLeaf: true,
            center: '109.941784,34.797073'
          },
          {
            adcode: '610502',
            name: '临渭区',
            districts: [],
            isLeaf: true,
            center: '109.510051,34.498902'
          },
          {
            adcode: '610528',
            name: '富平县',
            districts: [],
            isLeaf: true,
            center: '109.179903,34.751599'
          }
        ]
      },
      {
        adcode: '610900',
        name: '安康市',
        districts: [
          {
            adcode: '610923',
            name: '宁陕县',
            districts: [],
            isLeaf: true,
            center: '108.314299,33.310284'
          },
          {
            adcode: '610922',
            name: '石泉县',
            districts: [],
            isLeaf: true,
            center: '108.248061,33.03805'
          },
          {
            adcode: '610925',
            name: '岚皋县',
            districts: [],
            isLeaf: true,
            center: '108.902049,32.307001'
          },
          {
            adcode: '610924',
            name: '紫阳县',
            districts: [],
            isLeaf: true,
            center: '108.534291,32.520209'
          },
          {
            adcode: '610921',
            name: '汉阴县',
            districts: [],
            isLeaf: true,
            center: '108.508792,32.893057'
          },
          {
            adcode: '610927',
            name: '镇坪县',
            districts: [],
            isLeaf: true,
            center: '109.526873,31.883672'
          },
          {
            adcode: '610929',
            name: '白河县',
            districts: [],
            isLeaf: true,
            center: '110.112608,32.80901'
          },
          {
            adcode: '610981',
            name: '旬阳市',
            districts: [],
            isLeaf: true,
            center: '109.361783,32.832213'
          },
          {
            adcode: '610902',
            name: '汉滨区',
            districts: [],
            isLeaf: true,
            center: '109.026928,32.695436'
          },
          {
            adcode: '610926',
            name: '平利县',
            districts: [],
            isLeaf: true,
            center: '109.361919,32.389061'
          }
        ]
      },
      {
        adcode: '610800',
        name: '榆林市',
        districts: [
          {
            adcode: '610822',
            name: '府谷县',
            districts: [],
            isLeaf: true,
            center: '111.016431,39.015658'
          },
          {
            adcode: '610802',
            name: '榆阳区',
            districts: [],
            isLeaf: true,
            center: '109.72054,38.277078'
          },
          {
            adcode: '610831',
            name: '子洲县',
            districts: [],
            isLeaf: true,
            center: '110.035124,37.610554'
          },
          {
            adcode: '610827',
            name: '米脂县',
            districts: [],
            isLeaf: true,
            center: '110.183984,37.755134'
          },
          {
            adcode: '610826',
            name: '绥德县',
            districts: [],
            isLeaf: true,
            center: '110.263226,37.502984'
          },
          {
            adcode: '610881',
            name: '神木市',
            districts: [],
            isLeaf: true,
            center: '110.466867,38.899742'
          },
          {
            adcode: '610829',
            name: '吴堡县',
            districts: [],
            isLeaf: true,
            center: '110.739726,37.452179'
          },
          {
            adcode: '610824',
            name: '靖边县',
            districts: [],
            isLeaf: true,
            center: '108.794153,37.600351'
          },
          {
            adcode: '610830',
            name: '清涧县',
            districts: [],
            isLeaf: true,
            center: '110.121181,37.088921'
          },
          {
            adcode: '610825',
            name: '定边县',
            districts: [],
            isLeaf: true,
            center: '107.601048,37.594976'
          },
          {
            adcode: '610828',
            name: '佳县',
            districts: [],
            isLeaf: true,
            center: '110.491345,38.01951'
          },
          {
            adcode: '610803',
            name: '横山区',
            districts: [],
            isLeaf: true,
            center: '109.294144,37.962477'
          }
        ]
      },
      {
        adcode: '610400',
        name: '咸阳市',
        districts: [
          {
            adcode: '610424',
            name: '乾县',
            districts: [],
            isLeaf: true,
            center: '108.239316,34.528262'
          },
          {
            adcode: '610422',
            name: '三原县',
            districts: [],
            isLeaf: true,
            center: '108.940754,34.617282'
          },
          {
            adcode: '610430',
            name: '淳化县',
            districts: [],
            isLeaf: true,
            center: '108.580164,34.798596'
          },
          {
            adcode: '610403',
            name: '杨陵区',
            districts: [],
            isLeaf: true,
            center: '108.084661,34.272084'
          },
          {
            adcode: '610431',
            name: '武功县',
            districts: [],
            isLeaf: true,
            center: '108.200275,34.261026'
          },
          {
            adcode: '610481',
            name: '兴平市',
            districts: [],
            isLeaf: true,
            center: '108.490497,34.299199'
          },
          {
            adcode: '610429',
            name: '旬邑县',
            districts: [],
            isLeaf: true,
            center: '108.333815,35.111787'
          },
          {
            adcode: '610428',
            name: '长武县',
            districts: [],
            isLeaf: true,
            center: '107.79906,35.206273'
          },
          {
            adcode: '610404',
            name: '渭城区',
            districts: [],
            isLeaf: true,
            center: '108.737062,34.36202'
          },
          {
            adcode: '610402',
            name: '秦都区',
            districts: [],
            isLeaf: true,
            center: '108.706347,34.329478'
          },
          {
            adcode: '610425',
            name: '礼泉县',
            districts: [],
            isLeaf: true,
            center: '108.424682,34.481875'
          },
          {
            adcode: '610426',
            name: '永寿县',
            districts: [],
            isLeaf: true,
            center: '108.142197,34.69188'
          },
          {
            adcode: '610482',
            name: '彬州市',
            districts: [],
            isLeaf: true,
            center: '108.081892,35.035702'
          },
          {
            adcode: '610423',
            name: '泾阳县',
            districts: [],
            isLeaf: true,
            center: '108.843029,34.526557'
          }
        ]
      },
      {
        adcode: '610300',
        name: '宝鸡市',
        districts: [
          {
            adcode: '610327',
            name: '陇县',
            districts: [],
            isLeaf: true,
            center: '106.864404,34.892985'
          },
          {
            adcode: '610328',
            name: '千阳县',
            districts: [],
            isLeaf: true,
            center: '107.132421,34.642374'
          },
          {
            adcode: '610303',
            name: '金台区',
            districts: [],
            isLeaf: true,
            center: '107.232733,34.397071'
          },
          {
            adcode: '610302',
            name: '渭滨区',
            districts: [],
            isLeaf: true,
            center: '107.155344,34.355068'
          },
          {
            adcode: '610330',
            name: '凤县',
            districts: [],
            isLeaf: true,
            center: '106.515841,33.910797'
          },
          {
            adcode: '610331',
            name: '太白县',
            districts: [],
            isLeaf: true,
            center: '107.318932,34.058299'
          },
          {
            adcode: '610326',
            name: '眉县',
            districts: [],
            isLeaf: true,
            center: '107.750039,34.274774'
          },
          {
            adcode: '610324',
            name: '扶风县',
            districts: [],
            isLeaf: true,
            center: '107.900157,34.375636'
          },
          {
            adcode: '610304',
            name: '陈仓区',
            districts: [],
            isLeaf: true,
            center: '107.368993,34.35073'
          },
          {
            adcode: '610305',
            name: '凤翔区',
            districts: [],
            isLeaf: true,
            center: '107.401029,34.522167'
          },
          {
            adcode: '610323',
            name: '岐山县',
            districts: [],
            isLeaf: true,
            center: '107.621397,34.44373'
          },
          {
            adcode: '610329',
            name: '麟游县',
            districts: [],
            isLeaf: true,
            center: '107.793524,34.677902'
          }
        ]
      }
    ]
  },
  {
    adcode: '310000',
    name: '上海市',
    districts: [
      {
        adcode: '310100',
        name: '上海城区',
        districts: [
          {
            adcode: '310151',
            name: '崇明区',
            districts: [],
            isLeaf: true,
            center: '121.397662,31.623863'
          },
          {
            adcode: '310115',
            name: '浦东新区',
            districts: [],
            isLeaf: true,
            center: '121.544346,31.221461'
          },
          {
            adcode: '310120',
            name: '奉贤区',
            districts: [],
            isLeaf: true,
            center: '121.473945,30.918406'
          },
          {
            adcode: '310116',
            name: '金山区',
            districts: [],
            isLeaf: true,
            center: '121.341774,30.742769'
          },
          {
            adcode: '310107',
            name: '普陀区',
            districts: [],
            isLeaf: true,
            center: '122.323297,29.970571'
          },
          {
            adcode: '310101',
            name: '黄浦区',
            districts: [],
            isLeaf: true,
            center: '121.48442,31.231661'
          },
          {
            adcode: '310110',
            name: '杨浦区',
            districts: [],
            isLeaf: true,
            center: '121.525409,31.259588'
          },
          {
            adcode: '310109',
            name: '虹口区',
            districts: [],
            isLeaf: true,
            center: '121.504994,31.264917'
          },
          {
            adcode: '310104',
            name: '徐汇区',
            districts: [],
            isLeaf: true,
            center: '121.436307,31.188334'
          },
          {
            adcode: '310106',
            name: '静安区',
            districts: [],
            isLeaf: true,
            center: '121.447348,31.227718'
          },
          {
            adcode: '310113',
            name: '宝山区',
            districts: [],
            isLeaf: true,
            center: '121.489431,31.405242'
          },
          {
            adcode: '310114',
            name: '嘉定区',
            districts: [],
            isLeaf: true,
            center: '121.265276,31.375566'
          },
          {
            adcode: '310105',
            name: '长宁区',
            districts: [],
            isLeaf: true,
            center: '121.424751,31.220537'
          },
          {
            adcode: '310112',
            name: '闵行区',
            districts: [],
            isLeaf: true,
            center: '121.380857,31.112834'
          },
          {
            adcode: '310117',
            name: '松江区',
            districts: [],
            isLeaf: true,
            center: '121.227676,31.03257'
          },
          {
            adcode: '310118',
            name: '青浦区',
            districts: [],
            isLeaf: true,
            center: '121.124249,31.15098'
          }
        ]
      }
    ]
  },
  {
    adcode: '520000',
    name: '贵州省',
    districts: [
      {
        adcode: '520300',
        name: '遵义市',
        districts: [
          {
            adcode: '520328',
            name: '湄潭县',
            districts: [],
            isLeaf: true,
            center: '107.465492,27.748942'
          },
          {
            adcode: '520329',
            name: '余庆县',
            districts: [],
            isLeaf: true,
            center: '107.906043,27.21513'
          },
          {
            adcode: '520323',
            name: '绥阳县',
            districts: [],
            isLeaf: true,
            center: '107.191326,27.946049'
          },
          {
            adcode: '520304',
            name: '播州区',
            districts: [],
            isLeaf: true,
            center: '106.829081,27.535735'
          },
          {
            adcode: '520325',
            name: '道真仡佬族苗族自治县',
            districts: [],
            isLeaf: true,
            center: '107.613076,28.862548'
          },
          {
            adcode: '520326',
            name: '务川仡佬族苗族自治县',
            districts: [],
            isLeaf: true,
            center: '107.899353,28.562921'
          },
          {
            adcode: '520327',
            name: '凤冈县',
            districts: [],
            isLeaf: true,
            center: '107.71646,27.954424'
          },
          {
            adcode: '520324',
            name: '正安县',
            districts: [],
            isLeaf: true,
            center: '107.454463,28.553507'
          },
          {
            adcode: '520330',
            name: '习水县',
            districts: [],
            isLeaf: true,
            center: '106.19715,28.332923'
          },
          {
            adcode: '520303',
            name: '汇川区',
            districts: [],
            isLeaf: true,
            center: '106.933727,27.749716'
          },
          {
            adcode: '520302',
            name: '红花岗区',
            districts: [],
            isLeaf: true,
            center: '106.893598,27.644793'
          },
          {
            adcode: '520382',
            name: '仁怀市',
            districts: [],
            isLeaf: true,
            center: '106.401322,27.791883'
          },
          {
            adcode: '520381',
            name: '赤水市',
            districts: [],
            isLeaf: true,
            center: '105.697501,28.590474'
          },
          {
            adcode: '520322',
            name: '桐梓县',
            districts: [],
            isLeaf: true,
            center: '106.824661,28.132991'
          }
        ]
      },
      {
        adcode: '520600',
        name: '铜仁市',
        districts: [
          {
            adcode: '520623',
            name: '石阡县',
            districts: [],
            isLeaf: true,
            center: '108.223686,27.513499'
          },
          {
            adcode: '520626',
            name: '德江县',
            districts: [],
            isLeaf: true,
            center: '108.120773,28.264028'
          },
          {
            adcode: '520624',
            name: '思南县',
            districts: [],
            isLeaf: true,
            center: '108.253798,27.937464'
          },
          {
            adcode: '520621',
            name: '江口县',
            districts: [],
            isLeaf: true,
            center: '108.843993,27.704883'
          },
          {
            adcode: '520628',
            name: '松桃苗族自治县',
            districts: [],
            isLeaf: true,
            center: '109.202877,28.15427'
          },
          {
            adcode: '520625',
            name: '印江土家族苗族自治县',
            districts: [],
            isLeaf: true,
            center: '108.409638,27.994442'
          },
          {
            adcode: '520602',
            name: '碧江区',
            districts: [],
            isLeaf: true,
            center: '109.264271,27.815244'
          },
          {
            adcode: '520603',
            name: '万山区',
            districts: [],
            isLeaf: true,
            center: '109.153685,27.671268'
          },
          {
            adcode: '520627',
            name: '沿河土家族自治县',
            districts: [],
            isLeaf: true,
            center: '108.503152,28.564083'
          },
          {
            adcode: '520622',
            name: '玉屏侗族自治县',
            districts: [],
            isLeaf: true,
            center: '108.906415,27.235816'
          }
        ]
      },
      {
        adcode: '520200',
        name: '六盘水市',
        districts: [
          {
            adcode: '520201',
            name: '钟山区',
            districts: [],
            isLeaf: true,
            center: '104.843723,26.574699'
          },
          {
            adcode: '520204',
            name: '水城区',
            districts: [],
            isLeaf: true,
            center: '104.957871,26.547604'
          },
          {
            adcode: '520203',
            name: '六枝特区',
            districts: [],
            isLeaf: true,
            center: '105.477199,26.214356'
          },
          {
            adcode: '520281',
            name: '盘州市',
            districts: [],
            isLeaf: true,
            center: '104.471554,25.709878'
          }
        ]
      },
      {
        adcode: '522700',
        name: '黔南布依族苗族自治州',
        districts: [
          {
            adcode: '522731',
            name: '惠水县',
            districts: [],
            isLeaf: true,
            center: '106.656993,26.132045'
          },
          {
            adcode: '522727',
            name: '平塘县',
            districts: [],
            isLeaf: true,
            center: '107.322635,25.822395'
          },
          {
            adcode: '522728',
            name: '罗甸县',
            districts: [],
            isLeaf: true,
            center: '106.751724,25.42616'
          },
          {
            adcode: '522722',
            name: '荔波县',
            districts: [],
            isLeaf: true,
            center: '107.88057,25.402528'
          },
          {
            adcode: '522729',
            name: '长顺县',
            districts: [],
            isLeaf: true,
            center: '106.44057,26.003187'
          },
          {
            adcode: '522730',
            name: '龙里县',
            districts: [],
            isLeaf: true,
            center: '106.979177,26.453546'
          },
          {
            adcode: '522723',
            name: '贵定县',
            districts: [],
            isLeaf: true,
            center: '107.232208,26.557205'
          },
          {
            adcode: '522702',
            name: '福泉市',
            districts: [],
            isLeaf: true,
            center: '107.520371,26.686773'
          },
          {
            adcode: '522725',
            name: '瓮安县',
            districts: [],
            isLeaf: true,
            center: '107.471307,27.07838'
          },
          {
            adcode: '522732',
            name: '三都水族自治县',
            districts: [],
            isLeaf: true,
            center: '107.869489,25.983572'
          },
          {
            adcode: '522701',
            name: '都匀市',
            districts: [],
            isLeaf: true,
            center: '107.518628,26.259456'
          },
          {
            adcode: '522726',
            name: '独山县',
            districts: [],
            isLeaf: true,
            center: '107.557591,25.865418'
          }
        ]
      },
      {
        adcode: '520400',
        name: '安顺市',
        districts: [
          {
            adcode: '520402',
            name: '西秀区',
            districts: [],
            isLeaf: true,
            center: '105.966086,26.243928'
          },
          {
            adcode: '520425',
            name: '紫云苗族布依族自治县',
            districts: [],
            isLeaf: true,
            center: '106.084441,25.751047'
          },
          {
            adcode: '520424',
            name: '关岭布依族苗族自治县',
            districts: [],
            isLeaf: true,
            center: '105.538335,25.953518'
          },
          {
            adcode: '520403',
            name: '平坝区',
            districts: [],
            isLeaf: true,
            center: '106.255768,26.405968'
          },
          {
            adcode: '520422',
            name: '普定县',
            districts: [],
            isLeaf: true,
            center: '105.743156,26.301876'
          },
          {
            adcode: '520423',
            name: '镇宁布依族苗族自治县',
            districts: [],
            isLeaf: true,
            center: '105.770543,26.057248'
          }
        ]
      },
      {
        adcode: '522300',
        name: '黔西南布依族苗族自治州',
        districts: [
          {
            adcode: '522328',
            name: '安龙县',
            districts: [],
            isLeaf: true,
            center: '105.442702,25.099014'
          },
          {
            adcode: '522327',
            name: '册亨县',
            districts: [],
            isLeaf: true,
            center: '105.811592,24.983663'
          },
          {
            adcode: '522302',
            name: '兴仁市',
            districts: [],
            isLeaf: true,
            center: '105.186132,25.43509'
          },
          {
            adcode: '522324',
            name: '晴隆县',
            districts: [],
            isLeaf: true,
            center: '105.218956,25.834729'
          },
          {
            adcode: '522325',
            name: '贞丰县',
            districts: [],
            isLeaf: true,
            center: '105.64976,25.38558'
          },
          {
            adcode: '522326',
            name: '望谟县',
            districts: [],
            isLeaf: true,
            center: '106.094864,25.174204'
          },
          {
            adcode: '522301',
            name: '兴义市',
            districts: [],
            isLeaf: true,
            center: '104.895503,25.09196'
          },
          {
            adcode: '522323',
            name: '普安县',
            districts: [],
            isLeaf: true,
            center: '104.953289,25.784225'
          }
        ]
      },
      {
        adcode: '522600',
        name: '黔东南苗族侗族自治州',
        districts: [
          {
            adcode: '522634',
            name: '雷山县',
            districts: [],
            isLeaf: true,
            center: '108.078217,26.378892'
          },
          {
            adcode: '522632',
            name: '榕江县',
            districts: [],
            isLeaf: true,
            center: '108.52188,25.931893'
          },
          {
            adcode: '522627',
            name: '天柱县',
            districts: [],
            isLeaf: true,
            center: '109.207826,26.909548'
          },
          {
            adcode: '522623',
            name: '施秉县',
            districts: [],
            isLeaf: true,
            center: '108.124531,27.033107'
          },
          {
            adcode: '522625',
            name: '镇远县',
            districts: [],
            isLeaf: true,
            center: '108.429536,27.049033'
          },
          {
            adcode: '522601',
            name: '凯里市',
            districts: [],
            isLeaf: true,
            center: '107.981409,26.5662'
          },
          {
            adcode: '522636',
            name: '丹寨县',
            districts: [],
            isLeaf: true,
            center: '107.789301,26.19857'
          },
          {
            adcode: '522631',
            name: '黎平县',
            districts: [],
            isLeaf: true,
            center: '109.125826,26.213304'
          },
          {
            adcode: '522633',
            name: '从江县',
            districts: [],
            isLeaf: true,
            center: '108.904998,25.754638'
          },
          {
            adcode: '522628',
            name: '锦屏县',
            districts: [],
            isLeaf: true,
            center: '109.200808,26.675919'
          },
          {
            adcode: '522622',
            name: '黄平县',
            districts: [],
            isLeaf: true,
            center: '107.916651,26.905278'
          },
          {
            adcode: '522630',
            name: '台江县',
            districts: [],
            isLeaf: true,
            center: '108.321244,26.667525'
          },
          {
            adcode: '522635',
            name: '麻江县',
            districts: [],
            isLeaf: true,
            center: '107.589455,26.49129'
          },
          {
            adcode: '522629',
            name: '剑河县',
            districts: [],
            isLeaf: true,
            center: '108.441501,26.728274'
          },
          {
            adcode: '522624',
            name: '三穗县',
            districts: [],
            isLeaf: true,
            center: '108.675265,26.953359'
          },
          {
            adcode: '522626',
            name: '岑巩县',
            districts: [],
            isLeaf: true,
            center: '108.815855,27.174124'
          }
        ]
      },
      {
        adcode: '520500',
        name: '毕节市',
        districts: [
          {
            adcode: '520523',
            name: '金沙县',
            districts: [],
            isLeaf: true,
            center: '106.220112,27.458601'
          },
          {
            adcode: '520581',
            name: '黔西市',
            districts: [],
            isLeaf: true,
            center: '106.032277,27.008681'
          },
          {
            adcode: '520524',
            name: '织金县',
            districts: [],
            isLeaf: true,
            center: '105.770249,26.663649'
          },
          {
            adcode: '520526',
            name: '威宁彝族回族苗族自治县',
            districts: [],
            isLeaf: true,
            center: '104.252787,26.873872'
          },
          {
            adcode: '520521',
            name: '大方县',
            districts: [],
            isLeaf: true,
            center: '105.601963,27.14443'
          },
          {
            adcode: '520525',
            name: '纳雍县',
            districts: [],
            isLeaf: true,
            center: '105.414578,26.779344'
          },
          {
            adcode: '520502',
            name: '七星关区',
            districts: [],
            isLeaf: true,
            center: '105.305219,27.298304'
          },
          {
            adcode: '520527',
            name: '赫章县',
            districts: [],
            isLeaf: true,
            center: '104.728011,27.117933'
          }
        ]
      },
      {
        adcode: '520100',
        name: '贵阳市',
        districts: [
          {
            adcode: '520121',
            name: '开阳县',
            districts: [],
            isLeaf: true,
            center: '106.964716,27.057823'
          },
          {
            adcode: '520113',
            name: '白云区',
            districts: [],
            isLeaf: true,
            center: '106.623069,26.677932'
          },
          {
            adcode: '520123',
            name: '修文县',
            districts: [],
            isLeaf: true,
            center: '106.591958,26.836048'
          },
          {
            adcode: '520122',
            name: '息烽县',
            districts: [],
            isLeaf: true,
            center: '106.740407,27.090479'
          },
          {
            adcode: '520181',
            name: '清镇市',
            districts: [],
            isLeaf: true,
            center: '106.468686,26.570435'
          },
          {
            adcode: '520112',
            name: '乌当区',
            districts: [],
            isLeaf: true,
            center: '106.75069,26.630911'
          },
          {
            adcode: '520111',
            name: '花溪区',
            districts: [],
            isLeaf: true,
            center: '106.67026,26.409817'
          },
          {
            adcode: '520102',
            name: '南明区',
            districts: [],
            isLeaf: true,
            center: '106.714305,26.568055'
          },
          {
            adcode: '520103',
            name: '云岩区',
            districts: [],
            isLeaf: true,
            center: '106.724394,26.604604'
          },
          {
            adcode: '520115',
            name: '观山湖区',
            districts: [],
            isLeaf: true,
            center: '106.598978,26.616134'
          }
        ]
      }
    ]
  },
  {
    adcode: '500000',
    name: '重庆市',
    districts: [
      {
        adcode: '500200',
        name: '重庆郊县',
        districts: [
          {
            adcode: '500236',
            name: '奉节县',
            districts: [],
            isLeaf: true,
            center: '109.401056,31.018505'
          },
          {
            adcode: '500238',
            name: '巫溪县',
            districts: [],
            isLeaf: true,
            center: '109.570038,31.398619'
          },
          {
            adcode: '500243',
            name: '彭水苗族土家族自治县',
            districts: [],
            isLeaf: true,
            center: '108.165571,29.293748'
          },
          {
            adcode: '500235',
            name: '云阳县',
            districts: [],
            isLeaf: true,
            center: '108.6975,30.930628'
          },
          {
            adcode: '500241',
            name: '秀山土家族苗族自治县',
            districts: [],
            isLeaf: true,
            center: '109.007096,28.448248'
          },
          {
            adcode: '500240',
            name: '石柱土家族自治县',
            districts: [],
            isLeaf: true,
            center: '108.114251,29.999066'
          },
          {
            adcode: '500237',
            name: '巫山县',
            districts: [],
            isLeaf: true,
            center: '109.878995,31.07478'
          },
          {
            adcode: '500230',
            name: '丰都县',
            districts: [],
            isLeaf: true,
            center: '107.731056,29.863785'
          },
          {
            adcode: '500229',
            name: '城口县',
            districts: [],
            isLeaf: true,
            center: '108.664349,31.947319'
          },
          {
            adcode: '500231',
            name: '垫江县',
            districts: [],
            isLeaf: true,
            center: '107.332511,30.327548'
          },
          {
            adcode: '500242',
            name: '酉阳土家族苗族自治县',
            districts: [],
            isLeaf: true,
            center: '108.76726,28.841409'
          },
          {
            adcode: '500233',
            name: '忠县',
            districts: [],
            isLeaf: true,
            center: '108.038073,30.299817'
          }
        ]
      },
      {
        adcode: '500100',
        name: '重庆城区',
        districts: [
          {
            adcode: '500117',
            name: '合川区',
            districts: [],
            isLeaf: true,
            center: '106.27617,29.971968'
          },
          {
            adcode: '500152',
            name: '潼南区',
            districts: [],
            isLeaf: true,
            center: '105.840487,30.191077'
          },
          {
            adcode: '500115',
            name: '长寿区',
            districts: [],
            isLeaf: true,
            center: '107.080945,29.857916'
          },
          {
            adcode: '500151',
            name: '铜梁区',
            districts: [],
            isLeaf: true,
            center: '106.056265,29.845248'
          },
          {
            adcode: '500111',
            name: '大足区',
            districts: [],
            isLeaf: true,
            center: '105.721825,29.707555'
          },
          {
            adcode: '500104',
            name: '大渡口区',
            districts: [],
            isLeaf: true,
            center: '106.482299,29.484464'
          },
          {
            adcode: '500107',
            name: '九龙坡区',
            districts: [],
            isLeaf: true,
            center: '106.510515,29.502325'
          },
          {
            adcode: '500101',
            name: '万州区',
            districts: [],
            isLeaf: true,
            center: '108.408591,30.807621'
          },
          {
            adcode: '500114',
            name: '黔江区',
            districts: [],
            isLeaf: true,
            center: '108.770677,29.533609'
          },
          {
            adcode: '500108',
            name: '南岸区',
            districts: [],
            isLeaf: true,
            center: '106.644254,29.50109'
          },
          {
            adcode: '500156',
            name: '武隆区',
            districts: [],
            isLeaf: true,
            center: '107.759955,29.325707'
          },
          {
            adcode: '500118',
            name: '永川区',
            districts: [],
            isLeaf: true,
            center: '105.926951,29.356384'
          },
          {
            adcode: '500153',
            name: '荣昌区',
            districts: [],
            isLeaf: true,
            center: '105.6118,29.416892'
          },
          {
            adcode: '500110',
            name: '綦江区',
            districts: [],
            isLeaf: true,
            center: '106.651213,29.028117'
          },
          {
            adcode: '500120',
            name: '璧山区',
            districts: [],
            isLeaf: true,
            center: '106.204885,29.577455'
          },
          {
            adcode: '500116',
            name: '江津区',
            districts: [],
            isLeaf: true,
            center: '106.264435,29.319984'
          },
          {
            adcode: '500113',
            name: '巴南区',
            districts: [],
            isLeaf: true,
            center: '106.540603,29.402348'
          },
          {
            adcode: '500154',
            name: '开州区',
            districts: [],
            isLeaf: true,
            center: '108.39336,31.160416'
          },
          {
            adcode: '500105',
            name: '江北区',
            districts: [],
            isLeaf: true,
            center: '121.555067,29.88673'
          },
          {
            adcode: '500112',
            name: '渝北区',
            districts: [],
            isLeaf: true,
            center: '106.631155,29.718087'
          },
          {
            adcode: '500102',
            name: '涪陵区',
            districts: [],
            isLeaf: true,
            center: '107.246521,29.752475'
          },
          {
            adcode: '500109',
            name: '北碚区',
            districts: [],
            isLeaf: true,
            center: '106.395593,29.805197'
          },
          {
            adcode: '500155',
            name: '梁平区',
            districts: [],
            isLeaf: true,
            center: '107.769568,30.654233'
          },
          {
            adcode: '500106',
            name: '沙坪坝区',
            districts: [],
            isLeaf: true,
            center: '106.456939,29.541017'
          },
          {
            adcode: '500103',
            name: '渝中区',
            districts: [],
            isLeaf: true,
            center: '106.568955,29.552642'
          },
          {
            adcode: '500119',
            name: '南川区',
            districts: [],
            isLeaf: true,
            center: '107.099147,29.157879'
          }
        ]
      }
    ]
  },
  {
    adcode: '340000',
    name: '安徽省',
    districts: [
      {
        adcode: '340700',
        name: '铜陵市',
        districts: [
          {
            adcode: '340711',
            name: '郊区',
            districts: [],
            isLeaf: true,
            center: '113.593998,37.943703'
          },
          {
            adcode: '340705',
            name: '铜官区',
            districts: [],
            isLeaf: true,
            center: '117.856541,30.936772'
          },
          {
            adcode: '340722',
            name: '枞阳县',
            districts: [],
            isLeaf: true,
            center: '117.250191,30.705466'
          },
          {
            adcode: '340706',
            name: '义安区',
            districts: [],
            isLeaf: true,
            center: '117.937405,30.950619'
          }
        ]
      },
      {
        adcode: '341700',
        name: '池州市',
        districts: [
          {
            adcode: '341702',
            name: '贵池区',
            districts: [],
            isLeaf: true,
            center: '117.568432,30.687389'
          },
          {
            adcode: '341723',
            name: '青阳县',
            districts: [],
            isLeaf: true,
            center: '117.847366,30.639006'
          },
          {
            adcode: '341721',
            name: '东至县',
            districts: [],
            isLeaf: true,
            center: '117.027533,30.111182'
          },
          {
            adcode: '341722',
            name: '石台县',
            districts: [],
            isLeaf: true,
            center: '117.486211,30.210218'
          }
        ]
      },
      {
        adcode: '340800',
        name: '安庆市',
        districts: [
          {
            adcode: '340881',
            name: '桐城市',
            districts: [],
            isLeaf: true,
            center: '116.936588,31.035476'
          },
          {
            adcode: '340828',
            name: '岳西县',
            districts: [],
            isLeaf: true,
            center: '116.359732,30.849716'
          },
          {
            adcode: '340802',
            name: '迎江区',
            districts: [],
            isLeaf: true,
            center: '117.090878,30.512768'
          },
          {
            adcode: '340882',
            name: '潜山市',
            districts: [],
            isLeaf: true,
            center: '116.581224,30.631022'
          },
          {
            adcode: '340811',
            name: '宜秀区',
            districts: [],
            isLeaf: true,
            center: '116.987469,30.613189'
          },
          {
            adcode: '340803',
            name: '大观区',
            districts: [],
            isLeaf: true,
            center: '117.013469,30.553697'
          },
          {
            adcode: '340825',
            name: '太湖县',
            districts: [],
            isLeaf: true,
            center: '116.30881,30.454198'
          },
          {
            adcode: '340822',
            name: '怀宁县',
            districts: [],
            isLeaf: true,
            center: '116.829612,30.73484'
          },
          {
            adcode: '340827',
            name: '望江县',
            districts: [],
            isLeaf: true,
            center: '116.70641,30.128404'
          },
          {
            adcode: '340826',
            name: '宿松县',
            districts: [],
            isLeaf: true,
            center: '116.134485,30.171663'
          }
        ]
      },
      {
        adcode: '340600',
        name: '淮北市',
        districts: [
          {
            adcode: '340602',
            name: '杜集区',
            districts: [],
            isLeaf: true,
            center: '116.827949,33.991412'
          },
          {
            adcode: '340603',
            name: '相山区',
            districts: [],
            isLeaf: true,
            center: '116.795233,33.959927'
          },
          {
            adcode: '340604',
            name: '烈山区',
            districts: [],
            isLeaf: true,
            center: '116.813221,33.895407'
          },
          {
            adcode: '340621',
            name: '濉溪县',
            districts: [],
            isLeaf: true,
            center: '116.76616,33.915612'
          }
        ]
      },
      {
        adcode: '340500',
        name: '马鞍山市',
        districts: [
          {
            adcode: '340504',
            name: '雨山区',
            districts: [],
            isLeaf: true,
            center: '118.498675,31.682374'
          },
          {
            adcode: '340522',
            name: '含山县',
            districts: [],
            isLeaf: true,
            center: '118.101448,31.735559'
          },
          {
            adcode: '340521',
            name: '当涂县',
            districts: [],
            isLeaf: true,
            center: '118.497873,31.570857'
          },
          {
            adcode: '340506',
            name: '博望区',
            districts: [],
            isLeaf: true,
            center: '118.818276,31.55117'
          },
          {
            adcode: '340523',
            name: '和县',
            districts: [],
            isLeaf: true,
            center: '118.353668,31.74248'
          },
          {
            adcode: '340503',
            name: '花山区',
            districts: [],
            isLeaf: true,
            center: '118.511366,31.700127'
          }
        ]
      },
      {
        adcode: '340200',
        name: '芜湖市',
        districts: [
          {
            adcode: '340281',
            name: '无为市',
            districts: [],
            isLeaf: true,
            center: '117.902265,31.304462'
          },
          {
            adcode: '340202',
            name: '镜湖区',
            districts: [],
            isLeaf: true,
            center: '118.385133,31.34056'
          },
          {
            adcode: '340210',
            name: '湾沚区',
            districts: [],
            isLeaf: true,
            center: '118.576124,31.134809'
          },
          {
            adcode: '340209',
            name: '弋江区',
            districts: [],
            isLeaf: true,
            center: '118.372664,31.311421'
          },
          {
            adcode: '340207',
            name: '鸠江区',
            districts: [],
            isLeaf: true,
            center: '118.392337,31.369752'
          },
          {
            adcode: '340223',
            name: '南陵县',
            districts: [],
            isLeaf: true,
            center: '118.334083,30.914621'
          },
          {
            adcode: '340212',
            name: '繁昌区',
            districts: [],
            isLeaf: true,
            center: '118.198536,31.101766'
          }
        ]
      },
      {
        adcode: '341500',
        name: '六安市',
        districts: [
          {
            adcode: '341503',
            name: '裕安区',
            districts: [],
            isLeaf: true,
            center: '116.479505,31.738789'
          },
          {
            adcode: '341522',
            name: '霍邱县',
            districts: [],
            isLeaf: true,
            center: '116.277966,32.353362'
          },
          {
            adcode: '341502',
            name: '金安区',
            districts: [],
            isLeaf: true,
            center: '116.539458,31.75014'
          },
          {
            adcode: '341504',
            name: '叶集区',
            districts: [],
            isLeaf: true,
            center: '115.925271,31.863693'
          },
          {
            adcode: '341523',
            name: '舒城县',
            districts: [],
            isLeaf: true,
            center: '116.94863,31.462036'
          },
          {
            adcode: '341524',
            name: '金寨县',
            districts: [],
            isLeaf: true,
            center: '115.934253,31.727295'
          },
          {
            adcode: '341525',
            name: '霍山县',
            districts: [],
            isLeaf: true,
            center: '116.348274,31.409668'
          }
        ]
      },
      {
        adcode: '341600',
        name: '亳州市',
        districts: [
          {
            adcode: '341621',
            name: '涡阳县',
            districts: [],
            isLeaf: true,
            center: '116.21665,33.494134'
          },
          {
            adcode: '341622',
            name: '蒙城县',
            districts: [],
            isLeaf: true,
            center: '116.56434,33.265671'
          },
          {
            adcode: '341602',
            name: '谯城区',
            districts: [],
            isLeaf: true,
            center: '115.779081,33.876436'
          },
          {
            adcode: '341623',
            name: '利辛县',
            districts: [],
            isLeaf: true,
            center: '116.206396,33.158424'
          }
        ]
      },
      {
        adcode: '341000',
        name: '黄山市',
        districts: [
          {
            adcode: '341003',
            name: '黄山区',
            districts: [],
            isLeaf: true,
            center: '118.141632,30.272991'
          },
          {
            adcode: '341023',
            name: '黟县',
            districts: [],
            isLeaf: true,
            center: '117.938139,29.92477'
          },
          {
            adcode: '341002',
            name: '屯溪区',
            districts: [],
            isLeaf: true,
            center: '118.315012,29.69655'
          },
          {
            adcode: '341022',
            name: '休宁县',
            districts: [],
            isLeaf: true,
            center: '118.193533,29.784532'
          },
          {
            adcode: '341024',
            name: '祁门县',
            districts: [],
            isLeaf: true,
            center: '117.717452,29.854179'
          },
          {
            adcode: '341021',
            name: '歙县',
            districts: [],
            isLeaf: true,
            center: '118.415258,29.861354'
          },
          {
            adcode: '341004',
            name: '徽州区',
            districts: [],
            isLeaf: true,
            center: '118.336799,29.827291'
          }
        ]
      },
      {
        adcode: '340300',
        name: '蚌埠市',
        districts: [
          {
            adcode: '340311',
            name: '淮上区',
            districts: [],
            isLeaf: true,
            center: '117.359352,32.96557'
          },
          {
            adcode: '340322',
            name: '五河县',
            districts: [],
            isLeaf: true,
            center: '117.881312,33.126532'
          },
          {
            adcode: '340304',
            name: '禹会区',
            districts: [],
            isLeaf: true,
            center: '117.34186,32.929715'
          },
          {
            adcode: '340303',
            name: '蚌山区',
            districts: [],
            isLeaf: true,
            center: '117.373347,32.916393'
          },
          {
            adcode: '340302',
            name: '龙子湖区',
            districts: [],
            isLeaf: true,
            center: '117.454544,32.90863'
          },
          {
            adcode: '340321',
            name: '怀远县',
            districts: [],
            isLeaf: true,
            center: '117.205083,32.971543'
          },
          {
            adcode: '340323',
            name: '固镇县',
            districts: [],
            isLeaf: true,
            center: '117.316585,33.318371'
          }
        ]
      },
      {
        adcode: '341800',
        name: '宣城市',
        districts: [
          {
            adcode: '341825',
            name: '旌德县',
            districts: [],
            isLeaf: true,
            center: '118.550043,30.298287'
          },
          {
            adcode: '341823',
            name: '泾县',
            districts: [],
            isLeaf: true,
            center: '118.419552,30.688793'
          },
          {
            adcode: '341881',
            name: '宁国市',
            districts: [],
            isLeaf: true,
            center: '118.983085,30.634032'
          },
          {
            adcode: '341802',
            name: '宣州区',
            districts: [],
            isLeaf: true,
            center: '118.786098,30.944356'
          },
          {
            adcode: '341824',
            name: '绩溪县',
            districts: [],
            isLeaf: true,
            center: '118.578519,30.067533'
          },
          {
            adcode: '341821',
            name: '郎溪县',
            districts: [],
            isLeaf: true,
            center: '119.184326,31.142995'
          },
          {
            adcode: '341882',
            name: '广德市',
            districts: [],
            isLeaf: true,
            center: '119.420799,30.877608'
          }
        ]
      },
      {
        adcode: '341100',
        name: '滁州市',
        districts: [
          {
            adcode: '341181',
            name: '天长市',
            districts: [],
            isLeaf: true,
            center: '119.004838,32.667409'
          },
          {
            adcode: '341126',
            name: '凤阳县',
            districts: [],
            isLeaf: true,
            center: '117.531791,32.875714'
          },
          {
            adcode: '341125',
            name: '定远县',
            districts: [],
            isLeaf: true,
            center: '117.698528,32.530741'
          },
          {
            adcode: '341124',
            name: '全椒县',
            districts: [],
            isLeaf: true,
            center: '118.274149,32.08593'
          },
          {
            adcode: '341182',
            name: '明光市',
            districts: [],
            isLeaf: true,
            center: '118.018197,32.78193'
          },
          {
            adcode: '341102',
            name: '琅琊区',
            districts: [],
            isLeaf: true,
            center: '118.32115,32.334475'
          },
          {
            adcode: '341103',
            name: '南谯区',
            districts: [],
            isLeaf: true,
            center: '118.416715,32.200084'
          },
          {
            adcode: '341122',
            name: '来安县',
            districts: [],
            isLeaf: true,
            center: '118.432581,32.423072'
          }
        ]
      },
      {
        adcode: '341300',
        name: '宿州市',
        districts: [
          {
            adcode: '341324',
            name: '泗县',
            districts: [],
            isLeaf: true,
            center: '117.910277,33.483686'
          },
          {
            adcode: '341321',
            name: '砀山县',
            districts: [],
            isLeaf: true,
            center: '116.366257,34.442139'
          },
          {
            adcode: '341323',
            name: '灵璧县',
            districts: [],
            isLeaf: true,
            center: '117.549321,33.554464'
          },
          {
            adcode: '341302',
            name: '埇桥区',
            districts: [],
            isLeaf: true,
            center: '116.977039,33.640205'
          },
          {
            adcode: '341322',
            name: '萧县',
            districts: [],
            isLeaf: true,
            center: '116.947289,34.18884'
          }
        ]
      },
      {
        adcode: '341200',
        name: '阜阳市',
        districts: [
          {
            adcode: '341202',
            name: '颍州区',
            districts: [],
            isLeaf: true,
            center: '115.806916,32.883322'
          },
          {
            adcode: '341221',
            name: '临泉县',
            districts: [],
            isLeaf: true,
            center: '115.26312,33.038884'
          },
          {
            adcode: '341204',
            name: '颍泉区',
            districts: [],
            isLeaf: true,
            center: '115.807415,32.925461'
          },
          {
            adcode: '341282',
            name: '界首市',
            districts: [],
            isLeaf: true,
            center: '115.374688,33.257799'
          },
          {
            adcode: '341222',
            name: '太和县',
            districts: [],
            isLeaf: true,
            center: '115.621941,33.160327'
          },
          {
            adcode: '341226',
            name: '颍上县',
            districts: [],
            isLeaf: true,
            center: '116.256772,32.653211'
          },
          {
            adcode: '341225',
            name: '阜南县',
            districts: [],
            isLeaf: true,
            center: '115.596003,32.659681'
          },
          {
            adcode: '341203',
            name: '颍东区',
            districts: [],
            isLeaf: true,
            center: '115.856728,32.91173'
          }
        ]
      },
      {
        adcode: '340400',
        name: '淮南市',
        districts: [
          {
            adcode: '340405',
            name: '八公山区',
            districts: [],
            isLeaf: true,
            center: '116.832979,32.630922'
          },
          {
            adcode: '340406',
            name: '潘集区',
            districts: [],
            isLeaf: true,
            center: '116.834715,32.77208'
          },
          {
            adcode: '340402',
            name: '大通区',
            districts: [],
            isLeaf: true,
            center: '117.053314,32.631519'
          },
          {
            adcode: '340404',
            name: '谢家集区',
            districts: [],
            isLeaf: true,
            center: '116.860026,32.599173'
          },
          {
            adcode: '340421',
            name: '凤台县',
            districts: [],
            isLeaf: true,
            center: '116.710947,32.710536'
          },
          {
            adcode: '340403',
            name: '田家庵区',
            districts: [],
            isLeaf: true,
            center: '117.017263,32.647456'
          },
          {
            adcode: '340422',
            name: '寿县',
            districts: [],
            isLeaf: true,
            center: '116.798203,32.545027'
          }
        ]
      },
      {
        adcode: '340100',
        name: '合肥市',
        districts: [
          {
            adcode: '340111',
            name: '包河区',
            districts: [],
            isLeaf: true,
            center: '117.310133,31.793801'
          },
          {
            adcode: '340124',
            name: '庐江县',
            districts: [],
            isLeaf: true,
            center: '117.288165,31.256978'
          },
          {
            adcode: '340103',
            name: '庐阳区',
            districts: [],
            isLeaf: true,
            center: '117.265088,31.878722'
          },
          {
            adcode: '340121',
            name: '长丰县',
            districts: [],
            isLeaf: true,
            center: '117.167632,32.478347'
          },
          {
            adcode: '340181',
            name: '巢湖市',
            districts: [],
            isLeaf: true,
            center: '117.890236,31.624464'
          },
          {
            adcode: '340122',
            name: '肥东县',
            districts: [],
            isLeaf: true,
            center: '117.4779,31.878601'
          },
          {
            adcode: '340102',
            name: '瑶海区',
            districts: [],
            isLeaf: true,
            center: '117.30944,31.858111'
          },
          {
            adcode: '340123',
            name: '肥西县',
            districts: [],
            isLeaf: true,
            center: '117.158416,31.707006'
          },
          {
            adcode: '340104',
            name: '蜀山区',
            districts: [],
            isLeaf: true,
            center: '117.26053,31.851211'
          }
        ]
      }
    ]
  },
  {
    adcode: '350000',
    name: '福建省',
    districts: [
      {
        adcode: '350100',
        name: '福州市',
        districts: [
          {
            adcode: '350122',
            name: '连江县',
            districts: [],
            isLeaf: true,
            center: '119.539542,26.197737'
          },
          {
            adcode: '350124',
            name: '闽清县',
            districts: [],
            isLeaf: true,
            center: '118.863334,26.221099'
          },
          {
            adcode: '350123',
            name: '罗源县',
            districts: [],
            isLeaf: true,
            center: '119.549129,26.490215'
          },
          {
            adcode: '350112',
            name: '长乐区',
            districts: [],
            isLeaf: true,
            center: '119.522952,25.962925'
          },
          {
            adcode: '350181',
            name: '福清市',
            districts: [],
            isLeaf: true,
            center: '119.384388,25.720081'
          },
          {
            adcode: '350128',
            name: '平潭县',
            districts: [],
            isLeaf: true,
            center: '119.789893,25.498767'
          },
          {
            adcode: '350103',
            name: '台江区',
            districts: [],
            isLeaf: true,
            center: '119.31419,26.052826'
          },
          {
            adcode: '350104',
            name: '仓山区',
            districts: [],
            isLeaf: true,
            center: '119.27322,26.047027'
          },
          {
            adcode: '350125',
            name: '永泰县',
            districts: [],
            isLeaf: true,
            center: '118.932746,25.867198'
          },
          {
            adcode: '350105',
            name: '马尾区',
            districts: [],
            isLeaf: true,
            center: '119.439259,25.998657'
          },
          {
            adcode: '350102',
            name: '鼓楼区',
            districts: [],
            isLeaf: true,
            center: '117.185643,34.288736'
          },
          {
            adcode: '350111',
            name: '晋安区',
            districts: [],
            isLeaf: true,
            center: '119.328591,26.081963'
          },
          {
            adcode: '350121',
            name: '闽侯县',
            districts: [],
            isLeaf: true,
            center: '119.131362,26.150428'
          }
        ]
      },
      {
        adcode: '350900',
        name: '宁德市',
        districts: [
          {
            adcode: '350982',
            name: '福鼎市',
            districts: [],
            isLeaf: true,
            center: '120.216559,27.324796'
          },
          {
            adcode: '350922',
            name: '古田县',
            districts: [],
            isLeaf: true,
            center: '118.74603,26.578134'
          },
          {
            adcode: '350902',
            name: '蕉城区',
            districts: [],
            isLeaf: true,
            center: '119.526284,26.660658'
          },
          {
            adcode: '350921',
            name: '霞浦县',
            districts: [],
            isLeaf: true,
            center: '120.005267,26.885971'
          },
          {
            adcode: '350981',
            name: '福安市',
            districts: [],
            isLeaf: true,
            center: '119.647871,27.088274'
          },
          {
            adcode: '350926',
            name: '柘荣县',
            districts: [],
            isLeaf: true,
            center: '119.900597,27.23421'
          },
          {
            adcode: '350923',
            name: '屏南县',
            districts: [],
            isLeaf: true,
            center: '118.985759,26.908256'
          },
          {
            adcode: '350925',
            name: '周宁县',
            districts: [],
            isLeaf: true,
            center: '119.338998,27.104797'
          },
          {
            adcode: '350924',
            name: '寿宁县',
            districts: [],
            isLeaf: true,
            center: '119.514589,27.45467'
          }
        ]
      },
      {
        adcode: '350300',
        name: '莆田市',
        districts: [
          {
            adcode: '350305',
            name: '秀屿区',
            districts: [],
            isLeaf: true,
            center: '119.105177,25.318649'
          },
          {
            adcode: '350304',
            name: '荔城区',
            districts: [],
            isLeaf: true,
            center: '119.014821,25.432268'
          },
          {
            adcode: '350302',
            name: '城厢区',
            districts: [],
            isLeaf: true,
            center: '118.994261,25.419062'
          },
          {
            adcode: '350322',
            name: '仙游县',
            districts: [],
            isLeaf: true,
            center: '118.689745,25.373373'
          },
          {
            adcode: '350303',
            name: '涵江区',
            districts: [],
            isLeaf: true,
            center: '119.116173,25.459057'
          }
        ]
      },
      {
        adcode: '350500',
        name: '泉州市',
        districts: [
          {
            adcode: '350582',
            name: '晋江市',
            districts: [],
            isLeaf: true,
            center: '118.551659,24.781635'
          },
          {
            adcode: '350504',
            name: '洛江区',
            districts: [],
            isLeaf: true,
            center: '118.671168,24.939319'
          },
          {
            adcode: '350583',
            name: '南安市',
            districts: [],
            isLeaf: true,
            center: '118.385929,24.960056'
          },
          {
            adcode: '350527',
            name: '金门县',
            districts: [],
            isLeaf: true,
            center: '118.319179,24.437147'
          },
          {
            adcode: '350521',
            name: '惠安县',
            districts: [],
            isLeaf: true,
            center: '118.781037,25.012449'
          },
          {
            adcode: '350581',
            name: '石狮市',
            districts: [],
            isLeaf: true,
            center: '118.647945,24.731969'
          },
          {
            adcode: '350505',
            name: '泉港区',
            districts: [],
            isLeaf: true,
            center: '118.915804,25.120399'
          },
          {
            adcode: '350525',
            name: '永春县',
            districts: [],
            isLeaf: true,
            center: '118.29416,25.321849'
          },
          {
            adcode: '350524',
            name: '安溪县',
            districts: [],
            isLeaf: true,
            center: '118.186295,25.056065'
          },
          {
            adcode: '350503',
            name: '丰泽区',
            districts: [],
            isLeaf: true,
            center: '118.613007,24.890373'
          },
          {
            adcode: '350502',
            name: '鲤城区',
            districts: [],
            isLeaf: true,
            center: '118.58652,24.908133'
          },
          {
            adcode: '350526',
            name: '德化县',
            districts: [],
            isLeaf: true,
            center: '118.241104,25.492102'
          }
        ]
      },
      {
        adcode: '350200',
        name: '厦门市',
        districts: [
          {
            adcode: '350206',
            name: '湖里区',
            districts: [],
            isLeaf: true,
            center: '118.146825,24.512858'
          },
          {
            adcode: '350203',
            name: '思明区',
            districts: [],
            isLeaf: true,
            center: '118.082745,24.445676'
          },
          {
            adcode: '350213',
            name: '翔安区',
            districts: [],
            isLeaf: true,
            center: '118.247911,24.618583'
          },
          {
            adcode: '350205',
            name: '海沧区',
            districts: [],
            isLeaf: true,
            center: '118.032883,24.484688'
          },
          {
            adcode: '350212',
            name: '同安区',
            districts: [],
            isLeaf: true,
            center: '118.150823,24.723299'
          },
          {
            adcode: '350211',
            name: '集美区',
            districts: [],
            isLeaf: true,
            center: '118.097407,24.575976'
          }
        ]
      },
      {
        adcode: '350600',
        name: '漳州市',
        districts: [
          {
            adcode: '350604',
            name: '龙海区',
            districts: [],
            isLeaf: true,
            center: '117.818485,24.446839'
          },
          {
            adcode: '350623',
            name: '漳浦县',
            districts: [],
            isLeaf: true,
            center: '117.613783,24.11741'
          },
          {
            adcode: '350626',
            name: '东山县',
            districts: [],
            isLeaf: true,
            center: '117.4295,23.702034'
          },
          {
            adcode: '350622',
            name: '云霄县',
            districts: [],
            isLeaf: true,
            center: '117.334351,23.935433'
          },
          {
            adcode: '350627',
            name: '南靖县',
            districts: [],
            isLeaf: true,
            center: '117.357061,24.5151'
          },
          {
            adcode: '350628',
            name: '平和县',
            districts: [],
            isLeaf: true,
            center: '117.314798,24.363831'
          },
          {
            adcode: '350603',
            name: '龙文区',
            districts: [],
            isLeaf: true,
            center: '117.711188,24.503523'
          },
          {
            adcode: '350629',
            name: '华安县',
            districts: [],
            isLeaf: true,
            center: '117.53411,25.00445'
          },
          {
            adcode: '350605',
            name: '长泰区',
            districts: [],
            isLeaf: true,
            center: '117.759119,24.625989'
          },
          {
            adcode: '350602',
            name: '芗城区',
            districts: [],
            isLeaf: true,
            center: '117.653727,24.510937'
          },
          {
            adcode: '350624',
            name: '诏安县',
            districts: [],
            isLeaf: true,
            center: '117.175314,23.711674'
          }
        ]
      },
      {
        adcode: '350800',
        name: '龙岩市',
        districts: [
          {
            adcode: '350824',
            name: '武平县',
            districts: [],
            isLeaf: true,
            center: '116.100216,25.096139'
          },
          {
            adcode: '350823',
            name: '上杭县',
            districts: [],
            isLeaf: true,
            center: '116.419447,25.049867'
          },
          {
            adcode: '350821',
            name: '长汀县',
            districts: [],
            isLeaf: true,
            center: '116.357581,25.833531'
          },
          {
            adcode: '350825',
            name: '连城县',
            districts: [],
            isLeaf: true,
            center: '116.754472,25.710538'
          },
          {
            adcode: '350802',
            name: '新罗区',
            districts: [],
            isLeaf: true,
            center: '117.036816,25.098942'
          },
          {
            adcode: '350881',
            name: '漳平市',
            districts: [],
            isLeaf: true,
            center: '117.419823,25.290481'
          },
          {
            adcode: '350803',
            name: '永定区',
            districts: [],
            isLeaf: true,
            center: '110.537379,29.119662'
          }
        ]
      },
      {
        adcode: '350700',
        name: '南平市',
        districts: [
          {
            adcode: '350723',
            name: '光泽县',
            districts: [],
            isLeaf: true,
            center: '117.333812,27.541061'
          },
          {
            adcode: '350722',
            name: '浦城县',
            districts: [],
            isLeaf: true,
            center: '118.541079,27.917804'
          },
          {
            adcode: '350783',
            name: '建瓯市',
            districts: [],
            isLeaf: true,
            center: '118.304712,27.023233'
          },
          {
            adcode: '350725',
            name: '政和县',
            districts: [],
            isLeaf: true,
            center: '118.857703,27.366122'
          },
          {
            adcode: '350724',
            name: '松溪县',
            districts: [],
            isLeaf: true,
            center: '118.785429,27.526028'
          },
          {
            adcode: '350703',
            name: '建阳区',
            districts: [],
            isLeaf: true,
            center: '118.120392,27.331996'
          },
          {
            adcode: '350721',
            name: '顺昌县',
            districts: [],
            isLeaf: true,
            center: '117.809433,26.793455'
          },
          {
            adcode: '350702',
            name: '延平区',
            districts: [],
            isLeaf: true,
            center: '118.181806,26.637503'
          },
          {
            adcode: '350782',
            name: '武夷山市',
            districts: [],
            isLeaf: true,
            center: '118.035321,27.756422'
          },
          {
            adcode: '350781',
            name: '邵武市',
            districts: [],
            isLeaf: true,
            center: '117.492778,27.340672'
          }
        ]
      },
      {
        adcode: '350400',
        name: '三明市',
        districts: [
          {
            adcode: '350405',
            name: '沙县区',
            districts: [],
            isLeaf: true,
            center: '117.792551,26.396843'
          },
          {
            adcode: '350430',
            name: '建宁县',
            districts: [],
            isLeaf: true,
            center: '116.848488,26.833566'
          },
          {
            adcode: '350421',
            name: '明溪县',
            districts: [],
            isLeaf: true,
            center: '117.202119,26.355938'
          },
          {
            adcode: '350428',
            name: '将乐县',
            districts: [],
            isLeaf: true,
            center: '117.471127,26.728917'
          },
          {
            adcode: '350424',
            name: '宁化县',
            districts: [],
            isLeaf: true,
            center: '116.654369,26.262298'
          },
          {
            adcode: '350404',
            name: '三元区',
            districts: [],
            isLeaf: true,
            center: '117.646153,26.271653'
          },
          {
            adcode: '350423',
            name: '清流县',
            districts: [],
            isLeaf: true,
            center: '116.816772,26.177658'
          },
          {
            adcode: '350481',
            name: '永安市',
            districts: [],
            isLeaf: true,
            center: '117.365046,25.941671'
          },
          {
            adcode: '350426',
            name: '尤溪县',
            districts: [],
            isLeaf: true,
            center: '118.190515,26.169942'
          },
          {
            adcode: '350429',
            name: '泰宁县',
            districts: [],
            isLeaf: true,
            center: '117.175448,26.900244'
          },
          {
            adcode: '350425',
            name: '大田县',
            districts: [],
            isLeaf: true,
            center: '117.846828,25.692535'
          }
        ]
      }
    ]
  },
  {
    adcode: '430000',
    name: '湖南省',
    districts: [
      {
        adcode: '430600',
        name: '岳阳市',
        districts: [
          {
            adcode: '430623',
            name: '华容县',
            districts: [],
            isLeaf: true,
            center: '112.540448,29.530775'
          },
          {
            adcode: '430603',
            name: '云溪区',
            districts: [],
            isLeaf: true,
            center: '113.272312,29.472745'
          },
          {
            adcode: '430611',
            name: '君山区',
            districts: [],
            isLeaf: true,
            center: '113.006434,29.461017'
          },
          {
            adcode: '430681',
            name: '汨罗市',
            districts: [],
            isLeaf: true,
            center: '113.067097,28.806937'
          },
          {
            adcode: '430624',
            name: '湘阴县',
            districts: [],
            isLeaf: true,
            center: '112.909248,28.690123'
          },
          {
            adcode: '430682',
            name: '临湘市',
            districts: [],
            isLeaf: true,
            center: '113.450461,29.476687'
          },
          {
            adcode: '430602',
            name: '岳阳楼区',
            districts: [],
            isLeaf: true,
            center: '113.129362,29.371356'
          },
          {
            adcode: '430621',
            name: '岳阳县',
            districts: [],
            isLeaf: true,
            center: '113.116152,29.144681'
          },
          {
            adcode: '430626',
            name: '平江县',
            districts: [],
            isLeaf: true,
            center: '113.581296,28.702018'
          }
        ]
      },
      {
        adcode: '430800',
        name: '张家界市',
        districts: [
          {
            adcode: '430802',
            name: '永定区',
            districts: [],
            isLeaf: true,
            center: '110.537379,29.119662'
          },
          {
            adcode: '430821',
            name: '慈利县',
            districts: [],
            isLeaf: true,
            center: '111.139424,29.430429'
          },
          {
            adcode: '430811',
            name: '武陵源区',
            districts: [],
            isLeaf: true,
            center: '110.550309,29.345721'
          },
          {
            adcode: '430822',
            name: '桑植县',
            districts: [],
            isLeaf: true,
            center: '110.204804,29.414112'
          }
        ]
      },
      {
        adcode: '431200',
        name: '怀化市',
        districts: [
          {
            adcode: '431228',
            name: '芷江侗族自治县',
            districts: [],
            isLeaf: true,
            center: '109.684696,27.443429'
          },
          {
            adcode: '431230',
            name: '通道侗族自治县',
            districts: [],
            isLeaf: true,
            center: '109.784409,26.158032'
          },
          {
            adcode: '431222',
            name: '沅陵县',
            districts: [],
            isLeaf: true,
            center: '110.393782,28.452103'
          },
          {
            adcode: '431223',
            name: '辰溪县',
            districts: [],
            isLeaf: true,
            center: '110.183159,28.006235'
          },
          {
            adcode: '431224',
            name: '溆浦县',
            districts: [],
            isLeaf: true,
            center: '110.594419,27.908869'
          },
          {
            adcode: '431225',
            name: '会同县',
            districts: [],
            isLeaf: true,
            center: '109.734724,26.887156'
          },
          {
            adcode: '431229',
            name: '靖州苗族侗族自治县',
            districts: [],
            isLeaf: true,
            center: '109.696249,26.575111'
          },
          {
            adcode: '431226',
            name: '麻阳苗族自治县',
            districts: [],
            isLeaf: true,
            center: '109.817703,27.857666'
          },
          {
            adcode: '431227',
            name: '新晃侗族自治县',
            districts: [],
            isLeaf: true,
            center: '109.174808,27.352094'
          },
          {
            adcode: '431202',
            name: '鹤城区',
            districts: [],
            isLeaf: true,
            center: '110.040099,27.578581'
          },
          {
            adcode: '431221',
            name: '中方县',
            districts: [],
            isLeaf: true,
            center: '109.945436,27.440554'
          },
          {
            adcode: '431281',
            name: '洪江市',
            districts: [],
            isLeaf: true,
            center: '109.836475,27.209385'
          }
        ]
      },
      {
        adcode: '433100',
        name: '湘西土家族苗族自治州',
        districts: [
          {
            adcode: '433125',
            name: '保靖县',
            districts: [],
            isLeaf: true,
            center: '109.660433,28.699939'
          },
          {
            adcode: '433127',
            name: '永顺县',
            districts: [],
            isLeaf: true,
            center: '109.857142,28.979819'
          },
          {
            adcode: '433124',
            name: '花垣县',
            districts: [],
            isLeaf: true,
            center: '109.482378,28.57191'
          },
          {
            adcode: '433101',
            name: '吉首市',
            districts: [],
            isLeaf: true,
            center: '109.698348,28.261948'
          },
          {
            adcode: '433126',
            name: '古丈县',
            districts: [],
            isLeaf: true,
            center: '109.95072,28.616971'
          },
          {
            adcode: '433122',
            name: '泸溪县',
            districts: [],
            isLeaf: true,
            center: '110.219619,28.216109'
          },
          {
            adcode: '433123',
            name: '凤凰县',
            districts: [],
            isLeaf: true,
            center: '109.580815,27.957441'
          },
          {
            adcode: '433130',
            name: '龙山县',
            districts: [],
            isLeaf: true,
            center: '109.443974,29.457623'
          }
        ]
      },
      {
        adcode: '430700',
        name: '常德市',
        districts: [
          {
            adcode: '430723',
            name: '澧县',
            districts: [],
            isLeaf: true,
            center: '111.758806,29.633138'
          },
          {
            adcode: '430781',
            name: '津市市',
            districts: [],
            isLeaf: true,
            center: '111.877957,29.606512'
          },
          {
            adcode: '430722',
            name: '汉寿县',
            districts: [],
            isLeaf: true,
            center: '111.970725,28.906063'
          },
          {
            adcode: '430703',
            name: '鼎城区',
            districts: [],
            isLeaf: true,
            center: '111.679896,29.017596'
          },
          {
            adcode: '430725',
            name: '桃源县',
            districts: [],
            isLeaf: true,
            center: '111.488985,28.90232'
          },
          {
            adcode: '430721',
            name: '安乡县',
            districts: [],
            isLeaf: true,
            center: '112.171107,29.41131'
          },
          {
            adcode: '430726',
            name: '石门县',
            districts: [],
            isLeaf: true,
            center: '111.380056,29.584344'
          },
          {
            adcode: '430724',
            name: '临澧县',
            districts: [],
            isLeaf: true,
            center: '111.647547,29.440813'
          },
          {
            adcode: '430702',
            name: '武陵区',
            districts: [],
            isLeaf: true,
            center: '111.683043,29.055332'
          }
        ]
      },
      {
        adcode: '430900',
        name: '益阳市',
        districts: [
          {
            adcode: '430902',
            name: '资阳区',
            districts: [],
            isLeaf: true,
            center: '112.324286,28.59149'
          },
          {
            adcode: '430922',
            name: '桃江县',
            districts: [],
            isLeaf: true,
            center: '112.156432,28.518215'
          },
          {
            adcode: '430923',
            name: '安化县',
            districts: [],
            isLeaf: true,
            center: '111.213296,28.374223'
          },
          {
            adcode: '430921',
            name: '南县',
            districts: [],
            isLeaf: true,
            center: '112.396323,29.362396'
          },
          {
            adcode: '430981',
            name: '沅江市',
            districts: [],
            isLeaf: true,
            center: '112.354657,28.845755'
          },
          {
            adcode: '430903',
            name: '赫山区',
            districts: [],
            isLeaf: true,
            center: '112.373895,28.58011'
          }
        ]
      },
      {
        adcode: '430500',
        name: '邵阳市',
        districts: [
          {
            adcode: '430524',
            name: '隆回县',
            districts: [],
            isLeaf: true,
            center: '111.032437,27.113978'
          },
          {
            adcode: '430511',
            name: '北塔区',
            districts: [],
            isLeaf: true,
            center: '111.451988,27.246244'
          },
          {
            adcode: '430503',
            name: '大祥区',
            districts: [],
            isLeaf: true,
            center: '111.438892,27.222078'
          },
          {
            adcode: '430523',
            name: '邵阳县',
            districts: [],
            isLeaf: true,
            center: '111.273872,26.990785'
          },
          {
            adcode: '430527',
            name: '绥宁县',
            districts: [],
            isLeaf: true,
            center: '110.155532,26.582027'
          },
          {
            adcode: '430502',
            name: '双清区',
            districts: [],
            isLeaf: true,
            center: '111.496946,27.232286'
          },
          {
            adcode: '430582',
            name: '邵东市',
            districts: [],
            isLeaf: true,
            center: '111.744268,27.259812'
          },
          {
            adcode: '430528',
            name: '新宁县',
            districts: [],
            isLeaf: true,
            center: '110.857294,26.432913'
          },
          {
            adcode: '430525',
            name: '洞口县',
            districts: [],
            isLeaf: true,
            center: '110.575962,27.060274'
          },
          {
            adcode: '430581',
            name: '武冈市',
            districts: [],
            isLeaf: true,
            center: '110.632286,26.727414'
          },
          {
            adcode: '430529',
            name: '城步苗族自治县',
            districts: [],
            isLeaf: true,
            center: '110.322239,26.390598'
          },
          {
            adcode: '430522',
            name: '新邵县',
            districts: [],
            isLeaf: true,
            center: '111.458656,27.320917'
          }
        ]
      },
      {
        adcode: '430400',
        name: '衡阳市',
        districts: [
          {
            adcode: '430423',
            name: '衡山县',
            districts: [],
            isLeaf: true,
            center: '112.868361,27.230225'
          },
          {
            adcode: '430421',
            name: '衡阳县',
            districts: [],
            isLeaf: true,
            center: '112.370649,26.970258'
          },
          {
            adcode: '430405',
            name: '珠晖区',
            districts: [],
            isLeaf: true,
            center: '112.620653,26.894796'
          },
          {
            adcode: '430407',
            name: '石鼓区',
            districts: [],
            isLeaf: true,
            center: '112.597937,26.944257'
          },
          {
            adcode: '430412',
            name: '南岳区',
            districts: [],
            isLeaf: true,
            center: '112.737678,27.231846'
          },
          {
            adcode: '430422',
            name: '衡南县',
            districts: [],
            isLeaf: true,
            center: '112.677572,26.739665'
          },
          {
            adcode: '430406',
            name: '雁峰区',
            districts: [],
            isLeaf: true,
            center: '112.613914,26.840335'
          },
          {
            adcode: '430481',
            name: '耒阳市',
            districts: [],
            isLeaf: true,
            center: '112.859806,26.422149'
          },
          {
            adcode: '430408',
            name: '蒸湘区',
            districts: [],
            isLeaf: true,
            center: '112.567333,26.912388'
          },
          {
            adcode: '430482',
            name: '常宁市',
            districts: [],
            isLeaf: true,
            center: '112.399894,26.421717'
          },
          {
            adcode: '430424',
            name: '衡东县',
            districts: [],
            isLeaf: true,
            center: '112.953113,27.081086'
          },
          {
            adcode: '430426',
            name: '祁东县',
            districts: [],
            isLeaf: true,
            center: '112.090319,26.799284'
          }
        ]
      },
      {
        adcode: '430100',
        name: '长沙市',
        districts: [
          {
            adcode: '430104',
            name: '岳麓区',
            districts: [],
            isLeaf: true,
            center: '112.930116,28.234202'
          },
          {
            adcode: '430112',
            name: '望城区',
            districts: [],
            isLeaf: true,
            center: '112.83125,28.353221'
          },
          {
            adcode: '430181',
            name: '浏阳市',
            districts: [],
            isLeaf: true,
            center: '113.64328,28.163866'
          },
          {
            adcode: '430105',
            name: '开福区',
            districts: [],
            isLeaf: true,
            center: '112.985284,28.257286'
          },
          {
            adcode: '430121',
            name: '长沙县',
            districts: [],
            isLeaf: true,
            center: '113.080555,28.246821'
          },
          {
            adcode: '430102',
            name: '芙蓉区',
            districts: [],
            isLeaf: true,
            center: '113.032605,28.185351'
          },
          {
            adcode: '430111',
            name: '雨花区',
            districts: [],
            isLeaf: true,
            center: '113.03853,28.135795'
          },
          {
            adcode: '430103',
            name: '天心区',
            districts: [],
            isLeaf: true,
            center: '112.98978,28.114544'
          },
          {
            adcode: '430182',
            name: '宁乡市',
            districts: [],
            isLeaf: true,
            center: '112.551887,28.277709'
          }
        ]
      },
      {
        adcode: '430300',
        name: '湘潭市',
        districts: [
          {
            adcode: '430302',
            name: '雨湖区',
            districts: [],
            isLeaf: true,
            center: '112.907334,27.856115'
          },
          {
            adcode: '430304',
            name: '岳塘区',
            districts: [],
            isLeaf: true,
            center: '112.969444,27.872013'
          },
          {
            adcode: '430382',
            name: '韶山市',
            districts: [],
            isLeaf: true,
            center: '112.525364,27.914796'
          },
          {
            adcode: '430321',
            name: '湘潭县',
            districts: [],
            isLeaf: true,
            center: '112.950767,27.779669'
          },
          {
            adcode: '430381',
            name: '湘乡市',
            districts: [],
            isLeaf: true,
            center: '112.550268,27.718655'
          }
        ]
      },
      {
        adcode: '430200',
        name: '株洲市',
        districts: [
          {
            adcode: '430202',
            name: '荷塘区',
            districts: [],
            isLeaf: true,
            center: '113.173169,27.856314'
          },
          {
            adcode: '430224',
            name: '茶陵县',
            districts: [],
            isLeaf: true,
            center: '113.537907,26.777957'
          },
          {
            adcode: '430281',
            name: '醴陵市',
            districts: [],
            isLeaf: true,
            center: '113.497119,27.6457'
          },
          {
            adcode: '430223',
            name: '攸县',
            districts: [],
            isLeaf: true,
            center: '113.397152,27.01516'
          },
          {
            adcode: '430225',
            name: '炎陵县',
            districts: [],
            isLeaf: true,
            center: '113.772665,26.489847'
          },
          {
            adcode: '430204',
            name: '石峰区',
            districts: [],
            isLeaf: true,
            center: '113.117763,27.875513'
          },
          {
            adcode: '430211',
            name: '天元区',
            districts: [],
            isLeaf: true,
            center: '113.082227,27.826738'
          },
          {
            adcode: '430212',
            name: '渌口区',
            districts: [],
            isLeaf: true,
            center: '113.143832,27.699361'
          },
          {
            adcode: '430203',
            name: '芦淞区',
            districts: [],
            isLeaf: true,
            center: '113.153455,27.785198'
          }
        ]
      },
      {
        adcode: '431300',
        name: '娄底市',
        districts: [
          {
            adcode: '431381',
            name: '冷水江市',
            districts: [],
            isLeaf: true,
            center: '111.459199,27.656017'
          },
          {
            adcode: '431321',
            name: '双峰县',
            districts: [],
            isLeaf: true,
            center: '112.174623,27.457437'
          },
          {
            adcode: '431382',
            name: '涟源市',
            districts: [],
            isLeaf: true,
            center: '111.663837,27.693813'
          },
          {
            adcode: '431302',
            name: '娄星区',
            districts: [],
            isLeaf: true,
            center: '112.001922,27.730208'
          },
          {
            adcode: '431322',
            name: '新化县',
            districts: [],
            isLeaf: true,
            center: '111.327674,27.725416'
          }
        ]
      },
      {
        adcode: '431100',
        name: '永州市',
        districts: [
          {
            adcode: '431122',
            name: '东安县',
            districts: [],
            isLeaf: true,
            center: '111.315893,26.392173'
          },
          {
            adcode: '431102',
            name: '零陵区',
            districts: [],
            isLeaf: true,
            center: '111.629806,26.222278'
          },
          {
            adcode: '431103',
            name: '冷水滩区',
            districts: [],
            isLeaf: true,
            center: '111.592373,26.461313'
          },
          {
            adcode: '431128',
            name: '新田县',
            districts: [],
            isLeaf: true,
            center: '112.203396,25.903965'
          },
          {
            adcode: '431126',
            name: '宁远县',
            districts: [],
            isLeaf: true,
            center: '111.945925,25.570817'
          },
          {
            adcode: '431123',
            name: '双牌县',
            districts: [],
            isLeaf: true,
            center: '111.659967,25.961909'
          },
          {
            adcode: '431124',
            name: '道县',
            districts: [],
            isLeaf: true,
            center: '111.600866,25.526282'
          },
          {
            adcode: '431125',
            name: '江永县',
            districts: [],
            isLeaf: true,
            center: '111.343543,25.274461'
          },
          {
            adcode: '431129',
            name: '江华瑶族自治县',
            districts: [],
            isLeaf: true,
            center: '111.57951,25.185257'
          },
          {
            adcode: '431127',
            name: '蓝山县',
            districts: [],
            isLeaf: true,
            center: '112.195842,25.369575'
          },
          {
            adcode: '431181',
            name: '祁阳市',
            districts: [],
            isLeaf: true,
            center: '111.840253,26.580292'
          }
        ]
      },
      {
        adcode: '431000',
        name: '郴州市',
        districts: [
          {
            adcode: '431026',
            name: '汝城县',
            districts: [],
            isLeaf: true,
            center: '113.685193,25.533024'
          },
          {
            adcode: '431081',
            name: '资兴市',
            districts: [],
            isLeaf: true,
            center: '113.235841,25.977226'
          },
          {
            adcode: '431027',
            name: '桂东县',
            districts: [],
            isLeaf: true,
            center: '113.944792,26.077609'
          },
          {
            adcode: '431024',
            name: '嘉禾县',
            districts: [],
            isLeaf: true,
            center: '112.379216,25.616025'
          },
          {
            adcode: '431002',
            name: '北湖区',
            districts: [],
            isLeaf: true,
            center: '113.010606,25.784632'
          },
          {
            adcode: '431022',
            name: '宜章县',
            districts: [],
            isLeaf: true,
            center: '112.948806,25.40059'
          },
          {
            adcode: '431025',
            name: '临武县',
            districts: [],
            isLeaf: true,
            center: '112.563833,25.276459'
          },
          {
            adcode: '431028',
            name: '安仁县',
            districts: [],
            isLeaf: true,
            center: '113.269703,26.708569'
          },
          {
            adcode: '431021',
            name: '桂阳县',
            districts: [],
            isLeaf: true,
            center: '112.733804,25.75382'
          },
          {
            adcode: '431003',
            name: '苏仙区',
            districts: [],
            isLeaf: true,
            center: '113.042364,25.799624'
          },
          {
            adcode: '431023',
            name: '永兴县',
            districts: [],
            isLeaf: true,
            center: '113.116587,26.127155'
          }
        ]
      }
    ]
  },
  {
    adcode: '460000',
    name: '海南省',
    districts: [
      {
        adcode: '469024',
        name: '临高县',
        districts: [],
        isLeaf: true,
        center: '109.690508,19.912025'
      },
      {
        adcode: '469022',
        name: '屯昌县',
        districts: [],
        isLeaf: true,
        center: '110.101667,19.351662'
      },
      {
        adcode: '469025',
        name: '白沙黎族自治县',
        districts: [],
        isLeaf: true,
        center: '109.4429,19.221641'
      },
      {
        adcode: '469002',
        name: '琼海市',
        districts: [],
        isLeaf: true,
        center: '110.474524,19.259112'
      },
      {
        adcode: '469030',
        name: '琼中黎族苗族自治县',
        districts: [],
        isLeaf: true,
        center: '109.838423,19.03327'
      },
      {
        adcode: '469007',
        name: '东方市',
        districts: [],
        isLeaf: true,
        center: '108.651829,19.095187'
      },
      {
        adcode: '469006',
        name: '万宁市',
        districts: [],
        isLeaf: true,
        center: '110.392605,18.793697'
      },
      {
        adcode: '469027',
        name: '乐东黎族自治县',
        districts: [],
        isLeaf: true,
        center: '109.173384,18.750063'
      },
      {
        adcode: '469028',
        name: '陵水黎族自治县',
        districts: [],
        isLeaf: true,
        center: '110.037553,18.506045'
      },
      {
        adcode: '460300',
        name: '三沙市',
        districts: [
          {
            adcode: '460301',
            name: '西沙区',
            districts: [],
            isLeaf: true,
            center: '112.346961,16.834372'
          },
          {
            adcode: '460302',
            name: '南沙区',
            districts: [],
            isLeaf: true,
            center: '112.896229,9.548531'
          }
        ]
      },
      {
        adcode: '469005',
        name: '文昌市',
        districts: [],
        isLeaf: true,
        center: '110.797473,19.544234'
      },
      {
        adcode: '460400',
        name: '儋州市',
        districts: [],
        isLeaf: true,
        center: '109.580812,19.520948'
      },
      {
        adcode: '460200',
        name: '三亚市',
        districts: [
          {
            adcode: '460202',
            name: '海棠区',
            districts: [],
            isLeaf: true,
            center: '109.735676,18.384177'
          },
          {
            adcode: '460205',
            name: '崖州区',
            districts: [],
            isLeaf: true,
            center: '109.172298,18.357572'
          },
          {
            adcode: '460203',
            name: '吉阳区',
            districts: [],
            isLeaf: true,
            center: '109.578238,18.28146'
          },
          {
            adcode: '460204',
            name: '天涯区',
            districts: [],
            isLeaf: true,
            center: '109.452325,18.298975'
          }
        ]
      },
      {
        adcode: '469029',
        name: '保亭黎族苗族自治县',
        districts: [],
        isLeaf: true,
        center: '109.700279,18.640339'
      },
      {
        adcode: '469001',
        name: '五指山市',
        districts: [],
        isLeaf: true,
        center: '109.516784,18.774827'
      },
      {
        adcode: '460100',
        name: '海口市',
        districts: [
          {
            adcode: '460108',
            name: '美兰区',
            districts: [],
            isLeaf: true,
            center: '110.366359,20.028983'
          },
          {
            adcode: '460105',
            name: '秀英区',
            districts: [],
            isLeaf: true,
            center: '110.293566,20.007703'
          },
          {
            adcode: '460106',
            name: '龙华区',
            districts: [],
            isLeaf: true,
            center: '110.328628,20.030843'
          },
          {
            adcode: '460107',
            name: '琼山区',
            districts: [],
            isLeaf: true,
            center: '110.384318,19.984293'
          }
        ]
      },
      {
        adcode: '469026',
        name: '昌江黎族自治县',
        districts: [],
        isLeaf: true,
        center: '109.055783,19.298139'
      },
      {
        adcode: '469021',
        name: '定安县',
        districts: [],
        isLeaf: true,
        center: '110.358001,19.681215'
      },
      {
        adcode: '469023',
        name: '澄迈县',
        districts: [],
        isLeaf: true,
        center: '110.007497,19.738885'
      }
    ]
  },
  {
    adcode: '320000',
    name: '江苏省',
    districts: [
      {
        adcode: '320700',
        name: '连云港市',
        districts: [
          {
            adcode: '320724',
            name: '灌南县',
            districts: [],
            isLeaf: true,
            center: '119.315583,34.087251'
          },
          {
            adcode: '320707',
            name: '赣榆区',
            districts: [],
            isLeaf: true,
            center: '119.173173,34.841336'
          },
          {
            adcode: '320703',
            name: '连云区',
            districts: [],
            isLeaf: true,
            center: '119.338901,34.760317'
          },
          {
            adcode: '320723',
            name: '灌云县',
            districts: [],
            isLeaf: true,
            center: '119.239426,34.284074'
          },
          {
            adcode: '320706',
            name: '海州区',
            districts: [],
            isLeaf: true,
            center: '119.163492,34.572506'
          },
          {
            adcode: '320722',
            name: '东海县',
            districts: [],
            isLeaf: true,
            center: '118.752869,34.542194'
          }
        ]
      },
      {
        adcode: '320600',
        name: '南通市',
        districts: [
          {
            adcode: '320623',
            name: '如东县',
            districts: [],
            isLeaf: true,
            center: '121.18495,32.331584'
          },
          {
            adcode: '320613',
            name: '崇川区',
            districts: [],
            isLeaf: true,
            center: '120.857253,32.010277'
          },
          {
            adcode: '320681',
            name: '启东市',
            districts: [],
            isLeaf: true,
            center: '121.65523,31.792831'
          },
          {
            adcode: '320614',
            name: '海门区',
            districts: [],
            isLeaf: true,
            center: '121.182016,31.869418'
          },
          {
            adcode: '320612',
            name: '通州区',
            districts: [],
            isLeaf: true,
            center: '116.72923,39.916403'
          },
          {
            adcode: '320682',
            name: '如皋市',
            districts: [],
            isLeaf: true,
            center: '120.574028,32.371493'
          },
          {
            adcode: '320685',
            name: '海安市',
            districts: [],
            isLeaf: true,
            center: '120.46779,32.532853'
          }
        ]
      },
      {
        adcode: '321300',
        name: '宿迁市',
        districts: [
          {
            adcode: '321323',
            name: '泗阳县',
            districts: [],
            isLeaf: true,
            center: '118.703636,33.723576'
          },
          {
            adcode: '321324',
            name: '泗洪县',
            districts: [],
            isLeaf: true,
            center: '118.223941,33.476575'
          },
          {
            adcode: '321311',
            name: '宿豫区',
            districts: [],
            isLeaf: true,
            center: '118.330423,33.94813'
          },
          {
            adcode: '321302',
            name: '宿城区',
            districts: [],
            isLeaf: true,
            center: '118.242048,33.963891'
          },
          {
            adcode: '321322',
            name: '沭阳县',
            districts: [],
            isLeaf: true,
            center: '118.804321,34.110642'
          }
        ]
      },
      {
        adcode: '320800',
        name: '淮安市',
        districts: [
          {
            adcode: '320804',
            name: '淮阴区',
            districts: [],
            isLeaf: true,
            center: '119.034895,33.632588'
          },
          {
            adcode: '320813',
            name: '洪泽区',
            districts: [],
            isLeaf: true,
            center: '118.873132,33.294095'
          },
          {
            adcode: '320830',
            name: '盱眙县',
            districts: [],
            isLeaf: true,
            center: '118.544545,33.011905'
          },
          {
            adcode: '320831',
            name: '金湖县',
            districts: [],
            isLeaf: true,
            center: '119.020432,33.024663'
          },
          {
            adcode: '320826',
            name: '涟水县',
            districts: [],
            isLeaf: true,
            center: '119.260589,33.781648'
          },
          {
            adcode: '320812',
            name: '清江浦区',
            districts: [],
            isLeaf: true,
            center: '119.026662,33.552579'
          },
          {
            adcode: '320803',
            name: '淮安区',
            districts: [],
            isLeaf: true,
            center: '119.141183,33.50296'
          }
        ]
      },
      {
        adcode: '321100',
        name: '镇江市',
        districts: [
          {
            adcode: '321182',
            name: '扬中市',
            districts: [],
            isLeaf: true,
            center: '119.796816,32.236476'
          },
          {
            adcode: '321111',
            name: '润州区',
            districts: [],
            isLeaf: true,
            center: '119.411383,32.195146'
          },
          {
            adcode: '321112',
            name: '丹徒区',
            districts: [],
            isLeaf: true,
            center: '119.433854,32.132118'
          },
          {
            adcode: '321183',
            name: '句容市',
            districts: [],
            isLeaf: true,
            center: '119.168693,31.945732'
          },
          {
            adcode: '321181',
            name: '丹阳市',
            districts: [],
            isLeaf: true,
            center: '119.606071,32.010035'
          },
          {
            adcode: '321102',
            name: '京口区',
            districts: [],
            isLeaf: true,
            center: '119.470186,32.198285'
          }
        ]
      },
      {
        adcode: '321000',
        name: '扬州市',
        districts: [
          {
            adcode: '321023',
            name: '宝应县',
            districts: [],
            isLeaf: true,
            center: '119.358389,33.241125'
          },
          {
            adcode: '321003',
            name: '邗江区',
            districts: [],
            isLeaf: true,
            center: '119.397935,32.37741'
          },
          {
            adcode: '321002',
            name: '广陵区',
            districts: [],
            isLeaf: true,
            center: '119.431785,32.395654'
          },
          {
            adcode: '321084',
            name: '高邮市',
            districts: [],
            isLeaf: true,
            center: '119.45892,32.781606'
          },
          {
            adcode: '321012',
            name: '江都区',
            districts: [],
            isLeaf: true,
            center: '119.569684,32.43571'
          },
          {
            adcode: '321081',
            name: '仪征市',
            districts: [],
            isLeaf: true,
            center: '119.184468,32.272833'
          }
        ]
      },
      {
        adcode: '320100',
        name: '南京市',
        districts: [
          {
            adcode: '320115',
            name: '江宁区',
            districts: [],
            isLeaf: true,
            center: '118.83951,31.953195'
          },
          {
            adcode: '320114',
            name: '雨花台区',
            districts: [],
            isLeaf: true,
            center: '118.779087,31.991291'
          },
          {
            adcode: '320117',
            name: '溧水区',
            districts: [],
            isLeaf: true,
            center: '119.028414,31.651108'
          },
          {
            adcode: '320118',
            name: '高淳区',
            districts: [],
            isLeaf: true,
            center: '118.892074,31.328678'
          },
          {
            adcode: '320105',
            name: '建邺区',
            districts: [],
            isLeaf: true,
            center: '118.731642,32.003343'
          },
          {
            adcode: '320111',
            name: '浦口区',
            districts: [],
            isLeaf: true,
            center: '118.627165,32.059796'
          },
          {
            adcode: '320102',
            name: '玄武区',
            districts: [],
            isLeaf: true,
            center: '118.797779,32.048644'
          },
          {
            adcode: '320104',
            name: '秦淮区',
            districts: [],
            isLeaf: true,
            center: '118.794792,32.039065'
          },
          {
            adcode: '320116',
            name: '六合区',
            districts: [],
            isLeaf: true,
            center: '118.822241,32.323235'
          },
          {
            adcode: '320106',
            name: '鼓楼区',
            districts: [],
            isLeaf: true,
            center: '117.185643,34.288736'
          },
          {
            adcode: '320113',
            name: '栖霞区',
            districts: [],
            isLeaf: true,
            center: '118.909117,32.096423'
          }
        ]
      },
      {
        adcode: '320900',
        name: '盐城市',
        districts: [
          {
            adcode: '320922',
            name: '滨海县',
            districts: [],
            isLeaf: true,
            center: '119.820713,33.990372'
          },
          {
            adcode: '320924',
            name: '射阳县',
            districts: [],
            isLeaf: true,
            center: '120.326338,33.766277'
          },
          {
            adcode: '320903',
            name: '盐都区',
            districts: [],
            isLeaf: true,
            center: '120.153567,33.338538'
          },
          {
            adcode: '320921',
            name: '响水县',
            districts: [],
            isLeaf: true,
            center: '119.578332,34.199172'
          },
          {
            adcode: '320902',
            name: '亭湖区',
            districts: [],
            isLeaf: true,
            center: '120.196148,33.391414'
          },
          {
            adcode: '320981',
            name: '东台市',
            districts: [],
            isLeaf: true,
            center: '120.320308,32.867845'
          },
          {
            adcode: '320923',
            name: '阜宁县',
            districts: [],
            isLeaf: true,
            center: '119.802271,33.758857'
          },
          {
            adcode: '320925',
            name: '建湖县',
            districts: [],
            isLeaf: true,
            center: '119.788743,33.438931'
          },
          {
            adcode: '320904',
            name: '大丰区',
            districts: [],
            isLeaf: true,
            center: '120.500761,33.200047'
          }
        ]
      },
      {
        adcode: '320300',
        name: '徐州市',
        districts: [
          {
            adcode: '320381',
            name: '新沂市',
            districts: [],
            isLeaf: true,
            center: '118.354747,34.369585'
          },
          {
            adcode: '320305',
            name: '贾汪区',
            districts: [],
            isLeaf: true,
            center: '117.465137,34.436492'
          },
          {
            adcode: '320382',
            name: '邳州市',
            districts: [],
            isLeaf: true,
            center: '118.012511,34.339208'
          },
          {
            adcode: '320321',
            name: '丰县',
            districts: [],
            isLeaf: true,
            center: '116.658111,34.697232'
          },
          {
            adcode: '320302',
            name: '鼓楼区',
            districts: [],
            isLeaf: true,
            center: '117.185643,34.288736'
          },
          {
            adcode: '320311',
            name: '泉山区',
            districts: [],
            isLeaf: true,
            center: '117.194405,34.22655'
          },
          {
            adcode: '320312',
            name: '铜山区',
            districts: [],
            isLeaf: true,
            center: '117.169698,34.181162'
          },
          {
            adcode: '320324',
            name: '睢宁县',
            districts: [],
            isLeaf: true,
            center: '117.941364,33.913727'
          },
          {
            adcode: '320322',
            name: '沛县',
            districts: [],
            isLeaf: true,
            center: '116.936353,34.760761'
          },
          {
            adcode: '320303',
            name: '云龙区',
            districts: [],
            isLeaf: true,
            center: '117.251515,34.253638'
          }
        ]
      },
      {
        adcode: '321200',
        name: '泰州市',
        districts: [
          {
            adcode: '321202',
            name: '海陵区',
            districts: [],
            isLeaf: true,
            center: '119.923825,32.49248'
          },
          {
            adcode: '321282',
            name: '靖江市',
            districts: [],
            isLeaf: true,
            center: '120.277124,31.98325'
          },
          {
            adcode: '321204',
            name: '姜堰区',
            districts: [],
            isLeaf: true,
            center: '120.12807,32.51029'
          },
          {
            adcode: '321283',
            name: '泰兴市',
            districts: [],
            isLeaf: true,
            center: '120.051475,32.173072'
          },
          {
            adcode: '321203',
            name: '高港区',
            districts: [],
            isLeaf: true,
            center: '119.88154,32.31923'
          },
          {
            adcode: '321281',
            name: '兴化市',
            districts: [],
            isLeaf: true,
            center: '119.85255,32.911952'
          }
        ]
      },
      {
        adcode: '320500',
        name: '苏州市',
        districts: [
          {
            adcode: '320508',
            name: '姑苏区',
            districts: [],
            isLeaf: true,
            center: '120.617367,31.335648'
          },
          {
            adcode: '320505',
            name: '虎丘区',
            districts: [],
            isLeaf: true,
            center: '120.434238,31.329601'
          },
          {
            adcode: '320506',
            name: '吴中区',
            districts: [],
            isLeaf: true,
            center: '120.632094,31.263604'
          },
          {
            adcode: '320585',
            name: '太仓市',
            districts: [],
            isLeaf: true,
            center: '121.130344,31.458043'
          },
          {
            adcode: '320582',
            name: '张家港市',
            districts: [],
            isLeaf: true,
            center: '120.555979,31.876739'
          },
          {
            adcode: '320509',
            name: '吴江区',
            districts: [],
            isLeaf: true,
            center: '120.645728,31.138525'
          },
          {
            adcode: '320583',
            name: '昆山市',
            districts: [],
            isLeaf: true,
            center: '120.980795,31.385476'
          },
          {
            adcode: '320507',
            name: '相城区',
            districts: [],
            isLeaf: true,
            center: '120.642391,31.369189'
          },
          {
            adcode: '320581',
            name: '常熟市',
            districts: [],
            isLeaf: true,
            center: '120.752512,31.656016'
          }
        ]
      },
      {
        adcode: '320200',
        name: '无锡市',
        districts: [
          {
            adcode: '320282',
            name: '宜兴市',
            districts: [],
            isLeaf: true,
            center: '119.821873,31.338429'
          },
          {
            adcode: '320211',
            name: '滨湖区',
            districts: [],
            isLeaf: true,
            center: '120.284381,31.527846'
          },
          {
            adcode: '320214',
            name: '新吴区',
            districts: [],
            isLeaf: true,
            center: '120.364303,31.49085'
          },
          {
            adcode: '320205',
            name: '锡山区',
            districts: [],
            isLeaf: true,
            center: '120.357732,31.589484'
          },
          {
            adcode: '320281',
            name: '江阴市',
            districts: [],
            isLeaf: true,
            center: '120.284794,31.921642'
          },
          {
            adcode: '320213',
            name: '梁溪区',
            districts: [],
            isLeaf: true,
            center: '120.303551,31.566226'
          },
          {
            adcode: '320206',
            name: '惠山区',
            districts: [],
            isLeaf: true,
            center: '120.29843,31.680282'
          }
        ]
      },
      {
        adcode: '320400',
        name: '常州市',
        districts: [
          {
            adcode: '320404',
            name: '钟楼区',
            districts: [],
            isLeaf: true,
            center: '119.902081,31.802608'
          },
          {
            adcode: '320402',
            name: '天宁区',
            districts: [],
            isLeaf: true,
            center: '119.999439,31.79232'
          },
          {
            adcode: '320413',
            name: '金坛区',
            districts: [],
            isLeaf: true,
            center: '119.587503,31.722384'
          },
          {
            adcode: '320411',
            name: '新北区',
            districts: [],
            isLeaf: true,
            center: '119.960925,31.86355'
          },
          {
            adcode: '320412',
            name: '武进区',
            districts: [],
            isLeaf: true,
            center: '119.942441,31.701252'
          },
          {
            adcode: '320481',
            name: '溧阳市',
            districts: [],
            isLeaf: true,
            center: '119.484164,31.416967'
          }
        ]
      }
    ]
  },
  {
    adcode: '630000',
    name: '青海省',
    districts: [
      {
        adcode: '630200',
        name: '海东市',
        districts: [
          {
            adcode: '630202',
            name: '乐都区',
            districts: [],
            isLeaf: true,
            center: '102.401614,36.482242'
          },
          {
            adcode: '630222',
            name: '民和回族土族自治县',
            districts: [],
            isLeaf: true,
            center: '102.830775,36.320038'
          },
          {
            adcode: '630225',
            name: '循化撒拉族自治县',
            districts: [],
            isLeaf: true,
            center: '102.488958,35.851079'
          },
          {
            adcode: '630203',
            name: '平安区',
            districts: [],
            isLeaf: true,
            center: '102.108538,36.500166'
          },
          {
            adcode: '630223',
            name: '互助土族自治县',
            districts: [],
            isLeaf: true,
            center: '101.958519,36.844027'
          },
          {
            adcode: '630224',
            name: '化隆回族自治县',
            districts: [],
            isLeaf: true,
            center: '102.266007,36.096124'
          }
        ]
      },
      {
        adcode: '632500',
        name: '海南藏族自治州',
        districts: [
          {
            adcode: '632523',
            name: '贵德县',
            districts: [],
            isLeaf: true,
            center: '101.43403,36.039672'
          },
          {
            adcode: '632525',
            name: '贵南县',
            districts: [],
            isLeaf: true,
            center: '100.747731,35.587239'
          },
          {
            adcode: '632522',
            name: '同德县',
            districts: [],
            isLeaf: true,
            center: '100.577859,35.254297'
          },
          {
            adcode: '632524',
            name: '兴海县',
            districts: [],
            isLeaf: true,
            center: '99.987799,35.589086'
          },
          {
            adcode: '632521',
            name: '共和县',
            districts: [],
            isLeaf: true,
            center: '100.619405,36.284158'
          }
        ]
      },
      {
        adcode: '632800',
        name: '海西蒙古族藏族自治州',
        districts: [
          {
            adcode: '632823',
            name: '天峻县',
            districts: [],
            isLeaf: true,
            center: '99.02297,37.301206'
          },
          {
            adcode: '632802',
            name: '德令哈市',
            districts: [],
            isLeaf: true,
            center: '97.361528,37.369865'
          },
          {
            adcode: '632801',
            name: '格尔木市',
            districts: [],
            isLeaf: true,
            center: '94.928293,36.407272'
          },
          {
            adcode: '632822',
            name: '都兰县',
            districts: [],
            isLeaf: true,
            center: '98.095889,36.302098'
          },
          {
            adcode: '632821',
            name: '乌兰县',
            districts: [],
            isLeaf: true,
            center: '98.47988,36.941279'
          },
          {
            adcode: '632825',
            name: '海西蒙古族藏族自治州直辖',
            districts: [],
            isLeaf: true,
            center: '95.357233,37.853631'
          },
          {
            adcode: '632803',
            name: '茫崖市',
            districts: [],
            isLeaf: true,
            center: '90.856372,38.247537'
          }
        ]
      },
      {
        adcode: '632700',
        name: '玉树藏族自治州',
        districts: [
          {
            adcode: '632724',
            name: '治多县',
            districts: [],
            isLeaf: true,
            center: '95.618954,33.844854'
          },
          {
            adcode: '632726',
            name: '曲麻莱县',
            districts: [],
            isLeaf: true,
            center: '95.805463,34.137353'
          },
          {
            adcode: '632723',
            name: '称多县',
            districts: [],
            isLeaf: true,
            center: '97.109487,33.377504'
          },
          {
            adcode: '632722',
            name: '杂多县',
            districts: [],
            isLeaf: true,
            center: '95.300723,32.893185'
          },
          {
            adcode: '632701',
            name: '玉树市',
            districts: [],
            isLeaf: true,
            center: '97.009184,32.992934'
          },
          {
            adcode: '632725',
            name: '囊谦县',
            districts: [],
            isLeaf: true,
            center: '96.489538,32.203193'
          }
        ]
      },
      {
        adcode: '632300',
        name: '黄南藏族自治州',
        districts: [
          {
            adcode: '632301',
            name: '同仁市',
            districts: [],
            isLeaf: true,
            center: '102.018663,35.515769'
          },
          {
            adcode: '632323',
            name: '泽库县',
            districts: [],
            isLeaf: true,
            center: '101.466565,35.035322'
          },
          {
            adcode: '632322',
            name: '尖扎县',
            districts: [],
            isLeaf: true,
            center: '102.040094,35.943253'
          },
          {
            adcode: '632324',
            name: '河南蒙古族自治县',
            districts: [],
            isLeaf: true,
            center: '101.617719,34.734777'
          }
        ]
      },
      {
        adcode: '632600',
        name: '果洛藏族自治州',
        districts: [
          {
            adcode: '632621',
            name: '玛沁县',
            districts: [],
            isLeaf: true,
            center: '100.238873,34.478049'
          },
          {
            adcode: '632623',
            name: '甘德县',
            districts: [],
            isLeaf: true,
            center: '99.900923,33.969216'
          },
          {
            adcode: '632624',
            name: '达日县',
            districts: [],
            isLeaf: true,
            center: '99.651863,33.739293'
          },
          {
            adcode: '632625',
            name: '久治县',
            districts: [],
            isLeaf: true,
            center: '101.482831,33.429471'
          },
          {
            adcode: '632622',
            name: '班玛县',
            districts: [],
            isLeaf: true,
            center: '100.737138,32.932723'
          },
          {
            adcode: '632626',
            name: '玛多县',
            districts: [],
            isLeaf: true,
            center: '98.209217,34.915645'
          }
        ]
      },
      {
        adcode: '630100',
        name: '西宁市',
        districts: [
          {
            adcode: '630123',
            name: '湟源县',
            districts: [],
            isLeaf: true,
            center: '101.256464,36.682426'
          },
          {
            adcode: '630121',
            name: '大通回族土族自治县',
            districts: [],
            isLeaf: true,
            center: '101.685503,36.926872'
          },
          {
            adcode: '630104',
            name: '城西区',
            districts: [],
            isLeaf: true,
            center: '101.765915,36.628288'
          },
          {
            adcode: '630106',
            name: '湟中区',
            districts: [],
            isLeaf: true,
            center: '101.571683,36.501291'
          },
          {
            adcode: '630105',
            name: '城北区',
            districts: [],
            isLeaf: true,
            center: '101.766308,36.650064'
          },
          {
            adcode: '630103',
            name: '城中区',
            districts: [],
            isLeaf: true,
            center: '109.427398,24.366964'
          },
          {
            adcode: '630102',
            name: '城东区',
            districts: [],
            isLeaf: true,
            center: '101.803717,36.599744'
          }
        ]
      },
      {
        adcode: '632200',
        name: '海北藏族自治州',
        districts: [
          {
            adcode: '632223',
            name: '海晏县',
            districts: [],
            isLeaf: true,
            center: '100.994521,36.896667'
          },
          {
            adcode: '632221',
            name: '门源回族自治县',
            districts: [],
            isLeaf: true,
            center: '101.611524,37.388567'
          },
          {
            adcode: '632224',
            name: '刚察县',
            districts: [],
            isLeaf: true,
            center: '100.145662,37.325096'
          },
          {
            adcode: '632222',
            name: '祁连县',
            districts: [],
            isLeaf: true,
            center: '100.253211,38.177112'
          }
        ]
      }
    ]
  },
  {
    adcode: '450000',
    name: '广西壮族自治区',
    districts: [
      {
        adcode: '451000',
        name: '百色市',
        districts: [
          {
            adcode: '451031',
            name: '隆林各族自治县',
            districts: [],
            isLeaf: true,
            center: '105.343816,24.770649'
          },
          {
            adcode: '451028',
            name: '乐业县',
            districts: [],
            isLeaf: true,
            center: '106.556564,24.776812'
          },
          {
            adcode: '451027',
            name: '凌云县',
            districts: [],
            isLeaf: true,
            center: '106.56114,24.347444'
          },
          {
            adcode: '451030',
            name: '西林县',
            districts: [],
            isLeaf: true,
            center: '105.097229,24.50762'
          },
          {
            adcode: '451082',
            name: '平果市',
            districts: [],
            isLeaf: true,
            center: '107.589768,23.329815'
          },
          {
            adcode: '451002',
            name: '右江区',
            districts: [],
            isLeaf: true,
            center: '106.618151,23.900804'
          },
          {
            adcode: '451081',
            name: '靖西市',
            districts: [],
            isLeaf: true,
            center: '106.417666,23.134375'
          },
          {
            adcode: '451026',
            name: '那坡县',
            districts: [],
            isLeaf: true,
            center: '105.83234,23.387997'
          },
          {
            adcode: '451029',
            name: '田林县',
            districts: [],
            isLeaf: true,
            center: '106.22874,24.294627'
          },
          {
            adcode: '451022',
            name: '田东县',
            districts: [],
            isLeaf: true,
            center: '107.125609,23.596706'
          },
          {
            adcode: '451003',
            name: '田阳区',
            districts: [],
            isLeaf: true,
            center: '106.915424,23.735631'
          },
          {
            adcode: '451024',
            name: '德保县',
            districts: [],
            isLeaf: true,
            center: '106.615087,23.324084'
          }
        ]
      },
      {
        adcode: '450700',
        name: '钦州市',
        districts: [
          {
            adcode: '450702',
            name: '钦南区',
            districts: [],
            isLeaf: true,
            center: '108.657427,21.940438'
          },
          {
            adcode: '450703',
            name: '钦北区',
            districts: [],
            isLeaf: true,
            center: '108.638738,22.0024'
          },
          {
            adcode: '450721',
            name: '灵山县',
            districts: [],
            isLeaf: true,
            center: '109.290698,22.416671'
          },
          {
            adcode: '450722',
            name: '浦北县',
            districts: [],
            isLeaf: true,
            center: '109.556232,22.271902'
          }
        ]
      },
      {
        adcode: '450500',
        name: '北海市',
        districts: [
          {
            adcode: '450503',
            name: '银海区',
            districts: [],
            isLeaf: true,
            center: '109.139689,21.449729'
          },
          {
            adcode: '450512',
            name: '铁山港区',
            districts: [],
            isLeaf: true,
            center: '109.422099,21.529878'
          },
          {
            adcode: '450502',
            name: '海城区',
            districts: [],
            isLeaf: true,
            center: '109.117256,21.474914'
          },
          {
            adcode: '450521',
            name: '合浦县',
            districts: [],
            isLeaf: true,
            center: '109.207236,21.66132'
          }
        ]
      },
      {
        adcode: '450400',
        name: '梧州市',
        districts: [
          {
            adcode: '450423',
            name: '蒙山县',
            districts: [],
            isLeaf: true,
            center: '110.525003,24.193567'
          },
          {
            adcode: '450406',
            name: '龙圩区',
            districts: [],
            isLeaf: true,
            center: '111.247514,23.414869'
          },
          {
            adcode: '450481',
            name: '岑溪市',
            districts: [],
            isLeaf: true,
            center: '110.994955,22.918327'
          },
          {
            adcode: '450405',
            name: '长洲区',
            districts: [],
            isLeaf: true,
            center: '111.274276,23.486279'
          },
          {
            adcode: '450422',
            name: '藤县',
            districts: [],
            isLeaf: true,
            center: '110.914606,23.375538'
          },
          {
            adcode: '450421',
            name: '苍梧县',
            districts: [],
            isLeaf: true,
            center: '111.547244,23.868208'
          },
          {
            adcode: '450403',
            name: '万秀区',
            districts: [],
            isLeaf: true,
            center: '111.318065,23.470543'
          }
        ]
      },
      {
        adcode: '451400',
        name: '崇左市',
        districts: [
          {
            adcode: '451421',
            name: '扶绥县',
            districts: [],
            isLeaf: true,
            center: '107.90389,22.635542'
          },
          {
            adcode: '451402',
            name: '江州区',
            districts: [],
            isLeaf: true,
            center: '107.353694,22.40609'
          },
          {
            adcode: '451423',
            name: '龙州县',
            districts: [],
            isLeaf: true,
            center: '106.854001,22.343591'
          },
          {
            adcode: '451481',
            name: '凭祥市',
            districts: [],
            isLeaf: true,
            center: '106.766715,22.094421'
          },
          {
            adcode: '451422',
            name: '宁明县',
            districts: [],
            isLeaf: true,
            center: '107.076394,22.140242'
          },
          {
            adcode: '451424',
            name: '大新县',
            districts: [],
            isLeaf: true,
            center: '107.200654,22.829287'
          },
          {
            adcode: '451425',
            name: '天等县',
            districts: [],
            isLeaf: true,
            center: '107.143539,23.080818'
          }
        ]
      },
      {
        adcode: '450600',
        name: '防城港市',
        districts: [
          {
            adcode: '450602',
            name: '港口区',
            districts: [],
            isLeaf: true,
            center: '108.380273,21.643426'
          },
          {
            adcode: '450681',
            name: '东兴市',
            districts: [],
            isLeaf: true,
            center: '107.971828,21.547821'
          },
          {
            adcode: '450621',
            name: '上思县',
            districts: [],
            isLeaf: true,
            center: '107.983627,22.153671'
          },
          {
            adcode: '450603',
            name: '防城区',
            districts: [],
            isLeaf: true,
            center: '108.353978,21.768826'
          }
        ]
      },
      {
        adcode: '450100',
        name: '南宁市',
        districts: [
          {
            adcode: '450124',
            name: '马山县',
            districts: [],
            isLeaf: true,
            center: '108.177764,23.708448'
          },
          {
            adcode: '450125',
            name: '上林县',
            districts: [],
            isLeaf: true,
            center: '108.605089,23.432451'
          },
          {
            adcode: '450110',
            name: '武鸣区',
            districts: [],
            isLeaf: true,
            center: '108.274869,23.159257'
          },
          {
            adcode: '450126',
            name: '宾阳县',
            districts: [],
            isLeaf: true,
            center: '108.810336,23.217771'
          },
          {
            adcode: '450105',
            name: '江南区',
            districts: [],
            isLeaf: true,
            center: '108.273206,22.781166'
          },
          {
            adcode: '450109',
            name: '邕宁区',
            districts: [],
            isLeaf: true,
            center: '108.487438,22.758633'
          },
          {
            adcode: '450108',
            name: '良庆区',
            districts: [],
            isLeaf: true,
            center: '108.393889,22.753613'
          },
          {
            adcode: '450107',
            name: '西乡塘区',
            districts: [],
            isLeaf: true,
            center: '108.31344,22.833852'
          },
          {
            adcode: '450103',
            name: '青秀区',
            districts: [],
            isLeaf: true,
            center: '108.495204,22.785833'
          },
          {
            adcode: '450102',
            name: '兴宁区',
            districts: [],
            isLeaf: true,
            center: '108.368071,22.85354'
          },
          {
            adcode: '450123',
            name: '隆安县',
            districts: [],
            isLeaf: true,
            center: '107.695721,23.166356'
          },
          {
            adcode: '450181',
            name: '横州市',
            districts: [],
            isLeaf: true,
            center: '109.262448,22.681257'
          }
        ]
      },
      {
        adcode: '451300',
        name: '来宾市',
        districts: [
          {
            adcode: '451324',
            name: '金秀瑶族自治县',
            districts: [],
            isLeaf: true,
            center: '110.190354,24.130509'
          },
          {
            adcode: '451322',
            name: '象州县',
            districts: [],
            isLeaf: true,
            center: '109.704852,23.973832'
          },
          {
            adcode: '451302',
            name: '兴宾区',
            districts: [],
            isLeaf: true,
            center: '109.183284,23.727647'
          },
          {
            adcode: '451381',
            name: '合山市',
            districts: [],
            isLeaf: true,
            center: '108.885829,23.807032'
          },
          {
            adcode: '451323',
            name: '武宣县',
            districts: [],
            isLeaf: true,
            center: '109.663153,23.594444'
          },
          {
            adcode: '451321',
            name: '忻城县',
            districts: [],
            isLeaf: true,
            center: '108.665641,24.066176'
          }
        ]
      },
      {
        adcode: '450300',
        name: '桂林市',
        districts: [
          {
            adcode: '450311',
            name: '雁山区',
            districts: [],
            isLeaf: true,
            center: '110.286611,25.101798'
          },
          {
            adcode: '450305',
            name: '七星区',
            districts: [],
            isLeaf: true,
            center: '110.317576,25.253093'
          },
          {
            adcode: '450327',
            name: '灌阳县',
            districts: [],
            isLeaf: true,
            center: '111.160764,25.489419'
          },
          {
            adcode: '450303',
            name: '叠彩区',
            districts: [],
            isLeaf: true,
            center: '110.301489,25.314158'
          },
          {
            adcode: '450329',
            name: '资源县',
            districts: [],
            isLeaf: true,
            center: '110.652612,26.042452'
          },
          {
            adcode: '450330',
            name: '平乐县',
            districts: [],
            isLeaf: true,
            center: '110.632867,24.622814'
          },
          {
            adcode: '450332',
            name: '恭城瑶族自治县',
            districts: [],
            isLeaf: true,
            center: '110.82841,24.831581'
          },
          {
            adcode: '450324',
            name: '全州县',
            districts: [],
            isLeaf: true,
            center: '111.087089,25.94939'
          },
          {
            adcode: '450325',
            name: '兴安县',
            districts: [],
            isLeaf: true,
            center: '110.672013,25.612576'
          },
          {
            adcode: '450326',
            name: '永福县',
            districts: [],
            isLeaf: true,
            center: '109.982974,24.980447'
          },
          {
            adcode: '450328',
            name: '龙胜各族自治县',
            districts: [],
            isLeaf: true,
            center: '110.011662,25.79803'
          },
          {
            adcode: '450304',
            name: '象山区',
            districts: [],
            isLeaf: true,
            center: '110.281223,25.261585'
          },
          {
            adcode: '450381',
            name: '荔浦市',
            districts: [],
            isLeaf: true,
            center: '110.395232,24.48843'
          },
          {
            adcode: '450321',
            name: '阳朔县',
            districts: [],
            isLeaf: true,
            center: '110.496305,24.778785'
          },
          {
            adcode: '450312',
            name: '临桂区',
            districts: [],
            isLeaf: true,
            center: '110.212425,25.238556'
          },
          {
            adcode: '450323',
            name: '灵川县',
            districts: [],
            isLeaf: true,
            center: '110.319837,25.3948'
          },
          {
            adcode: '450302',
            name: '秀峰区',
            districts: [],
            isLeaf: true,
            center: '110.264102,25.273954'
          }
        ]
      },
      {
        adcode: '451100',
        name: '贺州市',
        districts: [
          {
            adcode: '451123',
            name: '富川瑶族自治县',
            districts: [],
            isLeaf: true,
            center: '111.296012,24.811601'
          },
          {
            adcode: '451121',
            name: '昭平县',
            districts: [],
            isLeaf: true,
            center: '110.811158,24.169675'
          },
          {
            adcode: '451103',
            name: '平桂区',
            districts: [],
            isLeaf: true,
            center: '111.479839,24.453094'
          },
          {
            adcode: '451122',
            name: '钟山县',
            districts: [],
            isLeaf: true,
            center: '111.303085,24.526041'
          },
          {
            adcode: '451102',
            name: '八步区',
            districts: [],
            isLeaf: true,
            center: '111.552029,24.411822'
          }
        ]
      },
      {
        adcode: '450800',
        name: '贵港市',
        districts: [
          {
            adcode: '450802',
            name: '港北区',
            districts: [],
            isLeaf: true,
            center: '109.572227,23.11136'
          },
          {
            adcode: '450803',
            name: '港南区',
            districts: [],
            isLeaf: true,
            center: '109.599357,23.075816'
          },
          {
            adcode: '450804',
            name: '覃塘区',
            districts: [],
            isLeaf: true,
            center: '109.452668,23.127405'
          },
          {
            adcode: '450821',
            name: '平南县',
            districts: [],
            isLeaf: true,
            center: '110.392489,23.540413'
          },
          {
            adcode: '450881',
            name: '桂平市',
            districts: [],
            isLeaf: true,
            center: '110.079315,23.394208'
          }
        ]
      },
      {
        adcode: '450900',
        name: '玉林市',
        districts: [
          {
            adcode: '450903',
            name: '福绵区',
            districts: [],
            isLeaf: true,
            center: '110.059564,22.585316'
          },
          {
            adcode: '450922',
            name: '陆川县',
            districts: [],
            isLeaf: true,
            center: '110.26412,22.321563'
          },
          {
            adcode: '450902',
            name: '玉州区',
            districts: [],
            isLeaf: true,
            center: '110.150717,22.628476'
          },
          {
            adcode: '450923',
            name: '博白县',
            districts: [],
            isLeaf: true,
            center: '109.975856,22.273539'
          },
          {
            adcode: '450924',
            name: '兴业县',
            districts: [],
            isLeaf: true,
            center: '109.875223,22.736395'
          },
          {
            adcode: '450981',
            name: '北流市',
            districts: [],
            isLeaf: true,
            center: '110.353765,22.708415'
          },
          {
            adcode: '450921',
            name: '容县',
            districts: [],
            isLeaf: true,
            center: '110.558093,22.85844'
          }
        ]
      },
      {
        adcode: '451200',
        name: '河池市',
        districts: [
          {
            adcode: '451222',
            name: '天峨县',
            districts: [],
            isLeaf: true,
            center: '107.172287,24.999181'
          },
          {
            adcode: '451225',
            name: '罗城仫佬族自治县',
            districts: [],
            isLeaf: true,
            center: '108.904613,24.777501'
          },
          {
            adcode: '451223',
            name: '凤山县',
            districts: [],
            isLeaf: true,
            center: '107.042157,24.546913'
          },
          {
            adcode: '451224',
            name: '东兰县',
            districts: [],
            isLeaf: true,
            center: '107.374259,24.510698'
          },
          {
            adcode: '451229',
            name: '大化瑶族自治县',
            districts: [],
            isLeaf: true,
            center: '107.998151,23.735699'
          },
          {
            adcode: '451227',
            name: '巴马瑶族自治县',
            districts: [],
            isLeaf: true,
            center: '107.258671,24.142216'
          },
          {
            adcode: '451228',
            name: '都安瑶族自治县',
            districts: [],
            isLeaf: true,
            center: '108.105229,23.932704'
          },
          {
            adcode: '451226',
            name: '环江毛南族自治县',
            districts: [],
            isLeaf: true,
            center: '108.258426,24.825923'
          },
          {
            adcode: '451221',
            name: '南丹县',
            districts: [],
            isLeaf: true,
            center: '107.540578,24.975066'
          },
          {
            adcode: '451202',
            name: '金城江区',
            districts: [],
            isLeaf: true,
            center: '108.037384,24.689475'
          },
          {
            adcode: '451203',
            name: '宜州区',
            districts: [],
            isLeaf: true,
            center: '108.611303,24.500981'
          }
        ]
      },
      {
        adcode: '450200',
        name: '柳州市',
        districts: [
          {
            adcode: '450226',
            name: '三江侗族自治县',
            districts: [],
            isLeaf: true,
            center: '109.607675,25.783197'
          },
          {
            adcode: '450225',
            name: '融水苗族自治县',
            districts: [],
            isLeaf: true,
            center: '109.256609,25.066624'
          },
          {
            adcode: '450224',
            name: '融安县',
            districts: [],
            isLeaf: true,
            center: '109.397538,25.224549'
          },
          {
            adcode: '450222',
            name: '柳城县',
            districts: [],
            isLeaf: true,
            center: '109.267125,24.654329'
          },
          {
            adcode: '450206',
            name: '柳江区',
            districts: [],
            isLeaf: true,
            center: '109.326365,24.255644'
          },
          {
            adcode: '450223',
            name: '鹿寨县',
            districts: [],
            isLeaf: true,
            center: '109.750527,24.472897'
          },
          {
            adcode: '450202',
            name: '城中区',
            districts: [],
            isLeaf: true,
            center: '109.427398,24.366964'
          },
          {
            adcode: '450205',
            name: '柳北区',
            districts: [],
            isLeaf: true,
            center: '109.402009,24.362611'
          },
          {
            adcode: '450203',
            name: '鱼峰区',
            districts: [],
            isLeaf: true,
            center: '109.452781,24.318276'
          },
          {
            adcode: '450204',
            name: '柳南区',
            districts: [],
            isLeaf: true,
            center: '109.385504,24.336229'
          }
        ]
      }
    ]
  },
  {
    adcode: '640000',
    name: '宁夏回族自治区',
    districts: [
      {
        adcode: '640400',
        name: '固原市',
        districts: [
          {
            adcode: '640423',
            name: '隆德县',
            districts: [],
            isLeaf: true,
            center: '106.111321,35.62592'
          },
          {
            adcode: '640425',
            name: '彭阳县',
            districts: [],
            isLeaf: true,
            center: '106.632151,35.860067'
          },
          {
            adcode: '640424',
            name: '泾源县',
            districts: [],
            isLeaf: true,
            center: '106.330569,35.497887'
          },
          {
            adcode: '640422',
            name: '西吉县',
            districts: [],
            isLeaf: true,
            center: '105.729189,35.963885'
          },
          {
            adcode: '640402',
            name: '原州区',
            districts: [],
            isLeaf: true,
            center: '106.28784,36.003777'
          }
        ]
      },
      {
        adcode: '640200',
        name: '石嘴山市',
        districts: [
          {
            adcode: '640205',
            name: '惠农区',
            districts: [],
            isLeaf: true,
            center: '106.781013,39.239292'
          },
          {
            adcode: '640221',
            name: '平罗县',
            districts: [],
            isLeaf: true,
            center: '106.523411,38.913923'
          },
          {
            adcode: '640202',
            name: '大武口区',
            districts: [],
            isLeaf: true,
            center: '106.368128,39.019375'
          }
        ]
      },
      {
        adcode: '640300',
        name: '吴忠市',
        districts: [
          {
            adcode: '640381',
            name: '青铜峡市',
            districts: [],
            isLeaf: true,
            center: '106.07906,38.021579'
          },
          {
            adcode: '640323',
            name: '盐池县',
            districts: [],
            isLeaf: true,
            center: '107.407184,37.783276'
          },
          {
            adcode: '640324',
            name: '同心县',
            districts: [],
            isLeaf: true,
            center: '105.895412,36.954635'
          },
          {
            adcode: '640302',
            name: '利通区',
            districts: [],
            isLeaf: true,
            center: '106.212479,37.984586'
          },
          {
            adcode: '640303',
            name: '红寺堡区',
            districts: [],
            isLeaf: true,
            center: '106.06216,37.425494'
          }
        ]
      },
      {
        adcode: '640100',
        name: '银川市',
        districts: [
          {
            adcode: '640105',
            name: '西夏区',
            districts: [],
            isLeaf: true,
            center: '106.161208,38.502325'
          },
          {
            adcode: '640121',
            name: '永宁县',
            districts: [],
            isLeaf: true,
            center: '106.25337,38.277376'
          },
          {
            adcode: '640181',
            name: '灵武市',
            districts: [],
            isLeaf: true,
            center: '106.339978,38.102456'
          },
          {
            adcode: '640106',
            name: '金凤区',
            districts: [],
            isLeaf: true,
            center: '106.239339,38.474277'
          },
          {
            adcode: '640104',
            name: '兴庆区',
            districts: [],
            isLeaf: true,
            center: '106.288452,38.473972'
          },
          {
            adcode: '640122',
            name: '贺兰县',
            districts: [],
            isLeaf: true,
            center: '106.365074,38.579147'
          }
        ]
      },
      {
        adcode: '640500',
        name: '中卫市',
        districts: [
          {
            adcode: '640522',
            name: '海原县',
            districts: [],
            isLeaf: true,
            center: '105.643449,36.565189'
          },
          {
            adcode: '640521',
            name: '中宁县',
            districts: [],
            isLeaf: true,
            center: '105.684729,37.492092'
          },
          {
            adcode: '640502',
            name: '沙坡头区',
            districts: [],
            isLeaf: true,
            center: '105.173875,37.516859'
          }
        ]
      }
    ]
  },
  {
    adcode: '330000',
    name: '浙江省',
    districts: [
      {
        adcode: '330900',
        name: '舟山市',
        districts: [
          {
            adcode: '330922',
            name: '嵊泗县',
            districts: [],
            isLeaf: true,
            center: '122.45132,30.725677'
          },
          {
            adcode: '330921',
            name: '岱山县',
            districts: [],
            isLeaf: true,
            center: '122.225718,30.264533'
          },
          {
            adcode: '330902',
            name: '定海区',
            districts: [],
            isLeaf: true,
            center: '122.106844,30.019795'
          },
          {
            adcode: '330903',
            name: '普陀区',
            districts: [],
            isLeaf: true,
            center: '122.323297,29.970571'
          }
        ]
      },
      {
        adcode: '330400',
        name: '嘉兴市',
        districts: [
          {
            adcode: '330482',
            name: '平湖市',
            districts: [],
            isLeaf: true,
            center: '121.015619,30.677804'
          },
          {
            adcode: '330402',
            name: '南湖区',
            districts: [],
            isLeaf: true,
            center: '120.782952,30.747738'
          },
          {
            adcode: '330483',
            name: '桐乡市',
            districts: [],
            isLeaf: true,
            center: '120.565127,30.630375'
          },
          {
            adcode: '330411',
            name: '秀洲区',
            districts: [],
            isLeaf: true,
            center: '120.709047,30.764811'
          },
          {
            adcode: '330424',
            name: '海盐县',
            districts: [],
            isLeaf: true,
            center: '120.94628,30.52664'
          },
          {
            adcode: '330421',
            name: '嘉善县',
            districts: [],
            isLeaf: true,
            center: '120.926031,30.83085'
          },
          {
            adcode: '330481',
            name: '海宁市',
            districts: [],
            isLeaf: true,
            center: '120.680224,30.511536'
          }
        ]
      },
      {
        adcode: '330200',
        name: '宁波市',
        districts: [
          {
            adcode: '330206',
            name: '北仑区',
            districts: [],
            isLeaf: true,
            center: '121.844601,29.899548'
          },
          {
            adcode: '330225',
            name: '象山县',
            districts: [],
            isLeaf: true,
            center: '121.869251,29.476826'
          },
          {
            adcode: '330211',
            name: '镇海区',
            districts: [],
            isLeaf: true,
            center: '121.596686,29.965212'
          },
          {
            adcode: '330205',
            name: '江北区',
            districts: [],
            isLeaf: true,
            center: '121.555067,29.88673'
          },
          {
            adcode: '330203',
            name: '海曙区',
            districts: [],
            isLeaf: true,
            center: '121.550485,29.873705'
          },
          {
            adcode: '330212',
            name: '鄞州区',
            districts: [],
            isLeaf: true,
            center: '121.546617,29.817302'
          },
          {
            adcode: '330213',
            name: '奉化区',
            districts: [],
            isLeaf: true,
            center: '121.406151,29.655292'
          },
          {
            adcode: '330281',
            name: '余姚市',
            districts: [],
            isLeaf: true,
            center: '121.154572,30.037967'
          },
          {
            adcode: '330282',
            name: '慈溪市',
            districts: [],
            isLeaf: true,
            center: '121.266525,30.170695'
          },
          {
            adcode: '330226',
            name: '宁海县',
            districts: [],
            isLeaf: true,
            center: '121.429729,29.287929'
          }
        ]
      },
      {
        adcode: '331000',
        name: '台州市',
        districts: [
          {
            adcode: '331082',
            name: '临海市',
            districts: [],
            isLeaf: true,
            center: '121.144625,28.859042'
          },
          {
            adcode: '331002',
            name: '椒江区',
            districts: [],
            isLeaf: true,
            center: '121.442859,28.673334'
          },
          {
            adcode: '331004',
            name: '路桥区',
            districts: [],
            isLeaf: true,
            center: '121.337874,28.578244'
          },
          {
            adcode: '331083',
            name: '玉环市',
            districts: [],
            isLeaf: true,
            center: '121.231915,28.136703'
          },
          {
            adcode: '331081',
            name: '温岭市',
            districts: [],
            isLeaf: true,
            center: '121.385435,28.372805'
          },
          {
            adcode: '331022',
            name: '三门县',
            districts: [],
            isLeaf: true,
            center: '121.39561,29.104888'
          },
          {
            adcode: '331024',
            name: '仙居县',
            districts: [],
            isLeaf: true,
            center: '120.728733,28.846856'
          },
          {
            adcode: '331003',
            name: '黄岩区',
            districts: [],
            isLeaf: true,
            center: '121.261804,28.649433'
          },
          {
            adcode: '331023',
            name: '天台县',
            districts: [],
            isLeaf: true,
            center: '121.006657,29.144594'
          }
        ]
      },
      {
        adcode: '330300',
        name: '温州市',
        districts: [
          {
            adcode: '330326',
            name: '平阳县',
            districts: [],
            isLeaf: true,
            center: '120.565161,27.662394'
          },
          {
            adcode: '330305',
            name: '洞头区',
            districts: [],
            isLeaf: true,
            center: '121.157406,27.836412'
          },
          {
            adcode: '330327',
            name: '苍南县',
            districts: [],
            isLeaf: true,
            center: '120.425957,27.518636'
          },
          {
            adcode: '330381',
            name: '瑞安市',
            districts: [],
            isLeaf: true,
            center: '120.655245,27.778967'
          },
          {
            adcode: '330328',
            name: '文成县',
            districts: [],
            isLeaf: true,
            center: '120.090929,27.786856'
          },
          {
            adcode: '330329',
            name: '泰顺县',
            districts: [],
            isLeaf: true,
            center: '119.717643,27.556578'
          },
          {
            adcode: '330382',
            name: '乐清市',
            districts: [],
            isLeaf: true,
            center: '120.986297,28.112519'
          },
          {
            adcode: '330324',
            name: '永嘉县',
            districts: [],
            isLeaf: true,
            center: '120.69136,28.153914'
          },
          {
            adcode: '330303',
            name: '龙湾区',
            districts: [],
            isLeaf: true,
            center: '120.812333,27.933261'
          },
          {
            adcode: '330302',
            name: '鹿城区',
            districts: [],
            isLeaf: true,
            center: '120.655199,28.015776'
          },
          {
            adcode: '330304',
            name: '瓯海区',
            districts: [],
            isLeaf: true,
            center: '120.615149,27.967445'
          },
          {
            adcode: '330383',
            name: '龙港市',
            districts: [],
            isLeaf: true,
            center: '120.552952,27.578379'
          }
        ]
      },
      {
        adcode: '330800',
        name: '衢州市',
        districts: [
          {
            adcode: '330802',
            name: '柯城区',
            districts: [],
            isLeaf: true,
            center: '118.874138,28.936937'
          },
          {
            adcode: '330881',
            name: '江山市',
            districts: [],
            isLeaf: true,
            center: '118.627228,28.73796'
          },
          {
            adcode: '330822',
            name: '常山县',
            districts: [],
            isLeaf: true,
            center: '118.511224,28.902446'
          },
          {
            adcode: '330803',
            name: '衢江区',
            districts: [],
            isLeaf: true,
            center: '118.959139,28.980356'
          },
          {
            adcode: '330825',
            name: '龙游县',
            districts: [],
            isLeaf: true,
            center: '119.1723,29.028214'
          },
          {
            adcode: '330824',
            name: '开化县',
            districts: [],
            isLeaf: true,
            center: '118.415756,29.136729'
          }
        ]
      },
      {
        adcode: '330500',
        name: '湖州市',
        districts: [
          {
            adcode: '330522',
            name: '长兴县',
            districts: [],
            isLeaf: true,
            center: '119.911212,31.026962'
          },
          {
            adcode: '330502',
            name: '吴兴区',
            districts: [],
            isLeaf: true,
            center: '120.185608,30.857184'
          },
          {
            adcode: '330503',
            name: '南浔区',
            districts: [],
            isLeaf: true,
            center: '120.418244,30.850835'
          },
          {
            adcode: '330521',
            name: '德清县',
            districts: [],
            isLeaf: true,
            center: '119.9774,30.54251'
          },
          {
            adcode: '330523',
            name: '安吉县',
            districts: [],
            isLeaf: true,
            center: '119.680261,30.638803'
          }
        ]
      },
      {
        adcode: '330700',
        name: '金华市',
        districts: [
          {
            adcode: '330784',
            name: '永康市',
            districts: [],
            isLeaf: true,
            center: '120.047356,28.88899'
          },
          {
            adcode: '330723',
            name: '武义县',
            districts: [],
            isLeaf: true,
            center: '119.816341,28.892562'
          },
          {
            adcode: '330703',
            name: '金东区',
            districts: [],
            isLeaf: true,
            center: '119.692821,29.099822'
          },
          {
            adcode: '330782',
            name: '义乌市',
            districts: [],
            isLeaf: true,
            center: '120.075679,29.306296'
          },
          {
            adcode: '330726',
            name: '浦江县',
            districts: [],
            isLeaf: true,
            center: '119.89259,29.453363'
          },
          {
            adcode: '330702',
            name: '婺城区',
            districts: [],
            isLeaf: true,
            center: '119.571574,29.087311'
          },
          {
            adcode: '330781',
            name: '兰溪市',
            districts: [],
            isLeaf: true,
            center: '119.460404,29.209059'
          },
          {
            adcode: '330727',
            name: '磐安县',
            districts: [],
            isLeaf: true,
            center: '120.449937,29.054491'
          },
          {
            adcode: '330783',
            name: '东阳市',
            districts: [],
            isLeaf: true,
            center: '120.24179,29.290158'
          }
        ]
      },
      {
        adcode: '331100',
        name: '丽水市',
        districts: [
          {
            adcode: '331124',
            name: '松阳县',
            districts: [],
            isLeaf: true,
            center: '119.481406,28.448883'
          },
          {
            adcode: '331181',
            name: '龙泉市',
            districts: [],
            isLeaf: true,
            center: '119.14126,28.074916'
          },
          {
            adcode: '331125',
            name: '云和县',
            districts: [],
            isLeaf: true,
            center: '119.573454,28.116024'
          },
          {
            adcode: '331123',
            name: '遂昌县',
            districts: [],
            isLeaf: true,
            center: '119.275865,28.592388'
          },
          {
            adcode: '331127',
            name: '景宁畲族自治县',
            districts: [],
            isLeaf: true,
            center: '119.635686,27.973594'
          },
          {
            adcode: '331122',
            name: '缙云县',
            districts: [],
            isLeaf: true,
            center: '120.091685,28.659294'
          },
          {
            adcode: '331121',
            name: '青田县',
            districts: [],
            isLeaf: true,
            center: '120.289693,28.1396'
          },
          {
            adcode: '331102',
            name: '莲都区',
            districts: [],
            isLeaf: true,
            center: '119.912266,28.4461'
          },
          {
            adcode: '331126',
            name: '庆元县',
            districts: [],
            isLeaf: true,
            center: '119.062572,27.619213'
          }
        ]
      },
      {
        adcode: '330600',
        name: '绍兴市',
        districts: [
          {
            adcode: '330683',
            name: '嵊州市',
            districts: [],
            isLeaf: true,
            center: '120.830505,29.561519'
          },
          {
            adcode: '330624',
            name: '新昌县',
            districts: [],
            isLeaf: true,
            center: '120.903918,29.500525'
          },
          {
            adcode: '330681',
            name: '诸暨市',
            districts: [],
            isLeaf: true,
            center: '120.246602,29.709398'
          },
          {
            adcode: '330603',
            name: '柯桥区',
            districts: [],
            isLeaf: true,
            center: '120.495532,30.083039'
          },
          {
            adcode: '330604',
            name: '上虞区',
            districts: [],
            isLeaf: true,
            center: '120.868571,30.033862'
          },
          {
            adcode: '330602',
            name: '越城区',
            districts: [],
            isLeaf: true,
            center: '120.582338,29.989092'
          }
        ]
      },
      {
        adcode: '330100',
        name: '杭州市',
        districts: [
          {
            adcode: '330108',
            name: '滨江区',
            districts: [],
            isLeaf: true,
            center: '120.211981,30.208332'
          },
          {
            adcode: '330122',
            name: '桐庐县',
            districts: [],
            isLeaf: true,
            center: '119.691755,29.79418'
          },
          {
            adcode: '330111',
            name: '富阳区',
            districts: [],
            isLeaf: true,
            center: '119.96022,30.048803'
          },
          {
            adcode: '330127',
            name: '淳安县',
            districts: [],
            isLeaf: true,
            center: '119.042015,29.609678'
          },
          {
            adcode: '330114',
            name: '钱塘区',
            districts: [],
            isLeaf: true,
            center: '120.493941,30.32304'
          },
          {
            adcode: '330102',
            name: '上城区',
            districts: [],
            isLeaf: true,
            center: '120.19732,30.226543'
          },
          {
            adcode: '330105',
            name: '拱墅区',
            districts: [],
            isLeaf: true,
            center: '120.141503,30.319126'
          },
          {
            adcode: '330182',
            name: '建德市',
            districts: [],
            isLeaf: true,
            center: '119.281195,29.474964'
          },
          {
            adcode: '330106',
            name: '西湖区',
            districts: [],
            isLeaf: true,
            center: '115.876998,28.657005'
          },
          {
            adcode: '330113',
            name: '临平区',
            districts: [],
            isLeaf: true,
            center: '120.299222,30.419154'
          },
          {
            adcode: '330109',
            name: '萧山区',
            districts: [],
            isLeaf: true,
            center: '120.264263,30.184119'
          },
          {
            adcode: '330110',
            name: '余杭区',
            districts: [],
            isLeaf: true,
            center: '119.978742,30.273705'
          },
          {
            adcode: '330112',
            name: '临安区',
            districts: [],
            isLeaf: true,
            center: '119.724457,30.234375'
          }
        ]
      }
    ]
  },
  {
    adcode: '130000',
    name: '河北省',
    districts: [
      {
        adcode: '130200',
        name: '唐山市',
        districts: [
          {
            adcode: '130283',
            name: '迁安市',
            districts: [],
            isLeaf: true,
            center: '118.701021,39.998861'
          },
          {
            adcode: '130225',
            name: '乐亭县',
            districts: [],
            isLeaf: true,
            center: '118.91245,39.425748'
          },
          {
            adcode: '130229',
            name: '玉田县',
            districts: [],
            isLeaf: true,
            center: '117.738196,39.901827'
          },
          {
            adcode: '130202',
            name: '路南区',
            districts: [],
            isLeaf: true,
            center: '118.154348,39.624988'
          },
          {
            adcode: '130209',
            name: '曹妃甸区',
            districts: [],
            isLeaf: true,
            center: '118.460197,39.273528'
          },
          {
            adcode: '130224',
            name: '滦南县',
            districts: [],
            isLeaf: true,
            center: '118.68363,39.520272'
          },
          {
            adcode: '130203',
            name: '路北区',
            districts: [],
            isLeaf: true,
            center: '118.201085,39.625079'
          },
          {
            adcode: '130207',
            name: '丰南区',
            districts: [],
            isLeaf: true,
            center: '118.085169,39.576031'
          },
          {
            adcode: '130205',
            name: '开平区',
            districts: [],
            isLeaf: true,
            center: '118.262246,39.671634'
          },
          {
            adcode: '130204',
            name: '古冶区',
            districts: [],
            isLeaf: true,
            center: '118.447134,39.73392'
          },
          {
            adcode: '130227',
            name: '迁西县',
            districts: [],
            isLeaf: true,
            center: '118.31467,40.141486'
          },
          {
            adcode: '130284',
            name: '滦州市',
            districts: [],
            isLeaf: true,
            center: '118.70301,39.740963'
          },
          {
            adcode: '130208',
            name: '丰润区',
            districts: [],
            isLeaf: true,
            center: '118.162426,39.832919'
          },
          {
            adcode: '130281',
            name: '遵化市',
            districts: [],
            isLeaf: true,
            center: '117.965878,40.189119'
          }
        ]
      },
      {
        adcode: '130300',
        name: '秦皇岛市',
        districts: [
          {
            adcode: '130324',
            name: '卢龙县',
            districts: [],
            isLeaf: true,
            center: '118.891931,39.892564'
          },
          {
            adcode: '130303',
            name: '山海关区',
            districts: [],
            isLeaf: true,
            center: '119.775187,39.978882'
          },
          {
            adcode: '130302',
            name: '海港区',
            districts: [],
            isLeaf: true,
            center: '119.564962,39.94756'
          },
          {
            adcode: '130306',
            name: '抚宁区',
            districts: [],
            isLeaf: true,
            center: '119.244847,39.876253'
          },
          {
            adcode: '130322',
            name: '昌黎县',
            districts: [],
            isLeaf: true,
            center: '119.199846,39.699677'
          },
          {
            adcode: '130304',
            name: '北戴河区',
            districts: [],
            isLeaf: true,
            center: '119.48449,39.834912'
          },
          {
            adcode: '130321',
            name: '青龙满族自治县',
            districts: [],
            isLeaf: true,
            center: '118.94985,40.407473'
          }
        ]
      },
      {
        adcode: '130800',
        name: '承德市',
        districts: [
          {
            adcode: '130826',
            name: '丰宁满族自治县',
            districts: [],
            isLeaf: true,
            center: '116.645798,41.209951'
          },
          {
            adcode: '130825',
            name: '隆化县',
            districts: [],
            isLeaf: true,
            center: '117.739026,41.314402'
          },
          {
            adcode: '130828',
            name: '围场满族蒙古族自治县',
            districts: [],
            isLeaf: true,
            center: '117.75934,41.938372'
          },
          {
            adcode: '130827',
            name: '宽城满族自治县',
            districts: [],
            isLeaf: true,
            center: '118.485472,40.611333'
          },
          {
            adcode: '130804',
            name: '鹰手营子矿区',
            districts: [],
            isLeaf: true,
            center: '117.659341,40.546424'
          },
          {
            adcode: '130824',
            name: '滦平县',
            districts: [],
            isLeaf: true,
            center: '117.332652,40.941644'
          },
          {
            adcode: '130803',
            name: '双滦区',
            districts: [],
            isLeaf: true,
            center: '117.799588,40.959426'
          },
          {
            adcode: '130802',
            name: '双桥区',
            districts: [],
            isLeaf: true,
            center: '117.943121,40.974679'
          },
          {
            adcode: '130821',
            name: '承德县',
            districts: [],
            isLeaf: true,
            center: '118.174166,40.768082'
          },
          {
            adcode: '130822',
            name: '兴隆县',
            districts: [],
            isLeaf: true,
            center: '117.500558,40.417358'
          },
          {
            adcode: '130881',
            name: '平泉市',
            districts: [],
            isLeaf: true,
            center: '118.702032,41.018482'
          }
        ]
      },
      {
        adcode: '130100',
        name: '石家庄市',
        districts: [
          {
            adcode: '130126',
            name: '灵寿县',
            districts: [],
            isLeaf: true,
            center: '114.383013,38.307908'
          },
          {
            adcode: '130130',
            name: '无极县',
            districts: [],
            isLeaf: true,
            center: '114.976256,38.178852'
          },
          {
            adcode: '130183',
            name: '晋州市',
            districts: [],
            isLeaf: true,
            center: '115.044141,38.033937'
          },
          {
            adcode: '130132',
            name: '元氏县',
            districts: [],
            isLeaf: true,
            center: '114.525508,37.767332'
          },
          {
            adcode: '130127',
            name: '高邑县',
            districts: [],
            isLeaf: true,
            center: '114.611659,37.615905'
          },
          {
            adcode: '130123',
            name: '正定县',
            districts: [],
            isLeaf: true,
            center: '114.57043,38.14699'
          },
          {
            adcode: '130184',
            name: '新乐市',
            districts: [],
            isLeaf: true,
            center: '114.683745,38.343952'
          },
          {
            adcode: '130181',
            name: '辛集市',
            districts: [],
            isLeaf: true,
            center: '115.217626,37.943239'
          },
          {
            adcode: '130121',
            name: '井陉县',
            districts: [],
            isLeaf: true,
            center: '114.145669,38.032366'
          },
          {
            adcode: '130107',
            name: '井陉矿区',
            districts: [],
            isLeaf: true,
            center: '114.062258,38.065446'
          },
          {
            adcode: '130128',
            name: '深泽县',
            districts: [],
            isLeaf: true,
            center: '115.20089,38.184572'
          },
          {
            adcode: '130133',
            name: '赵县',
            districts: [],
            isLeaf: true,
            center: '114.775914,37.756935'
          },
          {
            adcode: '130125',
            name: '行唐县',
            districts: [],
            isLeaf: true,
            center: '114.553044,38.437535'
          },
          {
            adcode: '130105',
            name: '新华区',
            districts: [],
            isLeaf: true,
            center: '116.866309,38.314094'
          },
          {
            adcode: '130110',
            name: '鹿泉区',
            districts: [],
            isLeaf: true,
            center: '114.313559,38.086536'
          },
          {
            adcode: '130131',
            name: '平山县',
            districts: [],
            isLeaf: true,
            center: '114.186007,38.260288'
          },
          {
            adcode: '130104',
            name: '桥西区',
            districts: [],
            isLeaf: true,
            center: '114.868604,40.819553'
          },
          {
            adcode: '130129',
            name: '赞皇县',
            districts: [],
            isLeaf: true,
            center: '114.386114,37.666549'
          },
          {
            adcode: '130111',
            name: '栾城区',
            districts: [],
            isLeaf: true,
            center: '114.647922,37.900915'
          },
          {
            adcode: '130108',
            name: '裕华区',
            districts: [],
            isLeaf: true,
            center: '114.531599,38.007002'
          },
          {
            adcode: '130109',
            name: '藁城区',
            districts: [],
            isLeaf: true,
            center: '114.846562,38.022177'
          },
          {
            adcode: '130102',
            name: '长安区',
            districts: [],
            isLeaf: true,
            center: '114.538955,38.03682'
          }
        ]
      },
      {
        adcode: '130500',
        name: '邢台市',
        districts: [
          {
            adcode: '130524',
            name: '柏乡县',
            districts: [],
            isLeaf: true,
            center: '114.693447,37.48288'
          },
          {
            adcode: '130581',
            name: '南宫市',
            districts: [],
            isLeaf: true,
            center: '115.40866,37.358907'
          },
          {
            adcode: '130530',
            name: '新河县',
            districts: [],
            isLeaf: true,
            center: '115.251005,37.520891'
          },
          {
            adcode: '130534',
            name: '清河县',
            districts: [],
            isLeaf: true,
            center: '115.665081,37.0451'
          },
          {
            adcode: '130531',
            name: '广宗县',
            districts: [],
            isLeaf: true,
            center: '115.142766,37.074795'
          },
          {
            adcode: '130532',
            name: '平乡县',
            districts: [],
            isLeaf: true,
            center: '115.03008,37.063771'
          },
          {
            adcode: '130506',
            name: '南和区',
            districts: [],
            isLeaf: true,
            center: '114.683683,37.005626'
          },
          {
            adcode: '130528',
            name: '宁晋县',
            districts: [],
            isLeaf: true,
            center: '114.940006,37.624524'
          },
          {
            adcode: '130533',
            name: '威县',
            districts: [],
            isLeaf: true,
            center: '115.266829,36.975164'
          },
          {
            adcode: '130535',
            name: '临西县',
            districts: [],
            isLeaf: true,
            center: '115.501258,36.871312'
          },
          {
            adcode: '130582',
            name: '沙河市',
            districts: [],
            isLeaf: true,
            center: '114.503023,36.855548'
          },
          {
            adcode: '130522',
            name: '临城县',
            districts: [],
            isLeaf: true,
            center: '114.498651,37.444512'
          },
          {
            adcode: '130523',
            name: '内丘县',
            districts: [],
            isLeaf: true,
            center: '114.512226,37.287612'
          },
          {
            adcode: '130502',
            name: '襄都区',
            districts: [],
            isLeaf: true,
            center: '114.507443,37.071314'
          },
          {
            adcode: '130503',
            name: '信都区',
            districts: [],
            isLeaf: true,
            center: '114.468229,37.093798'
          },
          {
            adcode: '130525',
            name: '隆尧县',
            districts: [],
            isLeaf: true,
            center: '114.770509,37.351232'
          },
          {
            adcode: '130529',
            name: '巨鹿县',
            districts: [],
            isLeaf: true,
            center: '115.037884,37.221293'
          },
          {
            adcode: '130505',
            name: '任泽区',
            districts: [],
            isLeaf: true,
            center: '114.671339,37.121958'
          }
        ]
      },
      {
        adcode: '131000',
        name: '廊坊市',
        districts: [
          {
            adcode: '131028',
            name: '大厂回族自治县',
            districts: [],
            isLeaf: true,
            center: '116.98961,39.886569'
          },
          {
            adcode: '131026',
            name: '文安县',
            districts: [],
            isLeaf: true,
            center: '116.457628,38.873185'
          },
          {
            adcode: '131003',
            name: '广阳区',
            districts: [],
            isLeaf: true,
            center: '116.710667,39.52343'
          },
          {
            adcode: '131082',
            name: '三河市',
            districts: [],
            isLeaf: true,
            center: '117.078269,39.982933'
          },
          {
            adcode: '131081',
            name: '霸州市',
            districts: [],
            isLeaf: true,
            center: '116.391488,39.125238'
          },
          {
            adcode: '131022',
            name: '固安县',
            districts: [],
            isLeaf: true,
            center: '116.298696,39.438797'
          },
          {
            adcode: '131024',
            name: '香河县',
            districts: [],
            isLeaf: true,
            center: '117.006072,39.763772'
          },
          {
            adcode: '131025',
            name: '大城县',
            districts: [],
            isLeaf: true,
            center: '116.653917,38.705232'
          },
          {
            adcode: '131023',
            name: '永清县',
            districts: [],
            isLeaf: true,
            center: '116.50608,39.330983'
          },
          {
            adcode: '131002',
            name: '安次区',
            districts: [],
            isLeaf: true,
            center: '116.6945443,39.50256863'
          }
        ]
      },
      {
        adcode: '130400',
        name: '邯郸市',
        districts: [
          {
            adcode: '130407',
            name: '肥乡区',
            districts: [],
            isLeaf: true,
            center: '114.800199,36.548545'
          },
          {
            adcode: '130431',
            name: '鸡泽县',
            districts: [],
            isLeaf: true,
            center: '114.889951,36.911525'
          },
          {
            adcode: '130435',
            name: '曲周县',
            districts: [],
            isLeaf: true,
            center: '114.957549,36.766347'
          },
          {
            adcode: '130408',
            name: '永年区',
            districts: [],
            isLeaf: true,
            center: '114.536626,36.74188'
          },
          {
            adcode: '130433',
            name: '馆陶县',
            districts: [],
            isLeaf: true,
            center: '115.281818,36.548295'
          },
          {
            adcode: '130430',
            name: '邱县',
            districts: [],
            isLeaf: true,
            center: '115.200049,36.811783'
          },
          {
            adcode: '130432',
            name: '广平县',
            districts: [],
            isLeaf: true,
            center: '114.921185,36.476727'
          },
          {
            adcode: '130423',
            name: '临漳县',
            districts: [],
            isLeaf: true,
            center: '114.585514,36.322504'
          },
          {
            adcode: '130434',
            name: '魏县',
            districts: [],
            isLeaf: true,
            center: '114.939217,36.35926'
          },
          {
            adcode: '130424',
            name: '成安县',
            districts: [],
            isLeaf: true,
            center: '114.670094,36.445331'
          },
          {
            adcode: '130404',
            name: '复兴区',
            districts: [],
            isLeaf: true,
            center: '114.462581,36.638879'
          },
          {
            adcode: '130427',
            name: '磁县',
            districts: [],
            isLeaf: true,
            center: '114.373965,36.37507'
          },
          {
            adcode: '130406',
            name: '峰峰矿区',
            districts: [],
            isLeaf: true,
            center: '114.212571,36.419298'
          },
          {
            adcode: '130403',
            name: '丛台区',
            districts: [],
            isLeaf: true,
            center: '114.492875,36.636434'
          },
          {
            adcode: '130402',
            name: '邯山区',
            districts: [],
            isLeaf: true,
            center: '114.531062,36.59457'
          },
          {
            adcode: '130425',
            name: '大名县',
            districts: [],
            isLeaf: true,
            center: '115.147985,36.286406'
          },
          {
            adcode: '130426',
            name: '涉县',
            districts: [],
            isLeaf: true,
            center: '113.692157,36.586073'
          },
          {
            adcode: '130481',
            name: '武安市',
            districts: [],
            isLeaf: true,
            center: '114.203685,36.69759'
          }
        ]
      },
      {
        adcode: '130600',
        name: '保定市',
        districts: [
          {
            adcode: '130636',
            name: '顺平县',
            districts: [],
            isLeaf: true,
            center: '115.135133,38.837988'
          },
          {
            adcode: '130632',
            name: '安新县',
            districts: [],
            isLeaf: true,
            center: '115.935688,38.936102'
          },
          {
            adcode: '130683',
            name: '安国市',
            districts: [],
            isLeaf: true,
            center: '115.327088,38.418985'
          },
          {
            adcode: '130682',
            name: '定州市',
            districts: [],
            isLeaf: true,
            center: '114.990321,38.516746'
          },
          {
            adcode: '130623',
            name: '涞水县',
            districts: [],
            isLeaf: true,
            center: '115.713651,39.394305'
          },
          {
            adcode: '130624',
            name: '阜平县',
            districts: [],
            isLeaf: true,
            center: '114.195118,38.849221'
          },
          {
            adcode: '130631',
            name: '望都县',
            districts: [],
            isLeaf: true,
            center: '115.15542,38.696221'
          },
          {
            adcode: '130627',
            name: '唐县',
            districts: [],
            isLeaf: true,
            center: '114.982968,38.748477'
          },
          {
            adcode: '130684',
            name: '高碑店市',
            districts: [],
            isLeaf: true,
            center: '115.873612,39.327233'
          },
          {
            adcode: '130626',
            name: '定兴县',
            districts: [],
            isLeaf: true,
            center: '115.808183,39.263219'
          },
          {
            adcode: '130629',
            name: '容城县',
            districts: [],
            isLeaf: true,
            center: '115.861635,39.043321'
          },
          {
            adcode: '130638',
            name: '雄县',
            districts: [],
            isLeaf: true,
            center: '116.108624,38.994825'
          },
          {
            adcode: '130637',
            name: '博野县',
            districts: [],
            isLeaf: true,
            center: '115.464295,38.458048'
          },
          {
            adcode: '130634',
            name: '曲阳县',
            districts: [],
            isLeaf: true,
            center: '114.740476,38.614409'
          },
          {
            adcode: '130633',
            name: '易县',
            districts: [],
            isLeaf: true,
            center: '115.497487,39.350219'
          },
          {
            adcode: '130635',
            name: '蠡县',
            districts: [],
            isLeaf: true,
            center: '115.583701,38.488064'
          },
          {
            adcode: '130628',
            name: '高阳县',
            districts: [],
            isLeaf: true,
            center: '115.779149,38.700846'
          },
          {
            adcode: '130681',
            name: '涿州市',
            districts: [],
            isLeaf: true,
            center: '115.97444,39.485684'
          },
          {
            adcode: '130630',
            name: '涞源县',
            districts: [],
            isLeaf: true,
            center: '114.694416,39.360622'
          },
          {
            adcode: '130609',
            name: '徐水区',
            districts: [],
            isLeaf: true,
            center: '115.655772,39.018781'
          },
          {
            adcode: '130602',
            name: '竞秀区',
            districts: [],
            isLeaf: true,
            center: '115.458671,38.877318'
          },
          {
            adcode: '130607',
            name: '满城区',
            districts: [],
            isLeaf: true,
            center: '115.322246,38.949732'
          },
          {
            adcode: '130608',
            name: '清苑区',
            districts: [],
            isLeaf: true,
            center: '115.48988,38.76527'
          },
          {
            adcode: '130606',
            name: '莲池区',
            districts: [],
            isLeaf: true,
            center: '115.497153,38.883528'
          }
        ]
      },
      {
        adcode: '131100',
        name: '衡水市',
        districts: [
          {
            adcode: '131102',
            name: '桃城区',
            districts: [],
            isLeaf: true,
            center: '115.675208,37.735152'
          },
          {
            adcode: '131182',
            name: '深州市',
            districts: [],
            isLeaf: true,
            center: '115.559576,38.001535'
          },
          {
            adcode: '131124',
            name: '饶阳县',
            districts: [],
            isLeaf: true,
            center: '115.725898,38.235313'
          },
          {
            adcode: '131125',
            name: '安平县',
            districts: [],
            isLeaf: true,
            center: '115.518918,38.234769'
          },
          {
            adcode: '131128',
            name: '阜城县',
            districts: [],
            isLeaf: true,
            center: '116.175424,37.862984'
          },
          {
            adcode: '131103',
            name: '冀州区',
            districts: [],
            isLeaf: true,
            center: '115.579392,37.550922'
          },
          {
            adcode: '131126',
            name: '故城县',
            districts: [],
            isLeaf: true,
            center: '115.965877,37.347873'
          },
          {
            adcode: '131122',
            name: '武邑县',
            districts: [],
            isLeaf: true,
            center: '115.887498,37.802036'
          },
          {
            adcode: '131123',
            name: '武强县',
            districts: [],
            isLeaf: true,
            center: '115.982119,38.041447'
          },
          {
            adcode: '131121',
            name: '枣强县',
            districts: [],
            isLeaf: true,
            center: '115.724365,37.514217'
          },
          {
            adcode: '131127',
            name: '景县',
            districts: [],
            isLeaf: true,
            center: '116.270558,37.692831'
          }
        ]
      },
      {
        adcode: '130900',
        name: '沧州市',
        districts: [
          {
            adcode: '130982',
            name: '任丘市',
            districts: [],
            isLeaf: true,
            center: '116.084412,38.685325'
          },
          {
            adcode: '130929',
            name: '献县',
            districts: [],
            isLeaf: true,
            center: '116.122767,38.189924'
          },
          {
            adcode: '130923',
            name: '东光县',
            districts: [],
            isLeaf: true,
            center: '116.537138,37.888844'
          },
          {
            adcode: '130924',
            name: '海兴县',
            districts: [],
            isLeaf: true,
            center: '117.497545,38.143308'
          },
          {
            adcode: '130928',
            name: '吴桥县',
            districts: [],
            isLeaf: true,
            center: '116.391557,37.628225'
          },
          {
            adcode: '130983',
            name: '黄骅市',
            districts: [],
            isLeaf: true,
            center: '117.330043,38.372266'
          },
          {
            adcode: '130902',
            name: '新华区',
            districts: [],
            isLeaf: true,
            center: '116.866309,38.314094'
          },
          {
            adcode: '130981',
            name: '泊头市',
            districts: [],
            isLeaf: true,
            center: '116.578322,38.084262'
          },
          {
            adcode: '130903',
            name: '运河区',
            districts: [],
            isLeaf: true,
            center: '116.842964,38.283456'
          },
          {
            adcode: '130921',
            name: '沧县',
            districts: [],
            isLeaf: true,
            center: '117.007478,38.21985569'
          },
          {
            adcode: '130930',
            name: '孟村回族自治县',
            districts: [],
            isLeaf: true,
            center: '117.104514,38.053438'
          },
          {
            adcode: '130925',
            name: '盐山县',
            districts: [],
            isLeaf: true,
            center: '117.230681,38.058074'
          },
          {
            adcode: '130927',
            name: '南皮县',
            districts: [],
            isLeaf: true,
            center: '116.708603,38.038761'
          },
          {
            adcode: '130922',
            name: '青县',
            districts: [],
            isLeaf: true,
            center: '116.804137,38.583657'
          },
          {
            adcode: '130926',
            name: '肃宁县',
            districts: [],
            isLeaf: true,
            center: '115.829619,38.423044'
          },
          {
            adcode: '130984',
            name: '河间市',
            districts: [],
            isLeaf: true,
            center: '116.099362,38.446656'
          }
        ]
      },
      {
        adcode: '130700',
        name: '张家口市',
        districts: [
          {
            adcode: '130724',
            name: '沽源县',
            districts: [],
            isLeaf: true,
            center: '115.688544,41.670497'
          },
          {
            adcode: '130723',
            name: '康保县',
            districts: [],
            isLeaf: true,
            center: '114.60018,41.853016'
          },
          {
            adcode: '130732',
            name: '赤城县',
            districts: [],
            isLeaf: true,
            center: '115.831256,40.913348'
          },
          {
            adcode: '130706',
            name: '下花园区',
            districts: [],
            isLeaf: true,
            center: '115.287127,40.502628'
          },
          {
            adcode: '130709',
            name: '崇礼区',
            districts: [],
            isLeaf: true,
            center: '115.282345,40.974741'
          },
          {
            adcode: '130731',
            name: '涿鹿县',
            districts: [],
            isLeaf: true,
            center: '115.196835,40.382681'
          },
          {
            adcode: '130702',
            name: '桥东区',
            districts: [],
            isLeaf: true,
            center: '114.894114,40.788472'
          },
          {
            adcode: '130705',
            name: '宣化区',
            districts: [],
            isLeaf: true,
            center: '115.099515,40.609444'
          },
          {
            adcode: '130726',
            name: '蔚县',
            districts: [],
            isLeaf: true,
            center: '114.589136,39.840154'
          },
          {
            adcode: '130728',
            name: '怀安县',
            districts: [],
            isLeaf: true,
            center: '114.386488,40.67527'
          },
          {
            adcode: '130703',
            name: '桥西区',
            districts: [],
            isLeaf: true,
            center: '114.868604,40.819553'
          },
          {
            adcode: '130722',
            name: '张北县',
            districts: [],
            isLeaf: true,
            center: '114.719927,41.159039'
          },
          {
            adcode: '130725',
            name: '尚义县',
            districts: [],
            isLeaf: true,
            center: '113.968763,41.076588'
          },
          {
            adcode: '130708',
            name: '万全区',
            districts: [],
            isLeaf: true,
            center: '114.740584,40.767377'
          },
          {
            adcode: '130730',
            name: '怀来县',
            districts: [],
            isLeaf: true,
            center: '115.517868,40.415625'
          },
          {
            adcode: '130727',
            name: '阳原县',
            districts: [],
            isLeaf: true,
            center: '114.150267,40.104303'
          }
        ]
      }
    ]
  },
  {
    adcode: '810000',
    name: '香港特别行政区',
    districts: [
      {
        adcode: '810015',
        name: '西贡区',
        isLeaf: true,
        center: '114.264645,22.31421306'
      },
      {
        adcode: '810013',
        name: '北区',
        isLeaf: true,
        center: '114.1473639,22.49610389'
      },
      {
        adcode: '810014',
        name: '大埔区',
        isLeaf: true,
        center: '114.1717431,22.44565306'
      },
      {
        adcode: '810016',
        name: '沙田区',
        isLeaf: true,
        center: '114.1953653,22.37953167'
      },
      {
        adcode: '810008',
        name: '黄大仙区',
        isLeaf: true,
        center: '114.2038856,22.33632056'
      },
      {
        adcode: '810018',
        name: '离岛区',
        isLeaf: true,
        center: '113.94612,22.28640778'
      },
      {
        adcode: '810006',
        name: '深水埗区',
        isLeaf: true,
        center: '114.1632417,22.33385417'
      },
      {
        adcode: '810011',
        name: '屯门区',
        isLeaf: true,
        center: '113.9765742,22.39384417'
      },
      {
        adcode: '810007',
        name: '九龙城区',
        isLeaf: true,
        center: '114.1928467,22.31251'
      },
      {
        adcode: '810009',
        name: '观塘区',
        isLeaf: true,
        center: '114.2140542,22.32083778'
      },
      {
        adcode: '810001',
        name: '中西区',
        isLeaf: true,
        center: '114.1543731,22.28198083'
      },
      {
        adcode: '810005',
        name: '油尖旺区',
        isLeaf: true,
        center: '114.1733317,22.31170389'
      },
      {
        adcode: '810003',
        name: '东区',
        isLeaf: true,
        center: '101.704129,26.546484'
      },
      {
        adcode: '810002',
        name: '湾仔区',
        isLeaf: true,
        center: '114.1829153,22.27638889'
      },
      {
        adcode: '810004',
        name: '南区',
        isLeaf: true,
        center: '114.1600117,22.24589667'
      },
      {
        adcode: '810012',
        name: '元朗区',
        isLeaf: true,
        center: '114.0324381,22.44142833'
      },
      {
        adcode: '810010',
        name: '荃湾区',
        isLeaf: true,
        center: '114.1210792,22.36830667'
      },
      {
        adcode: '810017',
        name: '葵青区',
        isLeaf: true,
        center: '114.1393194,22.36387667'
      }
    ]
  },
  {
    adcode: '710000',
    name: '台湾省',
    isLeaf: true,
    center: '121.509062,25.044332'
  },
  {
    adcode: '820000',
    name: '澳门特别行政区',
    districts: [
      {
        adcode: '820008',
        name: '圣方济各堂区',
        isLeaf: true,
        center: '113.5599542,22.12348639'
      },
      {
        adcode: '820007',
        name: '路凼填海区',
        isLeaf: true,
        center: '113.5695992,22.13663'
      },
      {
        adcode: '820005',
        name: '风顺堂区',
        isLeaf: true,
        center: '113.5419278,22.18736806'
      },
      {
        adcode: '820006',
        name: '嘉模堂区',
        isLeaf: true,
        center: '113.5587044,22.15375944'
      },
      {
        adcode: '820001',
        name: '花地玛堂区',
        isLeaf: true,
        center: '113.5528956,22.20787'
      },
      {
        adcode: '820002',
        name: '花王堂区',
        isLeaf: true,
        center: '113.5489608,22.1992075'
      },
      {
        adcode: '820003',
        name: '望德堂区',
        isLeaf: true,
        center: '113.5501828,22.19372083'
      },
      {
        adcode: '820004',
        name: '大堂区',
        isLeaf: true,
        center: '113.5536475,22.18853944'
      }
    ]
  },
  {
    adcode: '620000',
    name: '甘肃省',
    districts: [
      {
        adcode: '620900',
        name: '酒泉市',
        districts: [
          {
            adcode: '620923',
            name: '肃北蒙古族自治县',
            districts: [],
            isLeaf: true,
            center: '94.876533,39.512158'
          },
          {
            adcode: '620981',
            name: '玉门市',
            districts: [],
            isLeaf: true,
            center: '97.045617,40.292344'
          },
          {
            adcode: '620982',
            name: '敦煌市',
            districts: [],
            isLeaf: true,
            center: '94.66201,40.142141'
          },
          {
            adcode: '620921',
            name: '金塔县',
            districts: [],
            isLeaf: true,
            center: '98.901523,39.984146'
          },
          {
            adcode: '620902',
            name: '肃州区',
            districts: [],
            isLeaf: true,
            center: '98.507834,39.74511'
          },
          {
            adcode: '620922',
            name: '瓜州县',
            districts: [],
            isLeaf: true,
            center: '95.782255,40.520457'
          },
          {
            adcode: '620924',
            name: '阿克塞哈萨克族自治县',
            districts: [],
            isLeaf: true,
            center: '94.340202,39.633944'
          }
        ]
      },
      {
        adcode: '620200',
        name: '嘉峪关市',
        districts: [],
        isLeaf: true,
        center: '98.2882,39.77325'
      },
      {
        adcode: '620300',
        name: '金昌市',
        districts: [
          {
            adcode: '620321',
            name: '永昌县',
            districts: [],
            isLeaf: true,
            center: '101.984642,38.243534'
          },
          {
            adcode: '620302',
            name: '金川区',
            districts: [],
            isLeaf: true,
            center: '102.1939,38.520373'
          }
        ]
      },
      {
        adcode: '620100',
        name: '兰州市',
        districts: [
          {
            adcode: '620111',
            name: '红古区',
            districts: [],
            isLeaf: true,
            center: '102.859338,36.345768'
          },
          {
            adcode: '620104',
            name: '西固区',
            districts: [],
            isLeaf: true,
            center: '103.627966,36.08845'
          },
          {
            adcode: '620103',
            name: '七里河区',
            districts: [],
            isLeaf: true,
            center: '103.786261,36.066249'
          },
          {
            adcode: '620123',
            name: '榆中县',
            districts: [],
            isLeaf: true,
            center: '104.112541,35.843354'
          },
          {
            adcode: '620102',
            name: '城关区',
            districts: [],
            isLeaf: true,
            center: '91.140368,29.654813'
          },
          {
            adcode: '620105',
            name: '安宁区',
            districts: [],
            isLeaf: true,
            center: '103.718953,36.104493'
          },
          {
            adcode: '620122',
            name: '皋兰县',
            districts: [],
            isLeaf: true,
            center: '103.947345,36.332434'
          },
          {
            adcode: '620121',
            name: '永登县',
            districts: [],
            isLeaf: true,
            center: '103.260273,36.736457'
          }
        ]
      },
      {
        adcode: '620800',
        name: '平凉市',
        districts: [
          {
            adcode: '620825',
            name: '庄浪县',
            districts: [],
            isLeaf: true,
            center: '106.036201,35.20266'
          },
          {
            adcode: '620802',
            name: '崆峒区',
            districts: [],
            isLeaf: true,
            center: '106.67489,35.542437'
          },
          {
            adcode: '620823',
            name: '崇信县',
            districts: [],
            isLeaf: true,
            center: '107.025762,35.305648'
          },
          {
            adcode: '620826',
            name: '静宁县',
            districts: [],
            isLeaf: true,
            center: '105.732165,35.522035'
          },
          {
            adcode: '620881',
            name: '华亭市',
            districts: [],
            isLeaf: true,
            center: '106.628899,35.207695'
          },
          {
            adcode: '620822',
            name: '灵台县',
            districts: [],
            isLeaf: true,
            center: '107.595874,35.070027'
          },
          {
            adcode: '620821',
            name: '泾川县',
            districts: [],
            isLeaf: true,
            center: '107.367608,35.332992'
          }
        ]
      },
      {
        adcode: '620700',
        name: '张掖市',
        districts: [
          {
            adcode: '620724',
            name: '高台县',
            districts: [],
            isLeaf: true,
            center: '99.819265,39.378294'
          },
          {
            adcode: '620721',
            name: '肃南裕固族自治县',
            districts: [],
            isLeaf: true,
            center: '99.615601,38.836931'
          },
          {
            adcode: '620725',
            name: '山丹县',
            districts: [],
            isLeaf: true,
            center: '101.088611,38.7846'
          },
          {
            adcode: '620722',
            name: '民乐县',
            districts: [],
            isLeaf: true,
            center: '100.812047,38.430318'
          },
          {
            adcode: '620702',
            name: '甘州区',
            districts: [],
            isLeaf: true,
            center: '100.415324,38.944315'
          },
          {
            adcode: '620723',
            name: '临泽县',
            districts: [],
            isLeaf: true,
            center: '100.164333,39.15224'
          }
        ]
      },
      {
        adcode: '621000',
        name: '庆阳市',
        districts: [
          {
            adcode: '621025',
            name: '正宁县',
            districts: [],
            isLeaf: true,
            center: '108.359964,35.492158'
          },
          {
            adcode: '621027',
            name: '镇原县',
            districts: [],
            isLeaf: true,
            center: '107.200888,35.677705'
          },
          {
            adcode: '621022',
            name: '环县',
            districts: [],
            isLeaf: true,
            center: '107.308788,36.568566'
          },
          {
            adcode: '621026',
            name: '宁县',
            districts: [],
            isLeaf: true,
            center: '107.913463,35.509252'
          },
          {
            adcode: '621024',
            name: '合水县',
            districts: [],
            isLeaf: true,
            center: '108.021859,35.833908'
          },
          {
            adcode: '621002',
            name: '西峰区',
            districts: [],
            isLeaf: true,
            center: '107.650563,35.730318'
          },
          {
            adcode: '621021',
            name: '庆城县',
            districts: [],
            isLeaf: true,
            center: '107.881706,36.016125'
          },
          {
            adcode: '621023',
            name: '华池县',
            districts: [],
            isLeaf: true,
            center: '107.98972,36.461474'
          }
        ]
      },
      {
        adcode: '620400',
        name: '白银市',
        districts: [
          {
            adcode: '620402',
            name: '白银区',
            districts: [],
            isLeaf: true,
            center: '104.14956,36.53469'
          },
          {
            adcode: '620422',
            name: '会宁县',
            districts: [],
            isLeaf: true,
            center: '105.052883,35.693241'
          },
          {
            adcode: '620421',
            name: '靖远县',
            districts: [],
            isLeaf: true,
            center: '104.676202,36.571736'
          },
          {
            adcode: '620423',
            name: '景泰县',
            districts: [],
            isLeaf: true,
            center: '104.063125,37.18351'
          },
          {
            adcode: '620403',
            name: '平川区',
            districts: [],
            isLeaf: true,
            center: '104.825275,36.728494'
          }
        ]
      },
      {
        adcode: '620600',
        name: '武威市',
        districts: [
          {
            adcode: '620602',
            name: '凉州区',
            districts: [],
            isLeaf: true,
            center: '102.641959,37.928217'
          },
          {
            adcode: '620622',
            name: '古浪县',
            districts: [],
            isLeaf: true,
            center: '102.897542,37.470882'
          },
          {
            adcode: '620621',
            name: '民勤县',
            districts: [],
            isLeaf: true,
            center: '103.093671,38.624293'
          },
          {
            adcode: '620623',
            name: '天祝藏族自治县',
            districts: [],
            isLeaf: true,
            center: '103.142416,36.971978'
          }
        ]
      },
      {
        adcode: '621200',
        name: '陇南市',
        districts: [
          {
            adcode: '621227',
            name: '徽县',
            districts: [],
            isLeaf: true,
            center: '106.087156,33.769927'
          },
          {
            adcode: '621202',
            name: '武都区',
            districts: [],
            isLeaf: true,
            center: '104.921861,33.400685'
          },
          {
            adcode: '621222',
            name: '文县',
            districts: [],
            isLeaf: true,
            center: '104.6835,32.944031'
          },
          {
            adcode: '621225',
            name: '西和县',
            districts: [],
            isLeaf: true,
            center: '105.298756,34.014215'
          },
          {
            adcode: '621221',
            name: '成县',
            districts: [],
            isLeaf: true,
            center: '105.742508,33.750722'
          },
          {
            adcode: '621224',
            name: '康县',
            districts: [],
            isLeaf: true,
            center: '105.608609,33.329212'
          },
          {
            adcode: '621226',
            name: '礼县',
            districts: [],
            isLeaf: true,
            center: '105.17864,34.189345'
          },
          {
            adcode: '621223',
            name: '宕昌县',
            districts: [],
            isLeaf: true,
            center: '104.393385,34.047261'
          },
          {
            adcode: '621228',
            name: '两当县',
            districts: [],
            isLeaf: true,
            center: '106.304973,33.908952'
          }
        ]
      },
      {
        adcode: '621100',
        name: '定西市',
        districts: [
          {
            adcode: '621123',
            name: '渭源县',
            districts: [],
            isLeaf: true,
            center: '104.215092,35.137028'
          },
          {
            adcode: '621124',
            name: '临洮县',
            districts: [],
            isLeaf: true,
            center: '103.859446,35.394993'
          },
          {
            adcode: '621126',
            name: '岷县',
            districts: [],
            isLeaf: true,
            center: '104.036731,34.43833'
          },
          {
            adcode: '621125',
            name: '漳县',
            districts: [],
            isLeaf: true,
            center: '104.471722,34.84854'
          },
          {
            adcode: '621102',
            name: '安定区',
            districts: [],
            isLeaf: true,
            center: '104.610647,35.580918'
          },
          {
            adcode: '621122',
            name: '陇西县',
            districts: [],
            isLeaf: true,
            center: '104.634928,35.003899'
          },
          {
            adcode: '621121',
            name: '通渭县',
            districts: [],
            isLeaf: true,
            center: '105.242312,35.211311'
          }
        ]
      },
      {
        adcode: '620500',
        name: '天水市',
        districts: [
          {
            adcode: '620525',
            name: '张家川回族自治县',
            districts: [],
            isLeaf: true,
            center: '106.20513,34.988676'
          },
          {
            adcode: '620521',
            name: '清水县',
            districts: [],
            isLeaf: true,
            center: '106.137318,34.749906'
          },
          {
            adcode: '620502',
            name: '秦州区',
            districts: [],
            isLeaf: true,
            center: '105.724914,34.581187'
          },
          {
            adcode: '620524',
            name: '武山县',
            districts: [],
            isLeaf: true,
            center: '104.89121,34.72183'
          },
          {
            adcode: '620503',
            name: '麦积区',
            districts: [],
            isLeaf: true,
            center: '105.889452,34.571143'
          },
          {
            adcode: '620523',
            name: '甘谷县',
            districts: [],
            isLeaf: true,
            center: '105.340789,34.745447'
          },
          {
            adcode: '620522',
            name: '秦安县',
            districts: [],
            isLeaf: true,
            center: '105.675799,34.859094'
          }
        ]
      },
      {
        adcode: '622900',
        name: '临夏回族自治州',
        districts: [
          {
            adcode: '622924',
            name: '广河县',
            districts: [],
            isLeaf: true,
            center: '103.575582,35.488248'
          },
          {
            adcode: '622925',
            name: '和政县',
            districts: [],
            isLeaf: true,
            center: '103.350996,35.424602'
          },
          {
            adcode: '622927',
            name: '积石山保安族东乡族撒拉族自治县',
            districts: [],
            isLeaf: true,
            center: '102.875939,35.717697'
          },
          {
            adcode: '622923',
            name: '永靖县',
            districts: [],
            isLeaf: true,
            center: '103.285853,35.958306'
          },
          {
            adcode: '622921',
            name: '临夏县',
            districts: [],
            isLeaf: true,
            center: '103.04068,35.478726'
          },
          {
            adcode: '622901',
            name: '临夏市',
            districts: [],
            isLeaf: true,
            center: '103.24222,35.604682'
          },
          {
            adcode: '622926',
            name: '东乡族自治县',
            districts: [],
            isLeaf: true,
            center: '103.388122,35.664409'
          },
          {
            adcode: '622922',
            name: '康乐县',
            districts: [],
            isLeaf: true,
            center: '103.708507,35.370296'
          }
        ]
      },
      {
        adcode: '623000',
        name: '甘南藏族自治州',
        districts: [
          {
            adcode: '623027',
            name: '夏河县',
            districts: [],
            isLeaf: true,
            center: '102.548303,35.203857'
          },
          {
            adcode: '623001',
            name: '合作市',
            districts: [],
            isLeaf: true,
            center: '102.910305,35.000228'
          },
          {
            adcode: '623021',
            name: '临潭县',
            districts: [],
            isLeaf: true,
            center: '103.353665,34.693009'
          },
          {
            adcode: '623024',
            name: '迭部县',
            districts: [],
            isLeaf: true,
            center: '103.221735,34.055888'
          },
          {
            adcode: '623022',
            name: '卓尼县',
            districts: [],
            isLeaf: true,
            center: '103.506849,34.589476'
          },
          {
            adcode: '623026',
            name: '碌曲县',
            districts: [],
            isLeaf: true,
            center: '102.487512,34.591084'
          },
          {
            adcode: '623025',
            name: '玛曲县',
            districts: [],
            isLeaf: true,
            center: '102.072608,33.998145'
          },
          {
            adcode: '623023',
            name: '舟曲县',
            districts: [],
            isLeaf: true,
            center: '104.251543,33.793695'
          }
        ]
      }
    ]
  },
  {
    adcode: '510000',
    name: '四川省',
    districts: [
      {
        adcode: '510800',
        name: '广元市',
        districts: [
          {
            adcode: '510812',
            name: '朝天区',
            districts: [],
            isLeaf: true,
            center: '105.882848,32.651352'
          },
          {
            adcode: '510811',
            name: '昭化区',
            districts: [],
            isLeaf: true,
            center: '105.957612,32.333173'
          },
          {
            adcode: '510824',
            name: '苍溪县',
            districts: [],
            isLeaf: true,
            center: '105.934756,31.731709'
          },
          {
            adcode: '510823',
            name: '剑阁县',
            districts: [],
            isLeaf: true,
            center: '105.524699,32.288681'
          },
          {
            adcode: '510822',
            name: '青川县',
            districts: [],
            isLeaf: true,
            center: '105.238498,32.575821'
          },
          {
            adcode: '510821',
            name: '旺苍县',
            districts: [],
            isLeaf: true,
            center: '106.290124,32.229074'
          },
          {
            adcode: '510802',
            name: '利州区',
            districts: [],
            isLeaf: true,
            center: '105.845307,32.433756'
          }
        ]
      },
      {
        adcode: '511300',
        name: '南充市',
        districts: [
          {
            adcode: '511321',
            name: '南部县',
            districts: [],
            isLeaf: true,
            center: '106.036349,31.348255'
          },
          {
            adcode: '511322',
            name: '营山县',
            districts: [],
            isLeaf: true,
            center: '106.565524,31.077057'
          },
          {
            adcode: '511325',
            name: '西充县',
            districts: [],
            isLeaf: true,
            center: '105.900894,30.995546'
          },
          {
            adcode: '511304',
            name: '嘉陵区',
            districts: [],
            isLeaf: true,
            center: '106.07208,30.758831'
          },
          {
            adcode: '511324',
            name: '仪陇县',
            districts: [],
            isLeaf: true,
            center: '106.303082,31.271693'
          },
          {
            adcode: '511302',
            name: '顺庆区',
            districts: [],
            isLeaf: true,
            center: '106.092327,30.797366'
          },
          {
            adcode: '511381',
            name: '阆中市',
            districts: [],
            isLeaf: true,
            center: '106.005046,31.558356'
          },
          {
            adcode: '511303',
            name: '高坪区',
            districts: [],
            isLeaf: true,
            center: '106.118931,30.781284'
          },
          {
            adcode: '511323',
            name: '蓬安县',
            districts: [],
            isLeaf: true,
            center: '106.412156,31.028537'
          }
        ]
      },
      {
        adcode: '511900',
        name: '巴中市',
        districts: [
          {
            adcode: '511921',
            name: '通江县',
            districts: [],
            isLeaf: true,
            center: '107.245033,31.911705'
          },
          {
            adcode: '511902',
            name: '巴州区',
            districts: [],
            isLeaf: true,
            center: '106.76887,31.851458'
          },
          {
            adcode: '511922',
            name: '南江县',
            districts: [],
            isLeaf: true,
            center: '106.828697,32.346589'
          },
          {
            adcode: '511923',
            name: '平昌县',
            districts: [],
            isLeaf: true,
            center: '107.103613,31.560436'
          },
          {
            adcode: '511903',
            name: '恩阳区',
            districts: [],
            isLeaf: true,
            center: '106.654386,31.787186'
          }
        ]
      },
      {
        adcode: '510600',
        name: '德阳市',
        districts: [
          {
            adcode: '510623',
            name: '中江县',
            districts: [],
            isLeaf: true,
            center: '104.678601,31.03327'
          },
          {
            adcode: '510682',
            name: '什邡市',
            districts: [],
            isLeaf: true,
            center: '104.167345,31.12655'
          },
          {
            adcode: '510604',
            name: '罗江区',
            districts: [],
            isLeaf: true,
            center: '104.510457,31.317388'
          },
          {
            adcode: '510681',
            name: '广汉市',
            districts: [],
            isLeaf: true,
            center: '104.282204,30.977264'
          },
          {
            adcode: '510603',
            name: '旌阳区',
            districts: [],
            isLeaf: true,
            center: '104.417061,31.143335'
          },
          {
            adcode: '510683',
            name: '绵竹市',
            districts: [],
            isLeaf: true,
            center: '104.220679,31.339251'
          }
        ]
      },
      {
        adcode: '510700',
        name: '绵阳市',
        districts: [
          {
            adcode: '510781',
            name: '江油市',
            districts: [],
            isLeaf: true,
            center: '104.74564,31.778572'
          },
          {
            adcode: '510725',
            name: '梓潼县',
            districts: [],
            isLeaf: true,
            center: '105.170746,31.642635'
          },
          {
            adcode: '510704',
            name: '游仙区',
            districts: [],
            isLeaf: true,
            center: '104.766136,31.473788'
          },
          {
            adcode: '510722',
            name: '三台县',
            districts: [],
            isLeaf: true,
            center: '105.093722,31.095826'
          },
          {
            adcode: '510703',
            name: '涪城区',
            districts: [],
            isLeaf: true,
            center: '104.756917,31.455192'
          },
          {
            adcode: '510727',
            name: '平武县',
            districts: [],
            isLeaf: true,
            center: '104.555481,32.409684'
          },
          {
            adcode: '510726',
            name: '北川羌族自治县',
            districts: [],
            isLeaf: true,
            center: '104.468163,31.617192'
          },
          {
            adcode: '510723',
            name: '盐亭县',
            districts: [],
            isLeaf: true,
            center: '105.389453,31.208362'
          },
          {
            adcode: '510705',
            name: '安州区',
            districts: [],
            isLeaf: true,
            center: '104.567543,31.534319'
          }
        ]
      },
      {
        adcode: '510100',
        name: '成都市',
        districts: [
          {
            adcode: '510182',
            name: '彭州市',
            districts: [],
            isLeaf: true,
            center: '103.957706,30.990463'
          },
          {
            adcode: '510185',
            name: '简阳市',
            districts: [],
            isLeaf: true,
            center: '104.547644,30.410937'
          },
          {
            adcode: '510131',
            name: '蒲江县',
            districts: [],
            isLeaf: true,
            center: '103.506478,30.197558'
          },
          {
            adcode: '510116',
            name: '双流区',
            districts: [],
            isLeaf: true,
            center: '103.92342,30.574884'
          },
          {
            adcode: '510105',
            name: '青羊区',
            districts: [],
            isLeaf: true,
            center: '104.062415,30.674583'
          },
          {
            adcode: '510106',
            name: '金牛区',
            districts: [],
            isLeaf: true,
            center: '104.052236,30.691359'
          },
          {
            adcode: '510184',
            name: '崇州市',
            districts: [],
            isLeaf: true,
            center: '103.673025,30.630183'
          },
          {
            adcode: '510181',
            name: '都江堰市',
            districts: [],
            isLeaf: true,
            center: '103.647193,30.988763'
          },
          {
            adcode: '510107',
            name: '武侯区',
            districts: [],
            isLeaf: true,
            center: '104.043246,30.641849'
          },
          {
            adcode: '510121',
            name: '金堂县',
            districts: [],
            isLeaf: true,
            center: '104.411871,30.86203'
          },
          {
            adcode: '510129',
            name: '大邑县',
            districts: [],
            isLeaf: true,
            center: '103.51226,30.573004'
          },
          {
            adcode: '510118',
            name: '新津区',
            districts: [],
            isLeaf: true,
            center: '103.810906,30.410404'
          },
          {
            adcode: '510108',
            name: '成华区',
            districts: [],
            isLeaf: true,
            center: '104.101452,30.659966'
          },
          {
            adcode: '510115',
            name: '温江区',
            districts: [],
            isLeaf: true,
            center: '103.856423,30.681956'
          },
          {
            adcode: '510117',
            name: '郫都区',
            districts: [],
            isLeaf: true,
            center: '103.900486,30.795113'
          },
          {
            adcode: '510183',
            name: '邛崃市',
            districts: [],
            isLeaf: true,
            center: '103.464176,30.41029'
          },
          {
            adcode: '510112',
            name: '龙泉驿区',
            districts: [],
            isLeaf: true,
            center: '104.27536,30.556808'
          },
          {
            adcode: '510113',
            name: '青白江区',
            districts: [],
            isLeaf: true,
            center: '104.251342,30.8786'
          },
          {
            adcode: '510114',
            name: '新都区',
            districts: [],
            isLeaf: true,
            center: '104.158593,30.823568'
          },
          {
            adcode: '510104',
            name: '锦江区',
            districts: [],
            isLeaf: true,
            center: '104.117262,30.598726'
          }
        ]
      },
      {
        adcode: '511600',
        name: '广安市',
        districts: [
          {
            adcode: '511623',
            name: '邻水县',
            districts: [],
            isLeaf: true,
            center: '106.930242,30.334911'
          },
          {
            adcode: '511602',
            name: '广安区',
            districts: [],
            isLeaf: true,
            center: '106.641628,30.473943'
          },
          {
            adcode: '511681',
            name: '华蓥市',
            districts: [],
            isLeaf: true,
            center: '106.783151,30.390439'
          },
          {
            adcode: '511603',
            name: '前锋区',
            districts: [],
            isLeaf: true,
            center: '106.886039,30.496059'
          },
          {
            adcode: '511621',
            name: '岳池县',
            districts: [],
            isLeaf: true,
            center: '106.440056,30.538656'
          },
          {
            adcode: '511622',
            name: '武胜县',
            districts: [],
            isLeaf: true,
            center: '106.295945,30.349493'
          }
        ]
      },
      {
        adcode: '511700',
        name: '达州市',
        districts: [
          {
            adcode: '511724',
            name: '大竹县',
            districts: [],
            isLeaf: true,
            center: '107.204702,30.736222'
          },
          {
            adcode: '511725',
            name: '渠县',
            districts: [],
            isLeaf: true,
            center: '106.973114,30.836504'
          },
          {
            adcode: '511723',
            name: '开江县',
            districts: [],
            isLeaf: true,
            center: '107.868609,31.082945'
          },
          {
            adcode: '511702',
            name: '通川区',
            districts: [],
            isLeaf: true,
            center: '107.505053,31.214665'
          },
          {
            adcode: '511703',
            name: '达川区',
            districts: [],
            isLeaf: true,
            center: '107.511736,31.196573'
          },
          {
            adcode: '511722',
            name: '宣汉县',
            districts: [],
            isLeaf: true,
            center: '107.727198,31.353845'
          },
          {
            adcode: '511781',
            name: '万源市',
            districts: [],
            isLeaf: true,
            center: '108.034393,32.081698'
          }
        ]
      },
      {
        adcode: '510900',
        name: '遂宁市',
        districts: [
          {
            adcode: '510923',
            name: '大英县',
            districts: [],
            isLeaf: true,
            center: '105.236852,30.594472'
          },
          {
            adcode: '510981',
            name: '射洪市',
            districts: [],
            isLeaf: true,
            center: '105.388366,30.871079'
          },
          {
            adcode: '510921',
            name: '蓬溪县',
            districts: [],
            isLeaf: true,
            center: '105.707498,30.75763'
          },
          {
            adcode: '510904',
            name: '安居区',
            districts: [],
            isLeaf: true,
            center: '105.45636,30.355091'
          },
          {
            adcode: '510903',
            name: '船山区',
            districts: [],
            isLeaf: true,
            center: '105.568359,30.526201'
          }
        ]
      },
      {
        adcode: '512000',
        name: '资阳市',
        districts: [
          {
            adcode: '512022',
            name: '乐至县',
            districts: [],
            isLeaf: true,
            center: '105.019823,30.275418'
          },
          {
            adcode: '512021',
            name: '安岳县',
            districts: [],
            isLeaf: true,
            center: '105.35551,30.103623'
          },
          {
            adcode: '512002',
            name: '雁江区',
            districts: [],
            isLeaf: true,
            center: '104.676814,30.107778'
          }
        ]
      },
      {
        adcode: '511400',
        name: '眉山市',
        districts: [
          {
            adcode: '511421',
            name: '仁寿县',
            districts: [],
            isLeaf: true,
            center: '104.133931,29.995638'
          },
          {
            adcode: '511424',
            name: '丹棱县',
            districts: [],
            isLeaf: true,
            center: '103.512859,30.014398'
          },
          {
            adcode: '511423',
            name: '洪雅县',
            districts: [],
            isLeaf: true,
            center: '103.365035,29.915828'
          },
          {
            adcode: '511425',
            name: '青神县',
            districts: [],
            isLeaf: true,
            center: '103.846596,29.831302'
          },
          {
            adcode: '511403',
            name: '彭山区',
            districts: [],
            isLeaf: true,
            center: '103.872852,30.193278'
          },
          {
            adcode: '511402',
            name: '东坡区',
            districts: [],
            isLeaf: true,
            center: '103.831868,30.041956'
          }
        ]
      },
      {
        adcode: '511000',
        name: '内江市',
        districts: [
          {
            adcode: '511025',
            name: '资中县',
            districts: [],
            isLeaf: true,
            center: '104.851874,29.763419'
          },
          {
            adcode: '511011',
            name: '东兴区',
            districts: [],
            isLeaf: true,
            center: '105.076107,29.593528'
          },
          {
            adcode: '511024',
            name: '威远县',
            districts: [],
            isLeaf: true,
            center: '104.669032,29.527456'
          },
          {
            adcode: '511083',
            name: '隆昌市',
            districts: [],
            isLeaf: true,
            center: '105.287507,29.339408'
          },
          {
            adcode: '511002',
            name: '市中区',
            districts: [],
            isLeaf: true,
            center: '103.761329,29.555374'
          }
        ]
      },
      {
        adcode: '511100',
        name: '乐山市',
        districts: [
          {
            adcode: '511126',
            name: '夹江县',
            districts: [],
            isLeaf: true,
            center: '103.571313,29.738143'
          },
          {
            adcode: '511124',
            name: '井研县',
            districts: [],
            isLeaf: true,
            center: '104.06968,29.65165'
          },
          {
            adcode: '511111',
            name: '沙湾区',
            districts: [],
            isLeaf: true,
            center: '103.550118,29.412404'
          },
          {
            adcode: '511112',
            name: '五通桥区',
            districts: [],
            isLeaf: true,
            center: '103.818024,29.406837'
          },
          {
            adcode: '511113',
            name: '金口河区',
            districts: [],
            isLeaf: true,
            center: '103.078597,29.244345'
          },
          {
            adcode: '511123',
            name: '犍为县',
            districts: [],
            isLeaf: true,
            center: '103.949231,29.208118'
          },
          {
            adcode: '511132',
            name: '峨边彝族自治县',
            districts: [],
            isLeaf: true,
            center: '103.262048,29.230426'
          },
          {
            adcode: '511129',
            name: '沐川县',
            districts: [],
            isLeaf: true,
            center: '103.902691,28.956394'
          },
          {
            adcode: '511102',
            name: '市中区',
            districts: [],
            isLeaf: true,
            center: '103.761329,29.555374'
          },
          {
            adcode: '511133',
            name: '马边彝族自治县',
            districts: [],
            isLeaf: true,
            center: '103.546347,28.83552'
          },
          {
            adcode: '511181',
            name: '峨眉山市',
            districts: [],
            isLeaf: true,
            center: '103.484557,29.601189'
          }
        ]
      },
      {
        adcode: '510300',
        name: '自贡市',
        districts: [
          {
            adcode: '510321',
            name: '荣县',
            districts: [],
            isLeaf: true,
            center: '104.417438,29.445461'
          },
          {
            adcode: '510304',
            name: '大安区',
            districts: [],
            isLeaf: true,
            center: '104.773982,29.36371'
          },
          {
            adcode: '510322',
            name: '富顺县',
            districts: [],
            isLeaf: true,
            center: '104.975193,29.181779'
          },
          {
            adcode: '510311',
            name: '沿滩区',
            districts: [],
            isLeaf: true,
            center: '104.873826,29.27208'
          },
          {
            adcode: '510302',
            name: '自流井区',
            districts: [],
            isLeaf: true,
            center: '104.777182,29.337295'
          },
          {
            adcode: '510303',
            name: '贡井区',
            districts: [],
            isLeaf: true,
            center: '104.715294,29.345481'
          }
        ]
      },
      {
        adcode: '510500',
        name: '泸州市',
        districts: [
          {
            adcode: '510521',
            name: '泸县',
            districts: [],
            isLeaf: true,
            center: '105.381879,29.1516'
          },
          {
            adcode: '510504',
            name: '龙马潭区',
            districts: [],
            isLeaf: true,
            center: '105.437751,28.913257'
          },
          {
            adcode: '510503',
            name: '纳溪区',
            districts: [],
            isLeaf: true,
            center: '105.371324,28.772888'
          },
          {
            adcode: '510524',
            name: '叙永县',
            districts: [],
            isLeaf: true,
            center: '105.444777,28.155631'
          },
          {
            adcode: '510525',
            name: '古蔺县',
            districts: [],
            isLeaf: true,
            center: '105.812694,28.038763'
          },
          {
            adcode: '510502',
            name: '江阳区',
            districts: [],
            isLeaf: true,
            center: '105.434982,28.87881'
          },
          {
            adcode: '510522',
            name: '合江县',
            districts: [],
            isLeaf: true,
            center: '105.831011,28.811278'
          }
        ]
      },
      {
        adcode: '511500',
        name: '宜宾市',
        districts: [
          {
            adcode: '511523',
            name: '江安县',
            districts: [],
            isLeaf: true,
            center: '105.066982,28.723797'
          },
          {
            adcode: '511525',
            name: '高县',
            districts: [],
            isLeaf: true,
            center: '104.517748,28.436166'
          },
          {
            adcode: '511524',
            name: '长宁县',
            districts: [],
            isLeaf: true,
            center: '104.920643,28.582866'
          },
          {
            adcode: '511526',
            name: '珙县',
            districts: [],
            isLeaf: true,
            center: '104.708986,28.439514'
          },
          {
            adcode: '511503',
            name: '南溪区',
            districts: [],
            isLeaf: true,
            center: '104.969147,28.846366'
          },
          {
            adcode: '511528',
            name: '兴文县',
            districts: [],
            isLeaf: true,
            center: '105.236207,28.303669'
          },
          {
            adcode: '511527',
            name: '筠连县',
            districts: [],
            isLeaf: true,
            center: '104.511159,28.167981'
          },
          {
            adcode: '511502',
            name: '翠屏区',
            districts: [],
            isLeaf: true,
            center: '104.619853,28.766537'
          },
          {
            adcode: '511529',
            name: '屏山县',
            districts: [],
            isLeaf: true,
            center: '104.346138,28.82867'
          },
          {
            adcode: '511504',
            name: '叙州区',
            districts: [],
            isLeaf: true,
            center: '104.533244,28.690068'
          }
        ]
      },
      {
        adcode: '513400',
        name: '凉山彝族自治州',
        districts: [
          {
            adcode: '513435',
            name: '甘洛县',
            districts: [],
            isLeaf: true,
            center: '102.771441,28.959131'
          },
          {
            adcode: '513422',
            name: '木里藏族自治县',
            districts: [],
            isLeaf: true,
            center: '101.280216,27.928736'
          },
          {
            adcode: '513434',
            name: '越西县',
            districts: [],
            isLeaf: true,
            center: '102.507467,28.639874'
          },
          {
            adcode: '513436',
            name: '美姑县',
            districts: [],
            isLeaf: true,
            center: '103.132359,28.328591'
          },
          {
            adcode: '513437',
            name: '雷波县',
            districts: [],
            isLeaf: true,
            center: '103.571705,28.263202'
          },
          {
            adcode: '513433',
            name: '冕宁县',
            districts: [],
            isLeaf: true,
            center: '102.176622,28.550145'
          },
          {
            adcode: '513428',
            name: '普格县',
            districts: [],
            isLeaf: true,
            center: '102.540901,27.376413'
          },
          {
            adcode: '513429',
            name: '布拖县',
            districts: [],
            isLeaf: true,
            center: '102.809157,27.7121'
          },
          {
            adcode: '513423',
            name: '盐源县',
            districts: [],
            isLeaf: true,
            center: '101.509038,27.422593'
          },
          {
            adcode: '513402',
            name: '会理市',
            districts: [],
            isLeaf: true,
            center: '102.244774,26.655047'
          },
          {
            adcode: '513430',
            name: '金阳县',
            districts: [],
            isLeaf: true,
            center: '103.248786,27.696735'
          },
          {
            adcode: '513424',
            name: '德昌县',
            districts: [],
            isLeaf: true,
            center: '102.175661,27.402832'
          },
          {
            adcode: '513427',
            name: '宁南县',
            districts: [],
            isLeaf: true,
            center: '102.751587,27.061431'
          },
          {
            adcode: '513401',
            name: '西昌市',
            districts: [],
            isLeaf: true,
            center: '102.264166,27.89441'
          },
          {
            adcode: '513431',
            name: '昭觉县',
            districts: [],
            isLeaf: true,
            center: '102.839849,28.015301'
          },
          {
            adcode: '513426',
            name: '会东县',
            districts: [],
            isLeaf: true,
            center: '102.577979,26.634744'
          },
          {
            adcode: '513432',
            name: '喜德县',
            districts: [],
            isLeaf: true,
            center: '102.412451,28.306429'
          }
        ]
      },
      {
        adcode: '510400',
        name: '攀枝花市',
        districts: [
          {
            adcode: '510421',
            name: '米易县',
            districts: [],
            isLeaf: true,
            center: '102.111073,26.896372'
          },
          {
            adcode: '510422',
            name: '盐边县',
            districts: [],
            isLeaf: true,
            center: '101.854027,26.682611'
          },
          {
            adcode: '510411',
            name: '仁和区',
            districts: [],
            isLeaf: true,
            center: '101.738615,26.497796'
          },
          {
            adcode: '510402',
            name: '东区',
            districts: [],
            isLeaf: true,
            center: '101.704129,26.546484'
          },
          {
            adcode: '510403',
            name: '西区',
            districts: [],
            isLeaf: true,
            center: '101.630557,26.5977'
          }
        ]
      },
      {
        adcode: '513300',
        name: '甘孜藏族自治州',
        districts: [
          {
            adcode: '513332',
            name: '石渠县',
            districts: [],
            isLeaf: true,
            center: '98.102938,32.978728'
          },
          {
            adcode: '513328',
            name: '甘孜县',
            districts: [],
            isLeaf: true,
            center: '99.991683,31.622731'
          },
          {
            adcode: '513330',
            name: '德格县',
            districts: [],
            isLeaf: true,
            center: '98.580914,31.806118'
          },
          {
            adcode: '513327',
            name: '炉霍县',
            districts: [],
            isLeaf: true,
            center: '100.676014,31.390657'
          },
          {
            adcode: '513326',
            name: '道孚县',
            districts: [],
            isLeaf: true,
            center: '101.125157,30.979486'
          },
          {
            adcode: '513329',
            name: '新龙县',
            districts: [],
            isLeaf: true,
            center: '100.311569,30.93917'
          },
          {
            adcode: '513331',
            name: '白玉县',
            districts: [],
            isLeaf: true,
            center: '98.824025,31.209669'
          },
          {
            adcode: '513334',
            name: '理塘县',
            districts: [],
            isLeaf: true,
            center: '100.269145,29.994228'
          },
          {
            adcode: '513301',
            name: '康定市',
            districts: [],
            isLeaf: true,
            center: '101.95687,29.998544'
          },
          {
            adcode: '513335',
            name: '巴塘县',
            districts: [],
            isLeaf: true,
            center: '99.110555,30.00533'
          },
          {
            adcode: '513325',
            name: '雅江县',
            districts: [],
            isLeaf: true,
            center: '101.014366,30.031498'
          },
          {
            adcode: '513337',
            name: '稻城县',
            districts: [],
            isLeaf: true,
            center: '100.297369,29.03791'
          },
          {
            adcode: '513336',
            name: '乡城县',
            districts: [],
            isLeaf: true,
            center: '99.798116,28.931081'
          },
          {
            adcode: '513324',
            name: '九龙县',
            districts: [],
            isLeaf: true,
            center: '101.507294,29.000347'
          },
          {
            adcode: '513338',
            name: '得荣县',
            districts: [],
            isLeaf: true,
            center: '99.286366,28.713085'
          },
          {
            adcode: '513323',
            name: '丹巴县',
            districts: [],
            isLeaf: true,
            center: '101.89054,30.878618'
          },
          {
            adcode: '513333',
            name: '色达县',
            districts: [],
            isLeaf: true,
            center: '100.333011,32.268541'
          },
          {
            adcode: '513322',
            name: '泸定县',
            districts: [],
            isLeaf: true,
            center: '102.234814,29.914103'
          }
        ]
      },
      {
        adcode: '513200',
        name: '阿坝藏族羌族自治州',
        districts: [
          {
            adcode: '513225',
            name: '九寨沟县',
            districts: [],
            isLeaf: true,
            center: '104.2426,33.252008'
          },
          {
            adcode: '513231',
            name: '阿坝县',
            districts: [],
            isLeaf: true,
            center: '101.706548,32.902387'
          },
          {
            adcode: '513233',
            name: '红原县',
            districts: [],
            isLeaf: true,
            center: '102.544395,32.790841'
          },
          {
            adcode: '513228',
            name: '黑水县',
            districts: [],
            isLeaf: true,
            center: '102.987592,32.069268'
          },
          {
            adcode: '513201',
            name: '马尔康市',
            districts: [],
            isLeaf: true,
            center: '102.206386,31.905799'
          },
          {
            adcode: '513226',
            name: '金川县',
            districts: [],
            isLeaf: true,
            center: '102.063821,31.47625'
          },
          {
            adcode: '513222',
            name: '理县',
            districts: [],
            isLeaf: true,
            center: '103.163284,31.435831'
          },
          {
            adcode: '513224',
            name: '松潘县',
            districts: [],
            isLeaf: true,
            center: '103.60428,32.655685'
          },
          {
            adcode: '513227',
            name: '小金县',
            districts: [],
            isLeaf: true,
            center: '102.362984,30.995823'
          },
          {
            adcode: '513230',
            name: '壤塘县',
            districts: [],
            isLeaf: true,
            center: '100.978526,32.265796'
          },
          {
            adcode: '513221',
            name: '汶川县',
            districts: [],
            isLeaf: true,
            center: '103.590185,31.476875'
          },
          {
            adcode: '513223',
            name: '茂县',
            districts: [],
            isLeaf: true,
            center: '103.85332,31.681727'
          },
          {
            adcode: '513232',
            name: '若尔盖县',
            districts: [],
            isLeaf: true,
            center: '102.96738,33.578503'
          }
        ]
      },
      {
        adcode: '511800',
        name: '雅安市',
        districts: [
          {
            adcode: '511827',
            name: '宝兴县',
            districts: [],
            isLeaf: true,
            center: '102.814156,30.382154'
          },
          {
            adcode: '511802',
            name: '雨城区',
            districts: [],
            isLeaf: true,
            center: '103.032868,30.005718'
          },
          {
            adcode: '511822',
            name: '荥经县',
            districts: [],
            isLeaf: true,
            center: '102.846537,29.793254'
          },
          {
            adcode: '511824',
            name: '石棉县',
            districts: [],
            isLeaf: true,
            center: '102.358364,29.230963'
          },
          {
            adcode: '511803',
            name: '名山区',
            districts: [],
            isLeaf: true,
            center: '103.109238,30.069837'
          },
          {
            adcode: '511823',
            name: '汉源县',
            districts: [],
            isLeaf: true,
            center: '102.650417,29.344275'
          },
          {
            adcode: '511826',
            name: '芦山县',
            districts: [],
            isLeaf: true,
            center: '102.933618,30.145077'
          },
          {
            adcode: '511825',
            name: '天全县',
            districts: [],
            isLeaf: true,
            center: '102.771745,30.05728'
          }
        ]
      }
    ]
  },
  {
    adcode: '120000',
    name: '天津市',
    districts: [
      {
        adcode: '120100',
        name: '天津城区',
        districts: [
          {
            adcode: '120115',
            name: '宝坻区',
            districts: [],
            isLeaf: true,
            center: '117.309748,39.717054'
          },
          {
            adcode: '120105',
            name: '河北区',
            districts: [],
            isLeaf: true,
            center: '117.196874,39.148018'
          },
          {
            adcode: '120118',
            name: '静海区',
            districts: [],
            isLeaf: true,
            center: '116.975474,38.947772'
          },
          {
            adcode: '120119',
            name: '蓟州区',
            districts: [],
            isLeaf: true,
            center: '117.408432,40.046544'
          },
          {
            adcode: '120117',
            name: '宁河区',
            districts: [],
            isLeaf: true,
            center: '117.826674,39.329749'
          },
          {
            adcode: '120112',
            name: '津南区',
            districts: [],
            isLeaf: true,
            center: '117.356683,38.936971'
          },
          {
            adcode: '120101',
            name: '和平区',
            districts: [],
            isLeaf: true,
            center: '117.214713,39.116884'
          },
          {
            adcode: '120113',
            name: '北辰区',
            districts: [],
            isLeaf: true,
            center: '117.135614,39.224638'
          },
          {
            adcode: '120102',
            name: '河东区',
            districts: [],
            isLeaf: true,
            center: '117.251584,39.128294'
          },
          {
            adcode: '120110',
            name: '东丽区',
            districts: [],
            isLeaf: true,
            center: '117.313567,39.086789'
          },
          {
            adcode: '120106',
            name: '红桥区',
            districts: [],
            isLeaf: true,
            center: '117.151566,39.167349'
          },
          {
            adcode: '120116',
            name: '滨海新区',
            districts: [],
            isLeaf: true,
            center: '117.69641,39.017809'
          },
          {
            adcode: '120111',
            name: '西青区',
            districts: [],
            isLeaf: true,
            center: '117.008994,39.141811'
          },
          {
            adcode: '120103',
            name: '河西区',
            districts: [],
            isLeaf: true,
            center: '117.223379,39.109679'
          },
          {
            adcode: '120104',
            name: '南开区',
            districts: [],
            isLeaf: true,
            center: '117.150638,39.138551'
          },
          {
            adcode: '120114',
            name: '武清区',
            districts: [],
            isLeaf: true,
            center: '117.04456,39.384108'
          }
        ]
      }
    ]
  },
  {
    adcode: '360000',
    name: '江西省',
    districts: [
      {
        adcode: '360500',
        name: '新余市',
        districts: [
          {
            adcode: '360502',
            name: '渝水区',
            districts: [],
            isLeaf: true,
            center: '114.944499,27.799526'
          },
          {
            adcode: '360521',
            name: '分宜县',
            districts: [],
            isLeaf: true,
            center: '114.69264,27.814723'
          }
        ]
      },
      {
        adcode: '360100',
        name: '南昌市',
        districts: [
          {
            adcode: '360103',
            name: '西湖区',
            districts: [],
            isLeaf: true,
            center: '115.876998,28.657005'
          },
          {
            adcode: '360123',
            name: '安义县',
            districts: [],
            isLeaf: true,
            center: '115.549158,28.845585'
          },
          {
            adcode: '360104',
            name: '青云谱区',
            districts: [],
            isLeaf: true,
            center: '115.925709,28.62182'
          },
          {
            adcode: '360111',
            name: '青山湖区',
            districts: [],
            isLeaf: true,
            center: '115.962138,28.682867'
          },
          {
            adcode: '360121',
            name: '南昌县',
            districts: [],
            isLeaf: true,
            center: '115.93349,28.557921'
          },
          {
            adcode: '360113',
            name: '红谷滩区',
            districts: [],
            isLeaf: true,
            center: '115.858127,28.698188'
          },
          {
            adcode: '360124',
            name: '进贤县',
            districts: [],
            isLeaf: true,
            center: '116.242468,28.377627'
          },
          {
            adcode: '360112',
            name: '新建区',
            districts: [],
            isLeaf: true,
            center: '115.815244,28.693192'
          },
          {
            adcode: '360102',
            name: '东湖区',
            districts: [],
            isLeaf: true,
            center: '115.903576,28.698787'
          }
        ]
      },
      {
        adcode: '360400',
        name: '九江市',
        districts: [
          {
            adcode: '360404',
            name: '柴桑区',
            districts: [],
            isLeaf: true,
            center: '115.911005,29.608775'
          },
          {
            adcode: '360483',
            name: '庐山市',
            districts: [],
            isLeaf: true,
            center: '116.045118,29.448225'
          },
          {
            adcode: '360402',
            name: '濂溪区',
            districts: [],
            isLeaf: true,
            center: '115.992735,29.668008'
          },
          {
            adcode: '360425',
            name: '永修县',
            districts: [],
            isLeaf: true,
            center: '115.83207,29.011392'
          },
          {
            adcode: '360428',
            name: '都昌县',
            districts: [],
            isLeaf: true,
            center: '116.203999,29.273803'
          },
          {
            adcode: '360482',
            name: '共青城市',
            districts: [],
            isLeaf: true,
            center: '115.784269,29.235082'
          },
          {
            adcode: '360426',
            name: '德安县',
            districts: [],
            isLeaf: true,
            center: '115.767114,29.298599'
          },
          {
            adcode: '360481',
            name: '瑞昌市',
            districts: [],
            isLeaf: true,
            center: '115.681221,29.676026'
          },
          {
            adcode: '360430',
            name: '彭泽县',
            districts: [],
            isLeaf: true,
            center: '116.563858,29.877404'
          },
          {
            adcode: '360429',
            name: '湖口县',
            districts: [],
            isLeaf: true,
            center: '116.252206,29.731194'
          },
          {
            adcode: '360403',
            name: '浔阳区',
            districts: [],
            isLeaf: true,
            center: '116.001677,29.70547'
          },
          {
            adcode: '360423',
            name: '武宁县',
            districts: [],
            isLeaf: true,
            center: '115.092649,29.246785'
          },
          {
            adcode: '360424',
            name: '修水县',
            districts: [],
            isLeaf: true,
            center: '114.546536,29.026166'
          }
        ]
      },
      {
        adcode: '360200',
        name: '景德镇市',
        districts: [
          {
            adcode: '360222',
            name: '浮梁县',
            districts: [],
            isLeaf: true,
            center: '117.214984,29.352493'
          },
          {
            adcode: '360203',
            name: '珠山区',
            districts: [],
            isLeaf: true,
            center: '117.271365,29.305191'
          },
          {
            adcode: '360202',
            name: '昌江区',
            districts: [],
            isLeaf: true,
            center: '117.182648,29.273683'
          },
          {
            adcode: '360281',
            name: '乐平市',
            districts: [],
            isLeaf: true,
            center: '117.151499,28.978521'
          }
        ]
      },
      {
        adcode: '360600',
        name: '鹰潭市',
        districts: [
          {
            adcode: '360602',
            name: '月湖区',
            districts: [],
            isLeaf: true,
            center: '117.10247,28.266999'
          },
          {
            adcode: '360603',
            name: '余江区',
            districts: [],
            isLeaf: true,
            center: '116.854576,28.199657'
          },
          {
            adcode: '360681',
            name: '贵溪市',
            districts: [],
            isLeaf: true,
            center: '117.245178,28.292397'
          }
        ]
      },
      {
        adcode: '360800',
        name: '吉安市',
        districts: [
          {
            adcode: '360802',
            name: '吉州区',
            districts: [],
            isLeaf: true,
            center: '114.994991,27.144204'
          },
          {
            adcode: '360822',
            name: '吉水县',
            districts: [],
            isLeaf: true,
            center: '115.135963,27.229697'
          },
          {
            adcode: '360830',
            name: '永新县',
            districts: [],
            isLeaf: true,
            center: '114.242425,26.945157'
          },
          {
            adcode: '360825',
            name: '永丰县',
            districts: [],
            isLeaf: true,
            center: '115.421599,27.317301'
          },
          {
            adcode: '360803',
            name: '青原区',
            districts: [],
            isLeaf: true,
            center: '115.014836,27.082012'
          },
          {
            adcode: '360828',
            name: '万安县',
            districts: [],
            isLeaf: true,
            center: '114.759746,26.456821'
          },
          {
            adcode: '360829',
            name: '安福县',
            districts: [],
            isLeaf: true,
            center: '114.619818,27.393286'
          },
          {
            adcode: '360823',
            name: '峡江县',
            districts: [],
            isLeaf: true,
            center: '115.316567,27.5829'
          },
          {
            adcode: '360881',
            name: '井冈山市',
            districts: [],
            isLeaf: true,
            center: '114.289563,26.748132'
          },
          {
            adcode: '360824',
            name: '新干县',
            districts: [],
            isLeaf: true,
            center: '115.386853,27.740368'
          },
          {
            adcode: '360821',
            name: '吉安县',
            districts: [],
            isLeaf: true,
            center: '114.908337,27.039866'
          },
          {
            adcode: '360826',
            name: '泰和县',
            districts: [],
            isLeaf: true,
            center: '114.922987,26.801687'
          },
          {
            adcode: '360827',
            name: '遂川县',
            districts: [],
            isLeaf: true,
            center: '114.520825,26.313413'
          }
        ]
      },
      {
        adcode: '360700',
        name: '赣州市',
        districts: [
          {
            adcode: '360730',
            name: '宁都县',
            districts: [],
            isLeaf: true,
            center: '116.009171,26.470865'
          },
          {
            adcode: '360781',
            name: '瑞金市',
            districts: [],
            isLeaf: true,
            center: '116.027114,25.88623'
          },
          {
            adcode: '360735',
            name: '石城县',
            districts: [],
            isLeaf: true,
            center: '116.347611,26.31491'
          },
          {
            adcode: '360732',
            name: '兴国县',
            districts: [],
            isLeaf: true,
            center: '115.363475,26.338129'
          },
          {
            adcode: '360731',
            name: '于都县',
            districts: [],
            isLeaf: true,
            center: '115.415145,25.952564'
          },
          {
            adcode: '360734',
            name: '寻乌县',
            districts: [],
            isLeaf: true,
            center: '115.638534,24.969513'
          },
          {
            adcode: '360725',
            name: '崇义县',
            districts: [],
            isLeaf: true,
            center: '114.308135,25.682278'
          },
          {
            adcode: '360729',
            name: '全南县',
            districts: [],
            isLeaf: true,
            center: '114.530148,24.742353'
          },
          {
            adcode: '360783',
            name: '龙南市',
            districts: [],
            isLeaf: true,
            center: '114.804474,24.901216'
          },
          {
            adcode: '360733',
            name: '会昌县',
            districts: [],
            isLeaf: true,
            center: '115.809271,25.58734'
          },
          {
            adcode: '360726',
            name: '安远县',
            districts: [],
            isLeaf: true,
            center: '115.393992,25.136855'
          },
          {
            adcode: '360722',
            name: '信丰县',
            districts: [],
            isLeaf: true,
            center: '114.922485,25.38676'
          },
          {
            adcode: '360704',
            name: '赣县区',
            districts: [],
            isLeaf: true,
            center: '115.021196,25.854496'
          },
          {
            adcode: '360728',
            name: '定南县',
            districts: [],
            isLeaf: true,
            center: '115.027908,24.783372'
          },
          {
            adcode: '360724',
            name: '上犹县',
            districts: [],
            isLeaf: true,
            center: '114.55177,25.784754'
          },
          {
            adcode: '360702',
            name: '章贡区',
            districts: [],
            isLeaf: true,
            center: '114.920426,25.818247'
          },
          {
            adcode: '360723',
            name: '大余县',
            districts: [],
            isLeaf: true,
            center: '114.362306,25.401968'
          },
          {
            adcode: '360703',
            name: '南康区',
            districts: [],
            isLeaf: true,
            center: '114.765044,25.661369'
          }
        ]
      },
      {
        adcode: '360900',
        name: '宜春市',
        districts: [
          {
            adcode: '360981',
            name: '丰城市',
            districts: [],
            isLeaf: true,
            center: '115.771131,28.160053'
          },
          {
            adcode: '360922',
            name: '万载县',
            districts: [],
            isLeaf: true,
            center: '114.445477,28.106201'
          },
          {
            adcode: '360926',
            name: '铜鼓县',
            districts: [],
            isLeaf: true,
            center: '114.354623,28.513291'
          },
          {
            adcode: '360902',
            name: '袁州区',
            districts: [],
            isLeaf: true,
            center: '114.42794,27.796344'
          },
          {
            adcode: '360921',
            name: '奉新县',
            districts: [],
            isLeaf: true,
            center: '115.400624,28.688254'
          },
          {
            adcode: '360983',
            name: '高安市',
            districts: [],
            isLeaf: true,
            center: '115.366863,28.448054'
          },
          {
            adcode: '360982',
            name: '樟树市',
            districts: [],
            isLeaf: true,
            center: '115.546174,28.054632'
          },
          {
            adcode: '360925',
            name: '靖安县',
            districts: [],
            isLeaf: true,
            center: '115.362635,28.861296'
          },
          {
            adcode: '360924',
            name: '宜丰县',
            districts: [],
            isLeaf: true,
            center: '114.803036,28.394466'
          },
          {
            adcode: '360923',
            name: '上高县',
            districts: [],
            isLeaf: true,
            center: '114.948045,28.238591'
          }
        ]
      },
      {
        adcode: '360300',
        name: '萍乡市',
        districts: [
          {
            adcode: '360302',
            name: '安源区',
            districts: [],
            isLeaf: true,
            center: '113.87072,27.615'
          },
          {
            adcode: '360321',
            name: '莲花县',
            districts: [],
            isLeaf: true,
            center: '113.961589,27.128498'
          },
          {
            adcode: '360313',
            name: '湘东区',
            districts: [],
            isLeaf: true,
            center: '113.733047,27.640075'
          },
          {
            adcode: '360322',
            name: '上栗县',
            districts: [],
            isLeaf: true,
            center: '113.795294,27.880221'
          },
          {
            adcode: '360323',
            name: '芦溪县',
            districts: [],
            isLeaf: true,
            center: '114.029351,27.631273'
          }
        ]
      },
      {
        adcode: '361000',
        name: '抚州市',
        districts: [
          {
            adcode: '361028',
            name: '资溪县',
            districts: [],
            isLeaf: true,
            center: '117.060332,27.706088'
          },
          {
            adcode: '361003',
            name: '东乡区',
            districts: [],
            isLeaf: true,
            center: '116.603159,28.249059'
          },
          {
            adcode: '361024',
            name: '崇仁县',
            districts: [],
            isLeaf: true,
            center: '116.076363,27.754416'
          },
          {
            adcode: '361021',
            name: '南城县',
            districts: [],
            isLeaf: true,
            center: '116.637228,27.570031'
          },
          {
            adcode: '361026',
            name: '宜黄县',
            districts: [],
            isLeaf: true,
            center: '116.236174,27.554744'
          },
          {
            adcode: '361023',
            name: '南丰县',
            districts: [],
            isLeaf: true,
            center: '116.525898,27.21845'
          },
          {
            adcode: '361025',
            name: '乐安县',
            districts: [],
            isLeaf: true,
            center: '115.83099,27.428682'
          },
          {
            adcode: '361022',
            name: '黎川县',
            districts: [],
            isLeaf: true,
            center: '116.907446,27.282729'
          },
          {
            adcode: '361030',
            name: '广昌县',
            districts: [],
            isLeaf: true,
            center: '116.336556,26.843974'
          },
          {
            adcode: '361027',
            name: '金溪县',
            districts: [],
            isLeaf: true,
            center: '116.755018,27.91973'
          },
          {
            adcode: '361002',
            name: '临川区',
            districts: [],
            isLeaf: true,
            center: '116.311932,27.935098'
          }
        ]
      },
      {
        adcode: '361100',
        name: '上饶市',
        districts: [
          {
            adcode: '361125',
            name: '横峰县',
            districts: [],
            isLeaf: true,
            center: '117.596433,28.407116'
          },
          {
            adcode: '361130',
            name: '婺源县',
            districts: [],
            isLeaf: true,
            center: '117.861532,29.248491'
          },
          {
            adcode: '361123',
            name: '玉山县',
            districts: [],
            isLeaf: true,
            center: '118.244736,28.681936'
          },
          {
            adcode: '361127',
            name: '余干县',
            districts: [],
            isLeaf: true,
            center: '116.695787,28.70283'
          },
          {
            adcode: '361129',
            name: '万年县',
            districts: [],
            isLeaf: true,
            center: '117.058445,28.694582'
          },
          {
            adcode: '361181',
            name: '德兴市',
            districts: [],
            isLeaf: true,
            center: '117.594549,28.930557'
          },
          {
            adcode: '361124',
            name: '铅山县',
            districts: [],
            isLeaf: true,
            center: '117.734742,28.298415'
          },
          {
            adcode: '361103',
            name: '广丰区',
            districts: [],
            isLeaf: true,
            center: '118.189729,28.463697'
          },
          {
            adcode: '361126',
            name: '弋阳县',
            districts: [],
            isLeaf: true,
            center: '117.449346,28.378039'
          },
          {
            adcode: '361104',
            name: '广信区',
            districts: [],
            isLeaf: true,
            center: '117.907454,28.448893'
          },
          {
            adcode: '361102',
            name: '信州区',
            districts: [],
            isLeaf: true,
            center: '117.965997,28.430694'
          },
          {
            adcode: '361128',
            name: '鄱阳县',
            districts: [],
            isLeaf: true,
            center: '116.703731,29.005675'
          }
        ]
      }
    ]
  },
  {
    adcode: '140000',
    name: '山西省',
    districts: [
      {
        adcode: '140300',
        name: '阳泉市',
        districts: [
          {
            adcode: '140321',
            name: '平定县',
            districts: [],
            isLeaf: true,
            center: '113.629777,37.804986'
          },
          {
            adcode: '140322',
            name: '盂县',
            districts: [],
            isLeaf: true,
            center: '113.412279,38.086041'
          },
          {
            adcode: '140303',
            name: '矿区',
            districts: [],
            isLeaf: true,
            center: '113.555279,37.868494'
          },
          {
            adcode: '140311',
            name: '郊区',
            districts: [],
            isLeaf: true,
            center: '113.593998,37.943703'
          },
          {
            adcode: '140302',
            name: '城区',
            districts: [],
            isLeaf: true,
            center: '112.853452,35.50248'
          }
        ]
      },
      {
        adcode: '140400',
        name: '长治市',
        districts: [
          {
            adcode: '140425',
            name: '平顺县',
            districts: [],
            isLeaf: true,
            center: '113.435859,36.20037'
          },
          {
            adcode: '140430',
            name: '沁县',
            districts: [],
            isLeaf: true,
            center: '112.699176,36.756152'
          },
          {
            adcode: '140429',
            name: '武乡县',
            districts: [],
            isLeaf: true,
            center: '112.86396,36.837865'
          },
          {
            adcode: '140431',
            name: '沁源县',
            districts: [],
            isLeaf: true,
            center: '112.337726,36.499989'
          },
          {
            adcode: '140428',
            name: '长子县',
            districts: [],
            isLeaf: true,
            center: '112.877922,36.122258'
          },
          {
            adcode: '140405',
            name: '屯留区',
            districts: [],
            isLeaf: true,
            center: '112.892301,36.3162'
          },
          {
            adcode: '140423',
            name: '襄垣县',
            districts: [],
            isLeaf: true,
            center: '113.051298,36.535629'
          },
          {
            adcode: '140426',
            name: '黎城县',
            districts: [],
            isLeaf: true,
            center: '113.387025,36.502258'
          },
          {
            adcode: '140406',
            name: '潞城区',
            districts: [],
            isLeaf: true,
            center: '113.228897,36.334597'
          },
          {
            adcode: '140404',
            name: '上党区',
            districts: [],
            isLeaf: true,
            center: '113.051416,36.053104'
          },
          {
            adcode: '140403',
            name: '潞州区',
            districts: [],
            isLeaf: true,
            center: '113.122725,36.203305'
          },
          {
            adcode: '140427',
            name: '壶关县',
            districts: [],
            isLeaf: true,
            center: '113.206836,36.115645'
          }
        ]
      },
      {
        adcode: '140600',
        name: '朔州市',
        districts: [
          {
            adcode: '140623',
            name: '右玉县',
            districts: [],
            isLeaf: true,
            center: '112.466926,39.989198'
          },
          {
            adcode: '140622',
            name: '应县',
            districts: [],
            isLeaf: true,
            center: '113.190952,39.554471'
          },
          {
            adcode: '140681',
            name: '怀仁市',
            districts: [],
            isLeaf: true,
            center: '113.133061,39.820522'
          },
          {
            adcode: '140602',
            name: '朔城区',
            districts: [],
            isLeaf: true,
            center: '112.432071,39.320196'
          },
          {
            adcode: '140621',
            name: '山阴县',
            districts: [],
            isLeaf: true,
            center: '112.816421,39.527445'
          },
          {
            adcode: '140603',
            name: '平鲁区',
            districts: [],
            isLeaf: true,
            center: '112.28827,39.512219'
          }
        ]
      },
      {
        adcode: '140500',
        name: '晋城市',
        districts: [
          {
            adcode: '140502',
            name: '城区',
            districts: [],
            isLeaf: true,
            center: '112.853452,35.50248'
          },
          {
            adcode: '140525',
            name: '泽州县',
            districts: [],
            isLeaf: true,
            center: '112.922243,35.507061'
          },
          {
            adcode: '140581',
            name: '高平市',
            districts: [],
            isLeaf: true,
            center: '112.923798,35.798775'
          },
          {
            adcode: '140524',
            name: '陵川县',
            districts: [],
            isLeaf: true,
            center: '113.280755,35.775239'
          },
          {
            adcode: '140522',
            name: '阳城县',
            districts: [],
            isLeaf: true,
            center: '112.414798,35.486275'
          },
          {
            adcode: '140521',
            name: '沁水县',
            districts: [],
            isLeaf: true,
            center: '112.186726,35.690119'
          }
        ]
      },
      {
        adcode: '140900',
        name: '忻州市',
        districts: [
          {
            adcode: '140928',
            name: '五寨县',
            districts: [],
            isLeaf: true,
            center: '111.846808,38.91111'
          },
          {
            adcode: '140925',
            name: '宁武县',
            districts: [],
            isLeaf: true,
            center: '112.304734,39.001498'
          },
          {
            adcode: '140926',
            name: '静乐县',
            districts: [],
            isLeaf: true,
            center: '111.939498,38.359306'
          },
          {
            adcode: '140922',
            name: '五台县',
            districts: [],
            isLeaf: true,
            center: '113.255419,38.728056'
          },
          {
            adcode: '140929',
            name: '岢岚县',
            districts: [],
            isLeaf: true,
            center: '111.572964,38.703989'
          },
          {
            adcode: '140921',
            name: '定襄县',
            districts: [],
            isLeaf: true,
            center: '112.957234,38.471725'
          },
          {
            adcode: '140902',
            name: '忻府区',
            districts: [],
            isLeaf: true,
            center: '112.746357,38.403498'
          },
          {
            adcode: '140927',
            name: '神池县',
            districts: [],
            isLeaf: true,
            center: '112.210997,39.091079'
          },
          {
            adcode: '140924',
            name: '繁峙县',
            districts: [],
            isLeaf: true,
            center: '113.265464,39.188835'
          },
          {
            adcode: '140931',
            name: '保德县',
            districts: [],
            isLeaf: true,
            center: '111.085944,39.022616'
          },
          {
            adcode: '140930',
            name: '河曲县',
            districts: [],
            isLeaf: true,
            center: '111.138247,39.38452'
          },
          {
            adcode: '140981',
            name: '原平市',
            districts: [],
            isLeaf: true,
            center: '112.711025,38.731412'
          },
          {
            adcode: '140923',
            name: '代县',
            districts: [],
            isLeaf: true,
            center: '112.960102,39.066831'
          },
          {
            adcode: '140932',
            name: '偏关县',
            districts: [],
            isLeaf: true,
            center: '111.508922,39.436917'
          }
        ]
      },
      {
        adcode: '141100',
        name: '吕梁市',
        districts: [
          {
            adcode: '141128',
            name: '方山县',
            districts: [],
            isLeaf: true,
            center: '111.244309,37.895017'
          },
          {
            adcode: '141127',
            name: '岚县',
            districts: [],
            isLeaf: true,
            center: '111.672156,38.279587'
          },
          {
            adcode: '141129',
            name: '中阳县',
            districts: [],
            isLeaf: true,
            center: '111.178841,37.357816'
          },
          {
            adcode: '141130',
            name: '交口县',
            districts: [],
            isLeaf: true,
            center: '111.181241,36.982205'
          },
          {
            adcode: '141126',
            name: '石楼县',
            districts: [],
            isLeaf: true,
            center: '110.834712,36.998461'
          },
          {
            adcode: '141123',
            name: '兴县',
            districts: [],
            isLeaf: true,
            center: '111.127486,38.461818'
          },
          {
            adcode: '141122',
            name: '交城县',
            districts: [],
            isLeaf: true,
            center: '112.155857,37.55169'
          },
          {
            adcode: '141121',
            name: '文水县',
            districts: [],
            isLeaf: true,
            center: '112.028866,37.438101'
          },
          {
            adcode: '141182',
            name: '汾阳市',
            districts: [],
            isLeaf: true,
            center: '111.770834,37.261493'
          },
          {
            adcode: '141125',
            name: '柳林县',
            districts: [],
            isLeaf: true,
            center: '110.888993,37.429816'
          },
          {
            adcode: '141102',
            name: '离石区',
            districts: [],
            isLeaf: true,
            center: '111.150325,37.517797'
          },
          {
            adcode: '141124',
            name: '临县',
            districts: [],
            isLeaf: true,
            center: '110.992712,37.951008'
          },
          {
            adcode: '141181',
            name: '孝义市',
            districts: [],
            isLeaf: true,
            center: '111.778935,37.146051'
          }
        ]
      },
      {
        adcode: '140800',
        name: '运城市',
        districts: [
          {
            adcode: '140823',
            name: '闻喜县',
            districts: [],
            isLeaf: true,
            center: '111.224485,35.356664'
          },
          {
            adcode: '140822',
            name: '万荣县',
            districts: [],
            isLeaf: true,
            center: '110.837957,35.415805'
          },
          {
            adcode: '140829',
            name: '平陆县',
            districts: [],
            isLeaf: true,
            center: '111.193957,34.829595'
          },
          {
            adcode: '140802',
            name: '盐湖区',
            districts: [],
            isLeaf: true,
            center: '110.998135,35.015549'
          },
          {
            adcode: '140821',
            name: '临猗县',
            districts: [],
            isLeaf: true,
            center: '110.774553,35.144406'
          },
          {
            adcode: '140828',
            name: '夏县',
            districts: [],
            isLeaf: true,
            center: '111.219946,35.140733'
          },
          {
            adcode: '140827',
            name: '垣曲县',
            districts: [],
            isLeaf: true,
            center: '111.670215,35.298297'
          },
          {
            adcode: '140824',
            name: '稷山县',
            districts: [],
            isLeaf: true,
            center: '110.983273,35.604515'
          },
          {
            adcode: '140882',
            name: '河津市',
            districts: [],
            isLeaf: true,
            center: '110.712032,35.596357'
          },
          {
            adcode: '140881',
            name: '永济市',
            districts: [],
            isLeaf: true,
            center: '110.447771,34.866499'
          },
          {
            adcode: '140826',
            name: '绛县',
            districts: [],
            isLeaf: true,
            center: '111.568849,35.491764'
          },
          {
            adcode: '140830',
            name: '芮城县',
            districts: [],
            isLeaf: true,
            center: '110.694418,34.694173'
          },
          {
            adcode: '140825',
            name: '新绛县',
            districts: [],
            isLeaf: true,
            center: '111.224767,35.616007'
          }
        ]
      },
      {
        adcode: '141000',
        name: '临汾市',
        districts: [
          {
            adcode: '141030',
            name: '大宁县',
            districts: [],
            isLeaf: true,
            center: '110.752914,36.465126'
          },
          {
            adcode: '141028',
            name: '吉县',
            districts: [],
            isLeaf: true,
            center: '110.680513,36.096833'
          },
          {
            adcode: '141031',
            name: '隰县',
            districts: [],
            isLeaf: true,
            center: '110.940752,36.693345'
          },
          {
            adcode: '141026',
            name: '安泽县',
            districts: [],
            isLeaf: true,
            center: '112.250242,36.147921'
          },
          {
            adcode: '141025',
            name: '古县',
            districts: [],
            isLeaf: true,
            center: '111.92031,36.266767'
          },
          {
            adcode: '141032',
            name: '永和县',
            districts: [],
            isLeaf: true,
            center: '110.631981,36.759524'
          },
          {
            adcode: '141029',
            name: '乡宁县',
            districts: [],
            isLeaf: true,
            center: '110.846585,35.970834'
          },
          {
            adcode: '141033',
            name: '蒲县',
            districts: [],
            isLeaf: true,
            center: '111.096457,36.41183'
          },
          {
            adcode: '141002',
            name: '尧都区',
            districts: [],
            isLeaf: true,
            center: '111.578797,36.082463'
          },
          {
            adcode: '141027',
            name: '浮山县',
            districts: [],
            isLeaf: true,
            center: '111.848883,35.968124'
          },
          {
            adcode: '141021',
            name: '曲沃县',
            districts: [],
            isLeaf: true,
            center: '111.475783,35.641883'
          },
          {
            adcode: '141034',
            name: '汾西县',
            districts: [],
            isLeaf: true,
            center: '111.563993,36.652833'
          },
          {
            adcode: '141082',
            name: '霍州市',
            districts: [],
            isLeaf: true,
            center: '111.755111,36.569046'
          },
          {
            adcode: '141024',
            name: '洪洞县',
            districts: [],
            isLeaf: true,
            center: '111.67571,36.254087'
          },
          {
            adcode: '141022',
            name: '翼城县',
            districts: [],
            isLeaf: true,
            center: '111.719048,35.739706'
          },
          {
            adcode: '141081',
            name: '侯马市',
            districts: [],
            isLeaf: true,
            center: '111.372002,35.619105'
          },
          {
            adcode: '141023',
            name: '襄汾县',
            districts: [],
            isLeaf: true,
            center: '111.441725,35.876293'
          }
        ]
      },
      {
        adcode: '140200',
        name: '大同市',
        districts: [
          {
            adcode: '140225',
            name: '浑源县',
            districts: [],
            isLeaf: true,
            center: '113.69183,39.706485'
          },
          {
            adcode: '140223',
            name: '广灵县',
            districts: [],
            isLeaf: true,
            center: '114.282703,39.760404'
          },
          {
            adcode: '140221',
            name: '阳高县',
            districts: [],
            isLeaf: true,
            center: '113.748944,40.361059'
          },
          {
            adcode: '140215',
            name: '云州区',
            districts: [],
            isLeaf: true,
            center: '113.612496,40.040156'
          },
          {
            adcode: '140226',
            name: '左云县',
            districts: [],
            isLeaf: true,
            center: '112.703008,40.013442'
          },
          {
            adcode: '140224',
            name: '灵丘县',
            districts: [],
            isLeaf: true,
            center: '114.234452,39.442459'
          },
          {
            adcode: '140214',
            name: '云冈区',
            districts: [],
            isLeaf: true,
            center: '113.149604,40.005383'
          },
          {
            adcode: '140222',
            name: '天镇县',
            districts: [],
            isLeaf: true,
            center: '114.090871,40.42087'
          },
          {
            adcode: '140212',
            name: '新荣区',
            districts: [],
            isLeaf: true,
            center: '113.139628,40.256355'
          },
          {
            adcode: '140213',
            name: '平城区',
            districts: [],
            isLeaf: true,
            center: '113.300179,40.076991'
          }
        ]
      },
      {
        adcode: '140700',
        name: '晋中市',
        districts: [
          {
            adcode: '140724',
            name: '昔阳县',
            districts: [],
            isLeaf: true,
            center: '113.707151,37.612577'
          },
          {
            adcode: '140723',
            name: '和顺县',
            districts: [],
            isLeaf: true,
            center: '113.570295,37.329696'
          },
          {
            adcode: '140725',
            name: '寿阳县',
            districts: [],
            isLeaf: true,
            center: '113.176373,37.895191'
          },
          {
            adcode: '140722',
            name: '左权县',
            districts: [],
            isLeaf: true,
            center: '113.379412,37.082746'
          },
          {
            adcode: '140721',
            name: '榆社县',
            districts: [],
            isLeaf: true,
            center: '112.975378,37.071209'
          },
          {
            adcode: '140729',
            name: '灵石县',
            districts: [],
            isLeaf: true,
            center: '111.778692,36.848085'
          },
          {
            adcode: '140727',
            name: '祁县',
            districts: [],
            isLeaf: true,
            center: '112.335226,37.358425'
          },
          {
            adcode: '140728',
            name: '平遥县',
            districts: [],
            isLeaf: true,
            center: '112.175825,37.189614'
          },
          {
            adcode: '140703',
            name: '太谷区',
            districts: [],
            isLeaf: true,
            center: '112.551297,37.421392'
          },
          {
            adcode: '140781',
            name: '介休市',
            districts: [],
            isLeaf: true,
            center: '111.916451,37.027538'
          },
          {
            adcode: '140702',
            name: '榆次区',
            districts: [],
            isLeaf: true,
            center: '112.707564,37.698637'
          }
        ]
      },
      {
        adcode: '140100',
        name: '太原市',
        districts: [
          {
            adcode: '140123',
            name: '娄烦县',
            districts: [],
            isLeaf: true,
            center: '111.79715,38.067569'
          },
          {
            adcode: '140122',
            name: '阳曲县',
            districts: [],
            isLeaf: true,
            center: '112.67292,38.058511'
          },
          {
            adcode: '140181',
            name: '古交市',
            districts: [],
            isLeaf: true,
            center: '112.175034,37.907414'
          },
          {
            adcode: '140121',
            name: '清徐县',
            districts: [],
            isLeaf: true,
            center: '112.359367,37.608751'
          },
          {
            adcode: '140108',
            name: '尖草坪区',
            districts: [],
            isLeaf: true,
            center: '112.486141,37.940052'
          },
          {
            adcode: '140107',
            name: '杏花岭区',
            districts: [],
            isLeaf: true,
            center: '112.570412,37.894241'
          },
          {
            adcode: '140109',
            name: '万柏林区',
            districts: [],
            isLeaf: true,
            center: '112.515638,37.859738'
          },
          {
            adcode: '140110',
            name: '晋源区',
            districts: [],
            isLeaf: true,
            center: '112.477869,37.715519'
          },
          {
            adcode: '140105',
            name: '小店区',
            districts: [],
            isLeaf: true,
            center: '112.565524,37.736865'
          },
          {
            adcode: '140106',
            name: '迎泽区',
            districts: [],
            isLeaf: true,
            center: '112.563373,37.863308'
          }
        ]
      }
    ]
  },
  {
    adcode: '110000',
    name: '北京市',
    districts: [
      {
        adcode: '110100',
        name: '北京城区',
        districts: [
          {
            adcode: '110116',
            name: '怀柔区',
            districts: [],
            isLeaf: true,
            center: '116.631974,40.317003'
          },
          {
            adcode: '110109',
            name: '门头沟区',
            districts: [],
            isLeaf: true,
            center: '116.101668,39.940842'
          },
          {
            adcode: '110117',
            name: '平谷区',
            districts: [],
            isLeaf: true,
            center: '117.121589,40.140805'
          },
          {
            adcode: '110118',
            name: '密云区',
            districts: [],
            isLeaf: true,
            center: '116.843351,40.377058'
          },
          {
            adcode: '110107',
            name: '石景山区',
            districts: [],
            isLeaf: true,
            center: '116.223015,39.906304'
          },
          {
            adcode: '110102',
            name: '西城区',
            districts: [],
            isLeaf: true,
            center: '116.36585,39.9126'
          },
          {
            adcode: '110115',
            name: '大兴区',
            districts: [],
            isLeaf: true,
            center: '116.341483,39.726917'
          },
          {
            adcode: '110101',
            name: '东城区',
            districts: [],
            isLeaf: true,
            center: '116.416334,39.928359'
          },
          {
            adcode: '110108',
            name: '海淀区',
            districts: [],
            isLeaf: true,
            center: '116.2977,39.959893'
          },
          {
            adcode: '110113',
            name: '顺义区',
            districts: [],
            isLeaf: true,
            center: '116.661474,40.149891'
          },
          {
            adcode: '110114',
            name: '昌平区',
            districts: [],
            isLeaf: true,
            center: '116.231034,40.220952'
          },
          {
            adcode: '110105',
            name: '朝阳区',
            districts: [],
            isLeaf: true,
            center: '116.443136,39.921444'
          },
          {
            adcode: '110119',
            name: '延庆区',
            districts: [],
            isLeaf: true,
            center: '115.974609,40.457033'
          },
          {
            adcode: '110112',
            name: '通州区',
            districts: [],
            isLeaf: true,
            center: '116.72923,39.916403'
          },
          {
            adcode: '110106',
            name: '丰台区',
            districts: [],
            isLeaf: true,
            center: '116.286726,39.858538'
          },
          {
            adcode: '110111',
            name: '房山区',
            districts: [],
            isLeaf: true,
            center: '116.143426,39.748889'
          }
        ]
      }
    ]
  },
  {
    adcode: '540000',
    name: '西藏自治区',
    districts: [
      {
        adcode: '540300',
        name: '昌都市',
        districts: [
          {
            adcode: '540321',
            name: '江达县',
            districts: [],
            isLeaf: true,
            center: '98.218336,31.499176'
          },
          {
            adcode: '540324',
            name: '丁青县',
            districts: [],
            isLeaf: true,
            center: '95.619868,31.409024'
          },
          {
            adcode: '540323',
            name: '类乌齐县',
            districts: [],
            isLeaf: true,
            center: '96.600246,31.211601'
          },
          {
            adcode: '540302',
            name: '卡若区',
            districts: [],
            isLeaf: true,
            center: '97.196021,31.112087'
          },
          {
            adcode: '540329',
            name: '洛隆县',
            districts: [],
            isLeaf: true,
            center: '95.825169,30.741988'
          },
          {
            adcode: '540330',
            name: '边坝县',
            districts: [],
            isLeaf: true,
            center: '94.7078,30.933652'
          },
          {
            adcode: '540322',
            name: '贡觉县',
            districts: [],
            isLeaf: true,
            center: '98.27097,30.860099'
          },
          {
            adcode: '540325',
            name: '察雅县',
            districts: [],
            isLeaf: true,
            center: '97.568752,30.653943'
          },
          {
            adcode: '540326',
            name: '八宿县',
            districts: [],
            isLeaf: true,
            center: '96.917836,30.053209'
          },
          {
            adcode: '540327',
            name: '左贡县',
            districts: [],
            isLeaf: true,
            center: '97.841176,29.671488'
          },
          {
            adcode: '540328',
            name: '芒康县',
            districts: [],
            isLeaf: true,
            center: '98.593493,29.679957'
          }
        ]
      },
      {
        adcode: '540600',
        name: '那曲市',
        districts: [
          {
            adcode: '540624',
            name: '安多县',
            districts: [],
            isLeaf: true,
            center: '91.68195,32.265016'
          },
          {
            adcode: '540623',
            name: '聂荣县',
            districts: [],
            isLeaf: true,
            center: '92.303377,32.10775'
          },
          {
            adcode: '540628',
            name: '巴青县',
            districts: [],
            isLeaf: true,
            center: '94.053438,31.91847'
          },
          {
            adcode: '540625',
            name: '申扎县',
            districts: [],
            isLeaf: true,
            center: '88.709936,30.93045'
          },
          {
            adcode: '540627',
            name: '班戈县',
            districts: [],
            isLeaf: true,
            center: '90.009822,31.392157'
          },
          {
            adcode: '540622',
            name: '比如县',
            districts: [],
            isLeaf: true,
            center: '93.681302,31.477914'
          },
          {
            adcode: '540626',
            name: '索县',
            districts: [],
            isLeaf: true,
            center: '93.785068,31.888045'
          },
          {
            adcode: '540602',
            name: '色尼区',
            districts: [],
            isLeaf: true,
            center: '92.053498,31.469645'
          },
          {
            adcode: '540621',
            name: '嘉黎县',
            districts: [],
            isLeaf: true,
            center: '93.232347,30.641543'
          },
          {
            adcode: '540629',
            name: '尼玛县',
            districts: [],
            isLeaf: true,
            center: '87.236772,31.784701'
          },
          {
            adcode: '540630',
            name: '双湖县',
            districts: [],
            isLeaf: true,
            center: '88.838017,33.188355'
          }
        ]
      },
      {
        adcode: '540100',
        name: '拉萨市',
        districts: [
          {
            adcode: '540122',
            name: '当雄县',
            districts: [],
            isLeaf: true,
            center: '91.101186,30.473134'
          },
          {
            adcode: '540121',
            name: '林周县',
            districts: [],
            isLeaf: true,
            center: '91.265287,29.893545'
          },
          {
            adcode: '540127',
            name: '墨竹工卡县',
            districts: [],
            isLeaf: true,
            center: '91.739134,29.850344'
          },
          {
            adcode: '540104',
            name: '达孜区',
            districts: [],
            isLeaf: true,
            center: '91.349503,29.670173'
          },
          {
            adcode: '540102',
            name: '城关区',
            districts: [],
            isLeaf: true,
            center: '91.140368,29.654813'
          },
          {
            adcode: '540103',
            name: '堆龙德庆区',
            districts: [],
            isLeaf: true,
            center: '91.002866,29.646815'
          },
          {
            adcode: '540123',
            name: '尼木县',
            districts: [],
            isLeaf: true,
            center: '90.16446,29.43202'
          },
          {
            adcode: '540124',
            name: '曲水县',
            districts: [],
            isLeaf: true,
            center: '90.743355,29.354293'
          }
        ]
      },
      {
        adcode: '540200',
        name: '日喀则市',
        districts: [
          {
            adcode: '540232',
            name: '仲巴县',
            districts: [],
            isLeaf: true,
            center: '84.03153,29.770279'
          },
          {
            adcode: '540226',
            name: '昂仁县',
            districts: [],
            isLeaf: true,
            center: '87.227739,29.299444'
          },
          {
            adcode: '540227',
            name: '谢通门县',
            districts: [],
            isLeaf: true,
            center: '88.255784,29.425455'
          },
          {
            adcode: '540221',
            name: '南木林县',
            districts: [],
            isLeaf: true,
            center: '89.099243,29.682331'
          },
          {
            adcode: '540236',
            name: '萨嘎县',
            districts: [],
            isLeaf: true,
            center: '85.232941,29.328818'
          },
          {
            adcode: '540202',
            name: '桑珠孜区',
            districts: [],
            isLeaf: true,
            center: '88.898503,29.247726'
          },
          {
            adcode: '540225',
            name: '拉孜县',
            districts: [],
            isLeaf: true,
            center: '87.636934,29.08188'
          },
          {
            adcode: '540229',
            name: '仁布县',
            districts: [],
            isLeaf: true,
            center: '89.841983,29.230933'
          },
          {
            adcode: '540224',
            name: '萨迦县',
            districts: [],
            isLeaf: true,
            center: '88.021674,28.899664'
          },
          {
            adcode: '540234',
            name: '吉隆县',
            districts: [],
            isLeaf: true,
            center: '85.298109,28.856542'
          },
          {
            adcode: '540228',
            name: '白朗县',
            districts: [],
            isLeaf: true,
            center: '89.261977,29.107688'
          },
          {
            adcode: '540222',
            name: '江孜县',
            districts: [],
            isLeaf: true,
            center: '89.605654,28.91186'
          },
          {
            adcode: '540235',
            name: '聂拉木县',
            districts: [],
            isLeaf: true,
            center: '85.982237,28.155186'
          },
          {
            adcode: '540223',
            name: '定日县',
            districts: [],
            isLeaf: true,
            center: '87.12612,28.658743'
          },
          {
            adcode: '540230',
            name: '康马县',
            districts: [],
            isLeaf: true,
            center: '89.681663,28.555627'
          },
          {
            adcode: '540237',
            name: '岗巴县',
            districts: [],
            isLeaf: true,
            center: '88.515318,28.271189'
          },
          {
            adcode: '540233',
            name: '亚东县',
            districts: [],
            isLeaf: true,
            center: '88.907136,27.486094'
          },
          {
            adcode: '540231',
            name: '定结县',
            districts: [],
            isLeaf: true,
            center: '87.765872,28.364159'
          }
        ]
      },
      {
        adcode: '540500',
        name: '山南市',
        districts: [
          {
            adcode: '540523',
            name: '桑日县',
            districts: [],
            isLeaf: true,
            center: '92.017723,29.259439'
          },
          {
            adcode: '540528',
            name: '加查县',
            districts: [],
            isLeaf: true,
            center: '92.592701,29.140355'
          },
          {
            adcode: '540522',
            name: '贡嘎县',
            districts: [],
            isLeaf: true,
            center: '90.978425,29.290169'
          },
          {
            adcode: '540502',
            name: '乃东区',
            districts: [],
            isLeaf: true,
            center: '91.761538,29.224904'
          },
          {
            adcode: '540521',
            name: '扎囊县',
            districts: [],
            isLeaf: true,
            center: '91.33725,29.245113'
          },
          {
            adcode: '540526',
            name: '措美县',
            districts: [],
            isLeaf: true,
            center: '91.433509,28.438202'
          },
          {
            adcode: '540524',
            name: '琼结县',
            districts: [],
            isLeaf: true,
            center: '91.684307,29.024492'
          },
          {
            adcode: '540531',
            name: '浪卡子县',
            districts: [],
            isLeaf: true,
            center: '90.400251,28.96693'
          },
          {
            adcode: '540525',
            name: '曲松县',
            districts: [],
            isLeaf: true,
            center: '92.203738,29.062826'
          },
          {
            adcode: '540527',
            name: '洛扎县',
            districts: [],
            isLeaf: true,
            center: '90.859971,28.385811'
          },
          {
            adcode: '540529',
            name: '隆子县',
            districts: [],
            isLeaf: true,
            center: '92.461774,28.40743'
          },
          {
            adcode: '540530',
            name: '错那县',
            districts: [],
            isLeaf: true
          }
        ]
      },
      {
        adcode: '540400',
        name: '林芝市',
        districts: [
          {
            adcode: '540421',
            name: '工布江达县',
            districts: [],
            isLeaf: true,
            center: '93.246077,29.88528'
          },
          {
            adcode: '540424',
            name: '波密县',
            districts: [],
            isLeaf: true,
            center: '95.768439,29.859751'
          },
          {
            adcode: '540423',
            name: '墨脱县',
            districts: [],
            isLeaf: true,
            center: '95.332108,29.325425'
          },
          {
            adcode: '540402',
            name: '巴宜区',
            districts: [],
            isLeaf: true,
            center: '94.361094,29.636576'
          },
          {
            adcode: '540422',
            name: '米林县',
            districts: [],
            isLeaf: true
          },
          {
            adcode: '540426',
            name: '朗县',
            districts: [],
            isLeaf: true,
            center: '93.074702,29.046337'
          },
          {
            adcode: '540425',
            name: '察隅县',
            districts: [],
            isLeaf: true,
            center: '97.466919,28.66128'
          }
        ]
      },
      {
        adcode: '542500',
        name: '阿里地区',
        districts: [
          {
            adcode: '542526',
            name: '改则县',
            districts: [],
            isLeaf: true,
            center: '84.062612,32.303148'
          },
          {
            adcode: '542522',
            name: '札达县',
            districts: [],
            isLeaf: true,
            center: '79.807096,31.479882'
          },
          {
            adcode: '542527',
            name: '措勤县',
            districts: [],
            isLeaf: true,
            center: '85.151881,31.017272'
          },
          {
            adcode: '542521',
            name: '普兰县',
            districts: [],
            isLeaf: true,
            center: '81.177116,30.290467'
          },
          {
            adcode: '542524',
            name: '日土县',
            districts: [],
            isLeaf: true,
            center: '79.733552,33.382017'
          },
          {
            adcode: '542523',
            name: '噶尔县',
            districts: [],
            isLeaf: true,
            center: '80.096591,32.492078'
          },
          {
            adcode: '542525',
            name: '革吉县',
            districts: [],
            isLeaf: true,
            center: '81.145433,32.387233'
          }
        ]
      }
    ]
  },
  {
    adcode: '220000',
    name: '吉林省',
    districts: [
      {
        adcode: '220200',
        name: '吉林市',
        districts: [
          {
            adcode: '220283',
            name: '舒兰市',
            districts: [],
            isLeaf: true,
            center: '126.965515,44.405922'
          },
          {
            adcode: '220282',
            name: '桦甸市',
            districts: [],
            isLeaf: true,
            center: '126.746245,42.972272'
          },
          {
            adcode: '220281',
            name: '蛟河市',
            districts: [],
            isLeaf: true,
            center: '127.344773,43.72382'
          },
          {
            adcode: '220221',
            name: '永吉县',
            districts: [],
            isLeaf: true,
            center: '126.497899,43.672198'
          },
          {
            adcode: '220284',
            name: '磐石市',
            districts: [],
            isLeaf: true,
            center: '126.060328,42.946608'
          },
          {
            adcode: '220203',
            name: '龙潭区',
            districts: [],
            isLeaf: true,
            center: '126.562131,43.910743'
          },
          {
            adcode: '220211',
            name: '丰满区',
            districts: [],
            isLeaf: true,
            center: '126.561351,43.821192'
          },
          {
            adcode: '220202',
            name: '昌邑区',
            districts: [],
            isLeaf: true,
            center: '126.574414,43.881896'
          },
          {
            adcode: '220204',
            name: '船营区',
            districts: [],
            isLeaf: true,
            center: '126.540909,43.833806'
          }
        ]
      },
      {
        adcode: '220100',
        name: '长春市',
        districts: [
          {
            adcode: '220183',
            name: '德惠市',
            districts: [],
            isLeaf: true,
            center: '125.728552,44.521788'
          },
          {
            adcode: '220182',
            name: '榆树市',
            districts: [],
            isLeaf: true,
            center: '126.533126,44.840263'
          },
          {
            adcode: '220112',
            name: '双阳区',
            districts: [],
            isLeaf: true,
            center: '125.664583,43.525367'
          },
          {
            adcode: '220122',
            name: '农安县',
            districts: [],
            isLeaf: true,
            center: '125.184921,44.432829'
          },
          {
            adcode: '220113',
            name: '九台区',
            districts: [],
            isLeaf: true,
            center: '125.839549,44.151672'
          },
          {
            adcode: '220105',
            name: '二道区',
            districts: [],
            isLeaf: true,
            center: '125.37419,43.865912'
          },
          {
            adcode: '220103',
            name: '宽城区',
            districts: [],
            isLeaf: true,
            center: '125.326614,43.943522'
          },
          {
            adcode: '220102',
            name: '南关区',
            districts: [],
            isLeaf: true,
            center: '125.350341,43.86382'
          },
          {
            adcode: '220184',
            name: '公主岭市',
            districts: [],
            isLeaf: true,
            center: '124.79379,43.495116'
          },
          {
            adcode: '220106',
            name: '绿园区',
            districts: [],
            isLeaf: true,
            center: '125.256214,43.881047'
          },
          {
            adcode: '220104',
            name: '朝阳区',
            districts: [],
            isLeaf: true,
            center: '116.443136,39.921444'
          }
        ]
      },
      {
        adcode: '220800',
        name: '白城市',
        districts: [
          {
            adcode: '220881',
            name: '洮南市',
            districts: [],
            isLeaf: true,
            center: '122.79845,45.356849'
          },
          {
            adcode: '220882',
            name: '大安市',
            districts: [],
            isLeaf: true,
            center: '124.291032,45.507004'
          },
          {
            adcode: '220822',
            name: '通榆县',
            districts: [],
            isLeaf: true,
            center: '123.088093,44.812912'
          },
          {
            adcode: '220802',
            name: '洮北区',
            districts: [],
            isLeaf: true,
            center: '122.851105,45.621545'
          },
          {
            adcode: '220821',
            name: '镇赉县',
            districts: [],
            isLeaf: true,
            center: '123.19967,45.848195'
          }
        ]
      },
      {
        adcode: '220400',
        name: '辽源市',
        districts: [
          {
            adcode: '220403',
            name: '西安区',
            districts: [],
            isLeaf: true,
            center: '125.149488,42.927252'
          },
          {
            adcode: '220421',
            name: '东丰县',
            districts: [],
            isLeaf: true,
            center: '125.53096,42.677253'
          },
          {
            adcode: '220402',
            name: '龙山区',
            districts: [],
            isLeaf: true,
            center: '125.13703,42.902194'
          },
          {
            adcode: '220422',
            name: '东辽县',
            districts: [],
            isLeaf: true,
            center: '124.991045,42.925636'
          }
        ]
      },
      {
        adcode: '220500',
        name: '通化市',
        districts: [
          {
            adcode: '220502',
            name: '东昌区',
            districts: [],
            isLeaf: true,
            center: '125.927187,41.702872'
          },
          {
            adcode: '220503',
            name: '二道江区',
            districts: [],
            isLeaf: true,
            center: '126.042546,41.773989'
          },
          {
            adcode: '220521',
            name: '通化县',
            districts: [],
            isLeaf: true,
            center: '125.758927,41.680135'
          },
          {
            adcode: '220524',
            name: '柳河县',
            districts: [],
            isLeaf: true,
            center: '125.744654,42.284594'
          },
          {
            adcode: '220523',
            name: '辉南县',
            districts: [],
            isLeaf: true,
            center: '126.046006,42.684706'
          },
          {
            adcode: '220582',
            name: '集安市',
            districts: [],
            isLeaf: true,
            center: '126.193172,41.125805'
          },
          {
            adcode: '220581',
            name: '梅河口市',
            districts: [],
            isLeaf: true,
            center: '125.711129,42.539183'
          }
        ]
      },
      {
        adcode: '220700',
        name: '松原市',
        districts: [
          {
            adcode: '220721',
            name: '前郭尔罗斯蒙古族自治县',
            districts: [],
            isLeaf: true,
            center: '124.823417,45.118061'
          },
          {
            adcode: '220723',
            name: '乾安县',
            districts: [],
            isLeaf: true,
            center: '124.040651,45.003539'
          },
          {
            adcode: '220722',
            name: '长岭县',
            districts: [],
            isLeaf: true,
            center: '123.967244,44.276293'
          },
          {
            adcode: '220702',
            name: '宁江区',
            districts: [],
            isLeaf: true,
            center: '124.792839,45.186486'
          },
          {
            adcode: '220781',
            name: '扶余市',
            districts: [],
            isLeaf: true,
            center: '126.049534,44.989057'
          }
        ]
      },
      {
        adcode: '222400',
        name: '延边朝鲜族自治州',
        districts: [
          {
            adcode: '222404',
            name: '珲春市',
            districts: [],
            isLeaf: true,
            center: '130.365829,42.862551'
          },
          {
            adcode: '222402',
            name: '图们市',
            districts: [],
            isLeaf: true,
            center: '129.843807,42.968039'
          },
          {
            adcode: '222406',
            name: '和龙市',
            districts: [],
            isLeaf: true,
            center: '129.010932,42.546434'
          },
          {
            adcode: '222424',
            name: '汪清县',
            districts: [],
            isLeaf: true,
            center: '129.785137,43.317215'
          },
          {
            adcode: '222401',
            name: '延吉市',
            districts: [],
            isLeaf: true,
            center: '129.507655,42.891034'
          },
          {
            adcode: '222426',
            name: '安图县',
            districts: [],
            isLeaf: true,
            center: '128.899744,43.111978'
          },
          {
            adcode: '222403',
            name: '敦化市',
            districts: [],
            isLeaf: true,
            center: '128.232235,43.372669'
          },
          {
            adcode: '222405',
            name: '龙井市',
            districts: [],
            isLeaf: true,
            center: '129.426529,42.767172'
          }
        ]
      },
      {
        adcode: '220300',
        name: '四平市',
        districts: [
          {
            adcode: '220303',
            name: '铁东区',
            districts: [],
            isLeaf: true,
            center: '124.409852,43.162664'
          },
          {
            adcode: '220323',
            name: '伊通满族自治县',
            districts: [],
            isLeaf: true,
            center: '125.305393,43.345754'
          },
          {
            adcode: '220302',
            name: '铁西区',
            districts: [],
            isLeaf: true,
            center: '124.345994,43.146288'
          },
          {
            adcode: '220322',
            name: '梨树县',
            districts: [],
            isLeaf: true,
            center: '124.335389,43.30706'
          },
          {
            adcode: '220382',
            name: '双辽市',
            districts: [],
            isLeaf: true,
            center: '123.502398,43.518301'
          }
        ]
      },
      {
        adcode: '220600',
        name: '白山市',
        districts: [
          {
            adcode: '220622',
            name: '靖宇县',
            districts: [],
            isLeaf: true,
            center: '126.813262,42.388902'
          },
          {
            adcode: '220605',
            name: '江源区',
            districts: [],
            isLeaf: true,
            center: '126.591178,42.056747'
          },
          {
            adcode: '220621',
            name: '抚松县',
            districts: [],
            isLeaf: true,
            center: '127.449387,42.221038'
          },
          {
            adcode: '220602',
            name: '浑江区',
            districts: [],
            isLeaf: true,
            center: '126.416093,41.945409'
          },
          {
            adcode: '220623',
            name: '长白朝鲜族自治县',
            districts: [],
            isLeaf: true,
            center: '128.20066,41.420253'
          },
          {
            adcode: '220681',
            name: '临江市',
            districts: [],
            isLeaf: true,
            center: '126.918086,41.811979'
          }
        ]
      }
    ]
  },
  {
    adcode: '530000',
    name: '云南省',
    districts: [
      {
        adcode: '530600',
        name: '昭通市',
        districts: [
          {
            adcode: '530626',
            name: '绥江县',
            districts: [],
            isLeaf: true,
            center: '103.968995,28.592119'
          },
          {
            adcode: '530681',
            name: '水富市',
            districts: [],
            isLeaf: true,
            center: '104.415964,28.629951'
          },
          {
            adcode: '530625',
            name: '永善县',
            districts: [],
            isLeaf: true,
            center: '103.63756,28.229018'
          },
          {
            adcode: '530624',
            name: '大关县',
            districts: [],
            isLeaf: true,
            center: '103.891164,27.748054'
          },
          {
            adcode: '530629',
            name: '威信县',
            districts: [],
            isLeaf: true,
            center: '105.049012,27.846839'
          },
          {
            adcode: '530622',
            name: '巧家县',
            districts: [],
            isLeaf: true,
            center: '102.935343,26.896904'
          },
          {
            adcode: '530628',
            name: '彝良县',
            districts: [],
            isLeaf: true,
            center: '104.055991,27.624277'
          },
          {
            adcode: '530602',
            name: '昭阳区',
            districts: [],
            isLeaf: true,
            center: '103.706323,27.320035'
          },
          {
            adcode: '530621',
            name: '鲁甸县',
            districts: [],
            isLeaf: true,
            center: '103.557969,27.186668'
          },
          {
            adcode: '530623',
            name: '盐津县',
            districts: [],
            isLeaf: true,
            center: '104.234458,28.108475'
          },
          {
            adcode: '530627',
            name: '镇雄县',
            districts: [],
            isLeaf: true,
            center: '104.873486,27.441527'
          }
        ]
      },
      {
        adcode: '530300',
        name: '曲靖市',
        districts: [
          {
            adcode: '530326',
            name: '会泽县',
            districts: [],
            isLeaf: true,
            center: '103.297155,26.417116'
          },
          {
            adcode: '530304',
            name: '马龙区',
            districts: [],
            isLeaf: true,
            center: '103.578459,25.428102'
          },
          {
            adcode: '530381',
            name: '宣威市',
            districts: [],
            isLeaf: true,
            center: '104.104255,26.218956'
          },
          {
            adcode: '530325',
            name: '富源县',
            districts: [],
            isLeaf: true,
            center: '104.255082,25.674217'
          },
          {
            adcode: '530303',
            name: '沾益区',
            districts: [],
            isLeaf: true,
            center: '103.822104,25.600424'
          },
          {
            adcode: '530323',
            name: '师宗县',
            districts: [],
            isLeaf: true,
            center: '103.985224,24.822471'
          },
          {
            adcode: '530324',
            name: '罗平县',
            districts: [],
            isLeaf: true,
            center: '104.297124,24.865388'
          },
          {
            adcode: '530322',
            name: '陆良县',
            districts: [],
            isLeaf: true,
            center: '103.66671,25.0293'
          },
          {
            adcode: '530302',
            name: '麒麟区',
            districts: [],
            isLeaf: true,
            center: '103.804406,25.496472'
          }
        ]
      },
      {
        adcode: '532500',
        name: '红河哈尼族彝族自治州',
        districts: [
          {
            adcode: '532527',
            name: '泸西县',
            districts: [],
            isLeaf: true,
            center: '103.76615,24.531981'
          },
          {
            adcode: '532504',
            name: '弥勒市',
            districts: [],
            isLeaf: true,
            center: '103.414817,24.411774'
          },
          {
            adcode: '532502',
            name: '开远市',
            districts: [],
            isLeaf: true,
            center: '103.266908,23.714518'
          },
          {
            adcode: '532524',
            name: '建水县',
            districts: [],
            isLeaf: true,
            center: '102.826178,23.635824'
          },
          {
            adcode: '532529',
            name: '红河县',
            districts: [],
            isLeaf: true,
            center: '102.420566,23.368946'
          },
          {
            adcode: '532523',
            name: '屏边苗族自治县',
            districts: [],
            isLeaf: true,
            center: '103.675458,22.986733'
          },
          {
            adcode: '532528',
            name: '元阳县',
            districts: [],
            isLeaf: true,
            center: '102.835358,23.219671'
          },
          {
            adcode: '532531',
            name: '绿春县',
            districts: [],
            isLeaf: true,
            center: '102.392655,22.993654'
          },
          {
            adcode: '532501',
            name: '个旧市',
            districts: [],
            isLeaf: true,
            center: '103.152663,23.389935'
          },
          {
            adcode: '532530',
            name: '金平苗族瑶族傣族自治县',
            districts: [],
            isLeaf: true,
            center: '103.227982,22.769894'
          },
          {
            adcode: '532532',
            name: '河口瑶族自治县',
            districts: [],
            isLeaf: true,
            center: '103.939265,22.529438'
          },
          {
            adcode: '532525',
            name: '石屏县',
            districts: [],
            isLeaf: true,
            center: '102.496138,23.705707'
          },
          {
            adcode: '532503',
            name: '蒙自市',
            districts: [],
            isLeaf: true,
            center: '103.364936,23.396111'
          }
        ]
      },
      {
        adcode: '533300',
        name: '怒江傈僳族自治州',
        districts: [
          {
            adcode: '533324',
            name: '贡山独龙族怒族自治县',
            districts: [],
            isLeaf: true,
            center: '98.666279,27.740839'
          },
          {
            adcode: '533323',
            name: '福贡县',
            districts: [],
            isLeaf: true,
            center: '98.869132,26.901831'
          },
          {
            adcode: '533325',
            name: '兰坪白族普米族自治县',
            districts: [],
            isLeaf: true,
            center: '99.416628,26.453622'
          },
          {
            adcode: '533301',
            name: '泸水市',
            districts: [],
            isLeaf: true,
            center: '98.857723,25.822579'
          }
        ]
      },
      {
        adcode: '532800',
        name: '西双版纳傣族自治州',
        districts: [
          {
            adcode: '532801',
            name: '景洪市',
            districts: [],
            isLeaf: true,
            center: '100.799595,22.011792'
          },
          {
            adcode: '532822',
            name: '勐海县',
            districts: [],
            isLeaf: true,
            center: '100.452444,21.957323'
          },
          {
            adcode: '532823',
            name: '勐腊县',
            districts: [],
            isLeaf: true,
            center: '101.564635,21.459233'
          }
        ]
      },
      {
        adcode: '530400',
        name: '玉溪市',
        districts: [
          {
            adcode: '530425',
            name: '易门县',
            districts: [],
            isLeaf: true,
            center: '102.161947,24.672156'
          },
          {
            adcode: '530424',
            name: '华宁县',
            districts: [],
            isLeaf: true,
            center: '102.928914,24.19322'
          },
          {
            adcode: '530403',
            name: '江川区',
            districts: [],
            isLeaf: true,
            center: '102.748499,24.299441'
          },
          {
            adcode: '530402',
            name: '红塔区',
            districts: [],
            isLeaf: true,
            center: '102.540122,24.341215'
          },
          {
            adcode: '530426',
            name: '峨山彝族自治县',
            districts: [],
            isLeaf: true,
            center: '102.405698,24.168899'
          },
          {
            adcode: '530423',
            name: '通海县',
            districts: [],
            isLeaf: true,
            center: '102.725538,24.11114'
          },
          {
            adcode: '530481',
            name: '澄江市',
            districts: [],
            isLeaf: true,
            center: '102.904181,24.675536'
          },
          {
            adcode: '530428',
            name: '元江哈尼族彝族傣族自治县',
            districts: [],
            isLeaf: true,
            center: '101.998138,23.596068'
          },
          {
            adcode: '530427',
            name: '新平彝族傣族自治县',
            districts: [],
            isLeaf: true,
            center: '101.990805,24.070436'
          }
        ]
      },
      {
        adcode: '530700',
        name: '丽江市',
        districts: [
          {
            adcode: '530724',
            name: '宁蒗彝族自治县',
            districts: [],
            isLeaf: true,
            center: '100.851083,27.282207'
          },
          {
            adcode: '530722',
            name: '永胜县',
            districts: [],
            isLeaf: true,
            center: '100.750907,26.684215'
          },
          {
            adcode: '530723',
            name: '华坪县',
            districts: [],
            isLeaf: true,
            center: '101.265373,26.629598'
          },
          {
            adcode: '530702',
            name: '古城区',
            districts: [],
            isLeaf: true,
            center: '100.22583,26.876468'
          },
          {
            adcode: '530721',
            name: '玉龙纳西族自治县',
            districts: [],
            isLeaf: true,
            center: '100.236967,26.821494'
          }
        ]
      },
      {
        adcode: '530500',
        name: '保山市',
        districts: [
          {
            adcode: '530581',
            name: '腾冲市',
            districts: [],
            isLeaf: true,
            center: '98.490276,25.020283'
          },
          {
            adcode: '530502',
            name: '隆阳区',
            districts: [],
            isLeaf: true,
            center: '99.165638,25.121137'
          },
          {
            adcode: '530524',
            name: '昌宁县',
            districts: [],
            isLeaf: true,
            center: '99.605105,24.827739'
          },
          {
            adcode: '530521',
            name: '施甸县',
            districts: [],
            isLeaf: true,
            center: '99.18919,24.723084'
          },
          {
            adcode: '530523',
            name: '龙陵县',
            districts: [],
            isLeaf: true,
            center: '98.68941,24.586703'
          }
        ]
      },
      {
        adcode: '530900',
        name: '临沧市',
        districts: [
          {
            adcode: '530921',
            name: '凤庆县',
            districts: [],
            isLeaf: true,
            center: '99.92873,24.580559'
          },
          {
            adcode: '530922',
            name: '云县',
            districts: [],
            isLeaf: true,
            center: '100.1303,24.444461'
          },
          {
            adcode: '530923',
            name: '永德县',
            districts: [],
            isLeaf: true,
            center: '99.258702,24.018463'
          },
          {
            adcode: '530902',
            name: '临翔区',
            districts: [],
            isLeaf: true,
            center: '100.082073,23.895298'
          },
          {
            adcode: '530926',
            name: '耿马傣族佤族自治县',
            districts: [],
            isLeaf: true,
            center: '99.397126,23.538092'
          },
          {
            adcode: '530924',
            name: '镇康县',
            districts: [],
            isLeaf: true,
            center: '98.825389,23.762886'
          },
          {
            adcode: '530925',
            name: '双江拉祜族佤族布朗族傣族自治县',
            districts: [],
            isLeaf: true,
            center: '99.828225,23.472719'
          },
          {
            adcode: '530927',
            name: '沧源佤族自治县',
            districts: [],
            isLeaf: true,
            center: '99.245894,23.146758'
          }
        ]
      },
      {
        adcode: '532900',
        name: '大理白族自治州',
        districts: [
          {
            adcode: '532932',
            name: '鹤庆县',
            districts: [],
            isLeaf: true,
            center: '100.176331,26.560122'
          },
          {
            adcode: '532931',
            name: '剑川县',
            districts: [],
            isLeaf: true,
            center: '99.905041,26.536889'
          },
          {
            adcode: '532930',
            name: '洱源县',
            districts: [],
            isLeaf: true,
            center: '99.962294,26.11337'
          },
          {
            adcode: '532929',
            name: '云龙县',
            districts: [],
            isLeaf: true,
            center: '99.371021,25.885733'
          },
          {
            adcode: '532924',
            name: '宾川县',
            districts: [],
            isLeaf: true,
            center: '100.590274,25.830491'
          },
          {
            adcode: '532901',
            name: '大理市',
            districts: [],
            isLeaf: true,
            center: '100.301614,25.678466'
          },
          {
            adcode: '532922',
            name: '漾濞彝族自治县',
            districts: [],
            isLeaf: true,
            center: '99.958089,25.669944'
          },
          {
            adcode: '532928',
            name: '永平县',
            districts: [],
            isLeaf: true,
            center: '99.54063,25.464134'
          },
          {
            adcode: '532927',
            name: '巍山彝族回族自治县',
            districts: [],
            isLeaf: true,
            center: '100.306977,25.227065'
          },
          {
            adcode: '532923',
            name: '祥云县',
            districts: [],
            isLeaf: true,
            center: '100.549961,25.483727'
          },
          {
            adcode: '532926',
            name: '南涧彝族自治县',
            districts: [],
            isLeaf: true,
            center: '100.510333,25.032353'
          },
          {
            adcode: '532925',
            name: '弥渡县',
            districts: [],
            isLeaf: true,
            center: '100.491038,25.343778'
          }
        ]
      },
      {
        adcode: '530800',
        name: '普洱市',
        districts: [
          {
            adcode: '530825',
            name: '镇沅彝族哈尼族拉祜族自治县',
            districts: [],
            isLeaf: true,
            center: '101.108733,24.00445'
          },
          {
            adcode: '530822',
            name: '墨江哈尼族自治县',
            districts: [],
            isLeaf: true,
            center: '101.692461,23.431894'
          },
          {
            adcode: '530824',
            name: '景谷傣族彝族自治县',
            districts: [],
            isLeaf: true,
            center: '100.702807,23.496987'
          },
          {
            adcode: '530821',
            name: '宁洱哈尼族彝族自治县',
            districts: [],
            isLeaf: true,
            center: '101.045743,23.048809'
          },
          {
            adcode: '530828',
            name: '澜沧拉祜族自治县',
            districts: [],
            isLeaf: true,
            center: '99.932045,22.555799'
          },
          {
            adcode: '530802',
            name: '思茅区',
            districts: [],
            isLeaf: true,
            center: '100.977069,22.786769'
          },
          {
            adcode: '530829',
            name: '西盟佤族自治县',
            districts: [],
            isLeaf: true,
            center: '99.59016,22.644237'
          },
          {
            adcode: '530826',
            name: '江城哈尼族彝族自治县',
            districts: [],
            isLeaf: true,
            center: '101.862344,22.585858'
          },
          {
            adcode: '530823',
            name: '景东彝族自治县',
            districts: [],
            isLeaf: true,
            center: '100.833877,24.446731'
          },
          {
            adcode: '530827',
            name: '孟连傣族拉祜族佤族自治县',
            districts: [],
            isLeaf: true,
            center: '99.584225,22.329053'
          }
        ]
      },
      {
        adcode: '533400',
        name: '迪庆藏族自治州',
        districts: [
          {
            adcode: '533401',
            name: '香格里拉市',
            districts: [],
            isLeaf: true,
            center: '99.743582,27.842185'
          },
          {
            adcode: '533422',
            name: '德钦县',
            districts: [],
            isLeaf: true,
            center: '98.917851,28.464213'
          },
          {
            adcode: '533423',
            name: '维西傈僳族自治县',
            districts: [],
            isLeaf: true,
            center: '99.300937,27.163808'
          }
        ]
      },
      {
        adcode: '532300',
        name: '楚雄彝族自治州',
        districts: [
          {
            adcode: '532328',
            name: '元谋县',
            districts: [],
            isLeaf: true,
            center: '101.87434,25.704499'
          },
          {
            adcode: '532326',
            name: '大姚县',
            districts: [],
            isLeaf: true,
            center: '101.336576,25.729551'
          },
          {
            adcode: '532301',
            name: '楚雄市',
            districts: [],
            isLeaf: true,
            center: '101.546242,25.032945'
          },
          {
            adcode: '532323',
            name: '牟定县',
            districts: [],
            isLeaf: true,
            center: '101.546898,25.312939'
          },
          {
            adcode: '532325',
            name: '姚安县',
            districts: [],
            isLeaf: true,
            center: '101.241632,25.504287'
          },
          {
            adcode: '532302',
            name: '禄丰市',
            districts: [],
            isLeaf: true,
            center: '102.079082,25.151061'
          },
          {
            adcode: '532322',
            name: '双柏县',
            districts: [],
            isLeaf: true,
            center: '101.642369,24.688814'
          },
          {
            adcode: '532329',
            name: '武定县',
            districts: [],
            isLeaf: true,
            center: '102.403949,25.530731'
          },
          {
            adcode: '532327',
            name: '永仁县',
            districts: [],
            isLeaf: true,
            center: '101.666795,26.049522'
          },
          {
            adcode: '532324',
            name: '南华县',
            districts: [],
            isLeaf: true,
            center: '101.283236,25.200163'
          }
        ]
      },
      {
        adcode: '530100',
        name: '昆明市',
        districts: [
          {
            adcode: '530113',
            name: '东川区',
            districts: [],
            isLeaf: true,
            center: '103.187825,26.082997'
          },
          {
            adcode: '530129',
            name: '寻甸回族彝族自治县',
            districts: [],
            isLeaf: true,
            center: '103.256559,25.558163'
          },
          {
            adcode: '530125',
            name: '宜良县',
            districts: [],
            isLeaf: true,
            center: '103.141674,24.91983'
          },
          {
            adcode: '530102',
            name: '五华区',
            districts: [],
            isLeaf: true,
            center: '102.707262,25.043635'
          },
          {
            adcode: '530115',
            name: '晋宁区',
            districts: [],
            isLeaf: true,
            center: '102.595325,24.669077'
          },
          {
            adcode: '530126',
            name: '石林彝族自治县',
            districts: [],
            isLeaf: true,
            center: '103.290536,24.771761'
          },
          {
            adcode: '530124',
            name: '富民县',
            districts: [],
            isLeaf: true,
            center: '102.497722,25.221924'
          },
          {
            adcode: '530112',
            name: '西山区',
            districts: [],
            isLeaf: true,
            center: '102.664426,25.038039'
          },
          {
            adcode: '530181',
            name: '安宁市',
            districts: [],
            isLeaf: true,
            center: '102.47865,24.919831'
          },
          {
            adcode: '530127',
            name: '嵩明县',
            districts: [],
            isLeaf: true,
            center: '103.043384,25.327273'
          },
          {
            adcode: '530111',
            name: '官渡区',
            districts: [],
            isLeaf: true,
            center: '102.748888,24.950285'
          },
          {
            adcode: '530114',
            name: '呈贡区',
            districts: [],
            isLeaf: true,
            center: '102.822104,24.885738'
          },
          {
            adcode: '530128',
            name: '禄劝彝族苗族自治县',
            districts: [],
            isLeaf: true,
            center: '102.471993,25.551768'
          },
          {
            adcode: '530103',
            name: '盘龙区',
            districts: [],
            isLeaf: true,
            center: '102.751643,25.116512'
          }
        ]
      },
      {
        adcode: '532600',
        name: '文山壮族苗族自治州',
        districts: [
          {
            adcode: '532627',
            name: '广南县',
            districts: [],
            isLeaf: true,
            center: '105.055075,24.046378'
          },
          {
            adcode: '532626',
            name: '丘北县',
            districts: [],
            isLeaf: true,
            center: '104.166713,24.05064'
          },
          {
            adcode: '532622',
            name: '砚山县',
            districts: [],
            isLeaf: true,
            center: '104.336905,23.605075'
          },
          {
            adcode: '532624',
            name: '麻栗坡县',
            districts: [],
            isLeaf: true,
            center: '104.702732,23.125837'
          },
          {
            adcode: '532601',
            name: '文山市',
            districts: [],
            isLeaf: true,
            center: '104.233237,23.38683'
          },
          {
            adcode: '532623',
            name: '西畴县',
            districts: [],
            isLeaf: true,
            center: '104.671802,23.437707'
          },
          {
            adcode: '532625',
            name: '马关县',
            districts: [],
            isLeaf: true,
            center: '104.394524,23.013108'
          },
          {
            adcode: '532628',
            name: '富宁县',
            districts: [],
            isLeaf: true,
            center: '105.630921,23.625072'
          }
        ]
      },
      {
        adcode: '533100',
        name: '德宏傣族景颇族自治州',
        districts: [
          {
            adcode: '533122',
            name: '梁河县',
            districts: [],
            isLeaf: true,
            center: '98.296584,24.804275'
          },
          {
            adcode: '533103',
            name: '芒市',
            districts: [],
            isLeaf: true,
            center: '98.58809,24.433766'
          },
          {
            adcode: '533102',
            name: '瑞丽市',
            districts: [],
            isLeaf: true,
            center: '97.855423,24.018377'
          },
          {
            adcode: '533123',
            name: '盈江县',
            districts: [],
            isLeaf: true,
            center: '97.943474,24.691325'
          },
          {
            adcode: '533124',
            name: '陇川县',
            districts: [],
            isLeaf: true,
            center: '97.793359,24.182347'
          }
        ]
      }
    ]
  }
];
