import { deleteBusinessLineInfoById, putBusinessLineInfoUpdata, getBusinessLineInfoById, getBusinessLineGetById } from '@/services/DeliveryRouteList/api';
const DeliveryRouteList = {
  namespace: 'DeliveryRouteList',
  state: {
    searchParams: {
      current: 1,
      pageSize: 10,
      data: {}
    },
    total: 0,
    list: []
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async deleteBusinessLineInfoById(payload: any) {
      const { id } = payload;
      return await deleteBusinessLineInfoById(id);
    },
    async putBusinessLineInfoUpdata(payload: any) {
      const { data } = payload;
      return await putBusinessLineInfoUpdata({ data });
    },
    async getBusinessLineInfoById(payload: any) {
      const { id } = payload;
      return await getBusinessLineInfoById(id);
    },
    async getBusinessLineGetById(payload: any) {
      const { id } = payload;
      return await getBusinessLineGetById(id);
    }
  }
};

export default DeliveryRouteList;
