import { API } from '@/const';
import request from '@/utils/request';

export const postVehicleInfoList = async (data: any) =>
  request(`${API.basis}/basisVehicle/page`, {
    method: 'post',
    data
  });

export const deleteVehicleInfoById = async (id: number) =>
  request(`${API.basis}/basisVehicle/deleteById/${id}`, {
    method: 'delete'
  });

export const postVehicleInfoAdd = async (data: any) =>
  request(`${API.basis}/basisVehicle/save`, {
    method: 'post',
    data
  });

export const putVehicleInfoEdit = async (data: any) =>
  request(`${API.basis}/basisVehicle/modify`, {
    method: 'put',
    data
  });

export const putVehicleInfoUpdata = async (data: any) =>
  request(`${API.basis}/basisVehicle/updateById`, {
    method: 'put',
    data
  });
export const postVehicleOne = async (data: any) =>
  request(`${API.basis}/basisVehicle/getOne`, {
    method: 'post',
    data
  });
export const getVehicleById = async (id: string) => {
  return request(`${API.basis}/basisVehicle/getById/${id}`, {
    method: 'get'
  });
};
export const repairVehicleNo = async (data: any) => {
  return request(`${API.basis}/basisVehicle/repairVehicleNo`, {
    method: 'post',
    data
  });
};
