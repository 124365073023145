import { dataSave, getDataList, updateByIds, updateById, dataSaveFright } from '@/services/Wallet/InvoiceManagement';
const InvoiceManagement = {
  state: {
    loading: false,
    dataSource: [],
    total: 0,
    WorkBenchData: {}
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async dataSave(data: any) {
      return await dataSave(data);
    },
    async getDataList({ data }: any) {
      return await getDataList(data);
    },
    async updateByIds({ data }: any) {
      await updateByIds(data);
    },
    async updateById({ data }: any) {
      await updateById(data);
    },
    async dataSaveFright(data: any) {
        return await dataSaveFright(data);
      }

  }
};

export default InvoiceManagement;
