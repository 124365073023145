import { API_ENV } from "./env";

export const ROBOTAPPID = {
  "dev": "d5164db1-4c77-4e19-8f86-3d189dd24546",
  "dev2": "d5164db1-4c77-4e19-8f86-3d189dd24546",
  "test": "d5164db1-4c77-4e19-8f86-3d189dd24546",
  "test2": "d5164db1-4c77-4e19-8f86-3d189dd24546",
  "pre": "d5164db1-4c77-4e19-8f86-3d189dd24546",
  "lyt": "0d005f5e-50d4-4ef5-947e-7f78ed9522e9",

}[API_ENV]
