import { API } from '@/const';
import request from '@/utils/request';

export async function sysDeptGetTree({ data }: any) {
  return request(`${API.upms}/sysDept/getTree`, {
    method: 'post',
    data: {
      data: data || {
        deptId: null
      }
    }
  });
}


export async function sysUserRelationGetUserDeptTree() {
  return request(`${API.upms}/sysUserRelation/getUserDeptTree`, {
    method: 'get'
  });
}

export async function sysDeptSave({ data }: any) {
  return request(`${API.upms}/sysDept/save`, {
    method: 'post',
    data: {
      data
    }
  });
}

export async function sysDeptModify({ data }: any) {
  return request(`${API.upms}/sysDept/updateById`, {
    method: 'put',
    data: {
      data
    }
  });
}

export async function sysDeptDeleteById(id: any) {
  return request(`${API.upms}/sysDept/deleteById/${id}`, {
    method: 'delete'
  });
}

export async function sysUserRelationDeleteById(id: any) {
  return request(`${API.upms}/sysUserRelation/deleteByIds`, {
    method: 'delete',
    data: id
  });
}

export async function sysUserRelationUpdateByIds(data: any) {
  return request(`${API.upms}/sysUserRelation/updateByIds`, {
    method: 'put',
    data: data
  });
}

export async function sysUserRelationSave(data: any) {
  return request(`${API.upms}/sysUserRelation/save`, {
    method: 'post',
    data: data
  });
}


export async function sysgetUserList(deptId: any) {
  return request(`${API.upms}/sysUserRelation/getUserList/${deptId}`, {
    method: 'get'
  });
}
