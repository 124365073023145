import { API } from '@/const';
import request from '@/utils/request';

export const getDataList = async (data: any) => {
  return request(`${API.custom}/customInvoice/page`, {
    method: 'post',
    data
  });
};

export const dataDeleteById = async (id: string) => {
  return request(`${API.custom}/customInvoice/deleteById/${id}`, {
    method: 'delete'
  });
};
export const dataSave = async (data: any) =>
  request(`${API.custom}/customInvoice/save`, {
    method: 'post',
    data
  });

export const updateById = async (data: any) => {
  return request(`${API.custom}/customInvoice/updateById`, {
    method: 'put',
    data
  });
};
