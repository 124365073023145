import { deleteAddressInfoById, putAddressInfoUpdata } from '@/services/AddressList/api';
const AddressList = {
  namespace: 'AddressList',
  state: {},
  reducers: {},
  effects: {
    async deleteAddressInfoById(payload: any) {
      const { id } = payload;
      return await deleteAddressInfoById(id);
    },
    async putAddressInfoUpdata(payload: any) {
      const { data } = payload;
      return await putAddressInfoUpdata({ data });
    }
  }
};

export default AddressList;
