import {
  customAccountDeleteAccount,
  customAccountGetAccountList,
  customAccountSaveAccount,
  getList,
  sysAccountDeleteById,
  sysAccountGetByIds,
  sysAccountSave
} from '@/services/common/api';
import {
  customdeleteById,
  customModify,
  customUpdateById,
  getDataList,
  getSearchList,
  customUpdateByIds,
  walletPaymentAccountPage,
  walletPaymentAccountDeleteById,
  walletPaymentAccountSave
} from '@/services/FormalCustomers/api';

const FormalCustomers = {
  state: {
    dataSourceList: [],
    pageListQuery: {
      data: {
        type: '1'
      },
      pageSize: 10,
      current: 1
    },
    paginationTotal: 0,
    department: []
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getDataListModels(payload: any) {
      const { params } = payload;
      const res: any = await getDataList({
        params
      });
      if ((res.code === 0 && res.data) || res.data === null) {
        return {
          data: res?.data?.content || [],
          success: true,
          total: res?.data?.totalElements || 0
        };
      } else {
        return res;
      }
    },

    async getList({ pid }: any) {
      const res: any = await getList({ pid });
      if (res.code === 0 && res.data) {
        return {
          data: res.data[0].childList
        };
      } else {
        return {
          data: []
        };
      }
    },
    async getSearchListModals(querydata: any) {
      return await getSearchList({ ...querydata });
    },

    async customdeleteByIdModals({ customId }: any) {
      return await customdeleteById(customId);
    },

    async customModifyModals({ data }: any) {
      return await customModify({ data });
    },
    async customUpdateByIdModals({ data }: any) {
      return await customUpdateById({ data });
    },
    async customUpdateByIds(data: any) {
      return await customUpdateByIds(data);
    },
    async sysAccountGetByIdsModals({ contactIds }: any) {
      return contactIds.length ? await sysAccountGetByIds(contactIds) : () => {};
    },
    async sysAccountSaveModals({ data }: any) {
      return await sysAccountSave({ data });
    },
    async sysAccountDeleteByIdModals({ accountId }: any) {
      return await sysAccountDeleteById(accountId);
    },
    async customAccountGetAccountListModals({ customId }: any) {
      return await customAccountGetAccountList(customId);
    },
    async customAccountSaveAccountModals({ data, param }: any) {
      return await customAccountSaveAccount(data, param);
    },
    async customAccountDeleteAccountModals({ customId, accountId }: any) {
      return await customAccountDeleteAccount(customId, accountId);
    },
    async walletPaymentAccountDeleteById({ id }: any) {
      return await walletPaymentAccountDeleteById(id);
    },
    async walletPaymentAccountPage(payload: any) {
      return await walletPaymentAccountPage(payload);
    },
    async walletPaymentAccountSave({ data }: any) {
      return await walletPaymentAccountSave({ data });
    }
  }
};

export default FormalCustomers;
