import {
  basisTycCorpList,
  customModify,
  customSave,
  customFranchiseeLink,
  customFranchiseeLinkSave,
  customFranchiseeLinkDeleteById,
  deleteBusinessOrderLeague,
  businessOrderLeagueSave,
  leagueAccountSave,
  leagueAccountPage,
  deleteLeagueAccount,
  leagueSettleUpdateById,
  leagueSettleFreightUpdateById,
  leagueSettleRecordPage,
  withdrawalApprove,
  leagueWithdrawalFreightUpdateById,
  businessOrderDriverSave,
  businessOrderLeaderSave,
  deleteBusinessOrderDriver,
  deleteBusinessOrderLeader
} from '@/services/AddFormalCustomers/api';
import { customAccountGetAccountList, customAccountSaveAccount, customAccountDeleteAccount, sysAccountSave } from '@/services/common/api';

const AddFranchisee = {
  namespace: 'AddFranchisee',
  state: {},
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async customFranchiseeLink(payload: any) {
      return await customFranchiseeLink(payload);
    },
    async customFranchiseeLinkSave(payload: any) {
      return await customFranchiseeLinkSave(payload);
    },
    async customFranchiseeLinkDeleteById(payload: any) {
      const { id } = payload;
      return await customFranchiseeLinkDeleteById(id);
    },
    async basisTycCorpListModals({ data }: any) {
      return await basisTycCorpList({ ...data });
    },
    async postSave({ data, customId }: any) {
      return customId ? await customModify({ data: { ...data, customId } }) : await customSave(data);
    },
    async deleteBusinessOrderLeague(payload: any) {
      const { id } = payload;
      return await deleteBusinessOrderLeague(id);
    },
    async businessOrderLeagueSave({ data }: any) {
      return await businessOrderLeagueSave(data);
    },
    async leagueAccountSave({ data }: any) {
      return await leagueAccountSave(data);
    },
    async leagueAccountPage(payload: any) {
      return await leagueAccountPage(payload);
    },
    async deleteLeagueAccount(payload: any) {
      const { id } = payload;
      return await deleteLeagueAccount(id);
    },
    async leagueSettleUpdateById({ data }: any) {
      return await leagueSettleUpdateById(data);
    },
    async leagueSettleFreightUpdateById({ data }: any) {
      return await leagueSettleFreightUpdateById(data);
    },
    async leagueSettleRecordPage({ data }: any) {
      return await leagueSettleRecordPage(data);
    },
    async customAccountGetAccountListModals({ customId }: any) {
      return await customAccountGetAccountList(customId);
    },
    async customAccountSaveAccountModals({ data, param }: any) {
      return await customAccountSaveAccount(data, param);
    },
    async customAccountDeleteAccountModals({ customId, accountId }: any) {
      return await customAccountDeleteAccount(customId, accountId);
    },
    async withdrawalApprove({ data }: any) {
      return await withdrawalApprove(data);
    },
    async sysAccountSaveModals({ data }: any) {
      return await sysAccountSave({ data });
    },
    async leagueWithdrawalFreightUpdateById({ data }: any) {
      return await leagueWithdrawalFreightUpdateById({ data });
    },
    async businessOrderDriverSave({ data }: any) {
      return await businessOrderDriverSave(data);
    },
    async businessOrderLeaderSave({ data }: any) {
      return await businessOrderLeaderSave(data);
    },
    async deleteBusinessOrderDriver(payload: any) {
      const { id } = payload;
      return await deleteBusinessOrderDriver(id);
    },
    async deleteBusinessOrderLeader(payload: any) {
      const { id } = payload;
      return await deleteBusinessOrderLeader(id);
    }
  }
};

export default AddFranchisee;
