import { basisTycCorpList, customModify, customSave, getList } from '@/services/AddFormalCustomers/api';
import { message } from 'lieyun_design';

import { basisTysjUserGetOne, getDepartTree, sysAccountList, sysUserGetByIds } from '@/services/common/api';

const AddFormalCustomers = {
  namespace: 'AddFormalCustomers',
  state: {
    formProps: {
      layout: {
        labelCol: {
          span: '6'
        },
        wrapperCol: {
          span: '19'
        }
      }
    },
    personalInfo: {
      title: '个人信息',
      valueType: 'group',
      formItem: [
        [
          {
            valueType: 'select',
            itemProps: {
              name: 'type',
              label: '客户类型'
            },
            placeholder: '请选择客户类型',
            valueName: 'typeId',
            control: {
              options: []
            },
            rules: {
              required: true
            },
            innerStyle: {
              width: '70%'
            }
          },
          {
            valueType: 'text',
            itemProps: {
              name: 'name',
              label: '客户名称'
            },
            rules: {
              required: true,
              min: null,
              max: null
            },
            innerStyle: {
              width: '70%'
            }
          }
        ],
        [
          {
            valueType: 'phone',
            itemProps: {
              name: 'phone',
              label: '手机号'
            },
            rules: {
              required: true
            },
            innerStyle: {
              width: '70%'
            }
          },
          {
            valueType: 'text',
            itemProps: {
              name: 'idCardNo',
              label: '身份证号'
            },
            rules: {
              required: true,
              min: null,
              max: null
            },
            innerStyle: {
              width: '70%'
            }
          }
        ]
      ]
    },
    enterpriseInfo: {
      title: '企业信息',
      valueType: 'group',
      formItem: [
        [
          {
            valueType: 'select',
            itemProps: {
              name: 'type',
              label: '客户类型'
            },
            valueName: 'typeId',
            placeholder: '请选择客户类型',
            control: {
              options: []
            },
            rules: {
              required: true
            },
            innerStyle: {
              width: '70%'
            }
          },
          {
            valueType: 'selectSearch',
            itemProps: {
              name: 'name',
              label: '客户名称'
            },
            placeholder: '请输入客户名称',
            onSearch: (s: any) => {},
            control: {
              options: [],
              maxLength: 50
            },
            rules: {
              required: true,
              min: null,
              max: null
            },
            innerStyle: {
              width: '70%'
            }
          }
        ],
        [
          {
            valueType: 'text',
            itemProps: {
              name: 'uscc',
              label: '统一社会信用代码'
            },
            placeholder: '统一社会信用代码',
            enterButtonText: '校验',
            disabled: true,
            rules: {
              required: true,
              min: null,
              max: null
            },
            innerStyle: {
              width: '70%'
            }
          },
          {
            valueType: 'text',
            itemProps: {
              name: 'legalPersonName',
              label: '法人姓名'
            },
            placeholder: '法人姓名',
            disabled: true,
            rules: {
              required: true,
              min: null,
              max: null
            },
            innerStyle: {
              width: '70%'
            }
          },
          {
            valueType: 'text',
            itemProps: {
              name: 'businessScope',
              label: '经营范围'
            },
            placeholder: '经营范围',
            disabled: true,
            innerStyle: {
              width: '70%'
            }
          }
        ]
      ]
    },
    mangegementInfo: {
      title: '管理信息',
      valueType: 'group'
    }
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getList({ pid }: any) {
      const res = await getList({ pid });
      return res;
    },

    async basisTycCorpListModals({ data }: any) {
      const res = await basisTycCorpList({ ...data });
      return res;
    },
    async postSave({ pid, formVal, ContactList, AccountList, pageType, customId, invoiceList }: any) {
      let data;
      if (pid === '2') {
        const res: any = await basisTysjUserGetOne({
          name: formVal.name,
          idcard: formVal.idCardNo
        });
        if (res?.code !== 0 || !res?.data) {
          message.error('姓名和身份证号不匹配！');
          return {
            code: -1,
            msg: '姓名和身份证号不匹配！'
          };
        }
        data = {
          ownUserList: AccountList.map((item: any) => {
            return {
              userId: JSON.parse(item).accountId,
              deptId: JSON.parse(item).deptId
            };
          }),
          ownDeptList: formVal.ownDeptList,
          phone: formVal.phone,
          name: formVal.name,
          certificates: `${formVal.IDCardNo1 || ''},${formVal.IDCardNo2 || ''}`,
          type: pid,
          identity: formVal.type,
          idCardNo: formVal.idCardNo,
          customId,
          invoiceList
        };
      } else {
        data = {
          contactList: ContactList,
          ownUserList: AccountList.map((item: any) => {
            return {
              userId: JSON.parse(item).accountId,
              deptId: JSON.parse(item).deptId
            };
          }),
          ownDeptList: formVal.ownDeptList,
          certificates: formVal.certificates || '',
          legalPersonName: formVal.legalPersonName,
          businessScope: formVal.businessScope,
          name: JSON.parse(formVal.name).name,
          type: pid,
          identity: formVal.type,
          socialCreditCode: formVal.uscc,
          customId,
          invoiceList
        };
      }
      if (pageType === 'edit') {
        return await customModify({ data });
      } else {
        delete data.customId;
        return await customSave(data);
      }
    },
    async getDepartTreeModals() {
      const res = await getDepartTree();
      return res;
    },
    async sysAccountListModals() {
      const res = await sysAccountList();
      return res;
    },

    async customGetByIdsModals({ data }: any) {
      return await sysUserGetByIds({ data });
    }
  }
};

export default AddFormalCustomers;
