export default [
  {
    path: '/workBench/Contract/SigningManagement/AddSigning/1',
    name: '新增签约',
    hideInMenu: true,
    component: () => import('@/views/Contract/SigningManagement/AddSigning')
  },
  {
    path: '/workBench/Contract/SigningManagement/AddSigning/2',
    name: '批量签约',
    hideInMenu: true,
    component: () => import('@/views/Contract/SigningManagement/AddSigning')
  },
  {
    path: '/workBench/AddressManagement/AddressList/AddressListAdd/:type',
    name: '新增地址',
    hideInMenu: true,
    component: () => import('@/views/AddressManagement/AddressList/AddressListEdit')
  },
  {
    path: '/workBench/AddressManagement/AddressList/AddressListEdit/unready',
    name: '编辑地址',
    hideInMenu: true,
    component: () => import('@/views/AddressManagement/AddressList/AddressMaintenance')
  },
  {
    path: '/workBench/AddressManagement/AddressList/AddressListEdit/:type',
    name: '编辑地址',
    hideInMenu: true,
    component: () => import('@/views/AddressManagement/AddressList/AddressListEdit')
  },
  {
    path: '/workBench/DeliveryRoute/DeliveryRouteList/DeliveryRouteAdd/:type',
    name: '新增路线',
    hideInMenu: true,
    component: () => import('@/views/AddressManagement/DeliveryRouteList/DeliveryRouteListEdit')
  },
  {
    path: '/workBench/DeliveryRoute/DeliveryRouteList/DeliveryRouteEdit/:type',
    name: '编辑路线',
    hideInMenu: true,
    component: () => import('@/views/AddressManagement/DeliveryRouteList/DeliveryRouteListEdit')
  },
  {
    path: '/workBench/WaybillManage/WaybillTemplateList/WaybillTemplateListAdd',
    name: '添加模板',
    hideInMenu: true,
    component: () => import('@/views/WaybillTemplateList/WaybillTemplateListEdit')
  },
  {
    path: '/workBench/WaybillManage/WaybillTemplateList/WaybillTemplateListEdit',
    name: '编辑模板',
    hideInMenu: true,
    component: () => import('@/views/WaybillTemplateList/WaybillTemplateListEdit')
  },
  {
    path: '/workBench/WaybillManage/WaybillList/WaybillAddOrder',
    name: '立即下单',
    hideInMenu: true,
    component: () => import('@/views/WaybillList/WaybillListEdit')
  },
  {
    path: '/workBench/WaybillManage/WaybillList/WaybillTemplateListEditOrder',
    name: '模板下单',
    hideInMenu: true,
    component: () => import('@/views/WaybillList/WaybillListEdit')
  },
  {
    path: '/workBench/WaybillManage/WaybillList/WaybillListEdit',
    name: '编辑运单',
    hideInMenu: true,
    component: () => import('@/views/WaybillList/WaybillListEdit')
  },
  {
    path: '/workBench/WaybillManage/OrderManagement/OrderManagementAdd',
    name: '添加订单',
    hideInMenu: true,
    component: () => import('@/views/OrderManagement/OrderManagementEdit')
  },
  {
    path: '/workBench/WaybillManage/OrderManagement/OrderManagementEdit',
    name: '编辑订单',
    hideInMenu: true,
    component: () => import('@/views/OrderManagement/OrderManagementEdit')
  },
  {
    path: '/workBench/WaybillManage/OrderManagement/OrderManagementMore',
    name: '再来一单',
    hideInMenu: true,
    component: () => import('@/views/OrderManagement/OrderManagementEdit')
  },
  {
    path: '/workBench/CustomerManagement/ExperienceCustomer/AddExperienceCustomer/:pageType/:type',
    name: '新增客户',
    component: () => import('@/views/CustomerManagement/ExperienceCustomer/AddExperienceCustomer')
  },
  {
    path: '/workBench/CustomerManagement/ExperienceCustomer/EditExperienceCustomer/:pageType/:type',
    name: '编辑客户',
    component: () => import('@/views/CustomerManagement/ExperienceCustomer/AddExperienceCustomer')
  },
  {
    path: '/workBench/CustomerManagement/FormalCustomers/AddFormalCustomers/:pageType/:type',
    name: '新增客户',
    component: () => import('@/views/CustomerManagement/FormalCustomers/AddFormalCustomers')
  },
  {
    path: '/workBench/CustomerManagement/FormalCustomers/EditFormalCustomers/:pageType/:type',
    name: '编辑客户',
    component: () => import('@/views/CustomerManagement/FormalCustomers/AddFormalCustomers')
  },
  {
    path: '/workBench/CustomerManagement/ExperienceCustomer/ChangeExperienceCustomer/:pageType/:type',
    name: '转正式客户',
    component: () => import('@/views/CustomerManagement/ExperienceCustomer/AddExperienceCustomer')
  },
  {
    path: '/workBench/DriverManagement/DriverList/DriverListAdd',
    name: '添加司机',
    component: () => import('@/views/DriverList/DriverListEdit')
  },
  {
    path: '/workBench/DriverManagement/DriverList/DriverListEdit',
    name: '编辑司机',
    component: () => import('@/views/DriverList/DriverListEdit')
  },
  {
    path: '/workBench/KnowledgeManagement/QuestionKnowledgeSearch',
    name: '知识库查询',
    component: () => import('@/views/QuestionKnowledge/search')
  },
  {
    path: '/workBench/KnowledgeManagement/QuestionKnowledgeDetail',
    name: '知识详情',
    component: () => import('@/views/QuestionKnowledge/detail')
  },
  {
    path: '/workBench/VehicleManagment/VehicleList/VehicleListAdd/1',
    name: '添加车辆',
    hideInMenu: true,
    component: () => import('@/views/VehicleManagment/VehicleList/VehicleListEdit')
  },
  {
    path: '/workBench/VehicleManagment/VehicleList/VehicleListEdit/1',
    name: '编辑车辆',
    hideInMenu: true,
    component: () => import('@/views/VehicleManagment/VehicleList/VehicleListEdit')
  },
  {
    path: '/workBench/VehicleManagment/VehicleList/VehicleListAdd/2',
    name: '添加挂车',
    hideInMenu: true,
    component: () => import('@/views/VehicleManagment/VehicleList/VehicleListEdit')
  },
  {
    path: '/workBench/VehicleManagment/VehicleList/VehicleListEdit/2',
    name: '编辑挂车',
    hideInMenu: true,
    component: () => import('@/views/VehicleManagment/VehicleList/VehicleListEdit')
  },
  {
    path: '/workBench/SystemSet/PersonalSet',
    name: '个人设置',
    component: () => import('@/views/SystemSet/PersonalSet')
  }
];
