export const HTTP_STATUS = {
  SUCCESS: 200,
  NOT_MODIFIED: 304,
  CLIENT_ERROR: 400,
  AUTHENTICATE: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504
};

export const COMMON_STATUS = {
  CLIENT_ERROR: -40000,
  NOT_FOUND: -40400,
  API_NOT_FOUND: -40401,
  EXCEPTION: -50000,
  PARAM_ERROR: -40001,
  AUTH_FAILED: -40100,
  NO_PERMISION: -40302,
  NET_REQUEST_FAILED: -18939904,
  JWT_FAILED: 10004
};
