import {
  getPageNoRateCustom,
  businessRateSave,
  businessRateModify,
  businessRatedeleteById,
} from '@/services/RateManagement/api';
const RateManagement = {
  state: {
    pageProps: {},
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    },
  },
  effects: {
    async getPageNoRateCustom({ current, name }: any) {
      const res: any = await getPageNoRateCustom({
        current,
        name,
      });
      if (res?.code === 0 && res?.data) {
        return res.data;
      }
    },
    async businessRateSave({ data }: any) {
      const res: any = await businessRateSave({ data });
      return res;
    },
    async businessRateModify({ data }: any) {
      const res: any = await businessRateModify({ data });
      return res;
    },
    async businessRatedeleteById({ id }: any) {
      const res: any = await businessRatedeleteById({ id });
      return res;
    },
  },
};

export default RateManagement;
