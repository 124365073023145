import { API } from '.';

export const exportList = {
  '/workBench/DriverManagement/DriverList': `${API.basis}/basisDriver/export`,
  '/workBench/CustomerManagement/FormalCustomers': `${API.custom}/custom/export`,
  '/workBench/VehicleManagment/VehicleList': `${API.basis}/basisVehicle/export`,
  '/workBench/Wallet/CustomerWallet/PaymentManagement': `${API.wallet}/walletPayment/export`,
  '/workBench/Wallet/CustomerWallet/RechargeManagement': `${API.wallet}/walletRecharge/export`,
  '/workBench/Wallet/DriverWallet/WithdrawalAudit': `${API.wallet}/walletWithdrawal/export`,
  '/workBench/Wallet/DriverWallet/CapitalFlowManagement': `${API.wallet}/walletFlow/export`,
  '/workBench/WaybillManage/WaybillList': `${API.business}/businessFreightSub/exportFreight`,
  '/workBench/WaybillManage/WaybillList/Pay': `${API.business}/businessFreightSub/exportPayment`,
  '/workBench/WaybillManage/WaybillList/Fuel': `${API.business}/businessFreightSub/exportETC`,
  '/workBench/FinancialRecon/FinancialReconWaybill': `${API.business}/businessFreightSub/exportReconciliation`,
  '/workBench/FinancialRecon/FinancialReconDriver': `${API.business}/businessFreightSub/exportDriverReconciliation`,
  '/workBench/FinancialRecon/FinancialReconPayWaybill': `${API.business}/businessFreightProceeds/exportProceedsReconciliation`
};
