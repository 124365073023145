import { postBasisAllConfigAdd, putBasisAllConfigEdit, deleteBasisAllConfigById } from '@/services/SystemConfiguration/api';
const SystemConfiguration = {
  state: {},
  reducers: {},
  effects: {
    async deleteBasisAllConfigById(payload: any) {
      const { id } = payload;
      return await deleteBasisAllConfigById(id);
    },
    async postBasisAllConfigAdd(payload: any) {
      const { data } = payload;
      return await postBasisAllConfigAdd({ data });
    },
    async putBasisAllConfigEdit(payload: any) {
      const { data } = payload;
      return await putBasisAllConfigEdit({ data });
    }
  }
};
export default SystemConfiguration;
