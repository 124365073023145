import { message } from 'lieyun_design';
import { cloneDeep } from 'lodash';
import { getDataList, updateByIds, putRepealById } from '@/services/Wallet/PaymentManagement';

const PaymentManagement = {
  state: {
    dataSource: [],
    total: 0,
    loading: false
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async getDataList({ data }: any) {
      const { result } = this as any;
      result({
        loading: true
      });
      const datas = cloneDeep(data);
      datas.data.reviewState = Number(data.data.reviewState);
      const res: any = await getDataList(data);

      await result({
        loading: false,
        dataSource: res?.data?.content || [],
        total: res?.data?.totalElements || 0
      });
      return res?.data?.content || [];
    },
    async updateByIds({ data }: any) {
      const res: any = await updateByIds(data);
      if (res.code === 0) {
        message.success('操作成功！');
      }
    },
    async putRepealById(payload: any) {
      const { id } = payload;
      return await putRepealById(id);
    }
  }
};

export default PaymentManagement;
