import { deleteVehicleInfoById, putVehicleInfoUpdata, getVehicleById } from '@/services/VehicleList/api';
const VehicleList = {
  namespace: 'VehicleList',
  state: {},
  reducers: {},
  effects: {
    async deleteVehicleInfoById(payload: any) {
      const { id } = payload;
      return await deleteVehicleInfoById(id);
    },
    async putVehicleInfoUpdata(payload: any) {
      const { data } = payload;
      return await putVehicleInfoUpdata({ data });
    },
    async getVehicleById(payload: any) {
      const { id } = payload;
      return await getVehicleById(id);
    }
  }
};

export default VehicleList;
