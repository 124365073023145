import {
  getFakeCaptchaSafe,
  postBasisAccreditMessageSave,
  postWalletUserGetOne,
  updateById,
  getUserAccount,
  getFakeCaptchaAccount,
  resetAccount
} from '@/services/Wallet/SafeUser';
const SafeUser = {
  namespace: 'SafeUser',
  state: {},
  reducers: {},
  effects: {
    async getFakeCaptchaSafe(payload: any) {
      const { phone } = payload;
      return await getFakeCaptchaSafe(phone);
    },
    async postBasisAccreditMessageSave(payload: any) {
      const { data } = payload;
      return await postBasisAccreditMessageSave({ data });
    },
    async postWalletUserGetOne(payload: any) {
      const { id } = payload;
      return await postWalletUserGetOne(id);
    },
    async updateById(payload: any) {
      const { data } = payload;
      return await updateById(data);
    },
    async getUserAccount() {
      return await getUserAccount();
    },
    async getFakeCaptchaAccount(payload: any) {
      const { phone } = payload;
      return await getFakeCaptchaAccount(phone);
    },
    async resetAccount(payload: any) {
      return await resetAccount(payload);
    }
  }
};

export default SafeUser;
