import { postAddInfo, putEditInfo, postSaveFreight, postUpdateFreight } from '@/services/WaybillTemplateList/api';
import { getRateById, postCustomInfo } from '@/services/common/api';
import { businessRateConfigGetById } from '@/services/RateManagement/api';
import { postDriverInfoList } from '@/services/DriverManagement/api';
import { postVehicleInfoList } from '@/services/VehicleList/api';
const WaybillTemplateListEdit = {
  namespace: 'WaybillTemplateListEdit',
  state: {
    searchParams: {
      current: 1,
      size: 10
    },
    total: 0,
    list: [],
    commonInfo: {}
  },
  reducers: {
    result(state: any, payload: any) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: {
    async postAddInfo(payload: any) {
      const { data } = payload;
      return await postAddInfo({ data });
    },
    async putEditInfo(payload: any) {
      const { data } = payload;
      return await putEditInfo({ data });
    },
    async getCustomInfo(payload: any) {
      const { keyword } = payload;
      const res: any = await postCustomInfo({
        data: {
          name: keyword
        }
      });
      const { data } = res || {};
      return data || [];
    },
    async postDriverInfoList(payload: any) {
      const { name } = payload;
      const res: any = await postDriverInfoList({
        data: {
          name
        }
      });
      const { data } = res || {};
      return data || [];
    },
    async postVehicleInfoList(payload: any) {
      const { name } = payload;
      const res: any = await postVehicleInfoList({
        data: {
          vehicleNo: name,
          vehicleType: '0'
        }
      });
      const { data } = res || {};
      return data || [];
    },
    async getBusinessRateConfigById(payload: any) {
      const { id } = payload;
      const res: any = await businessRateConfigGetById(id);
      return res.data?.rate || {};
    },
    async getRateById() {
      const res: any = await getRateById();
      return res.data || {};
    },
    async postSaveFreight(payload: any) {
      const { data } = payload;
      return await postSaveFreight({ data });
    },
    async postUpdateFreight(payload: any) {
      const { data } = payload;
      return await postUpdateFreight({ data });
    }
  }
};

export default WaybillTemplateListEdit;
