import { save, modify } from '@/services/ServiceCentre/api';

const InsuranceClaimSettlementStore = {
  state: {
    expandedKeys: ['0'],
    editMenuData: {}
  },
  reducers: {
    result(state: any, payload: any) {
      return Object.assign({}, state, payload);
    }
  },
  effects: {
    async save({ data }: any) {
      const res: any = await save({ data });
      return res;
    },
    async modify({ data }: any) {
      const res: any = await modify({ data });
      return res;
    },

  }
};

export default InsuranceClaimSettlementStore;
