import { API } from '@/const';
import request from '@/utils/request';

export const postBasisProblemBaseList = async (data: any) =>
  request(`${API.basis}/basisProblemBase/page`, {
    method: 'post',
    data
  });

export const deleteBasisProblemBaseById = async (id: number) =>
  request(`${API.basis}/basisProblemBase/deleteById/${id}`, {
    method: 'delete'
  });

export const postBasisProblemBaseAdd = async (data: any) =>
  request(`${API.basis}/basisProblemBase/save`, {
    method: 'post',
    data
  });

export const putBasisProblemBaseEdit = async (data: any) =>
  request(`${API.basis}/basisProblemBase/updateById`, {
    method: 'put',
    data
  });

export const getBasisProblemBaseById = async (id: string) =>
  request(`${API.basis}/basisProblemBase/getById/${id}`, {
    method: 'get'
  });
export const getProblemTopTen = async (data: any) =>
  request(`${API.basis}/basisProblemBase/getProblemTopTen`, {
    method: 'post',
    data
  });
  export const getContributorRanking = async (data: any) =>
  request(`${API.basis}/basisProblemBase/getContributorRanking`, {
    method: 'post',
    data
  });